import React, { FC } from 'react';
import { criteriaHasNoStack, isACriteriaStack } from '../../../../core/segment/criteria';

import { SegmentCriteriaFlat } from '../SegmentCriteriaFlat/SegmentCriteriaFlat';
import { SegmentCriteriaBlock } from '../SegmentCriteriaBlock/SegmentCriteriaBlock';
import { OperatorLabel } from '../OperatorLabel';
import { PanelCriteria, PanelCriteriaStack, ResponseInfos, VariableInfos } from 'hawaii';

interface Props {
  criterias: PanelCriteriaStack;
  color: string;
  disabled: boolean;
  removeCriteriaAction: (id: string) => void;
  addCriteriaAction: (criteriaId: string | null, operator: string) => void;
  updateResponsesAction: (criteriaId: string) => void;
  removeResponsesAction: (criteriaId: string, response: ResponseInfos) => void;
  updateZIndexAction: (criteriaId: string, zIndex: number) => void;
  changeCriteriaRuleAction: (criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => void;
  addOperatorButton: boolean;
}

export const SegmentTree: FC<Props> = ({
  criterias,
  color,
  disabled,
  removeCriteriaAction,
  addCriteriaAction,
  updateResponsesAction,
  removeResponsesAction,
  updateZIndexAction,
  changeCriteriaRuleAction,
  addOperatorButton,
}) => {
  const isACriteriaStackContent = (criteria: PanelCriteriaStack<VariableInfos, ResponseInfos>) => {
    return criteriaHasNoStack(criteria) ? (
      <SegmentCriteriaFlat
        criterias={criteria}
        removeCriteriaAction={removeCriteriaAction}
        addCriteriaAction={addCriteriaAction}
        removeResponsesAction={removeResponsesAction}
        updateResponsesAction={updateResponsesAction}
        updateZIndexAction={updateZIndexAction}
        changeCriteriaRuleAction={changeCriteriaRuleAction}
        color={color}
        disabled={disabled}
      />
    ) : (
      <div key={criteria.id} className="indented">
        <SegmentTree
          criterias={criteria}
          removeCriteriaAction={removeCriteriaAction}
          addCriteriaAction={addCriteriaAction}
          removeResponsesAction={removeResponsesAction}
          updateResponsesAction={updateResponsesAction}
          updateZIndexAction={updateZIndexAction}
          changeCriteriaRuleAction={changeCriteriaRuleAction}
          color={color}
          disabled={disabled}
          addOperatorButton={true}
        />
      </div>
    );
  };

  const isNotACriteriaStackContent = (criteria: PanelCriteria<VariableInfos, ResponseInfos>) => {
    return (
      <SegmentCriteriaBlock
        criteria={criteria}
        operatorControl={criterias.operator === 'AND' ? 'OR' : 'AND'}
        removeCriteriaAction={() => {
          removeCriteriaAction(criteria.id);
        }}
        addCriteriaAction={(operator: 'AND' | 'OR') => {
          addCriteriaAction(criteria.id, operator);
        }}
        removeResponsesAction={(response: ResponseInfos) => {
          removeResponsesAction(criteria.id, response);
        }}
        updateResponsesAction={() => updateResponsesAction(criteria.id)}
        changeCriteriaRuleAction={changeCriteriaRuleAction}
        color={color}
        disabled={disabled}
      />
    );
  };

  return (
    <div className="criteria-tree">
      {criterias.criterias.map((criteria: PanelCriteria | PanelCriteriaStack, index) => (
        <React.Fragment key={index}>
          {isACriteriaStack(criteria) ? isACriteriaStackContent(criteria) : isNotACriteriaStackContent(criteria)}
          {index === criterias.criterias.length - 1 || (
            <OperatorLabel
              color={color}
              zIndex={criterias.zIndex}
              maxZIndex={criterias.criterias.length}
              operator={criterias.operator}
              onChange={(zIndex: number) => {
                updateZIndexAction(criterias.id, zIndex);
              }}
            />
          )}
        </React.Fragment>
      ))}
      {addOperatorButton && !disabled && (
        <button
          className="operator"
          // style={{ backgroundColor: color, borderColor: color, marginLeft: '4.5em' }}
          onClick={() => {
            console.log('SegmentTree: ', criterias.operator);
            return addCriteriaAction(criterias.id, criterias.operator);
          }}
        >
          {criterias.operator}...
        </button>
      )}
    </div>
  );
};
