import { FC, ReactElement, useState, useEffect } from 'react';
import FormatedNumber from '../../../Common/FormatedNumber';
import { Serie, SerieTag } from '../../../../core/series/interface';
import { globalTagValues, seriesTranspose } from '../../../../core/series/serie';
import { Collapse } from 'antd';
import _uniqueId from 'lodash/uniqueId';
import { useSelector } from 'react-redux';
import MainTable from './MainTable';
import MediaConsTable from './mediaConsTable';
import { GlobalState } from '../../../store/global';
import { countryMetadatas } from 'hawaii';

interface InestedTableData {
  key: string;
  name: string;
  values: number[][];
  variableName: string;
  color: string;
}

export interface TableSortCriteria {
  section: string | undefined;
  column: string;
  order: 'ASC' | 'DESC';
}

export interface Props {
  series: Serie[];
  sectionGroupTag: string | undefined;
  columnGroupTag: string;
  formatLineLabel: (tags: SerieTag, globalTags: SerieTag, offTitle: boolean) => ReactElement;
  valueFormatter?: (val: number, globalTags: SerieTag, tags: SerieTag) => JSX.Element;
  sectionsSelection: { name: string; checked: boolean }[];
  focusedCountry?: string | null;
}

const FusionTable: FC<Props> = ({
  series,
  sectionGroupTag,
  columnGroupTag,
  formatLineLabel,
  valueFormatter,
  sectionsSelection,
  focusedCountry,
}) => {
  const [nestedTableData, setNestedTableData] = useState<Record<string, InestedTableData[]> | null>(null);
  const mediaConsumption = useSelector((state: GlobalState) => state.mediaConsumption);
  const segments = useSelector((state: GlobalState) => state.segments);
  const [selectedCountry, setSelectedCountry] = useState<string | null>();
  const [dataSet, setDataSet] = useState<{
    values: {
      [key: string]: {
        [key: string]: { [key: string]: number[] };
      };
    };
    segmentNames: { name: string; color: string }[];
  } | null>();

  if (series.length === 0) {
    return <></>;
  }

  const formatValue = (val: number, globalTags: SerieTag, tags: SerieTag): JSX.Element => {
    return typeof valueFormatter === 'function' ? (
      (valueFormatter(val, globalTags, tags) as ReactElement)
    ) : (
      <FormatedNumber value={val} />
    );
  };

  const sectionValues = sectionGroupTag ? (globalTagValues(sectionGroupTag, series) as string[]) : [];
  const columnValues = globalTagValues(columnGroupTag, series) as string[];

  const ordonnedSeries: Serie[] = sectionValues.flatMap((sectionName: string) =>
    columnValues.flatMap((columnName: string) =>
      series.filter(
        (serie: Serie) =>
          serie.globalTags[sectionGroupTag!] === sectionName && serie.globalTags[columnGroupTag] === columnName,
      ),
    ),
  );

  const makeNestedData = (sections: Serie[]) => {
    return sections.reduce((list, item, i) => {
      const arr: InestedTableData[] = [];
      if (typeof item.globalTags.variableName !== 'string') return list;

      const group = item.globalTags.variableName;

      if (!list[group]) {
        list[group] = [];
      }
      item.tags.map((tag, tagIndex) => {
        let obj = arr.find((element: InestedTableData) => element.name === tag.name);
        if (!obj) {
          obj = {
            key: _uniqueId().toString(),
            name: tag.name as string,
            values: [],
            variableName: tag.variableName as string,
            color: tag.color as string,
          };
          arr.push(obj);
        }

        if (obj.values.length - 1 < i) {
          obj.values.push([]);
        }

        for (let j = 0; j < item.values.length; j++) {
          if (j % item.tags.length === tagIndex) {
            if (!obj.values[i]) i = 0;

            obj.values[i].push(item.values[j]);
          }
        }
      }),
        arr.forEach((el) => {
          const isExsists = list[group].find((listElement) => listElement.name === el.name);

          if (isExsists) {
            isExsists.values.push(el.values.flat());
          } else {
            list[group].push(el);
          }
        });

      return list;
    }, {} as Record<string, InestedTableData[]>);
  };

  const sections = seriesTranspose(ordonnedSeries);

  const changeOnAudiences = (newData: Record<string, InestedTableData[]>) => {
    if (!nestedTableData) return setNestedTableData(newData);
    return Object.keys(nestedTableData).map((key) => {
      return (
        newData[key][0].values[0].length !== nestedTableData[key][0].values[0].length ||
        newData[key].length !== nestedTableData[key].length
      );
    });
  };
  useEffect(() => {
    const newData = makeNestedData(sections);

    // const difference = changeOnAudiences(newData);
    // if (!difference || (!difference[0] && !difference[1])) return;
    // console.log('ENTRY ?');

    setNestedTableData(newData);
  }, [series]);

  useEffect(() => {
    if (!mediaConsumption || !focusedCountry) return;
    setDataSet(null);

    if (focusedCountry !== 'Total countries') {
      const country = countryMetadatas.filter((c) => c.name === focusedCountry);
      setSelectedCountry(country[0].id.toString());
    }
    const segmentLabels: { name: string; color: string }[] = [];
    const dataList = segments.reduce(
      (acc, segment) => {
        if (Object.keys(mediaConsumption).includes(segment.id)) {
          segmentLabels.push({ name: segment.name, color: segment.color });

          Object.keys(mediaConsumption[segment.id]).map((key) => {
            if (focusedCountry === 'Total countries') {
              if (!acc['Total countries']) {
                acc['Total countries'] = {};
              }

              mediaConsumption[segment.id][key]
                .slice()
                .sort((a, b) => a.variableId.localeCompare(b.variableId))
                .map((item) => {
                  const name = item.varName + ' - ' + item.variableId;

                  if (!acc['Total countries'][name]) {
                    acc['Total countries'][name] = {};
                  }
                  if (!acc['Total countries'][name][segment.name]) {
                    acc['Total countries'][name][segment.name] = [];
                  }
                  acc['Total countries'][name][segment.name].push(
                    Number(item.respCount.toFixed()),
                    Number(item.Distribution.toFixed()),
                    Number(item.marketSize.toFixed()),
                    Number(item.IndexNum.toFixed()),
                  );
                });
              Object.keys(acc['Total countries']).map((key) => {
                const n = 4;
                acc['Total countries'][key][segment.name] = Array.from({ length: n }, (_, i) =>
                  acc['Total countries'][key][segment.name].reduce(
                    (acc, curr, index) => (index % n === i ? acc + curr : acc),
                    0,
                  ),
                );
              });

              setSelectedCountry(focusedCountry);
            } else {
              if (!acc[key]) {
                acc[key] = {};
              }

              mediaConsumption[segment.id][key]
                .slice()
                .sort((a, b) => a.variableId.localeCompare(b.variableId))
                .map((item) => {
                  const name = item.varName + ' - ' + item.variableId;
                  if (!acc[key][name]) {
                    acc[key][name] = {};
                  }
                  if (!acc[key][name][segment.name]) {
                    acc[key][name][segment.name] = [];
                  }
                  acc[key][name][segment.name].push(
                    Number(item.respCount.toFixed()),
                    Number(item.Distribution.toFixed()),
                    Number(item.marketSize.toFixed()),
                    Number(item.IndexNum.toFixed()),
                  );
                });
            }
          });
        }
        return acc;
      },
      {} as {
        [key: string]: {
          [key: string]: { [key: string]: number[] };
        };
      },
    );

    setDataSet({ values: dataList, segmentNames: segmentLabels });
  }, [mediaConsumption, focusedCountry, series]);

  const defaultActiveKeys = nestedTableData ? Object.keys(nestedTableData).map((_, i) => i.toString()) : '0';
  if (!nestedTableData) return <></>;

  return (
    <>
      {dataSet && dataSet.segmentNames.length > 0 && (
        <MediaConsTable
          data={dataSet}
          focusedCountry={focusedCountry}
          selectedCountry={selectedCountry}
          sectionsSelection={sectionsSelection}
        ></MediaConsTable>
      )}
      <Collapse defaultActiveKey={defaultActiveKeys}>
        {Object.keys(nestedTableData).flatMap((key, i) => {
          return (
            <Collapse.Panel header={key} key={i}>
              <div className="mainTable-container">
                <MainTable
                  ordonnedSeries={ordonnedSeries}
                  sectionGroupTag={sectionGroupTag}
                  sections={sections}
                  nestedTableData={nestedTableData}
                  sectionsSelection={sectionsSelection}
                  nestedTableKey={key}
                  formatValue={formatValue}
                  formatLineLabel={formatLineLabel}
                />
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </>
  );
};

export default FusionTable;
