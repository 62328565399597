import './VariablesHistory.less';
import { Popover } from '../Popover';
import { FC, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import * as actions from '../../store/actions';
import VariableList from './VariableList';

const VariablesHistory: FC = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [popconfirmStatus, setPopconfirmStatus] = useState<boolean>(false);

  const dispatch = useDispatch();
  const variables = useSelector((state: GlobalState) => state.variablesHistory.data);

  const selectedCountry = useSelector((state: GlobalState) => state.secmdmState.countries.selected);

  useEffect(() => {
    if (selectedCountry) dispatch(actions.getVariablesHistoryViaCountry(selectedCountry.iso));
  }, [!variables, selectedCountry]);

  return (
    <div className="variablesHistory-container">
      {variables && (
        <Popover
          placement="bottom-end"
          isOpen={!popconfirmStatus ? open : popconfirmStatus}
          openClose={setOpen}
          render={() => (
            <VariableList
              variables={variables as actions.IVariablesHistory[]}
              setOpen={setOpen}
              setPopconfirmStatus={setPopconfirmStatus}
            />
          )}
        >
          <button className="btn--md" onClick={(e) => e.preventDefault()}>
            Variables history
          </button>
        </Popover>
      )}
    </div>
  );
};

export default VariablesHistory;
