import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HawaiiState } from '../../core/hawaiiState';
import { refreshHawaiiStateThunk } from './thunk/refreshHawaiiState';

const hawaiiStateSlice = createSlice({
  name: 'hawaiiState',
  initialState: {
    stale: true,
    surveyRefreshDate: '',
    responseLevelTableRecords: 0,
    referenceDataTableRecords: 0,
  } as HawaiiState,
  reducers: {
    setHawaiiState(draft: HawaiiState, action: PayloadAction<{ state: HawaiiState }>) {
      return action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshHawaiiStateThunk.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export const { setHawaiiState } = hawaiiStateSlice.actions;

export default hawaiiStateSlice;
