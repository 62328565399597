import { Checkbox, Col, Popover, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FC } from 'react';

interface Props {
  options: string[];
  values: string[];
  selectionAction: (values: string[]) => void;
  title: string;
}

const DropdownCheckbox: FC<Props> = ({ options, values, selectionAction, title }) => {
  const handleChange = (values: CheckboxValueType[]) => {
    selectionAction(values as string[]);
  };

  const groups = options
    .map((element, i) => {
      return i % 10 === 0 ? options.slice(i, i + 10) : null;
    })
    .filter((e) => e);

  const checkboxes = (
    <Checkbox.Group onChange={handleChange} value={values}>
      <Row>
        {groups.map((group, i) => {
          return (
            <Col key={i} span={Math.floor(24 / groups.length)}>
              {!group ||
                group.map((label) => {
                  return (
                    <div key={label}>
                      <Checkbox value={label}>{label}</Checkbox>
                    </div>
                  );
                })}
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );
  return (
    <Popover content={checkboxes} trigger="click" placement="bottomLeft">
      <input type="button" value={title}></input>
    </Popover>
  );
};

export default DropdownCheckbox;
