export const chunkArray = (array: string[], chunkByteSize: number): string[][] => {
  const chunkedArr = [];
  let currentChunk: string[] = [];
  let currentChunkSize = 0;

  array.forEach((item) => {
    const itemSize = 29;
    if (currentChunkSize + itemSize > chunkByteSize) {
      chunkedArr.push(currentChunk);
      currentChunk = [item];
      currentChunkSize = itemSize;
    } else {
      currentChunk.push(item);
      currentChunkSize += itemSize;
    }
  });
  if (currentChunk.length > 0) {
    chunkedArr.push(currentChunk);
  }

  return chunkedArr;
};
