import Color from 'color';

export interface SemanticColors {
  main: string;
  darker1: string;
  darker2: string;
  darker3: string;
  light1: string;
  light2: string;
  light3: string;
}

export const colorTosemanticColors = (color: string | Color): SemanticColors => {
  const c = typeof color === 'string' ? Color(color) : color;

  const lc = 1.2;
  const baseLightness = 35;

  return {
    main: c.lightness(baseLightness).hex(),
    light1: c.lightness(baseLightness + 15 * lc).hex(),
    light2: c.lightness(baseLightness + 30 * lc).hex(),
    light3: c.lightness(baseLightness + 45 * lc).hex(),
    darker1: c.lightness(baseLightness - 5 * lc).hex(),
    darker2: c.lightness(baseLightness - 10 * lc).hex(),
    darker3: c.lightness(baseLightness - 15 * lc).hex(),
  };
};
