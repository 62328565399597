import { PanelCriteria, ResponseInfos } from 'hawaii';
import { FC } from 'react';
import SegmentOperatorIcon from '../SegmentOperatorIcon';
import { HavasIconAddParameter, IconClose, IconRemove } from '../../../Svg/HavasIcons';
import { Tooltip } from 'antd';

interface Props {
  criteria: PanelCriteria;
  operatorControl: 'AND' | 'OR';
  addCriteriaAction: (operator: 'AND' | 'OR') => void;
  updateResponsesAction: () => void;
  removeCriteriaAction: () => void;
  removeResponsesAction: (response: ResponseInfos) => void;
  changeCriteriaRuleAction: (criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => void;
  color: string;
  disabled: boolean;
}
const VariableCard: FC<Props> = ({
  criteria,
  operatorControl,
  removeCriteriaAction,
  addCriteriaAction,
  updateResponsesAction,
  removeResponsesAction,
  changeCriteriaRuleAction,
  color,
  disabled,
}) => {
  return (
    <div className="variable-container">
      <div
        style={{ cursor: 'pointer' }}
        onClick={() =>
          disabled || changeCriteriaRuleAction(criteria.id, criteria.rule === 'INCLUDE' ? 'EXCLUDE' : 'INCLUDE')
        }
      >
        <SegmentOperatorIcon color={color} operator={criteria.rule} />
      </div>
      <Tooltip title={criteria.variable.variable_text}>
        <div className="audience-variable" style={{ borderColor: color }}>
          <div className="upper-side">
            <span className="audience-option-header">{criteria.variable.variable_name}</span>
            <div
              className="throw-container"
              onClick={() => {
                disabled || removeCriteriaAction();
              }}
            >
              <IconRemove className="c-icon-stroke c-icon--20 c-icon--danger" />
            </div>
          </div>

          <div className="choices">
            {criteria.responses.map((response, index) => (
              <div className="audience-option-choice" key={index}>
                <div className="inner-text">{response.label}&nbsp;&nbsp;</div>
                <div className="close-action" onClick={() => disabled || removeResponsesAction(response)}>
                  <IconClose className="c-icon-stroke c-icon--14" />
                </div>
              </div>
            ))}
            <div className="add-container" onClick={() => disabled || updateResponsesAction()}>
              <HavasIconAddParameter className="c-icon-stroke c-icon--20" />
            </div>
          </div>
        </div>
      </Tooltip>

      {disabled || (
        <button className="operator" onClick={() => disabled || addCriteriaAction(operatorControl)}>
          {operatorControl}...
        </button>
      )}
    </div>
  );
};

export default VariableCard;
