import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { getSegmentVariablesNumber } from '../../../core/segment/helper';
import { SelectedVariable } from '../../Common/Context/interfaces/audiences';
import { Serie } from '../../../core/series/interface';
import { GlobalState } from '../global';
import { injectCrossResultsThunk } from './injectCrossResults';
import { DataScope, SegmentWithUiMeta } from 'hawaii';

/**
 * Retrieve cross population between segment and single variable
 *
 * @param store
 * @param variableDetailsService
 * @param variableId
 * @param countryId
 * @param segment
 * @param owner
 */
export const retrieveCrossResultsThunk = createAsyncThunk<
  void,
  {
    variable: SelectedVariable;
    countryId: number;
    segment: SegmentWithUiMeta;
    dataScope: DataScope;
  },
  { state: GlobalState; extra: { factory: FactoryInterface } }
>(
  'series/retrieve',

  async ({ variable, countryId, segment, dataScope }, { getState, dispatch, extra }): Promise<void> => {
    const segmentService = extra.factory.getSegmentService();
    // no variable in the segment ?
    if (getSegmentVariablesNumber(segment) === 0) {
      return;
    }

    const variableDetails = getState().variablesDetails.filter(
      (vdSerie: Serie) => vdSerie.globalTags.variableId === variable.variable.variable_id,
    )[0];

    const [unweightedSeries, weightedCountSerie] = await segmentService.retrieveCrossResults(
      countryId,
      segment,
      variable,
      dataScope,
      variableDetails,
    );

    await dispatch(injectCrossResultsThunk({ serie: unweightedSeries, segmentId: segment.id }));
    await dispatch(injectCrossResultsThunk({ serie: weightedCountSerie, segmentId: segment.id }));
  },
);
