import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getActivateStudioState } from './thunk/amdm/getActivateStudioState';

export interface ActivateStudioProvider {
  id: string;
  provider: 'AUDIENCE_STUDIO' | 'META';
  audienceRepresentationId?: any;
  statusActivateStudio: 'MATCHING' | 'MATCHED' | 'READY' | 'PUSHED' | 'ACTIVATED';
  algorithmData?: string;
  algorithmDataSelection?: string;
  audienceNameSelected?: null;
  reach?: null;
  reachFacebook?: null;
  reachInstagram?: null;
  creationDate: number;
  lastModificationDate: number;
  ownerUpn?: null;
  audienceNameInAMDM: string;
  countryId: number;
  adSetId?: null;
  accountId?: null;
  micsId?: null;
}

export interface AMDMActivateStudioState {
  error?: any;
  data: Array<ActivateStudioProvider>;
  actualPage: number;
  firstResult: number;
  totalResults: number;
  totalPages: number;
  enableMatching: boolean;
}

export interface DataBricksMatching {
  actualPage: number;
  data: any;
  error?: any;
  firstResult: number;
  totalPages: number;
  totalResults: number;
}

export interface ActivateStudioState {
  activateState?: AMDMActivateStudioState | null;
}

export const initialState: ActivateStudioState = {
  activateState: null,
};

const activateStudioSlice = createSlice({
  name: 'ActivateStudio',
  initialState,
  reducers: {
    setActivateStudioState: (state, action: PayloadAction<ActivateStudioState['activateState']>) => {
      state.activateState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivateStudioState.fulfilled, (state, action) => {
      state.activateState = action.payload;
    });
  },
});

export const { setActivateStudioState } = activateStudioSlice.actions;

export default activateStudioSlice;
