import React, { FC } from 'react';
import { SegmentCriteriaBlock } from './clusterCriteriaBlockComponent';
import { PanelCriteria, PanelCriteriaStack, ResponseInfos } from 'hawaii';

interface Props {
  criterias: PanelCriteriaStack;
  color: string;
  disabled: boolean;
  removeCriteriaAction: (id: string) => void;
  addCriteriaAction: (criteriaId: string | null, operator: string) => void;
  updateResponsesAction: (criteriaId: string) => void;
  removeResponsesAction: (criteriaId: string, response: ResponseInfos) => void;
  updateZIndexAction: (criteriaId: string, zIndex: number) => void;
  changeCriteriaRuleAction: (criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => void;
  addOperatorButton: boolean;
}

export const ClusterTree: FC<Props> = ({
  criterias,
  color,
  disabled,
  removeCriteriaAction,
  addCriteriaAction,
  updateResponsesAction,
  removeResponsesAction,
  changeCriteriaRuleAction,
  addOperatorButton,
}) => {
  return (
    <div className="criteria-tree">
      {criterias.criterias.map((criteria: PanelCriteria | PanelCriteriaStack, index) => (
        <React.Fragment key={index}>
          <SegmentCriteriaBlock
            criteria={criteria as PanelCriteria}
            operatorControl={criterias.operator === 'AND' ? 'OR' : 'AND'}
            removeCriteriaAction={() => {
              removeCriteriaAction(criteria.id);
            }}
            addCriteriaAction={(operator: string) => {
              addCriteriaAction(criteria.id, operator);
            }}
            removeResponsesAction={(response: ResponseInfos) => {
              removeResponsesAction(criteria.id, response);
            }}
            updateResponsesAction={() => updateResponsesAction(criteria.id)}
            changeCriteriaRuleAction={changeCriteriaRuleAction}
            color={color}
            disabled={false}
          />
        </React.Fragment>
      ))}
      {addOperatorButton && !disabled && (
        <button
          className="operator"
          style={{ backgroundColor: color, borderColor: color, marginLeft: '4.5em' }}
          onClick={() => addCriteriaAction(criterias.id, criterias.operator)}
        >
          {criterias.operator}...
        </button>
      )}
    </div>
  );
};
