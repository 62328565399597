import { FC } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import './Load.less';

const Load: FC<React.ComponentPropsWithoutRef<'p'>> = (props) => (
  <div className="load hawaii-rotating" {...props}>
    <AiOutlineLoading />
  </div>
);

export default Load;
