import BackendFacadeFactory from './backendFacade';
import ResponseLevelServiceFactory, { ResponseLevelServiceInterface } from './requests/responseLevel';
import VariablesSelectionServiceFactory, { VariablesSelectionServiceInterface } from './variablesSelection';
import { config } from './config';
import SharedSegmentServiceFactory, { SharedSegmentServiceInterface } from './sharedSegment';
import CategoryServiceFactory, { CategoryServiceInterface } from './category';
import VariableDetailsServiceFactory, { VariableDetailsServiceInterface } from './variableDetails';
import HawaiiStateServiceFactory, { HawaiiStateServiceInterface } from './hawaiiState';
import SegmentServiceFactory, { SegmentServiceInterface } from './segment/service';
import ActivationServiceFactory, { ActivationServiceInterface } from './activation/activationService';
import AmdmServiceFactory, { AmdmServiceInterface } from './amdm';
import SecmdmServiceFactory, { SecmdmServiceInterface } from './secmdm';

export interface FactoryInterface {
  getResponseLevelService: () => ResponseLevelServiceInterface;
  getVariableSelectionService: () => VariablesSelectionServiceInterface;
  getSegmentService: () => SegmentServiceInterface;
  getSharedSegmentService: () => SharedSegmentServiceInterface;
  getAmdmService: () => AmdmServiceInterface;
  getCategoryService: () => CategoryServiceInterface;
  getVariableDetailsService: () => VariableDetailsServiceInterface;
  getHawaiiStateService: () => HawaiiStateServiceInterface;
  getActivationService: () => ActivationServiceInterface;
  getSecmdmService: () => SecmdmServiceInterface;
}

const Factory = (): FactoryInterface => {
  const backendFacadeFactory = BackendFacadeFactory(config.api.baseUrl as string);
  const responseLevelServiceFactory = ResponseLevelServiceFactory(backendFacadeFactory);
  const variableSelectionServiceFactory = VariablesSelectionServiceFactory(backendFacadeFactory);
  const segmentServiceFactory = SegmentServiceFactory(backendFacadeFactory);
  const sharedSegmentServiceFactory = SharedSegmentServiceFactory(backendFacadeFactory);
  const amdmServiceFactory = AmdmServiceFactory(backendFacadeFactory);
  const categoryServiceFactory = CategoryServiceFactory(backendFacadeFactory);
  const variableDetailsServiceFactory = VariableDetailsServiceFactory(backendFacadeFactory);
  const hawaiiStateServiceFactory = HawaiiStateServiceFactory(backendFacadeFactory);
  const activationServiceFactory = ActivationServiceFactory(backendFacadeFactory);
  const secmdmServiceFactory = SecmdmServiceFactory(backendFacadeFactory);

  return {
    getResponseLevelService: (): ResponseLevelServiceInterface => {
      return responseLevelServiceFactory.get();
    },
    getVariableSelectionService: (): VariablesSelectionServiceInterface => {
      return variableSelectionServiceFactory.get();
    },
    getSegmentService: (): SegmentServiceInterface => {
      return segmentServiceFactory.get();
    },
    getSharedSegmentService: (): SharedSegmentServiceInterface => {
      return sharedSegmentServiceFactory.get();
    },
    getAmdmService: (): AmdmServiceInterface => {
      return amdmServiceFactory.get();
    },
    getCategoryService: (): CategoryServiceInterface => {
      return categoryServiceFactory.get();
    },
    getVariableDetailsService: (): VariableDetailsServiceInterface => {
      return variableDetailsServiceFactory.get();
    },
    getHawaiiStateService: (): HawaiiStateServiceInterface => {
      return hawaiiStateServiceFactory.get();
    },
    getActivationService: (): ActivationServiceInterface => {
      return activationServiceFactory.get();
    },
    getSecmdmService() {
      return secmdmServiceFactory.get();
    },
  };
};

export const factory = Factory();
export default Factory;
