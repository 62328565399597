import { useDispatch } from 'react-redux';
import { setLanguage } from '../../store/actions';
import { CountryMetadata } from 'hawaii';

export const useSetLanguage = () => {
  const dispatch = useDispatch();

  const handleSetLanguage = (value: string, meta: CountryMetadata) => {
    dispatch(setLanguage({ value, meta }));
  };

  return handleSetLanguage;
};
