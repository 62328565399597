import './split-charts.less';
import { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HcMore from 'highcharts/highcharts-more';

// Activate highcharts polar graph features
HcMore(Highcharts);

type PieChartData = {
  name: string;
  y: string;
  respondents: string;
  color: string;
};

interface Props {
  clusters: PieChartData[];
  marketSize: { [key: string]: number };
}

const PieChart: FC<Props> = ({ clusters, marketSize }) => {
  // const Hcolors = clusters
  //   .map((cluster) => cluster.color)
  //   .flatMap((color) => {
  //     return {
  //       radialGradient: {
  //         cx: 0.5,
  //         cy: 0.3,
  //         r: 0.7,
  //       },
  //       stops: [
  //         [0, color],
  //         [1, Highcharts.color(color).brighten(-0.3).get('rgb')],
  //       ],
  //     };
  //   }) as any[];

  // Highcharts.setOptions({ colors: Hcolors });

  const options = {
    chart: {
      height: 360,
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      type: 'pie',
      spacingRight: 250,
      spacingLeft: 150,
      options3d: {
        enabled: true,
        alpha: 25,
        beta: 0,
        depth: 50,
      },
    },
    style: {
      fontFamily: 'Baikal Regular, sans-serif',
    },
    title: {
      text: 'Cluster Analysis',
      align: 'center',
      style: {
        fontSize: '24px',
      },
    },
    tooltip: {
      pointFormat:
        '{series.name}: <b>{point.percentage:.0f}%</b><br>Respondents: {point.respondents} <br>Market: {point.marketSize} ',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        borderColor: '#ffffff',
        depth: 50,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format:
            '<b>{point.name}</b>:  {point.percentage:.0f} % <br>Respondents: {point.respondents}  <br>Market: {point.marketSize}',
          connectorColor: 'silver',
          style: {
            fontSize: '12px',
            color: '#333',
          },
        },
        showInLegend: true,
        events: {
          legendItemClick: function () {
            return (this as unknown as Highcharts.Point).series.update({
              type: 'pie',
            });
          },
        },
        colors: ['#00DCB9', '#785AFA', '#328CF5', '#F055FA', '#FFDC32'],
      },
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
      itemStyle: {
        fontWeight: 'bold',
        fontSize: '14px',
      },
    },
    series: [
      {
        name: 'Weighted Proportion',
        data: clusters.map((item, index) => {
          const value = Number(item.y);
          return {
            name: item.name,
            y: value,
            marketSize: Object.values(marketSize)[index]?.toLocaleString() || 0,
            respondents: item.respondents?.toLocaleString() || 0,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            // color: Hcolors[index],
          };
        }),
      },
    ],
  };

  return (
    <div className="pie-container" style={{ width: '100%', height: '500', marginBottom: '50px' }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { width: '100%', height: '90%' } }}
      />
    </div>
  );
};

export default PieChart;
