import { ActivationCustomSettings } from 'hawaii/dist/activation';
import { ActivationProcess } from '../../react/store/activationProcessSlice';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from '../backendFacade';

export interface ActivationServiceInterface {
  abort: () => void;
  getCurrentProcess: () => Promise<ActivationProcess[]>;
  reachCalculation: (
    activationProcessId: string,
    settings: ActivationCustomSettings,
    provider: string,
    destination: string,
  ) => Promise<number>;
}

const ActivationService = (backend: BackendFacadeInterface): ActivationServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    getCurrentProcess: async (): Promise<ActivationProcess[]> => {
      return await backend.post('/v1/segment-activation-process/all', {});
    },

    reachCalculation: async (
      activationProcessId: string,
      settings: ActivationCustomSettings,
      provider: string,
      destination: string,
    ): Promise<number> => {
      const body = {
        activationProcessId,
        settings,
        provider,
        destination,
      };
      const result = await backend.post<{ reach: number }>('/v1/segment-activation-process/calculate-reach', body);
      return result.reach;
    },
  };
};

export interface ActivationServiceFactoryInterface {
  get: () => ActivationServiceInterface;
}

const ActivationServiceFactory = (BackendFacadeFactory: BackendFacadeFactoryInterface) => ({
  get: () => ActivationService(BackendFacadeFactory.get()),
});

export default ActivationServiceFactory;
