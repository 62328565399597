import { FC } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import './OrderedCheckbox.less';

export interface Props {
  values: { name: string; checked: boolean }[];
  changeValueAction: (newSelectionValue: { name: string; checked: boolean }[], name: string, checked: boolean) => void;
  moveValueAction: (
    newSelectionValue: { name: string; checked: boolean }[],
    name: string,
    direction: 'left' | 'right',
  ) => void;
}

const OrderedCheckbox: FC<Props> = ({ values, changeValueAction, moveValueAction }) => {
  const handleChange = (name: string) => {
    const index = values.findIndex((couple) => couple.name == name);
    const newSelectionValue = [...values];
    const checked = !values.filter((couple) => couple.name === name)[0].checked;

    newSelectionValue[index] = { name, checked };

    changeValueAction(newSelectionValue, name, checked);
  };

  const handleMoveClick = (index: number, direction: 'left' | 'right') => {
    const newSelectionValue = [...values];

    if (direction === 'right') {
      const a = newSelectionValue[index];
      newSelectionValue[index] = newSelectionValue[index + 1];
      newSelectionValue[index + 1] = a;
    } else {
      const a = newSelectionValue[index];
      newSelectionValue[index] = newSelectionValue[index - 1];
      newSelectionValue[index - 1] = a;
    }
    moveValueAction(newSelectionValue, values[index].name, direction);
  };

  return (
    <div className="ordered-checkbox">
      {values.map(({ name, checked }, index) => (
        <div key={index} className="hawaii-item item">
          {index !== 0 && (
            <div className="order-control left" onClick={() => handleMoveClick(index, 'left')}>
              <MdArrowBackIosNew />
            </div>
          )}
          <input className="form-check-input" type="checkbox" checked={checked} onChange={() => handleChange(name)} />
          <span>{name}</span>
          {index !== values.length - 1 && (
            <div className="order-control right" onClick={() => handleMoveClick(index, 'right')}>
              <MdArrowForwardIos />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OrderedCheckbox;
