import { useRef, useEffect, useState } from 'react';
import { SegmentJobState } from '../../modules/common/interface/interface';

interface IRemainingTime {
  [key: string]: {
    countDownTime: string;
    module: string;
    startTime: number;
    status: string;
  };
}

interface IDatabricks {
  [module: string]: {
    [segmentID: string]: SegmentJobState;
  };
}

export const useDataToCountDown = () => {
  const [currentRunsTime, setCurrentRunsTime] = useState<IRemainingTime | undefined>({});

  const getCurrentRunsTime = (databricks: IDatabricks) => {
    const modulesKeys = Object.keys(databricks);
    modulesKeys?.forEach((module: string) => {
      const runningJobs = Object.keys(databricks[module]);

      runningJobs.forEach((runningID) => {
        if (
          !databricks?.[module]?.[runningID]?.jobEstTime?.upper_bound ||
          !databricks?.[module]?.[runningID]?.jobTimeStart
        )
          return;

        setCurrentRunsTime((prevTime) => ({
          ...prevTime,
          [runningID]: {
            countDownTime: databricks?.[module]?.[runningID]?.jobEstTime?.upper_bound,
            startTime: databricks?.[module]?.[runningID]?.jobTimeStart,
            module: module,
            status: databricks?.[module]?.[runningID]?.jobStatus,
          },
        }));
      });
    });
  };

  return { currentRunsTime, getCurrentRunsTime };
};

export const useCountDown = (
  startTime: number | undefined,
  timeToLeft: string | undefined,
  status: string | undefined,
) => {
  if (!startTime || !timeToLeft) return undefined;
  const [timeLeft, setTimeLeft] = useState(
    status !== 'RUNNING' ? status : formatTime(timeDifferenceCounter(startTime, timeToLeft)),
  );
  const intervalRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (status && status !== 'RUNNING') {
      setTimeLeft(status);
      return;
    }

    intervalRef.current = window.setInterval(() => {
      const currentTime = formatTime(timeDifferenceCounter(startTime, timeToLeft));

      if (currentTime === '00:00:00' || status !== 'RUNNING') {
        if (!status) return;
        window.clearInterval(intervalRef.current);
        intervalRef.current = undefined;
        setTimeLeft(status !== 'RUNNING' ? status : currentTime);
      } else {
        setTimeLeft(currentTime);
      }
    }, 1000) as unknown as number;

    return () => {
      if (typeof intervalRef.current === 'number') {
        window.clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [startTime, timeToLeft, status]);
  return timeLeft;
};

export const usePercentageBar = (
  startTime: number | undefined,
  timeToLeft: string | undefined,
  status: string | undefined,
) => {
  if (!startTime || !timeToLeft) return undefined;
  const [percentage, setPercentage] = useState<number>(
    status !== 'RUNNING' ? 100 : timeLeftInPercentage(startTime, timeToLeft),
  );
  const intervalRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (status !== 'RUNNING') {
      setPercentage(100);
      return;
    }

    intervalRef.current = window.setInterval(() => {
      const percentage = timeLeftInPercentage(startTime, timeToLeft);

      if (percentage === 100 || status !== 'RUNNING') {
        if (typeof intervalRef.current === 'number') {
          window.clearInterval(intervalRef.current);
          intervalRef.current = undefined;
          setPercentage(status !== 'RUNNING' ? 100 : percentage);
        }
      }
      setPercentage(status !== 'RUNNING' ? 100 : percentage);
    }, 1000) as unknown as number;
    return () => {
      if (typeof intervalRef.current === 'number') {
        window.clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
    };
  }, [startTime, timeToLeft, status]);
  return percentage;
};

const timeLeftInPercentage = (startTime: number, timeToLeft: string) => {
  const currentTimeInSeconds = getCurrentTimeInSeconds();
  const boundTimeInSeconds = timeToSecondsConverter(timeToLeft);
  const elapsed = Math.max(0, currentTimeInSeconds - startTime);
  const percentage = Math.min(100, (elapsed / boundTimeInSeconds) * 100);
  return percentage === 0 ? 0.1 : percentage;
};

const timeDifferenceCounter = (timeStart: number, upperBoundTime: string) => {
  const currentTimeInSeconds = getCurrentTimeInSeconds();
  const startTimeInSeconds = timeStart;
  const boundTimeInSeconds = timeToSecondsConverter(upperBoundTime);
  const elapsedTime = currentTimeInSeconds - startTimeInSeconds;
  const remainingTime = boundTimeInSeconds - elapsedTime;
  return Math.max(remainingTime, 0);
};

const timeToSecondsConverter = (time: string) => {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const getCurrentTimeInSeconds = () => {
  const now = new Date();
  return now.getHours() * 3600 + now.getMinutes() * 60 + now.getSeconds();
};

const formatTime = (seconds: number) =>
  `${Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0')}:${Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;
