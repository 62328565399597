import './AutoCompleteBar.less';
import { FC, ReactElement } from 'react';
import { AutoComplete, Input } from 'antd';

export interface Props {
  selectedValue: string;
  icon: ReactElement;
  options: object[];
  handleAutoCompleteAction: (value: string) => void;
}

interface IoptionType {
  value: string;
}

const AutocompleteBar: FC<Props> = ({ selectedValue, icon, options, handleAutoCompleteAction }): ReactElement => {
  const handleSearch = (v: string) => handleAutoCompleteAction(v);
  const handleSelect = (v: string) => handleAutoCompleteAction(v);
  const handleOnClear = () => handleAutoCompleteAction('');

  return (
    <div className="autoComplete-container">
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: '100%' }}
        options={options}
        allowClear={true}
        onClear={() => handleOnClear()}
        filterOption={(inputValue, option) =>
          (option! as IoptionType).value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        onSelect={(v: string) => handleSelect(v)}
        onSearch={(v: string) => handleSearch(v)}
      >
        <Input size="large" placeholder="Search" value={selectedValue} suffix={icon} />
      </AutoComplete>
    </div>
  );
};

export default AutocompleteBar;
