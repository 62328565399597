import './searchBar.less';
import { FC, useState } from 'react';
import InputComponent from './input';

interface Props {
  handleSearch: (prompt: string) => void;
  defaultValue: string;
}

const SearchBar: FC<Props> = ({ defaultValue, handleSearch }) => {
  const [inputVal, setInputVal] = useState<string>(defaultValue);

  return (
    <div className="searchBar-container">
      <InputComponent defaultValue={inputVal} setInputVal={setInputVal} />
      <div className="btn--sm btn--full sb" onClick={() => handleSearch(inputVal)}>
        <span>Build Audience</span>
      </div>
    </div>
  );
};

export default SearchBar;
