import { VariableResponsesInfos } from './interfaces/referenceData';
import { getCountryIdFromIso } from './helpers/country';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';
import { DataScope, ResponseInfos, VariableInfos } from 'hawaii';

export interface VariablesSelectionServiceInterface {
  abort: () => void;
  getVariablesFromCategories: (
    selectedCategories: string[],
    countries: string[],
    languageCode: number,
    dataScope: DataScope,
  ) => Promise<VariableInfos[]>;
  getVariablesFromInput: (
    regex: string,
    countries: string[],
    languageCode: number,
    dataScope: DataScope,
  ) => Promise<VariableInfos[]>;
  getVariableResponses: (
    variableId: string,
    isparent: 0 | 1,
    countries: string[],
    languageCode: number,
    dataScope: DataScope,
  ) => Promise<ResponseInfos[]>;
}

const VariableSelectionService = (backend: BackendFacadeInterface): VariablesSelectionServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    getVariablesFromCategories: async (
      selectedCategories: string[],
      countries: string[],
      languageCode: number,
      datascope: DataScope,
    ): Promise<VariableInfos[]> => {
      const countryCodes = countries.map(getCountryIdFromIso);
      if (selectedCategories.length > 0) {
        return backend.post<VariableInfos[]>('/v1/variables/from_categories', {
          categoryPath: selectedCategories,
          countryCodes,
          languageCode,
          datascope,
        });
      }
      return [];
    },

    getVariablesFromInput: async (
      input: string,
      countries: string[],
      languageCode: number,
      datascope: DataScope,
    ): Promise<VariableInfos[]> => {
      const countryCodes = countries.map(getCountryIdFromIso);
      return backend.post<VariableInfos[]>('/v1/variables/from_input', {
        input,
        countryCodes,
        languageCode,
        datascope,
      });
    },

    getVariableResponses: async (
      variableId: string,
      isparent: 0 | 1,
      countries: string[],
      languageCode: number,
      datascope: DataScope,
    ): Promise<ResponseInfos[]> => {
      const countryCodes = countries.map(getCountryIdFromIso);
      const responses = await backend.post<VariableResponsesInfos[]>('/v1/variables/responses', {
        variableId,
        countryCodes,
        isparent,
        languageCode,
        datascope,
      });

      return responses.map((res) => {
        return {
          label: res.response_text,
          value: res.response_id,
          variableId: res.variable_id,
          parentResponseId: res.parent_response_id,
        };
      });
    },
  };
};

export interface VariablesSelectionServiceFactoryInterface {
  get: () => VariablesSelectionServiceInterface;
}

const VariablesSelectionServiceFactory = (
  backendFactory: BackendFacadeFactoryInterface,
): VariablesSelectionServiceFactoryInterface => ({
  get: () => VariableSelectionService(backendFactory.get()),
});

export default VariablesSelectionServiceFactory;
