import { Configuration, PopupRequest } from '@azure/msal-browser';
import { config } from './core/config';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export const msalConfig: Configuration = {
  auth: {
    clientId: config.auth.clientId,
    authority: config.auth.authority,
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage', //keeps login token across sites
    storeAuthStateInCookie: true, //stores the auth state in your cache
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
