import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { DataScope, ResponseInfos, VariableInfos, SegmentWithUiMeta } from 'hawaii';
import { FactoryInterface } from '../../../core/factory';
import { GlobalState } from '../global';
import { addVariableDetails } from '../variablesDetailsSlice';
import { retrieveCrossResultsThunk } from './retrieveCrossResults';
import { setAppIsLoadingState } from '../appIsLoadingSlice';

/**
 * If not already existing, retrieve details about a specific variable
 *
 * @param store
 * @param countryCode
 * @param selectedVariable
 * @param selectedResponses
 * @param responsePanel
 * @param variableDetailsService
 * @param owner
 * @returns
 */
export const addVariableDetailsThunk = createAsyncThunk<
  void,
  {
    countryId: number;
    selectedVariable: VariableInfos;
    responsePanel: ResponseInfos[];
    dataScope: DataScope;
    selectedResponses?: ResponseInfos[];
  },
  { state: GlobalState; extra: { factory: FactoryInterface } }
>(
  'variableDetails/retrieve',

  async (
    { countryId, selectedVariable, responsePanel, dataScope, selectedResponses },
    { getState, dispatch, extra },
  ): Promise<void> => {
    const variableDetailsService = extra.factory.getVariableDetailsService();

    const existingVariable = getState().variablesDetails.find(
      (variable) =>
        variable.globalTags.variableId === selectedVariable.variable_id &&
        variable.globalTags.countryCode === countryId,
    );

    if (existingVariable === undefined) {
      const variableDetails = await variableDetailsService.retrieveCountrySeries(
        countryId,
        selectedVariable,
        responsePanel,
        dataScope,
        selectedResponses,
      );
      const countryTotal = variableDetails.globalTags.countryTotal as string;
      const valuesLength = variableDetails.values.length;
      if (!countryTotal || valuesLength < 1) {
        void message.error(`Unusable variable : lack of results`, 3);
        return;
      }

      dispatch(addVariableDetails({ variableDetails }));
    }

    const segments = getState().segments;
    await Promise.all(
      segments.map((segment: SegmentWithUiMeta) =>
        Promise.resolve(
          dispatch(
            retrieveCrossResultsThunk({
              // @TODO : remove the SelectedVariable type ...
              variable: {
                variable: selectedVariable,
                responses: selectedResponses || responsePanel,
                variableUse: 'INCLUDE',
              },
              countryId,
              segment,
              dataScope,
            }),
          ),
        ),
      ),
    );

    dispatch(setAppIsLoadingState({ state: false }));
  },
);
