import { ActivationCustomSettings, SegmentWithUiMeta } from 'hawaii';
import { createContext } from 'react';
import { ActivationProcess } from '../../store/activationProcessSlice';

export type SegmentStage = 'Definition' | 'Matching' | 'Smart Builder';
export type SmartSegmentStage = 'Smart Builder' | 'Definition';

export interface SegmentUJContext {
  segment: SegmentWithUiMeta;
  stages: SegmentStage[];
  currentStage: SegmentStage;
  selectedStage: SegmentStage | SmartSegmentStage;
  setSelectedStage: (value: SegmentStage) => void;
  handleActivateStudioDataBricksMatch: () => void;
  handleBreadCrumbSelection: (state: SegmentStage) => void;
  enableExport: boolean;
  setEnableExport: (value: boolean) => void;
  setMatchingModalOpen: (value: boolean) => void;
  matchingModalOpen: boolean;
  selectedResultAudienceName: string | undefined;
  setSelectedResultAudienceName: (value: string | undefined) => void;
  matchingOptionName: string | undefined;
  setMatchingOptionName: (value: string | undefined) => void;
}

const DefaultValues = {
  segment: {} as SegmentWithUiMeta,
  activationProcess: {} as ActivationProcess,
  stages: [] as SegmentStage[],
  currentStage: 'Definition' as SegmentStage,
  selectedStage: 'Definition' as SegmentStage,
  setSelectedStage: () => void 0,
  pathLength: 1,
  handleActivateStudioDataBricksMatch: () => 0,
  handleBreadCrumbSelection: () => 0,
  enableExport: false,
  setEnableExport: () => void 0,
  setMatchingModalOpen: () => void 0,
  matchingModalOpen: false,
  activationModalOpen: false,
  setActivationModalOpen: () => void 0,
  customSettings: {},
  setCustomSettings: () => void 0,
  emptySelection: {},
  selectedResultAudienceName: undefined,
  setSelectedResultAudienceName: () => void 0,
  customSettingsModified: false,
  setCustomSettingsModified: () => void 0,
  matchingOptionName: undefined,
  setMatchingOptionName: () => void 0,
};

const UJContext = createContext<SegmentUJContext>(DefaultValues);

export default UJContext;
