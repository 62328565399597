import './Table.less';
import { FC } from 'react';
import { Serie } from '../../../../core/series/interface';
import { Tabs } from '../../../interface';
import { CountryMetadata } from 'hawaii';
import ResultsTable from './ResultsTable';

interface Props {
  graphType: Tabs;
  series: Serie[];
  country: CountryMetadata;
}

const Table: FC<Props> = ({ graphType, series, country }) => {
  return (
    <div className="table-container">
      <ResultsTable graphType={graphType} series={series} country={country} />
    </div>
  );
};

export default Table;
