import { Button, Modal, Typography } from 'antd';
import { SegmentWithUiMeta } from 'hawaii';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import AceEditor from 'react-ace';
import { setAmdmTargetedAudience, setGetAudienceModalOpen } from '../../../store/amdmSlice';

const { Title, Text } = Typography;

const GetAudienceModal = ({ segment }: { segment: SegmentWithUiMeta }) => {
  const dispatch = useDispatch();

  const { audiences } = useSelector((state: GlobalState) => state.amdmState);

  const handleCloseGetModal = () => {
    dispatch(setGetAudienceModalOpen(false));
    dispatch(setAmdmTargetedAudience({}));
  };

  const getAudienceModal = () => {
    if (Object.keys(audiences.targetedAudience).length > 0) {
      return (
        <Modal
          title={
            <div>
              <Title level={5} style={{ color: `${segment.color}` }}>
                {segment.name}
              </Title>
              <Text italic type="secondary">
                Most recent record from AMDM
              </Text>
            </div>
          }
          open={audiences.getModalIsOpen}
          closable={false}
          centered
          width={1000}
          mask
          footer={[
            <Button key="close" type="primary" onClick={handleCloseGetModal}>
              Close
            </Button>,
          ]}
        >
          <AceEditor
            className="amdm-audience-display"
            mode={'json'}
            value={JSON.stringify(audiences.targetedAudience, null, '\t')}
            theme="github"
            name={'Content'}
            width={'100%'}
            showPrintMargin={false}
            readOnly={true}
          />
        </Modal>
      );
    }

    return <div></div>;
  };

  return getAudienceModal();
};

export default GetAudienceModal;
