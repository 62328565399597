import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';

export interface HawaiiState {
  stale?: boolean;
  surveyRefreshDate: string;
  responseLevelTableRecords: number;
  referenceDataTableRecords: number;
}

export interface HawaiiStateServiceInterface {
  retrieve: () => Promise<HawaiiState>;
}

const HawaiiStateService = (backend: BackendFacadeInterface): HawaiiStateServiceInterface => {
  return {
    retrieve: async (): Promise<HawaiiState> => {
      return await backend.get<HawaiiState>('/state');
    },
  };
};

export interface HawaiiStateServiceFactoryInterface {
  get: () => HawaiiStateServiceInterface;
}

const HawaiiStateServiceFactory = (
  backendFacadeFactory: BackendFacadeFactoryInterface,
): HawaiiStateServiceFactoryInterface => ({
  get: () => HawaiiStateService(backendFacadeFactory.get()),
});

export default HawaiiStateServiceFactory;
