import Color from 'color';
import { FC, CSSProperties } from 'react';
import './ButtonIcon.less';

export interface Props extends React.ComponentPropsWithoutRef<'button'> {
  label: string;
  color?: string;
  icon?: JSX.Element;
}

const ButtonIcon: FC<Props> = (props) => {
  const extendedStyle = {} as CSSProperties;

  const { className, ...otherProps } = props;

  if (props.color) {
    extendedStyle.backgroundColor = props.color;
    extendedStyle.border = props.color;
    if (props.disabled) {
      const c = Color(props.color);
      const light = c.lightness(85).hex();
      const dark = c.darken(75).hex();
      extendedStyle.color = dark;
      extendedStyle.textShadow = `1px 1px 1px ${light}`;
    }
  }

  const buttonProps = { style: extendedStyle, ...otherProps };
  return (
    <button className={`button-icon ${className !== undefined ? className : ''}`} {...buttonProps}>
      {props.icon ? (
        <span style={{ ...extendedStyle, fontSize: '1.6em', marginRight: '0.2em', lineHeight: '0%' }}>
          {props.icon}
        </span>
      ) : null}
      <span>{props.label}</span>
    </button>
  );
};

export default ButtonIcon;
