import { DataScope } from 'hawaii';
import { FirstLevelCategory } from '../react/Common/VariableSelector/CategoriesSelector';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';
import { getCountryIdFromIso } from './helpers/country';

export interface CategoryServiceInterface {
  abort: () => void;
  get: (
    path: string[],
    countries: string[],
    languageCode: number,
    dataScope: DataScope,
  ) => Promise<FirstLevelCategory[]>;
}

const CategoryService = (backend: BackendFacadeInterface): CategoryServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    /**
     * Retreve categories at given path
     * @param audience
     */
    get: async (
      path: string[],
      countries: string[],
      languageCode: number,
      datascope: DataScope,
    ): Promise<FirstLevelCategory[]> => {
      const body = {
        path,
        countryCodes: countries.map(getCountryIdFromIso),
        languageCode,
        datascope,
      };
      const raw = await backend.post<FirstLevelCategory[]>('/v1/category', body);
      return raw;
    },
  };
};

export interface CategoryServiceFactoryInterface {
  get: () => CategoryServiceInterface;
}

const CategoryServiceFactory = (
  backendFacadeFactory: BackendFacadeFactoryInterface,
): CategoryServiceFactoryInterface => ({
  get: () => CategoryService(backendFacadeFactory.get()),
});

export default CategoryServiceFactory;
