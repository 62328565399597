import './Infos.less';

import { FC, useEffect } from 'react';
import CountrySelector from '../../../Common/Header/CountrySettings/CountrySelector';
import LanguageSelector from '../../../Common/Header/CountrySettings/LanguageSelector';
import { useDispatch, useSelector } from 'react-redux';
import FormatedNumber from '../../../Common/FormatedNumber';
import { selectLanguage } from '../../../store/selectedLanguageSlice';
import { GlobalState } from '../../../store/global';
import { CountryMetadata } from 'hawaii';
import { useSetLanguage } from '../../../Common/hooks/useSettingsUI';
import { getCountryDimFromIso } from '../../../../core/helpers/country';
interface Props {
  country: CountryMetadata;
  selectCountryAction: (iso: string) => void;
  totalRespondent: number | undefined;
  totalPopulationInvisible?: boolean;
  languageSelectorInvisible?: boolean;
}

const Infos: FC<Props> = ({
  country,
  selectCountryAction,
  totalRespondent,
  totalPopulationInvisible,
  languageSelectorInvisible,
}) => {
  const { language } = useSelector((state: GlobalState) => state.settingsUI);
  const setLanguage = useSetLanguage();
  const dispatch = useDispatch();
  const meta: CountryMetadata = getCountryDimFromIso(language.value);
  useEffect(() => {
    setLanguage(language.value, meta);
  }, []);

  const handleLanguageChange = (value: number) => {
    dispatch(selectLanguage({ language: value }));
  };

  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);

  return (
    <div className="infos">
      <div className="infos__content">
        <label className="form-label">Country</label>
        <div className="form-field">
          <CountrySelector selectedCountry={country} selectCountryAction={selectCountryAction} />

          {languageSelectorInvisible ? (
            ''
          ) : (
            <LanguageSelector countryIso={country.iso} value={selectedLanguage} changeAction={handleLanguageChange} />
          )}
        </div>
      </div>
      {totalPopulationInvisible ? (
        ''
      ) : (
        <div className="country-stats">
          Total pop.&nbsp;
          <FormatedNumber value={country.population} />
          {totalRespondent && (
            <>
              &nbsp;// Total Respondents:
              <FormatedNumber value={totalRespondent} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Infos;
