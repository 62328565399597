import { FC, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { PageProperties } from '../../interface';
import TabsContent from './components/tabs-content';
import { MdOutlineSettings } from 'react-icons/md';
import FileLoader from './components/file-loader';
import { IBrandsDetails } from '../common/interface/interface';
import { CountryMetadata } from 'hawaii';

interface Props {
  onProcessBrand: IBrandsDetails[] | [];
  selectedCountry: CountryMetadata;
  runID: string | undefined;
  setSelectedBrand: React.Dispatch<React.SetStateAction<string>>;
  jobBQStatus: string | null;
  jobStatus: string | null;
  modelRunned: boolean;
}

const BrandResults: FC<Props> = ({
  selectedCountry,
  runID,
  onProcessBrand,
  setSelectedBrand,
  jobBQStatus,
  jobStatus,
  modelRunned,
}) => {
  const [activeKey, setActiveKey] = useState<string>('');
  const { brandID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!brandID) {
      setActiveKey('');
    } else {
      setActiveKey(brandID);
    }
  }, [brandID]);

  const handleOnChange = (key: string) => {
    const url = PageProperties['propensity-modeling'].url.replace(':country', selectedCountry.iso) + `/${key}`;
    setActiveKey(key);
    setSelectedBrand(key);
    navigate(url);
  };

  return (
    <div className="l-wrapper__right-content">
      {onProcessBrand.length > 0 && (
        <Tabs
          activeKey={activeKey}
          size={'middle'}
          onChange={(key) => handleOnChange(key)}
          items={onProcessBrand.map((brand) => {
            return {
              label: (
                <>
                  <div>{brand.brand_name}</div>
                  <div style={{ fontSize: 'smaller' }}>{brand.category4}</div>
                </>
              ),
              key: brand?.brand_id.toString(),
              children: !brandID ? (
                <></>
              ) : brand.results ? (
                <TabsContent
                  selectedCountry={selectedCountry}
                  yougovIDs={brand.results.yougovIDs}
                  toChartData={brand.results.toChartData}
                  distinct={brand.results.distinct}
                  propensityName={brand.brand_name}
                  propensityCategory={brand.category4}
                  savedResults={brand.savedResults}
                />
              ) : modelRunned ||
                (jobStatus === 'RUNNING' && jobBQStatus === 'QUEUED') ||
                (jobStatus === 'SUCCESS' && jobBQStatus === 'RUNNING') ||
                (jobStatus === 'SUCCESS' && jobBQStatus === 'QUEUED') ? (
                <div className="matching-progress">
                  <MdOutlineSettings className="hawaii-rotating" />
                  <span>Propensity model is running, please wait...</span>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 220px',
                    }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                      <span style={{ marginRight: '10px', fontSize: '14px' }}>Status:</span>
                      <span style={{ fontSize: '14px', color: '#656565' }}>{jobBQStatus}</span>
                    </span>
                    <FileLoader />
                  </div>
                </div>
              ) : (
                <div
                  className="matching-progress"
                  style={{ width: '820px', margin: 'auto', fontSize: '3em', color: '#25cacf', textAlign: 'center' }}
                >
                  <span style={{ fontSize: '24px', color: 'red', textAlign: 'center' }}>
                    Error on loading data, contact with IT service. Refresh page and try again.
                  </span>
                  <br />
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0px 220px',
                    }}
                  >
                    <span style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                      <span style={{ marginRight: '10px', fontSize: '14px' }}>Status:</span>
                      <span style={{ fontSize: '14px', color: '#656565' }}>{jobStatus}</span>
                      <span style={{ fontSize: '14px', color: '#656565', margin: '0px 10px' }}>{'=>'}</span>
                      <span style={{ fontSize: '14px', color: '#656565' }}>{jobBQStatus}</span>
                    </span>
                  </div>
                </div>
              ),
            };
          })}
        />
      )}

      {!brandID && (
        <div>
          <h2>What is YouGov Propensity Model solution?</h2>
          <ul>
            <li>
              It helps dividing YouGov Profiles Panelists into High, Medium and Low propensity customers based on the
              likelihood of purchasing/subscribing to a specific brand.
            </li>
            <li>
              The principle of the Propensity model is based on the assignment of a probability score to each panelist
              based on their likelihood of conversion.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default BrandResults;
