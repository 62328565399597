import { FC } from 'react';
import './SegmentGraph.less';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HcMore from 'highcharts/highcharts-more';
import { Serie, SerieTag, MediaInter } from '../../core/series/interface';
import { getHighchartsSettings } from '../../core/graph/highchartsAdapter';
import { convertSerieToHighChart } from '../../core/series/serie';
import { isArray } from 'lodash';
import { Tabs } from '../interface';

// Activate highcharts polar graph features
HcMore(Highcharts);

interface Props {
  highchartsSettings: Tabs;
  series: Serie[] | (() => Serie[]);
  labelFormatter?: (
    p: Highcharts.AxisLabelsFormatterContextObject,
    tags: Record<string, string | boolean | number | MediaInter[]>[],
  ) => string;
  tagConverter: (globalTags: SerieTag) => SerieTag;
}

const SegmentGraph: FC<Props> = ({ highchartsSettings, series, labelFormatter = undefined, tagConverter }) => {
  const highchartsGeneralOptions = getHighchartsSettings(highchartsSettings);

  const innerSeries = isArray(series) ? series : series();

  const formatter =
    labelFormatter === undefined
      ? (p: Highcharts.AxisLabelsFormatterContextObject) => p.value.toString()
      : (p: Highcharts.AxisLabelsFormatterContextObject) => {
          return labelFormatter(p, innerSeries[0].tags);
        };
  const xAxisProperties: Highcharts.XAxisOptions = {
    categories: [],
    tickmarkPlacement: 'on' as Highcharts.OptionsTickmarkPlacementValue,
    lineWidth: 0,
    labels: {
      useHTML: true,
      formatter,
      style: {
        color: '#374151',
        fontSize: '14px',
      },
    },
  };

  const highchartsSeries = convertSerieToHighChart(innerSeries, tagConverter, xAxisProperties);

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={{ ...highchartsGeneralOptions, ...highchartsSeries }}
        containerProps={{ style: { width: '100%', height: '100%' } }}
      />
    </>
  );
};

export default SegmentGraph;
