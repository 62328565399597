import { Serie } from '../../core/series/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const variablesDetailsSlice = createSlice({
  name: 'variableDetails',
  initialState: [] as Serie[],
  reducers: {
    addVariableDetails(draft: Serie[], action: PayloadAction<{ variableDetails: Serie }>) {
      const { variableId, countryCode } = action.payload.variableDetails.globalTags as {
        variableId: string;
        countryCode: string;
      };
      const existingVariable = draft.find(
        (variable) => variable.globalTags.variableId === variableId && variable.globalTags.countryCode === countryCode,
      );
      if (!existingVariable) {
        draft.push(action.payload.variableDetails);
      }
    },
    deleteVariableDetails(draft: Serie[], action: PayloadAction<{ variableId: string }>) {
      return [...draft.filter((serie) => serie.globalTags.variableId !== action.payload.variableId)];
    },
  },
});

export const { addVariableDetails, deleteVariableDetails } = variablesDetailsSlice.actions;

export default variablesDetailsSlice;
