/* eslint-disable */
import { FC, useState } from 'react';
import '../SmartBuilder.less';
import { Modal } from 'antd';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SubAudienceCard from './subAudienceCard';
import SearchBar from './searchBar';
import { Segment } from '../../../../core/segment/segments';
import { ColumnWidthOutlined, ColumnHeightOutlined } from '@ant-design/icons';

interface Props {
  handleSearch: (prompt: string) => void;
  segment: Segment;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (index: number, optionIndex: number) => void;
  defaultPrompt: string;
}

const SubAudiences: FC<Props> = ({ defaultPrompt, handleSearch, segment, openModal, setOpenModal, onChange }) => {
  const [selectedCarusel, setSelectedCarusel] = useState<boolean>(true);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1920 },
      items: 3,
      partialVisibilityGutter: 24,
    },
    desktop: {
      breakpoint: { max: 1920, min: 1024 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 24,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 24,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 24,
    },
  };

  return (
    <>
      <div className="editor-content-border">
        <div className="editor-content hawaii-thin-scrollbar" style={{ paddingRight: '0px' }}>
          <SearchBar defaultValue={defaultPrompt} handleSearch={handleSearch} />
          <div className="viewBtns">
            <span
              className={selectedCarusel ? 'btn btn_selected' : 'btn'}
              onClick={() => (selectedCarusel ? '' : setSelectedCarusel(!selectedCarusel))}
            >
              <ColumnWidthOutlined style={{ fontSize: '24px', color: 'black' }} />
            </span>
            <span
              className={selectedCarusel ? 'btn' : 'btn btn_selected'}
              onClick={() => (selectedCarusel ? setSelectedCarusel(!selectedCarusel) : '')}
            >
              <ColumnHeightOutlined style={{ fontSize: '24px', color: 'black' }} />
            </span>
          </div>
          <div>
            <p>Select the questions to be used in the definition of the Audience</p>
          </div>
          {selectedCarusel && (
            <div className="smart-builder__carousel">
              <Carousel
                swipeable={false}
                draggable={false}
                responsive={responsive}
                showDots={true}
                // infinite={true}
                // autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={['tablet', 'mobile']}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-24-px"
              >
                {segment &&
                  segment.smartBuilder?.recommander
                    .filter((el) => el.options.length > 0)
                    .map((item, index) => (
                      <div key={index} className="subAdience-container">
                        <SubAudienceCard
                          segment={segment}
                          title={`${item.title
                            .split(' ')
                            .map((title) => title.charAt(0).toLocaleUpperCase() + title.slice(1))
                            .join(' ')}`}
                          subtitle={`${index + 1}/${
                            segment.smartBuilder?.recommander.filter((el) => el.options.length > 0).length || 0
                          }`}
                          options={item.options}
                          index={index}
                          onChange={onChange}
                        />
                      </div>
                    ))}
              </Carousel>
            </div>
          )}
          {!selectedCarusel && (
            <>
              {segment &&
                segment.smartBuilder?.recommander
                  .filter((el) => el.options.length > 0)
                  .map((item, index) => (
                    <div key={index} className="subAdience-container-row">
                      <SubAudienceCard
                        segment={segment}
                        title={`${item.title
                          .split(' ')
                          .map((title) => title.charAt(0).toLocaleUpperCase() + title.slice(1))
                          .join(' ')}`}
                        subtitle={`${index + 1}/${
                          segment.smartBuilder?.recommander.filter((el) => el.options.length > 0).length || 0
                        }`}
                        options={item.options}
                        index={index}
                        onChange={onChange}
                      />
                    </div>
                  ))}
            </>
          )}
        </div>
      </div>

      <Modal
        className="ant-modal__no-footer"
        title={[
          <div className="ant-modal-title__flex">
            <span>Preview Sub-Audiences</span>
            {/* <span className="text-gray">Resp: 1,029/Market: 584,516</span> */}
          </div>,
        ]}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={[]}
      >
        <div className="preview-sub-audiences">
          {segment.smartBuilder &&
            segment.smartBuilder?.selectedSubAudiencies.map((item, i) => {
              return (
                <div key={i} className="variable-container">
                  <div className="audience-variable" style={{ borderColor: '#B09FF9' }}>
                    <div className="upper-side">
                      <span className="audience-option-header">
                        {segment.smartBuilder?.recommander[item.index].title
                          .split(' ')
                          .map((title) => title.charAt(0).toLocaleUpperCase() + title.slice(1))
                          .join(' ')}
                      </span>
                    </div>
                    {item.options.map((option) => {
                      return (
                        <div key={option} className="choices">
                          <div className="audience-option-choice">
                            <div className="inner-text">
                              {segment.smartBuilder?.recommander[item.index].options[option].label}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    </>
  );
};

export default SubAudiences;
