import { serieGlobalTagsMatch } from '../../core/series/serie';
import { MediaInter, Serie, SerieTag } from '../../core/series/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './global';
import { createSelector } from '@reduxjs/toolkit';
import { SegmentWithUiMeta } from 'hawaii';

const seriesSlice = createSlice({
  name: 'serie',
  initialState: [] as Serie[],
  reducers: {
    // update if already existing
    setSeries(draft: Serie[], action: PayloadAction<{ serie: Serie }>) {
      const existingSerieIndex = draft.findIndex((s) =>
        serieGlobalTagsMatch({
          isWeighted: action.payload.serie.globalTags['isWeighted'],
          segmentId: action.payload.serie.globalTags['segmentId'],
          countryCode: action.payload.serie.globalTags['countryCode'],
          variableId: action.payload.serie.globalTags['variableId'],
        })(s),
      );

      if (existingSerieIndex > -1) {
        draft[existingSerieIndex] = action.payload.serie;
      } else {
        draft.push(action.payload.serie);
      }
    },
    deleteSerie(draft: Serie[], action: PayloadAction<{ criteriaTags: SerieTag }>) {
      return draft.filter((serie) => !serieGlobalTagsMatch(action.payload.criteriaTags)(serie));
    },
    updateSerieGlobalTags(draft: Serie[], action: PayloadAction<{ selectTags: SerieTag; tags: SerieTag }>) {
      return draft.map((serie) => {
        return serieGlobalTagsMatch(action.payload.selectTags)(serie)
          ? { ...serie, globalTags: { ...serie.globalTags, ...action.payload.tags } }
          : serie;
      });
    },
    deleteVariableDetails(draft: Serie[], action: PayloadAction<{ variableId: string; countryIsos: string[] }>) {
      return draft.filter(
        (serie) =>
          !serieGlobalTagsMatch({ variableId: action.payload.variableId })(serie) ||
          action.payload.countryIsos.indexOf(serie.globalTags.countryCode as string) !== -1,
      );
    },
    refreshSerieState(state: Serie[], action: PayloadAction<{ id: number; segment: SegmentWithUiMeta }>) {
      const serie = state[action.payload.id];

      state[action.payload.id] = {
        ...serie,
        globalTags: { ...serie.globalTags, irrelevant: action.payload.segment.uiMeta.irrelevant },
      };
    },
  },
});

export default seriesSlice;

// ****************************
//          SELECTORS
// ****************************

export const selectSegmentsSeries = createSelector(
  [(state: GlobalState) => state.series, (state: GlobalState, segments: SegmentWithUiMeta[]) => ({ segments })],
  (series: Serie[], { segments }: { segments: SegmentWithUiMeta[] }) => {
    const segmentIds = segments.map((segment) => segment.id);

    return series.filter((serie) => segmentIds.indexOf(serie.globalTags.segmentId as string) !== -1);
  },
);
