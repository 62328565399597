import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { GlobalState } from '../global';
import { SegmentWithUiMeta } from 'hawaii';

interface IAction {
  oldSegmentId: string;
  segment: SegmentWithUiMeta;
}

export const saveSegmentinProfilesThunk = createAsyncThunk<
  IAction,
  {
    segment: SegmentWithUiMeta;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`segment/save`, async ({ segment }, { extra }): Promise<IAction> => {
  const sharedSegmentService = extra.factory.getSharedSegmentService();
  let freshSegment;
  try {
    freshSegment = await sharedSegmentService.save(segment);
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }

  // Replace the old-id segment with backend one, but keeping the uiMeta
  return {
    oldSegmentId: segment.id,
    segment: {
      ...freshSegment,
      uiMeta: { ...segment.uiMeta, modified: false },
    },
  };
});

export const copySegmentProfilesThunk = createAsyncThunk<
  IAction,
  {
    segment: SegmentWithUiMeta;
    agency?: number;
    client?: number | string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>('segment/copySave', async ({ segment, agency, client }, { extra }): Promise<IAction> => {
  const sharedSegmentService = extra.factory.getSharedSegmentService();
  let freshSegment;
  try {
    freshSegment = await sharedSegmentService.saveCopySegment(segment, agency, client);
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }

  // Replace the old-id segment with backend one, but keeping the uiMeta
  return {
    oldSegmentId: segment.id,
    segment: {
      ...freshSegment,
      uiMeta: { ...segment.uiMeta, modified: false },
    },
  };
});
