import { FC, useState, useContext } from 'react';
import { Serie, SerieTag } from '../../../../core/series/interface';
import VerticalTabs from '../../../Common/VerticalTabs';
import GraphWrapper from './Graph/GraphWrapper';
import './TabsSelector.less';
import DisplaySelector from '../../../Common/DisplaySelector';
import { exportTableAsXlsx } from '../../../../core/helpers/ExportTableAsXlsx';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { serieTagFilter } from '../../../../core/series/serie';
import { forExcel } from '../../../../core/helpers/countSeries';
import { GlobalState } from '../../../store/global';
import {
  crossResultsAddResponses,
  crossResultsRemoveVariable,
  crossResultsToogleResponse,
} from '../../../store/crossResultsSlice';
import { Tabs } from '../../../interface';
import VariableSelector from '../../../MetaComponents/VariablesDrawer/VariableSelector';
import { FirstLevelCategory } from '../../../Common/VariableSelector/CategoriesSelector';
import { Context } from '../../../Common/Context/Context';
import { VariablesSelectionServiceInterface } from '../../../../core/variablesSelection';
import { addVariableDetailsThunk } from '../../../store/thunk/addVariableDetails';
import seriesSlice, { selectSegmentsSeries } from '../../../store/seriesSlice';
import { selectSegmentByScope } from '../../../store/segmentSlice';
import { CountryMetadata, ResponseInfos, VariableInfos } from 'hawaii';
import { setAppIsLoadingState } from '../../../store/appIsLoadingSlice';
import * as actions from '../../../store/actions';
import VariablesHistory from '../../../Common/variablesHistory/VariablesHistory';

interface Props {
  selectedCountry: CountryMetadata;
  selectedLanguage: number;
}

const TabsSelector: FC<Props> = ({ selectedCountry, selectedLanguage }) => {
  const xlsExport = () => {
    const exportedData = [
      {
        countryIso: country,
        data: forExcel(
          () => filteredSerie,
          () => variableDetails,
          [selectedCountry],
        )(),
      },
    ];

    const regex = /([0-9]+)-([0-9]+)-([0-9]+)/g;
    exportTableAsXlsx(
      segments,
      exportedData,
      'Excel',
      [selectedCountry],
      hawaiiState.surveyRefreshDate.replace(regex, '$3/$2/$1'),
    );
  };

  const handleToogleResponsesChange = (variableId: string, responseId: string) => {
    store.dispatch(crossResultsToogleResponse({ countryIsos: [country], variableId, responseId }));
  };

  const categoryTreeProvider = (path: string[]): Promise<FirstLevelCategory[]> => {
    return categoryService.get(path, [selectedCountry.iso], selectedLanguage, 'Original');
  };

  const handleAddComparaisonVariable = () => {
    setComparaisonVariableSelectorOpened(true);
  };

  const handleDeleteVariable = (variableId: string) => {
    dispatch(seriesSlice.actions.deleteVariableDetails({ variableId, countryIsos: [selectedCountry.iso] }));
    dispatch(crossResultsRemoveVariable({ variableId, countryIsos: [selectedCountry.iso] }));
    dispatch(actions.cleanUpVariablesHistory(variableId));
  };

  const handleClearVariableSelection = (variableIds: string[]) => {
    variableIds.forEach((variableId) => {
      dispatch(seriesSlice.actions.deleteVariableDetails({ variableId, countryIsos: [selectedCountry.iso] }));
      dispatch(crossResultsRemoveVariable({ countryIsos: [selectedCountry.iso], variableId }));
      dispatch(actions.cleanUpVariablesHistory('all'));
    });
  };

  const searchCallback = async (regex: string): Promise<VariableInfos[]> => {
    return await variableService.getVariablesFromInput(regex, [selectedCountry.iso], selectedLanguage, 'Original');
  };

  const handleComparaisonVariableValidation = (
    selectedVariable: VariableInfos,
    selectedResponses: ResponseInfos[],
    responsePanel: ResponseInfos[],
  ) => {
    dispatch(setAppIsLoadingState({ state: true }));

    dispatch(
      crossResultsAddResponses({
        countryIsos: [selectedCountry.iso],
        variableId: selectedResponses[0].variableId,
        responseIds: selectedResponses.map((infos: ResponseInfos) => infos.value),
      }),
    );

    dispatch(
      addVariableDetailsThunk({
        countryId: selectedCountry.id,
        selectedVariable,
        responsePanel,
        dataScope: 'Original',
      }),
    );

    const variablesHistory = {
      variablesDetails: {
        countryId: selectedCountry.id,
        selectedVariable,
        responsePanel,
        dataScope: 'Original',
      },
      crossResults: {
        countryIsos: [selectedCountry.iso],
        variableId: selectedResponses[0].variableId,
        responseIds: selectedResponses.map((infos: ResponseInfos) => infos.value),
      },
    };

    dispatch(actions.saveVariablesHistory(variablesHistory as actions.IVariablesHistory));

    setComparaisonVariableSelectorOpened(false);
  };

  const { factory } = useContext(Context);
  const categoryService = factory.getCategoryService();
  const variableService = factory.getVariableSelectionService();

  const store = useStore<GlobalState>();
  const dispatch = useDispatch();

  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const variableDetails = useSelector((state: GlobalState) => state.variablesDetails);
  const segments = useSelector((state: GlobalState) => selectSegmentByScope(state, 'Original', [selectedCountry]));
  const series = useSelector((state: GlobalState) => selectSegmentsSeries(state, segments));

  const crossResults = useSelector((state: GlobalState) => state.crossResults);
  const country = selectedCountry.iso;

  const [selectedTab, setSelectedTab] = useState<Tabs>('COLUMNS');
  const [comparaisonVariableSelectorOpened, setComparaisonVariableSelectorOpened] = useState<boolean>(false);

  const tabs = ['SPIDER', 'FUSION TABLE', 'TABLE', 'BARS', 'SPLIT BARS', 'SPLIT COLUMNS', 'COLUMNS'] as Tabs[];

  const selectedResponses = crossResults.selectedResponses[country] || [];

  const filteredSerie: Serie[] = series.map((serie): Serie => {
    const s = selectedResponses[serie.globalTags.variableId as string];
    return serieTagFilter((tag: SerieTag) => s?.includes(tag.responseId as string))(serie);
  });

  const variableIds = Object.keys(crossResults.selectedResponses[country] || {});
  const variables = variableDetails.filter(
    (variable) => variableIds.indexOf(variable.globalTags.variableId as string) !== -1,
  );

  return (
    <>
      <VerticalTabs
        tabs={tabs}
        value={selectedTab}
        selectAction={(tab) => {
          setSelectedTab(tab as Tabs);
        }}
      >
        <div className="right-tab-content">
          <DisplaySelector
            selectedResponses={selectedResponses}
            onToogleSelectedResponses={handleToogleResponsesChange}
            xlsExportAction={xlsExport}
            variables={variables}
            deleteVariableAction={handleDeleteVariable}
            clearSelection={handleClearVariableSelection}
          />
          <div className="pit-charts">
            <GraphWrapper graphType={selectedTab} series={filteredSerie} country={selectedCountry} />
            {segments.length &&
            segments.every(({ amdmInfos }) => amdmInfos !== null && amdmInfos !== undefined) &&
            !variables.length ? (
              <div className="notify_content">
                <p>
                  Select variables to analyze your Audience, or create other Audiences before analyzing them against
                  attributes
                </p>
              </div>
            ) : null}
          </div>
          <div className="pit-add-button">
            <input type="button" className="btn--md" value="Select a variable" onClick={handleAddComparaisonVariable} />
            <VariablesHistory />
          </div>
        </div>
      </VerticalTabs>
      {comparaisonVariableSelectorOpened && (
        <VariableSelector
          color="#25cacf"
          categoryTreeProvider={categoryTreeProvider}
          title="Compare your audience"
          subtitle="With a selection of variables and responses"
          validationCaption="Compare"
          visible={true}
          variableService={variableService}
          countries={[selectedCountry.iso]}
          languageCode={selectedLanguage}
          dataScope="Original"
          onClose={(variableService: VariablesSelectionServiceInterface) => {
            variableService.abort();
            setComparaisonVariableSelectorOpened(false);
          }}
          searchCallback={searchCallback}
          validateVariableChoice={handleComparaisonVariableValidation}
        />
      )}
    </>
  );
};

export default TabsSelector;
