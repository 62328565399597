import { PanelCriteria, PanelCriteriaStack, ResponseInfos } from 'hawaii';
import { FC } from 'react';
import { OperatorLabel } from '../OperatorLabel';
import { SegmentCriteriaBlock } from '../SegmentCriteriaBlock/SegmentCriteriaBlock';

interface Props {
  criterias: PanelCriteriaStack;
  addCriteriaAction: (criteriaId: string | null, operator: string) => void;
  removeCriteriaAction: (id: string) => void;
  updateResponsesAction: (criteriaId: string) => void;
  removeResponsesAction: (criteriaId: string, response: ResponseInfos) => void;
  updateZIndexAction: (criteriaId: string, zIndex: number) => void;
  changeCriteriaRuleAction: (criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => void;
  color: string;
  disabled: boolean;
}

export const SegmentCriteriaFlat: FC<Props> = ({
  criterias,
  removeCriteriaAction,
  addCriteriaAction,
  updateResponsesAction,
  removeResponsesAction,
  updateZIndexAction,
  changeCriteriaRuleAction,
  color,
  disabled,
}) => (
  <div className="criteria-tree">
    {criterias.criterias.map((criteria, index: number) => {
      return (
        <div key={criteria.id} className="indented">
          <SegmentCriteriaBlock
            criteria={criteria as PanelCriteria}
            operatorControl={criterias.operator === 'AND' ? 'OR' : 'AND'}
            removeCriteriaAction={() => {
              removeCriteriaAction(criteria.id);
            }}
            addCriteriaAction={(operator: 'AND' | 'OR') => {
              addCriteriaAction(criteria.id, operator);
            }}
            removeResponsesAction={(response: ResponseInfos) => {
              removeResponsesAction(criteria.id, response);
            }}
            updateResponsesAction={() => updateResponsesAction(criteria.id)}
            changeCriteriaRuleAction={changeCriteriaRuleAction}
            color={color}
            disabled={disabled}
          />
          {!disabled && index === criterias.criterias.length - 1 ? (
            <button
              className="operator"
              // style={{ backgroundColor: color, borderColor: color, marginLeft: '4.5em' }}
              onClick={() => addCriteriaAction(criterias.id, criterias.operator)}
            >
              {criterias.operator}...
            </button>
          ) : (
            <OperatorLabel
              operator={criterias.operator}
              zIndex={criterias.zIndex}
              color={color}
              maxZIndex={criterias.criterias.length}
              onChange={(zIndex: number) => {
                updateZIndexAction(criterias.id, zIndex);
              }}
            />
          )}
        </div>
      );
    })}
  </div>
);
