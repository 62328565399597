import { PanelCriteria, PanelCriteriaStack } from 'hawaii';

/**
 * return true if criteria is an instance of PanelCriteriaStack
 *
 * @param criteria
 * @returns
 */
export const isACriteriaStack = (criteria: PanelCriteria | PanelCriteriaStack): criteria is PanelCriteriaStack => {
  const test: Record<string, unknown> = { ...criteria };
  return test.criterias !== undefined;
};

/**
 * Return true if there is no PanelCriteriaStack in the passed PanelCriteriaStack
 * @param criterias
 */
export const criteriaHasNoStack = (criterias: PanelCriteriaStack): boolean => {
  return criterias.criterias.filter((criteria) => isACriteriaStack(criteria)).length === 0;
};
