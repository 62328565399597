import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import {
  selectChartClusterIndex,
  selectChartClusterProfile,
  selectChartClusterResponseRate,
  selectClusterRespondents,
  selectClusterColors,
  selectChartClusterIndexV2,
} from '../../../store/modules/databricks/selectors';
import { config } from '../../../../core/config';
import axios from 'axios';
import { setAppIsLoadingState } from '../../../store/appIsLoadingSlice';
import { setSavedResults } from '../../../store/modules/databricks//actions';
import { message } from 'antd';
import { addBearerTokenInHeaders } from '../../../../core/helpers/addBearTokenInHeaders';
import { chunkArray } from '../../../../core/helpers/chunkArray';
import { RespondentsData } from '../../common/interface/interface';
import { Segment } from '../../../../core/segment/segments';
import { PanelCriteria, PanelCriteriaStack, ResponseInfos, SegmentWithUiMeta } from 'hawaii';

interface ClusterProfile {
  name: string;
  y: string;
  respondents: string;
  color: string;
}

interface ClusterTableData {
  question: string;
  response: string;
  clusters: { default: number; percentage: number }[];
  respondents: {
    [key: string]: RespondentsData[];
  };
  marketSize: string;
  colors: string[] | undefined;
}

const BASE_URL = config.api.baseUrl as string;

export const useClustersData = (segmentID: string, module: string) => {
  const dispatch = useDispatch();
  const [errorClusterSave, setErrorClusterSave] = useState<Error | null | number>(null);
  const settingsUI = useSelector((state: GlobalState) => state.settingsUI);
  const infoAMDM = useSelector((state: GlobalState) => state.amdmState);
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);

  const clusterIndex = (): ReturnType<typeof selectChartClusterIndex> =>
    useSelector((state: GlobalState) => {
      const clusterIndex = selectChartClusterIndex(state, segmentID, module);
      clusterIndex.colors = selectClusterColors(state, segmentID, module);
      return clusterIndex;
    });

  const clusterIndexV2 = (): ReturnType<typeof selectChartClusterIndexV2> =>
    useSelector((state: GlobalState) => {
      const clusterIndexV2 = selectChartClusterIndexV2(state, segmentID, module);
      clusterIndexV2.colors = selectClusterColors(state, segmentID, module);
      return clusterIndexV2;
    });

  const useClusterRespondentsData = (): ReturnType<typeof selectClusterRespondents> =>
    useSelector((state: GlobalState) => selectClusterRespondents(state, segmentID, module));

  const clusterIndexTable = (): ClusterTableData[] => {
    const segments = useSelector((state: GlobalState) => state.segments);

    const marketSize =
      segments?.filter((segment) => segment.publicId === segmentID)[0]?.uiMeta?.marketSize?.toString() || '';
    const RespondentsData = useClusterRespondentsData();
    const { rawClusterIndex, colors } = clusterIndexV2();
    return rawClusterIndex.map((item) => {
      const clusters = Object.keys(item)
        .filter((key) => key.startsWith('cluster_') && item[key] !== null)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map((clusterKey) => {
          const split = (item[clusterKey] as string).slice(1, -1).split(',');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return { default: Number(split[0]), percentage: Number(split[1]) };
        });

      return {
        question: item.question,
        response: item.response,
        clusters: clusters,
        respondents: RespondentsData,
        marketSize: marketSize,
        colors: colors,
      };
    });
  };

  const clustersMarketSize = (): { [key: string]: number } => {
    const clusterProfile = useSelector((state: GlobalState) => selectChartClusterProfile(state, segmentID, module));
    const segments = useSelector((state: GlobalState) => state.segments);
    const population =
      segments?.filter((segment) => segment.publicId === segmentID)[0]?.uiMeta?.marketSize?.toString() || '';
    const { rawClusterIndex } = clusterIndexV2();
    const clustersValues = Object.values(rawClusterIndex).map((item) => {
      const clusters = Object.keys(item)
        .filter((key) => key.startsWith('cluster_') && item[key] !== null)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map((clusterKey) => {
          const split = (item[clusterKey] as string).slice(1, -1).split(',');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          return { [clusterKey]: Number(split[0]) };
        });
      return clusters;
    });

    const clusterWeight = clustersValues.reduce((acc, item) => {
      item.map((el) => {
        if (!acc[Object.keys(el)[0]]) {
          acc[Object.keys(el)[0]] = 0;
        }
        acc[Object.keys(el)[0]] += Object.values(el)[0];
      });

      return acc;
    }, {} as { [key: string]: number });

    const respondents = clusterProfile.reduce((acc, item) => {
      acc += Number(item.respondents);
      return acc;
    }, 0);

    const marketSize = Object.keys(clusterWeight).reduce((acc, key, index) => {
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] = Number(((Number(clusterProfile[index].respondents) * Number(population)) / respondents).toFixed());

      return acc;
    }, {} as { [key: string]: number });

    return marketSize;
  };

  const clusterRates = (): ReturnType<typeof selectChartClusterResponseRate> =>
    useSelector((state: GlobalState) => selectChartClusterResponseRate(state, segmentID, module));

  const clusterProfile = (): ClusterProfile[] => {
    const clusterProfile = useSelector((state: GlobalState) => selectChartClusterProfile(state, segmentID, module));
    const { colors } = clusterIndex();
    return clusterProfile.map((profile, index) => ({
      ...profile,
      color: colors ? colors[index] : '#fff',
    }));
  };

  type MetaEntity = {
    audienceClusterName: string | undefined;
    selectedAgency: number | undefined;
    selectedClient: number | string | undefined;
    selectedCluster: number | undefined;
    marketSize: number;
  };

  const saveClusterAMDM = async (metaEntity: MetaEntity, respondents: RespondentsData[]) => {
    const { language } = settingsUI;
    const { connection } = infoAMDM;
    const hide = message.loading({ content: 'Loading...' });
    dispatch(setAppIsLoadingState({ state: true }));

    setErrorClusterSave(null);

    if (!connection.isConnected) throw new Error('AMDM is not connected !');
    const audienceEntity = {
      agencyId: Number(metaEntity.selectedAgency),
      clientId: metaEntity.selectedClient,
      name: metaEntity.audienceClusterName,
      datasetDate: new Date(hawaiiState.surveyRefreshDate).toISOString(),
      population: metaEntity.marketSize,
      countryCode: language.meta.iso,
    };

    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const respondentsArr = respondents.map((res) => res.respondent_id);
      const chunkyData = chunkArray(respondentsArr, 81920);
      const totalChunks = chunkyData.length;

      await Promise.all(
        chunkyData.map(async (chunk, index) => {
          const payload = {
            entity: totalChunks - 1 === index ? audienceEntity : undefined,
            chunkRespondentsList: chunk,
            totalChunks: totalChunks,
            chunkIndex: index,
          };
          return await axios.post(`${BASE_URL}/v1/clusters/save-cluster`, payload, {
            withCredentials: true,
            signal: abortController.signal,
          });
        }),
      ).then(async (promises) => {
        const responses = promises.every((r) => r.status !== 200);

        if (!responses) {
          dispatch(setSavedResults(metaEntity.selectedCluster, segmentID, 'clusters'));
          await message.success({ content: 'Cluster saved on AMDM direction.', duration: 3 });
        } else {
          await message.error({ content: 'Error cluster saving on AMDM direction.', duration: 3 });
          if (metaEntity.selectedCluster !== undefined) {
            setErrorClusterSave(metaEntity.selectedCluster);
          }
        }
      });

      hide();
    } catch (err) {
      if (err instanceof Error) {
        await message.error({ content: err.message, duration: 3 });
        if (metaEntity.selectedCluster !== undefined) {
          setErrorClusterSave(metaEntity.selectedCluster);
        } else {
          setErrorClusterSave(err);
        }
      } else {
        await message.error(new Error('Unknown error occurred'));
        if (metaEntity.selectedCluster !== undefined) {
          setErrorClusterSave(metaEntity.selectedCluster);
        }
      }
    } finally {
      dispatch(setAppIsLoadingState({ state: false }));
    }
  };

  const saveHistoryClusters = async (segment: SegmentWithUiMeta, country: number) => {
    const body = {
      audience_name: segment.name,
      date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      country: country,
      criterasID: (segment.criterias.criterias as PanelCriteria[]).reduce((acc, item) => {
        acc.push(item.id);
        return acc;
      }, [] as string[]),
      responsesID: (segment.criterias.criterias as PanelCriteria[]).reduce((acc, item) => {
        item.responses.map((el) => acc.push(el.value));
        return acc;
      }, [] as string[]),
    } as unknown as {
      audience_name: string;
      date: string;
      country: number;
      criterasID: string[];
      responsesID: string[];
    };

    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      await axios.post(`${BASE_URL}/v1/clusters/saveClusterHistory`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };

  const getHistoryClusters = async (segment: SegmentWithUiMeta, country: number) => {
    const body = {
      audience_name: segment.name,
      date: new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      country: country,
      criterasID: (segment.criterias.criterias as PanelCriteria[]).reduce((acc, item) => {
        acc.push(item.id);
        return acc;
      }, [] as string[]),
      responsesID: (segment.criterias.criterias as PanelCriteria[]).reduce((acc, item) => {
        item.responses.map((el) => acc.push(el.value));
        return acc;
      }, [] as string[]),
    } as unknown as {
      audience_name: string;
      date: string;
      country: number;
      criterasID: string[];
      responsesID: string[];
    };

    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const res = await axios.post(`${BASE_URL}/v1/clusters/getClusterHistory`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });
      if (res.status === 200) {
        return (
          res.data as {
            audience_name: string;
            date: string;
            country: number;
            criterasID: string[];
            responsesID: string[];
            num_cluster: string;
          }[]
        ).map((item) => ({
          label: item.date,
          value: item.date,
        }));
      } else {
        return [];
      }
    } catch (err) {
      if (err instanceof Error) {
        return console.log(err);
      } else {
        return console.log(new Error('Unknown error occurred'));
      }
    }
  };

  return {
    clusterIndex,
    clusterIndexV2,
    clusterRates,
    clusterProfile,
    clusterIndexTable,
    saveClusterAMDM,
    clustersMarketSize,
    errorClusterSave,
    setErrorClusterSave,
    saveHistoryClusters,
    getHistoryClusters,
  };
};
