import { CountryMetadata } from 'hawaii';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  marketSizedSeries,
  audienceResponsePercentageSeries,
  indexSeries,
  unweightedResponseCountSeries,
} from '../../../../core/crossResults';
import { Serie, SerieTag } from '../../../../core/series/interface';
import { Tabs } from '../../../interface';
import { serieApplyPermutations, seriePermutationsOfValues, seriesRegroup } from '../../../../core/series/serie';
import FormatedNumber from '../../../Common/FormatedNumber';
import OrderedCheckbox from '../../../Common/OrderedCheckbox';
import SerieTable, { TableSortCriteria } from '../../../Common/SerieTable';
import { GlobalState } from '../../../store/global';
import FusionTable from './FusionTable';

interface Props {
  graphType: Tabs;
  series: Serie[];
  country: CountryMetadata;
}

const ResultsTable: FC<Props> = ({ graphType, series, country }) => {
  const [sortCriteria, setSortCriteria] = useState<TableSortCriteria | undefined>(undefined);
  const [sectionsSelection, setSectionSelection] = useState<{ name: string; checked: boolean }[]>([
    { name: 'Unweighted respondents', checked: true },
    { name: 'Distribution of the respondents', checked: true },
    { name: 'Total market size', checked: true },
    { name: 'Index', checked: true },
  ]);

  const variableDetails = useSelector((state: GlobalState) => state.variablesDetails);

  const handleSortColumn = (criteria: TableSortCriteria): void => {
    setSortCriteria(criteria);
  };

  const formatTableLineLabel = (tags: SerieTag, globalTags: SerieTag, offTitle: boolean): React.ReactElement => (
    <>
      {!offTitle && (
        <>
          <strong>{globalTags.variableName as string}</strong>
          <br />
          {globalTags.responseText}
        </>
      )}
      {offTitle && <strong>{globalTags.responseText}</strong>}
    </>
  );

  const crossresultsComputedSeries = (series: Serie[]): Serie[] =>
    sectionsSelection
      .map(({ name, checked }) => {
        if (!checked) {
          return [];
        }

        switch (name) {
          case 'Unweighted respondents':
            return [...unweightedResponseCountSeries(series)().flat()];
          case 'Distribution of the respondents':
            return [...audienceResponsePercentageSeries(series)().flat()];
          case 'Total market size':
            return [...marketSizedSeries(country.population, series)().flat()];
          case 'Index':
            return [...indexSeries(variableDetails, series)().flat()];
          default:
            throw 'Unexpected label';
        }
      })
      .flat();

  const crossresultsAlignVariable = (series: Serie[]): Serie[] =>
    seriesRegroup(['label', 'name'], ['variableName'])(() => series);

  const renderedSeries = crossresultsComputedSeries(series);

  const variableFlatten = crossresultsAlignVariable(renderedSeries);

  let sortMethod;
  if (sortCriteria?.column !== undefined) {
    const targetedSerie = variableFlatten.filter(
      (serie: Serie) =>
        serie.globalTags.label === sortCriteria?.section && serie.globalTags.name === sortCriteria?.column,
    )[0];

    const permutations =
      undefined !== targetedSerie
        ? seriePermutationsOfValues(
            (a: number, b: number) => (sortCriteria?.order === 'ASC' ? a - b : b - a),
            targetedSerie,
          )
        : [];

    sortMethod =
      undefined === targetedSerie
        ? (serie: Serie) => serie
        : (serie: Serie) => serieApplyPermutations(permutations, serie);
  } else {
    sortMethod = (serie: Serie) => serie;
  }

  const handleChangeSectionSelection = (sectionsSelection: { name: string; checked: boolean }[]) => {
    setSectionSelection([...sectionsSelection]);
  };

  const handleMoveSectionSelection = (newSelectionValue: { name: string; checked: boolean }[]) => {
    setSectionSelection([...newSelectionValue]);
  };

  const valueFormatter = (val: number, globalTags: SerieTag, tags: SerieTag): JSX.Element => {
    return (
      <span
        style={{ fontStyle: tags.irrelevant ? 'italic' : 'normal' }}
        className={tags.irrelevant ? 'hawaii-grey' : ''}
      >
        <FormatedNumber value={val} />
      </span>
    );
  };

  return (
    <>
      <OrderedCheckbox
        values={sectionsSelection}
        changeValueAction={handleChangeSectionSelection}
        moveValueAction={handleMoveSectionSelection}
      />
      {graphType === 'TABLE' && variableFlatten.length > 0 && (
        <SerieTable
          series={variableFlatten.map(sortMethod)}
          sectionGroupTag="label"
          columnGroupTag="name"
          sortAction={handleSortColumn}
          formatLineLabel={formatTableLineLabel}
          sortCriteria={sortCriteria}
          valueFormatter={valueFormatter}
        />
      )}
      {graphType === 'FUSION TABLE' && variableFlatten.length > 0 && (
        <FusionTable
          series={variableFlatten.map(sortMethod)}
          sectionGroupTag="label"
          sectionsSelection={sectionsSelection}
          columnGroupTag="name"
          formatLineLabel={formatTableLineLabel}
          valueFormatter={valueFormatter}
        />
      )}
    </>
  );
};

export default ResultsTable;
