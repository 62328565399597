import './split-charts.less';
import { FC, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HcMore from 'highcharts/highcharts-more';
import { Serie } from '../../../core/series/interface';
import { isArray } from 'lodash';

// Activate highcharts polar graph features
HcMore(Highcharts);

interface IbarsData {
  name: string;
  data: Array<{ color: string; audience: string; value: number }>;
}

interface Props {
  series: Serie[] | (() => Serie[]);
  type: string;
}
const SplitCharts: FC<Props> = ({ series, type }) => {
  const [splitSeries, setSplitSeries] = useState<Record<string, IbarsData[]> | null>(null);

  const innerSeries = isArray(series) ? series : series();

  useEffect(() => {
    const barData = innerSeries.reduce((list, item) => {
      const audience = item.globalTags.name;
      const color = item.globalTags.color as string;

      item.tags.forEach((tag, tagIndex) => {
        if (typeof tag.variableName === 'string') {
          const group = tag.variableName;

          if (!list[group]) {
            list[group] = [];
          }

          let obj = list[group].find((element: IbarsData) => element.name === tag.responseText);

          if (!obj) {
            obj = {
              name: tag.responseText as string,
              data: [],
            };
            list[group].push(obj);
          }

          for (let i = 0; i < item.values.length; i++) {
            if (i % item.tags.length === tagIndex) {
              obj.data.push({
                color: color,
                audience: audience as string,
                value: item.values[i],
              });
            }
          }
        }
      });
      return list;
    }, {} as Record<string, IbarsData[]>);

    setSplitSeries(barData);
  }, [series]);

  const options = splitSeries
    ? Object.keys(splitSeries).map((group) => {
        const audiences = Array.from(new Set(splitSeries[group].flatMap((item) => item.data.map((d) => d.audience))));
        const series = audiences.map((audience) => {
          const color = splitSeries[group].flatMap((item) => item.data).find((d) => d.audience === audience)?.color;

          return {
            name: audience,
            color: color,
            data: splitSeries[group].map((item) => {
              const datum = item.data.find((d) => d.audience === audience);

              return {
                y: datum ? datum.value : null,
                color: color,
              };
            }),
          };
        });

        return {
          chart: {
            polar: false,
            type: type,
          },
          ownHeader: {
            text: group,
          },
          plotBackgroundColor: 'red',
          style: {
            fontFamily: 'Baikal Regular, sans-serif',
          },
          title: {
            text: null,
          },
          xAxis: {
            categories: splitSeries[group].map((item) => item.name),
          },
          plotOptions: {
            bar: {
              borderRadius: 5,
            },
            series: {
              borderColor: '#F3F4F6',
            },
          },
          yAxis: {
            title: {
              text: null,
            },
          },
          series: series,
          credits: {
            enabled: false,
          },
        };
      })
    : undefined;

  const defaultActiveKeys = options ? options.map((_, i) => i.toString()) : '0';

  return (
    <div className="splitBars-container">
      {options && (
        <Collapse defaultActiveKey={defaultActiveKeys}>
          {options.map((opt, i) => (
            <Collapse.Panel header={opt.ownHeader.text} key={i}>
              <HighchartsReact
                key={i}
                highcharts={Highcharts}
                options={opt}
                containerProps={{ style: { width: '100%', height: '100%' } }}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default SplitCharts;
