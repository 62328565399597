/**
 * Manage all population modification consequences on an audience
 *
 * @param store
 * @param segmentId
 * @param segmentService
 * @param seriesService
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { getCountryIdFromIso } from '../../../core/helpers/country';
import { SelectedVariable } from '../../Common/Context/interfaces/audiences';
import { Serie } from '../../../core/series/interface';
import { GlobalState } from '../global';
import { retrieveCrossResultsThunk } from './retrieveCrossResults';
import { retrieveStatThunk } from './retrieveStats';

export const updateSegmentPopulationThunk = createAsyncThunk<
  void,
  {
    segmentId: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface } }
>('segment/population/update', async ({ segmentId }, { getState, dispatch }): Promise<void> => {
  const segments = getState().segments.filter((segment) => segment.id == segmentId);
  if (segments.length === 0) {
    return;
  }
  const segment = segments[0];
  await dispatch(retrieveStatThunk({ segment }));

  const countries = segment.countryIsos;
  const crossResults = getState().crossResults.selectedResponses[[...countries].sort().join('')];
  // no comparaison vars ever been set for these countries?
  if (!crossResults) {
    return;
  }
  const variableIds = Object.entries(crossResults);

  for (const [variableId, responses] of variableIds) {
    const variableDetails = getState().variablesDetails.filter(
      (vdSerie: Serie) => vdSerie.globalTags.variableId === variableId,
    )[0];

    if (!variableDetails) {
      throw 'Unfound variable details !';
    }

    const variable = {
      variable: {
        variable_id: variableId,
        variable_name: variableDetails.globalTags.variableName,
        isparent: variableDetails.globalTags.isParent,
      },
      responses: responses
        .map((res) => {
          if (res === 'mediaConsumption') {
            const responses = variableDetails.tags.filter((el) => el.responseId !== 'mediaConsumption');
            return responses.flatMap((resp) => ({
              value: resp.responseId,
            }));
          }
          return { value: res };
        })
        .flat(),
      variableUse: 'INCLUDE',
    } as SelectedVariable;

    await Promise.all(
      countries.map(async (country) => {
        await dispatch(
          retrieveCrossResultsThunk({
            variable,
            countryId: getCountryIdFromIso(country),
            segment,
            dataScope: segment.dataScope,
          }),
        );
      }),
    );
  }
});
