// import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { isArray } from 'lodash';
import { FC, useState } from 'react';
import { SemanticColors } from '../../../core/helpers/colors';
import './VariableSelector.less';
import { IconChevronDown, IconChevronRight } from '../../Svg/HavasIcons';

export interface FirstLevelCategory {
  name: string;
  children: SubLevelCategory[];
}

export interface SubLevelCategory {
  name: string;
  leaf: boolean;
}

const SubSection: FC<{
  subQuestion: SubLevelCategory;
  handler: (id: string[], loadNext: boolean) => void;
  selected: boolean;
  // colors: SemanticColors;
}> = ({ subQuestion, handler, selected }) => {
  // const [isHover, setIsHover] = useState(false);

  // const hoverColor = colors.darker2;
  // const handleMouseEnter = () => {
  //   setIsHover(true);
  // };
  // const handleMouseLeave = () => {
  //   setIsHover(false);
  // };
  // const style = {
  //   background: isHover ? hoverColor : 'transparent',
  // };
  return (
    <div
      className={'question' + (selected ? ' selected' : '')}
      // style={style}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
      onClick={(e) => {
        handler([subQuestion.name], !subQuestion.leaf);
        e.stopPropagation();
        return;
      }}
    >
      {subQuestion.name}
    </div>
  );
};

const CategoryWrapper: FC<{
  id: string;
  items: SubLevelCategory[];
  onSelect: (selection: string[], loadNext: boolean) => void;
  colors: SemanticColors;
  selected: boolean;
  selection: string[] | undefined;
}> = ({ id, items, onSelect, selected, selection }) => {
  const [collapse, setCollapse] = useState(!isArray(selection));
  const handleClick = (selection: string[], loadNext: boolean) => {
    onSelect([id, ...selection], loadNext);
  };

  const isLeaf = items.length == 0;
  return (
    <>
      <div
        className="item-wrapper"
        key={id}
        onClick={() => {
          isLeaf || setCollapse(!collapse);
        }}
      >
        <span
          className={'question' + (selected ? ' selected' : '')}
          onClick={() => {
            onSelect([id], true);
          }}
        >
          {id}
        </span>
        {!isLeaf || !selected || <IconChevronRight className="appendix" />}
        {isLeaf ? (
          selected || <span className="placeholder"></span>
        ) : collapse ? (
          <IconChevronRight className="c-icon-stroke c-icon--16" />
        ) : (
          <IconChevronDown className="c-icon-stroke c-icon--16" />
        )}
      </div>
      {collapse || (
        <div className="listing hawaii-thin-scrollbar">
          {items.map((subCategory) => (
            <SubSection
              subQuestion={subCategory}
              key={subCategory.name}
              handler={handleClick}
              selected={isArray(selection) && subCategory.name === selection[0]}
              // colors={colors}
            ></SubSection>
          ))}
        </div>
      )}
    </>
  );
};

interface Props {
  categories: FirstLevelCategory[];
  colors: SemanticColors;
  onSelect: (selection: string[], loadNext: boolean) => void;
  selection?: string[];
}

const CategoriesSelector: FC<Props> = ({ categories, colors, onSelect, selection }) => {
  return (
    <div className="variable-selector-section hawaii-thin-scrollbar">
      {categories.length === 0 ? (
        <div className="loader-container">
          <Spin size="large" />
        </div>
      ) : (
        categories.map((category) => (
          <CategoryWrapper
            id={category.name}
            key={category.name}
            items={category.children}
            onSelect={onSelect}
            colors={colors}
            selected={isArray(selection) && selection.length !== 0 && selection[0] === category.name}
            selection={
              !selection || selection.length === 0 || category.name !== selection[0] ? undefined : selection.slice(1)
            }
          />
        ))
      )}
    </div>
  );
};

export default CategoriesSelector;
