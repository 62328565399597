import './settings.less';
import { FC } from 'react';
import { Row, Col } from 'antd';
import Selector from './selector';
import SearchBar from './searchBar';

interface Props {
  handleSearch: (prompt: string) => void;
  setMaxAlternate: React.Dispatch<React.SetStateAction<number>>;
  setMaxResults: React.Dispatch<React.SetStateAction<number>>;
  setValResults: React.Dispatch<React.SetStateAction<boolean>>;
  setMatchCriteria: React.Dispatch<React.SetStateAction<string>>;
  maxAlternate: number;
  maxResults: number;
  valResults: boolean;
  matchCriteria: string;
  defaultPrompt: string;
}

const Settings: FC<Props> = ({
  handleSearch,
  setMaxAlternate,
  setMaxResults,
  setValResults,
  setMatchCriteria,
  maxAlternate,
  maxResults,
  valResults,
  matchCriteria,
  defaultPrompt,
}) => {
  const generateOptions = (
    arr: (string | number | boolean)[],
  ): { value: string | number | boolean; label: string }[] => {
    return arr.map((item: string | number | boolean) => {
      let label: string;
      if (typeof item === 'boolean') {
        label = item ? 'Yes' : 'No';
      } else {
        label = item.toString();
      }

      return { value: item, label: label };
    });
  };

  return (
    <div className="settings_container">
      <h3>Settings</h3>
      <div className="selectors">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <Selector
              label={'Select the maximum number of versions of subgroup that the AI will use'}
              toolTip={
                'The AI generates different versions of each subgroup in order to capture the meaning of it. e.g. [3] “on a budget” -> “affordable”, “cost effective”, “budget conscious”'
              }
              options={generateOptions([1, 2, 3, 4])}
              defaultValue={maxAlternate}
              handleChange={setMaxAlternate}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Select the maximum number of attributes per subgroup'}
              toolTip={
                'For each subgroup created by the AI, you will be proposed a maximum number of corresponding attributes'
              }
              options={generateOptions([1, 2, 3, 4])}
              defaultValue={maxResults}
              handleChange={setMaxResults}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Do you want to force the AI to be the most accurate?'}
              toolTip={
                'If you select ‘yes’, the AI will apply some filters to propose you the most relevant attributes'
              }
              options={generateOptions([true, false])}
              defaultValue={valResults}
              handleChange={setValResults}
            />
          </Col>
          <Col span={12}>
            <Selector
              label={'Matching criteria'}
              toolTip={
                'Select the level of matching accuracy between the prompt and the attributes suggested by the AI'
              }
              options={generateOptions(['High', 'Medium', 'Low'])}
              defaultValue={matchCriteria}
              handleChange={setMatchCriteria}
            />
          </Col>
        </Row>
      </div>
      <div className="searchPanel">
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <SearchBar defaultValue={defaultPrompt} handleSearch={handleSearch} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Settings;
