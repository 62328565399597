import { PanelCriteria, ResponseInfos } from 'hawaii';
import { FC } from 'react';
import ClusterVariableCard from './clusterVariableCardComponent';

interface Props {
  criteria: PanelCriteria;
  operatorControl: 'AND' | 'OR';
  removeCriteriaAction: () => void;
  updateResponsesAction: () => void;
  removeResponsesAction: (response: ResponseInfos) => void;
  addCriteriaAction: (operator: 'AND' | 'OR') => void;
  changeCriteriaRuleAction: (criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => void;
  color: string;
  disabled: boolean;
}

export const SegmentCriteriaBlock: FC<Props> = ({
  criteria,
  operatorControl,
  removeCriteriaAction,
  updateResponsesAction,
  removeResponsesAction,
  addCriteriaAction,
  changeCriteriaRuleAction,
  color,
  disabled,
}) => {
  return (
    <div className="criteria">
      <ClusterVariableCard
        criteria={criteria}
        operatorControl={operatorControl}
        removeCriteriaAction={removeCriteriaAction}
        addCriteriaAction={(operator: 'AND' | 'OR') => addCriteriaAction(operator)}
        updateResponsesAction={updateResponsesAction}
        removeResponsesAction={removeResponsesAction}
        changeCriteriaRuleAction={changeCriteriaRuleAction}
        color={color}
        disabled={disabled}
      ></ClusterVariableCard>
    </div>
  );
};
