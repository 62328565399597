import '../styles/styles.less';
import { FC, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ContextProvider from './Common/Context/Context';
import SegmentBuilder from './Pages/SegmentBuilder/SegmentBuilder';
import ClusterBuilder from './Pages/Cluster/clusterBuilder';
import ClusterEditor from './Pages/Cluster/clusterEditor';
import Propensity from './Pages/Propensity';
import CrossCountry from './Pages/CrossCountry/CrossCountry';
import SegmentEditorBootstrap from './Pages/SegmentEditor/SegmentEditorBootstrap';
import { factory } from '../core/factory';
import { useDispatch, useSelector } from 'react-redux';

import NotFound from './Pages/NotFound';
import { GlobalState } from './store/global';
import { clarity } from 'react-microsoft-clarity';
import { config } from '../core/config';
import Load from './Common/Load';
import CacheList from './Pages/cacheList';
import HomePage from './Pages/Home/Home';
import { verifyMdmUser } from './store/thunk/secmdm/verifyMdmUser';

const App: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    clarity.init(config.clarity.projectId as string);
  }, []);

  useEffect(() => {
    dispatch(verifyMdmUser());
  }, [dispatch]);

  const user = useSelector((state: GlobalState) => state.secmdmState).user;

  if (!user.userExist)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Load style={{ width: '3.5%' }} />
      </div>
    );

  return (
    <ContextProvider>
      <Routes>
        <Route path="/cache" element={<CacheList />} />

        <Route path="/client">
          <Route path="" element={<HomePage />} />
          <Route path="segment-builder" element={<SegmentBuilder />} />
          <Route path="segment-builder/:country" element={<SegmentBuilder />} />

          <Route path="cluster-builder/:country" element={<ClusterBuilder />} />
          <Route path="cluster-builder/:country/:segmentID" element={<ClusterBuilder />} />

          <Route path="propensity/:country/:brandID?" element={<Propensity />} />

          <Route path="cross-country-analysis" element={<CrossCountry />} />
          <Route path="cross-country-analysis/:countries" element={<CrossCountry />} />
        </Route>

        <Route path="cluster-editor" element={<ClusterEditor />} />
        <Route path="segment-editor/:audienceRecordId?" element={<SegmentEditorBootstrap />} />
        <Route path="/" element={<Navigate to={{ pathname: '/client' }} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ContextProvider>
  );
};

export default App;
