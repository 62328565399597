import { MediaInter, SerieTag } from '../series/interface';

/**
 * Return all the values of a particular tag
 *
 * @param tagName
 * @returns
 */
export const tagValues = (
  tagName: string,
): ((tags: () => SerieTag[]) => Array<string | number | boolean | MediaInter[]>) => {
  return (tags: () => SerieTag[]): Array<string | number | boolean | MediaInter[]> =>
    tags()
      .map((tag) => tag[tagName]!)
      .filter((value, index, self) => self.indexOf(value) === index);
};

/**
 * Return true if the tags are contained in the operand tags
 *
 * @param tags
 * @returns
 */
export const tagsMatch = (tags: SerieTag): ((operandTags: SerieTag) => boolean) => {
  return (operandTags: SerieTag) => {
    const serieTags = Object.keys(operandTags);
    for (const tag in tags) {
      if (!serieTags.includes(tag)) {
        return false;
      } else {
        if (operandTags[tag] !== tags[tag]) {
          return false;
        }
      }
    }
    return true;
  };
};

/**
 * For a given tagName, return the first occurence of each value
 *
 * @param tagName
 * @returns
 */
export const tagSelectDistinct = (tagName: string): ((tags: () => SerieTag[]) => () => SerieTag[]) => {
  return (tags: () => SerieTag[]): (() => SerieTag[]) => {
    return (): SerieTag[] => {
      const result: SerieTag[] = [];
      const distinct: Array<string | number | boolean | MediaInter[]> = [];
      tags().forEach((tag) => {
        const value = tag[tagName];
        if (!distinct.includes(value)) {
          result.push(tag);
          distinct.push(value);
        }
      });
      return result;
    };
  };
};

export const tagIndexOf = (searchItem: SerieTag): ((tags: () => SerieTag[]) => number) => {
  return (tags: () => SerieTag[]): number => {
    return tags().findIndex((tag: SerieTag) => tagsMatch(searchItem)(tag));
  };
};
