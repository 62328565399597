import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CrossResults {
  selectedResponses: Record<string, Record<string, string[]>>;
}

const countries2String = (countries: string[]): string => {
  return [...countries].sort().join('');
};

const crossResultSlice = createSlice({
  name: 'crossResult',
  initialState: { selectedResponses: {} } as CrossResults,
  reducers: {
    toogleResponse(
      draft: CrossResults,
      action: PayloadAction<{ countryIsos: string[]; responseId: string; variableId: string }>,
    ) {
      const countries = countries2String(action.payload.countryIsos);
      const responsesIds = draft.selectedResponses[countries][action.payload.variableId] || [];
      const position = responsesIds.indexOf(action.payload.responseId);
      if (position === -1) {
        responsesIds.push(action.payload.responseId);
      } else {
        responsesIds.splice(position, 1);
      }
      draft.selectedResponses[countries][action.payload.variableId] = responsesIds;
    },
    addResponses(
      draft: CrossResults,
      action: PayloadAction<{ countryIsos: string[]; responseIds: string[]; variableId: string }>,
    ) {
      const countries = countries2String(action.payload.countryIsos);
      if (!draft.selectedResponses[countries]) {
        draft.selectedResponses[countries] = {};
      }

      if (!draft.selectedResponses[countries][action.payload.variableId]) {
        draft.selectedResponses[countries][action.payload.variableId] = action.payload.responseIds;
      }
    },
    removeVariable(draft: CrossResults, action: PayloadAction<{ countryIsos: string[]; variableId: string }>) {
      const countries = countries2String(action.payload.countryIsos);
      const updated = Object.assign(draft) as CrossResults;
      delete updated.selectedResponses[countries][action.payload.variableId];
      return updated;
    },
  },
});

export const {
  toogleResponse: crossResultsToogleResponse,
  addResponses: crossResultsAddResponses,
  removeVariable: crossResultsRemoveVariable,
} = crossResultSlice.actions;

export default crossResultSlice;
