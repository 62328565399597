import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { setSaveAudienceAction, setSaveAudienceModalOpen } from '../../amdmSlice';

export const exploreAudienceFromAmdmThunk = createAsyncThunk<
  void,
  {
    audienceRecordId: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/explore`, async ({ audienceRecordId }, { dispatch, extra }): Promise<void> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    const { agencyId } = await amdmService.explore(audienceRecordId);

    if (!agencyId || agencyId === -1) {
      dispatch(setSaveAudienceAction('update-infos'));
      dispatch(setSaveAudienceModalOpen(true));
    }
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
