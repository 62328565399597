export type Tabs = 'SPIDER' | 'FUSION TABLE' | 'TABLE' | 'BARS' | 'SPLIT BARS' | 'COLUMNS' | 'SPLIT COLUMNS' | 'PIE';

export interface PageProperty {
  title: string;
  menuCaption: string | undefined;
  url: string;
  defaultParams?: Record<string, string>;
}

export const PageProperties: Record<string, PageProperty> = {
  'segment-builder': {
    title: 'Segment Builder',
    menuCaption: 'Segment Builder',
    url: '/client/segment-builder/:country',
  },
  'cross-country': {
    title: 'Cross Country',
    menuCaption: 'Cross Country Analysis',
    url: '/client/cross-country-analysis/:countries',
    defaultParams: {
      countries: '',
    },
  },
  'cluster-builder': {
    title: 'Cluster Builder',
    menuCaption: 'Cluster Builder',
    url: '/client/cluster-builder/:country/:segmentID',
  },
  'cluster-editor': {
    title: 'Cluster Edition',
    menuCaption: undefined,
    url: '/cluster-editor',
  },
  'propensity-modeling': {
    title: 'Propensity',
    menuCaption: 'Propensity',
    url: '/client/propensity/:country',
  },
  'segment-editor': {
    title: 'Segment Edition',
    menuCaption: undefined,
    url: '/segment-editor',
  },
};

export class BackendUnmanagedException extends Error {}
