import '../../../MetaComponents/Segments/SegmentsWrapper.less';
import { FC, useEffect, useState, useRef, useContext } from 'react';
import SegmentManager from '../../../MetaComponents/SegmentManager/SegmentManager';
import { Segment } from '../../../../core/segment/segments';
import SegmentCard from '../../../Common/SegmentCard/SegmentCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { PageProperties } from '../../../interface';
import { Context } from '../../../Common/Context/Context';
import { importSegmentThunk } from '../../../store/thunk/importSegment';
import { GlobalState } from '../../../store/global';
import { selectActivationProcessByCountry } from '../../../store/activationProcessSlice';
import { setEditionSegment } from '../../../store/segmentEditionSlice';
import segmentSlice from '../../../store/segmentSlice';
import { DataScope, SegmentOrigin, SegmentWithUiMeta } from 'hawaii';
import { useDataToCountDown } from '../../../Common/hooks/useCountDown';

interface Props {
  segments: Segment[];
  countryIsos: string[];
  newSegmentAction: () => SegmentWithUiMeta;
  dataScope: DataScope;
  link: string;
  origin: SegmentOrigin;
}

const SegmentsWrapper: FC<Props> = ({ segments, countryIsos, newSegmentAction, dataScope, link, origin }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [selectedSegment, setSelectedSegment] = useState<number | undefined | null>(undefined);
  const [storedSegments, setStoredSegments] = useState<Segment[]>([]);
  const [sharedSegments, setSharedSegments] = useState<Segment[]>([]);
  const databricks = useSelector((state: GlobalState) => state.databricks);
  const { currentRunsTime, getCurrentRunsTime } = useDataToCountDown();
  const navigate = useNavigate();

  const retrieveStoredSegments = async () => {
    const segments = await sharedSegmentService.getClustersMine(countryIsos, dataScope);
    setStoredSegments(segments);
  };

  const retrieveSharedSegments = async () => {
    const segments = await sharedSegmentService.getClustersAll(countryIsos, dataScope);
    setSharedSegments(segments);
  };

  const handleDeleteSegment = (id: string) => {
    dispatch(segmentSlice.actions.removeSegment({ id }));
    setSelectedSegment(null);
    navigate(`/client/cluster-builder/${countryIsos[0]}`);
  };

  const handleRefreshStoredSegments = async () => {
    await retrieveStoredSegments();
  };

  const handleImportSegment = (segment: Segment) => {
    dispatch(importSegmentThunk({ segment }));
  };

  const handleSelect = (index: number) => {
    setSelectedSegment(index);
    dispatch(
      setEditionSegment({
        segment: {
          ...segments[index],
          origin: origin,
        },
      }),
    );
    if (databricks['clusters']?.[segments[index].publicId]) {
      navigate(`/client/cluster-builder/${countryIsos[0]}/${segments[index].publicId}`);
    } else {
      navigate(PageProperties[link].url);
    }
  };

  const dispatch = useDispatch();

  const { factory } = useContext(Context);
  const sharedSegmentService = factory.getSharedSegmentService();

  const activationProcesses = useSelector((state: GlobalState) =>
    dataScope === 'Original' ? selectActivationProcessByCountry(state, countryIsos[0]) : [],
  );

  useEffect(() => {
    !elementRef.current || elementRef.current.scrollIntoView();
  });

  useEffect(() => {
    const run = async () => {
      await retrieveStoredSegments();
      await retrieveSharedSegments();
    };
    void run();
  }, [countryIsos]);

  useEffect(() => {
    if (Object.keys(databricks).length < 0) return;
    getCurrentRunsTime(databricks);
  }, [databricks]);

  return (
    <>
      <div className="audiences-wrapper no-scrollbar">
        <div className="audience-cards">
          {segments.map((segment, index) => {
            const ap = activationProcesses.find((ap) => ap.segmentId === segment.id);
            return (
              <SegmentCard
                segment={segment}
                indexingState={ap !== undefined ? ap.status : undefined}
                removeAction={() => handleDeleteSegment(segment.id)}
                selectionAction={(selected: boolean) => {
                  if (selected) {
                    handleSelect(index);
                  }
                }}
                selected={selectedSegment === index}
                key={index}
                currentRunsTime={currentRunsTime}
              ></SegmentCard>
            );
          })}
          <div ref={elementRef} />
        </div>
      </div>
      <SegmentManager
        buttonTitle="Select variables"
        newAudienceAction={newSegmentAction}
        importSegmentAction={handleImportSegment}
        sharedSegmentService={sharedSegmentService}
        storedSegments={storedSegments}
        sharedSegments={sharedSegments}
        refreshStoredSegmentsAction={handleRefreshStoredSegments}
        activationProcesses={activationProcesses}
      />
    </>
  );
};

export default SegmentsWrapper;
