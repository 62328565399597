import axios, { AxiosResponse, AxiosError } from 'axios';
import { msalInstance } from '..';
import { AccountInfo } from '@azure/msal-browser';

export class NotFoundException extends Error {}
export class BackendAuthRequired extends Error {}
export class BackendForbidden extends Error {}
export class BackendErrorCanRetry extends Error {}
export class BackendErrorDontRetry extends Error {}
export class CanceledRequest extends Error {}
export class AMDMRequestError extends Error {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertException = (response: AxiosResponse<any>): Error => {
  const { message } = response.data as { message: string };
  switch (response.status) {
    case 404:
      return new NotFoundException(message);
    case 401:
      return new BackendAuthRequired();
    case 403:
      return new BackendForbidden(message);
    case 408:
    case 502:
    case 504:
    case 522:
    case 523:
    case 524:
      return new BackendErrorCanRetry(`Backend error : ${response.status}`);
    case 400:
    case 500:
      return new BackendErrorDontRetry(`Backend error ${response.status}`);
    case 901:
      return new AMDMRequestError(message);
    default:
      return new BackendErrorDontRetry(`Backend error ${response.status}`);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function instanceOfAxiosResponse(object: any): object is AxiosError {
  // the only property that is need to check
  return 'response' in object;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCanceled = (object: any): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return 'message' in object && object.message === 'canceled';
};

export interface BackendFacadeInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post: <Type>(
    path: string,
    body: any,
    params?: Record<string, string | boolean | number | undefined>,
  ) => Promise<Type>;
  get: <Type>(path: string) => Promise<Type>;
  abort: () => void;
}

const backendFacade = (backendUrl: string): BackendFacadeInterface => {
  let abortController = new AbortController();

  /**
   * If not already, configure axios to add the Bearer Token in all request.
   *
   * @returns
   */
  const addBearerTokenInHeaders = async (): Promise<void> => {
    try {
      const userAccount = msalInstance.getActiveAccount() as AccountInfo;
      const authentication = await msalInstance.acquireTokenSilent({
        scopes: ['user.read'],
        account: userAccount,
        forceRefresh: true,
      });
      axios.defaults.headers.common['Authorization'] = `Bearer ${authentication.idToken}`;
    } catch (error) {
      await msalInstance.acquireTokenRedirect({
        scopes: ['user.read'],
      });
      console.error('Unable to get token from header !');
    }
  };

  return {
    /**
     * Run a basic post request and return the content
     *
     * @param path
     * @param body
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post: async <Type>(
      path: string,
      body: any,
      params?: Record<string, string | boolean | number | undefined>,
    ): Promise<Type> => {
      await addBearerTokenInHeaders();
      let response: AxiosResponse<Type> | undefined = undefined;
      try {
        response = await axios.post(`${backendUrl}${path}`, body, {
          withCredentials: true,
          signal: abortController.signal,
          params,
        });
      } catch (err) {
        if (isCanceled(err)) {
          throw new CanceledRequest();
        }
        if (instanceOfAxiosResponse(err)) {
          response = err.response as AxiosResponse;
          if (response.status) {
            if (response.status === 401 || response.status === 406) {
              await msalInstance.logoutRedirect();
              throw convertException(response);
            } else {
              throw convertException(response);
            }
          }
          throw new BackendErrorDontRetry(`Backend return an unmanaged code`);
        }
        throw new BackendErrorDontRetry(`Unrecoverable backend error`);
      }

      if (response === undefined || !response.status || !response.data) {
        throw new BackendErrorCanRetry(`Empty response`);
      }

      if (response.status !== 200) {
        throw convertException(response);
      }

      // @TODO : move this on a dedicated place
      const messages = response.headers['front-message'];
      let data;
      if (messages !== undefined) {
        try {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          data = JSON.parse(messages) as Record<string, any>;
        } catch (err) {
          console.info(messages);
        }
        if (data !== undefined) {
          if (data.bigQuery !== undefined) {
            /* eslint-disable @typescript-eslint/no-unsafe-member-access */
            /* eslint-disable @typescript-eslint/no-unsafe-call */
            /* eslint-disable @typescript-eslint/no-unsafe-argument */
            /* eslint-disable @typescript-eslint/no-unsafe-return */
            console.info(
              `"QUERY ${path}"`,
              ...data.bigQuery
                .map((bq: { query: { query: string; params: any } }) => [bq.query.query, bq.query.params])
                .flat(),
            );
          } else {
            console.info(data);
          }
        }
      }
      return response.data;
    },
    /**
     * Run a basic HTTP get and return the content
     *
     * @param path
     * @returns
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get: async <Type>(path: string): Promise<Type> => {
      await addBearerTokenInHeaders();
      let response: AxiosResponse<Type> | undefined = undefined;
      try {
        response = await axios.get(`${backendUrl}${path}`, {
          withCredentials: true,
          signal: abortController.signal,
        });
      } catch (err) {
        if (isCanceled(err)) {
          throw new CanceledRequest();
        }
        if (instanceOfAxiosResponse(err)) {
          response = err.response as AxiosResponse;
          if (response.status) {
            throw convertException(response);
          }
          throw new BackendErrorDontRetry(`Backend return an unmanaged code`);
        }
        throw new BackendErrorDontRetry(`Unrecoverable backend error`);
      }

      if (response === undefined || !response.status || !response.data) {
        throw new BackendErrorCanRetry(`Empty response`);
      }

      if (response.status !== 200) {
        throw convertException(response);
      }
      return response.data;
    },

    /**
     * Abort all requests
     */
    abort: () => {
      abortController.abort();
      abortController = new AbortController();
    },
  };
};

export interface BackendFacadeFactoryInterface {
  get: () => BackendFacadeInterface;
}

/**
 * The factory distribute backendFacade with brand new abort controller
 *
 * @param backendFacade
 * @param notFoundHandler
 * @param forbiddenHandler
 * @param canRetryHandler
 * @param authRequiredHandler
 * @param dontRetryHandler
 * @returns
 */
const BackendFacadeFactory = (backendUrl: string): BackendFacadeFactoryInterface => ({
  get: (): BackendFacadeInterface => backendFacade(backendUrl),
});

export default BackendFacadeFactory;
