import { SegmentCommonProperties, SegmentWithUiMeta } from 'hawaii';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';

import { initialSegmentProperties } from './segment/helper';
import { AMDMActivateStudioState, DataBricksMatching } from '../react/store/activateStudioSlice';

export interface AMDMInfos {
  id: string;
  audienceRecordId: string;
  audienceVersionId: string;
}

export interface AMDMMxInfos {
  id: number;
  name: string;
}

export interface AMDMClientsInfos extends AMDMMxInfos {
  isPitch: boolean;
}

export interface AmdmServiceInterface {
  abort: () => void;
  setOwnerUpn: (ownerUpn: { ownerUpn: string }) => Promise<AmdmUpn>;
  getOwnerUpn: () => Promise<AmdmUpn>;
  save: (segment: SegmentWithUiMeta, agency: number, client?: number | string) => Promise<AMDMInfos>;
  updateInfos: (segment: SegmentWithUiMeta, agency: number, client?: number | string) => Promise<void>;
  get: (audienceRecordId: string) => Promise<Record<string, string | number>>;
  explore: (audienceRecordId: string) => Promise<{ agencyId: number; clientId: number }>;
  delete: (segment: SegmentWithUiMeta) => Promise<SegmentWithUiMeta>;
  listAgencies: () => Promise<AMDMMxInfos[]>;
  listClients: () => Promise<AMDMClientsInfos[]>;
  getAudienceMatchingState: (audienceRecordId: string, selectedCountry: string) => Promise<AMDMActivateStudioState>;
  dataBricksMatch: (audienceRecordId: string) => Promise<DataBricksMatching>;
}

export interface AmdmUpn {
  encryptedUpn: string;
  ownerUpn: string;
}

const AmdmService = (backend: BackendFacadeInterface): AmdmServiceInterface => {
  const mandatoryKeys = ['version', 'id', 'criterias'];

  /**
   * Check the result from backend
   *
   * @param raw
   */
  const checkKeys = (raw: SegmentCommonProperties) => {
    const keys = Object.keys(raw);
    mandatoryKeys.forEach((mandatoryKey) => {
      if (keys.indexOf(mandatoryKey) === -1) {
        throw new Error(`Mandatory key ${mandatoryKey} not present`);
      }
    });
  };

  /**
   * Assemble result from the backend response
   *
   * The id fields from the backend wrap takes precedence (hence the frontend cannot spoof it)
   *
   * @param raw
   */
  const assemble = (raw: SegmentCommonProperties): SegmentWithUiMeta => {
    checkKeys(raw);
    return initialSegmentProperties(raw);
  };

  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    setOwnerUpn: async (ownerUpn: { ownerUpn: string }): Promise<AmdmUpn> => {
      const body = ownerUpn;
      return await backend.post<AmdmUpn>('/v1/amdm/user/set-amdm-upn', body);
    },

    getOwnerUpn: async (): Promise<AmdmUpn> => {
      return await backend.get<AmdmUpn>('/v1/amdm/user/get-amdm-upn');
    },

    /**
     * Call the backend to save the segment parameters in the Amdm
     * @param segment
     */
    save: async (segment: SegmentWithUiMeta, agency: number, client?: number | string): Promise<AMDMInfos> => {
      const body = { segment, agency, client };
      return await backend.post<AMDMInfos>('/v1/amdm/audience/save', body);
    },

    /**
     * Call the backend to update the segment agency and client in the Amdm
     * @param segment
     */
    updateInfos: async (segment: SegmentWithUiMeta, agency: number, client?: number | string): Promise<void> => {
      const body = { segment, agency, client };
      return await backend.post<void>('/v1/amdm/audience/update-infos', body);
    },

    /**
     * Call the backend to get the segment information from the Amdm
     * @param segment
     */
    get: async (audienceRecordId: string): Promise<Record<string, string | number>> => {
      const audience = await backend.get<Record<string, string | number>>(`/v1/amdm/audience/${audienceRecordId}`);
      return audience;
    },

    /**
     * Call the backend to update the segment expiration date from the Amdm
     * @param segment
     */
    explore: async (audienceRecordId: string): Promise<{ agencyId: number; clientId: number }> => {
      return await backend.get<{ agencyId: number; clientId: number }>(`/v1/amdm/audience/explore/${audienceRecordId}`);
    },

    /**
     * Call the backend to delete the segment information from the Amdm
     * @param segment
     */
    delete: async (segment: SegmentWithUiMeta): Promise<SegmentWithUiMeta> => {
      const body = segment;
      const raw = await backend.post<SegmentWithUiMeta>(`/v1/amdm/audience/deletion`, body);
      return assemble(raw);
    },

    listAgencies: async (): Promise<AMDMMxInfos[]> => {
      return await backend.get<AMDMMxInfos[]>(`/v1/amdm/agencies`);
    },

    listClients: async (): Promise<AMDMClientsInfos[]> => {
      return await backend.get<AMDMClientsInfos[]>(`/v1/amdm/clients`);
    },

    getAudienceMatchingState: async (
      audienceRecordId: string,
      selectedCountry: string,
    ): Promise<AMDMActivateStudioState> => {
      return await backend.get<AMDMActivateStudioState>(
        `/v1/amdm/activation-studio/${audienceRecordId}/${selectedCountry}`,
      );
    },

    dataBricksMatch: async (audienceRecordId: string): Promise<DataBricksMatching> => {
      return await backend.post<DataBricksMatching>(`/v1/amdm/activation-studio`, { audienceRecordId });
    },
  };
};

export interface AmdmServiceFactoryInterface {
  get: () => AmdmServiceInterface;
}

const AmdmServiceFactory = (backendFactory: BackendFacadeFactoryInterface): AmdmServiceFactoryInterface => ({
  get: () => AmdmService(backendFactory.get()),
});

export default AmdmServiceFactory;
