import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { getCountryIdFromIso } from '../../../core/helpers/country';
import { GlobalState } from '../global';
import { SegmentWithUiMeta } from 'hawaii';

export const retrieveStatThunk = createAsyncThunk<
  { count: number; totalRespondents: number; marketSize: number },
  { segment: SegmentWithUiMeta },
  { state: GlobalState; extra: { factory: FactoryInterface } }
>(
  'stats/retrieve',
  async ({ segment }, { extra }): Promise<{ count: number; totalRespondents: number; marketSize: number }> => {
    const segmentService = extra.factory.getSegmentService();
    return await segmentService.retrieveStats(segment, segment.countryIsos.map(getCountryIdFromIso));
  },
);
