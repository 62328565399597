import xlsx, { WorkSheet } from 'node-xlsx';
import downloadjs from 'downloadjs';
import { humanReadable } from '../segment/helper';
import { CountryMetadata, SegmentWithUiMeta } from 'hawaii';

export type TableMimeType = 'Excel';

const convertMimeNameToRealMimeType = (name: TableMimeType): string => {
  if (name == 'Excel') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  }
  throw new Error(`Mime type not implemented : ${name as string}`);
};

export const exportTableAsXlsx = (
  segments: SegmentWithUiMeta[],
  data: { countryIso: string; data: unknown[][] }[],
  mimetype: TableMimeType = 'Excel',
  countries: CountryMetadata[],
  surveyDate: string,
): void => {
  const date = new Date().toLocaleDateString().replaceAll('/', '-');

  const content: WorkSheet<unknown>[] = [];

  const audienceDescription = [
    ...segments.map((segment: SegmentWithUiMeta) => [segment.name, humanReadable(segment)]),
    [],
    [],
  ];

  const surveyDesctiption = [['time', surveyDate]];

  for (const countryDim of countries) {
    const countryDescription = [['country', countryDim.name]];

    const targetedData = data.find((d) => d.countryIso === countryDim.iso);
    if (targetedData) {
      content.push({
        name: `${countryDim.iso}-compared-audiences`,
        data: [...countryDescription, ...surveyDesctiption, ...audienceDescription, ...targetedData.data],
        options: {},
      });
    }
  }

  const totalCountries = data.find((d) => d.countryIso === 'tt');
  if (totalCountries) {
    const countryDescription = [['countries', countries.map((c) => c.name).join(',')]];
    content.push({
      name: `tt-compared-audiences`,
      data: [...countryDescription, ...surveyDesctiption, ...audienceDescription, ...totalCountries.data],
      options: {},
    });
  }

  const buffer = xlsx.build(content);

  downloadjs(
    buffer.toString(),
    `profiles-studio-export-${date}-compared-audiences.xlsx`,
    convertMimeNameToRealMimeType(mimetype),
  );
};
