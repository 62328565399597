import { FC, useEffect, useState } from 'react';
const fakeTypes = [
  'aa-enabled',
  'aa-exec',
  'aa-features-abi',
  'aclocal',
  'aclocal-1.16',
  'aconnect',
  'acpi_listen',
  'acyclic',
  'add-apt-repository',
  'addpart',
  'addr2line',
  'airscan-discover',
  'alien',
  'alsabat',
  'alsaloop',
  'alsamixer',
  'alsatplg',
  'alsaucm',
  'amidi',
  'amixer',
  'anthoscli',
  'anydesk',
  'anydesk-global-settings',
  'apg',
  'apgbfm',
  'aplay',
  'aplaymidi',
  'apport-bug',
  'apport-cli',
  'apport-collect',
  'apport-unpack',
  'appres',
  'appstreamcli',
  'apropos',
  'apt',
  'apt-add-repository',
  'apt-cache',
  'apt-cdrom',
  'apt-config',
  'aptdcon',
  'apt-extracttemplates',
  'apt-ftparchive',
  'apt-get',
  'apt-key',
  'apt-mark',
  'apt-sortpkgs',
  'apturl',
  'apturl-gtk',
  'ar',
  'arch',
  'arecord',
  'arecordmidi',
  'arm2hpdl',
  'as',
  'aseqdump',
  'aseqnet',
  'aspell',
  'aspell-import',
  'atobm',
  'autoconf',
  'autoheader',
  'autom4te',
  'automake',
  'automake-1.16',
  'autopoint',
  'autoreconf',
  'autoscan',
  'autoupdate',
  'avahi-browse',
  'avahi-browse-domains',
  'avahi-publish',
  'avahi-publish-address',
  'avahi-publish-service',
  'avahi-resolve',
  'avahi-resolve-address',
  'avahi-resolve-host-name',
  'avahi-set-host-name',
  'avinfo',
  'awk',
  'axfer',
  'b2sum',
  'baobab',
  'base32',
  'base64',
  'basename',
  'basenc',
  'bash',
  'bashbug',
  'bc',
  'bcomps',
  'bdftopcf',
  'bdftruncate',
  'bitmap',
  'bluemoon',
  'bluetoothctl',
  'bluetooth-sendto',
  'bmtoa',
  'boltctl',
  'bootctl',
  'bq',
  'brltty',
  'brltty-ctb',
  'brltty-trtxt',
  'brltty-ttb',
  'broadwayd',
  'browse',
  'btattach',
  'btmgmt',
  'btmon',
  'bunzip2',
  'busctl',
  'busybox',
  'bwrap',
  'bzcat',
  'bzcmp',
  'bzdiff',
  'bzegrep',
  'bzexe',
  'bzfgrep',
  'bzgrep',
  'bzip2',
  'bzip2recover',
  'bzless',
  'bzmore',
  'c++',
  'c89',
  'c89-gcc',
  'c99',
  'c99-gcc',
  'calibrate_ppa',
  'canberra-gtk-play',
  'cancel',
  'captoinfo',
  'cat',
  'catman',
  'cautious-launcher',
  'cc',
  'ccomps',
  'ckbcomp',
  'cksum',
  'clear',
  'clear_console',
  'cluster',
  'cmp',
  'codepage',
  'col',
  'colcrt',
  'colormgr',
  'colrm',
  'column',
  'comm',
  'compose',
  'containerd',
  'containerd-shim',
  'containerd-shim-runc-v1',
  'containerd-shim-runc-v2',
  'corelist',
  'cp',
  'cpan',
  'cpan5.34-x86_64-linux-gnu',
  'cpio',
  'cpio-filter',
  'cpp',
  'cpp-11',
  'cpp-12',
  'crc32',
  'c_rehash',
  'crontab',
  'csplit',
  'ctr',
  'ctstat',
  'cupstestppd',
  'curl',
  'cut',
  'cvt',
  'cvtsudoers',
  'dash',
  'date',
  'dbus-cleanup-sockets',
  'dbus-daemon',
  'dbus-monitor',
  'dbus-run-session',
  'dbus-send',
  'dbus-update-activation-environment',
  'dbus-uuidgen',
  'dbxtool',
  'dh_installinit',
  'dh_installinitramfs',
  'dh_installlogcheck',
  'dh_installlogrotate',
  'dh_installman',
  'dh_installmanpages',
  'dh_installmenu',
  'dh_installmime',
  'dh_installmodules',
  'dh_installpam',
  'dh_installppp',
  'dh_installsystemd',
  'dh_installsystemduser',
  'dh_installsysusers',
  'dh_installtmpfiles',
  'dh_installudev',
  'dh_installwm',
  'dh_installxfonts',
  'dh_installxmlcatalogs',
  'dh_link',
  'dh_lintian',
  'dh_listpackages',
  'dh_makeshlibs',
  'dh_md5sums',
  'dh_missing',
  'dh_movefiles',
  'dh_perl',
  'dh_perl_openssl',
  'dh_prep',
  'dh_shlibdeps',
  'dh_strip',
  'dh_strip_nondeterminism',
  'dh_systemd_enable',
  'dh_systemd_start',
  'dh_testdir',
  'dh_testroot',
  'dh_ucf',
  'dh_update_autotools_config',
  'dh_usrlocal',
  'diff',
  'diff3',
  'diffimg',
  'dig',
  'dijkstra',
  'dir',
  'dircolors',
  'dirmngr',
  'dirmngr-client',
  'dirname',
  'dirsplit',
  'distro-info',
  'dmesg',
  'dnsdomainname',
  'docker',
  'docker-credential-gcloud',
  'dockerd',
  'dockerd-rootless-setuptool.sh',
  'dockerd-rootless.sh',
  'docker-proxy',
  'domainname',
  'do-release-upgrade',
  'dot',
  'dot2gxl',
  'dot_builtins',
  'dotty',
  'dpkg',
  'dpkg-architecture',
  'dpkg-buildflags',
  'dpkg-buildpackage',
  'dpkg-checkbuilddeps',
  'dpkg-deb',
  'dpkg-distaddfile',
  'dpkg-divert',
  'dpkg-genbuildinfo',
  'dpkg-genchanges',
  'dpkg-gencontrol',
  'dpkg-gensymbols',
  'dpkg-maintscript-helper',
  'dpkg-mergechangelogs',
  'dpkg-name',
  'dpkg-parsechangelog',
  'dpkg-query',
  'dpkg-realpath',
  'dpkg-scanpackages',
  'dpkg-scansources',
  'dpkg-shlibdeps',
  'dpkg-source',
  'dpkg-split',
  'dpkg-statoverride',
  'dpkg-trigger',
  'dpkg-vendor',
  'driverless',
  'driverless-fax',
  'du',
  'dumpkeys',
  'duplicity',
  'dvipdf',
  'dwp',
  'dwz',
  'echo',
  'ed',
  'edgepaint',
  'edit',
  'editor',
  'editres',
  'efibootdump',
  'efibootmgr',
  'egrep',
  'eject',
  'elfedit',
  'enc2xs',
  'encguess',
  'enchant-2',
  'enchant-lsmod-2',
  'env',
  'envsubst',
  'eog',
  'eps2eps',
  'eqn',
  'esc-m',
  'eutp',
  'evince',
  'evince-previewer',
  'evince-thumbnailer',
  'ex',
  'expand',
  'expiry',
  'expr',
  'factor',
  'faillog',
  'faked-sysv',
  'faked-tcp',
  'fakeroot',
  'fakeroot-sysv',
  'fakeroot-tcp',
  'fallocate',
  'false',
  'fc-cache',
  'fc-cat',
  'fc-conflist',
  'fc-list',
  'fc-match',
  'fc-pattern',
  'fc-query',
  'fc-scan',
  'fc-validate',
  'fdp',
  'fgconsole',
  'fgrep',
  'file',
  'file2brl',
  'file-roller',
  'fincore',
  'find',
  'find-debuginfo',
  'findmnt',
  'git-upload-archive',
  'git-upload-pack',
  'gjs',
  'gjs-console',
  'gkbd-keyboard-display',
  'gke-gcloud-auth-plugin',
  'glib-compile-schemas',
  'globalprotect',
  'gmake',
  'gml2gv',
  'gpgconf',
  'gpg-connect-agent',
  'gpgparsemail',
  'gpgsm',
  'gpgsplit',
  'gpgtar',
  'gpgv',
  'gpg-wks-server',
  'gpg-zip',
  'gpic',
  'gprof',
  'gpu-manager',
  'graphml2gv',
  'grdctl',
  'grep',
  'gresource',
  'groff',
  'grog',
  'grops',
  'grotty',
  'groups',
  'grub-editenv',
  'grub-file',
  'grub-fstest',
  'grub-glue-efi',
  'grub-kbdcomp',
  'grub-menulst2cfg',
  'grub-mkfont',
  'grub-mkimage',
  'grub-mklayout',
  'grub-mknetdir',
  'grub-mkpasswd-pbkdf2',
  'grub-mkrelpath',
  'grub-mkrescue',
  'grub-mkstandalone',
  'grub-mount',
  'grub-ntldr-img',
  'grub-render-label',
  'grub-script-check',
  'grub-syslinux2cfg',
  'gts-config',
  'gtstemplate',
  'gunzip',
  'gv2gml',
  'gv2gxl',
  'gvcolor',
  'gvgen',
  'gvmap',
  'gvmap.sh',
  'gvpack',
  'gvpr',
  'gxl2dot',
  'gxl2gv',
  'gzexe',
  'gzip',
  'h2ph',
  'h2xs',
  'hardlink',
  'hbpldecode',
  'hciattach',
  'hciconfig',
  'hcitool',
  'hd',
  'head',
  'HEAD',
  'helpztags',
  'hex2hcd',
  'hexdump',
  'hipercdecode',
  'host',
  'hostid',
  'hostname',
  'hostnamectl',
  'hp-align',
  'hp-check',
  'hp-clean',
  'hp-colorcal',
  'hp-config_usb_printer',
  'hp-doctor',
  'install',
  'install-info',
  'install-printerdriver',
  'instmodsh',
  'intel-virtual-output',
  'ionice',
  'ip',
  'ipcmk',
  'ipcrm',
  'ipcs',
  'ipod-read-sysinfo-extended',
  'ipod-time-sync',
  'ippfind',
  'ipptool',
  'iptables-xml',
  'ischroot',
  'isdv4-serial-debugger',
  'isdv4-serial-inputattach',
  'isodump',
  'isoinfo',
  'isovfy',
  'ispell-wrapper',
  'java',
  'jexec',
  'jjs',
  'join',
  'journalctl',
  'jpgicc',
  'json_pp',
  'kbdinfo',
  'kbd_mode',
  'kbxutil',
  'kernel-install',
  'kerneloops-submit',
  'keyring',
  'keytool',
  'kill',
  'killall',
  'kmod',
  'kmodsign',
  'l2ping',
  'l2test',
  'laptop-detect',
  'last',
  'lastb',
  'lastlog',
  'lavadecode',
  'lcf',
  'ld',
  'ld.bfd',
  'ldd',
  'ld.gold',
  'lefty',
  'less',
  'lessecho',
  'lessfile',
  'lesskey',
  'lesspipe',
  'lexgrog',
  'libnetcfg',
  'libreoffice',
  'libtoolize',
  'libwacom-list-devices',
  'libwacom-list-local-devices',
  'libwacom-show-stylus',
  'libwacom-update-db',
  'link',
  'linkicc',
  'linux32',
  'linux64',
  'linux-boot-prober',
  'linux-check-removal',
  'linux-update-symlinks',
  'linux-version',
  'listres',
  'lwp-mirror',
  'lwp-request',
  'lzcat',
  'lzcmp',
  'lzdiff',
  'lzegrep',
  'lzfgrep',
  'lzgrep',
  'lzless',
  'lzma',
  'lzmainfo',
  'lzmore',
  'm2300w',
  'm2300w-wrapper',
  'm2400w',
  'm4',
  'make',
  'make-first-existing-target',
  'mako-render',
  'man',
  'mandb',
  'manpath',
  'man-recode',
  'mapscrn',
  'mawk',
  'mcookie',
  'md5sum',
  'md5sum.textutils',
  'mdig',
  'memusage',
  'memusagestat',
  'mesa-overlay-control.py',
  'mesg',
  'migrate-pubring-from-classic-gpg',
  'mimeopen',
  'mimetype',
  'min12xxw',
  'mingle',
  'mkdir',
  'mkfifo',
  'mkfontdir',
  'mkfontscale',
  'mkisofs',
  'mk_modmap',
  'mknod',
  'mksquashfs',
  'mktemp',
  'msgmerge',
  'msgunfmt',
  'msguniq',
  'mt',
  'mt-gnu',
  'mtr',
  'mtrace',
  'mtr-packet',
  'mv',
  'namei',
  'nano',
  'nautilus',
  'nautilus-autorun-software',
  'nautilus-sendto',
  'nawk',
  'nc',
  'nroff',
  'nsenter',
  'nslookup',
  'nstat',
  'nsupdate',
  'ntfs-3g',
  'ntfs-3g.probe',
  'ntfscat',
  'ntfscluster',
  'ntfscmp',
  'ntfsdecrypt',
  'ntfsfallocate',
  'ntfsfix',
  'ntfsinfo',
  'ntfsls',
  'ntfsmove',
  'ntfsrecover',
  'ntfssecaudit',
  'ntfstruncate',
  'ntfsusermap',
  'ntfswipe',
  'numfmt',
  'nvidia-detector',
  'oakdecode',
  'obexctl',
  'objcopy',
  'objdump',
  'oclock',
  'od',
  'oem-getlogs',
  'on_ac_power',
  'oomctl',
  'open',
  'openssl',
  'openvt',
  'opldecode',
  'orca',
  'orca-dm-wrapper',
  'osage',
  'os-prober',
  'p11-kit',
  'pacat',
  'pack200',
  'pacmd',
  'pactl',
  'padsp',
  'pager',
  'pa-info',
  'pamon',
  'paperconf',
  'paplay',
  'parec',
  'parecord',
  'partx',
  'passwd',
  'paste',
  'pasteurize',
  'pasuspender',
  'patch',
  'pw-v4l2',
  'py3clean',
  'py3compile',
  'py3versions',
  'pydoc3',
  'pydoc3.10',
];
const fakeDir = [
  '/sys/kernel/',
  '/sys/devices/cpu/',
  '/sys/devices/cpu/events/',
  '/sys/dev/block/',
  '/etc/alternatives/',
  '/proc/sysvipc/',
  '/var/cache/apt/',
];

const FileLoader: FC = () => {
  const [currentFile, setCurrentFile] = useState('');
  const initDirIndex = Math.floor(Math.random() * fakeDir.length);
  const [currentDir, setCurrentDir] = useState(fakeDir[initDirIndex]);
  const [fileChangeCount, setFileChangeCount] = useState(0);

  useEffect(() => {
    const fileIntervalId = setInterval(() => {
      const randomFileIndex = Math.floor(Math.random() * fakeTypes.length);

      setCurrentFile(fakeTypes[randomFileIndex]);
      setFileChangeCount((count) => {
        return count + 1;
      });
    }, 100);
    return () => clearInterval(fileIntervalId);
  }, []);

  useEffect(() => {
    const dirFrequency = 5;
    if (fileChangeCount >= dirFrequency) {
      const randomDirIndex = Math.floor(Math.random() * fakeDir.length);
      setCurrentDir(fakeDir[randomDirIndex]);
      setFileChangeCount(0);
    } else return;
  }, [fileChangeCount]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
      <span style={{ marginRight: '10px', minWidth: '50px' }}>Loading:</span>
      <span style={{ overflow: 'hidden', color: '#656565', minWidth: 'fit-content', whiteSpace: 'nowrap' }}>
        "{currentDir}
        {currentFile}"
      </span>
    </div>
  );
};

export default FileLoader;
