import { msalInstance } from '../../';
import { AccountInfo } from '@azure/msal-browser';
import axios from 'axios';

export const addBearerTokenInHeaders = async (): Promise<void> => {
  try {
    const userAccount = msalInstance.getActiveAccount() as AccountInfo;
    const authentication = await msalInstance.acquireTokenSilent({
      scopes: ['user.read'],
      account: userAccount,
      forceRefresh: true,
    });
    axios.defaults.headers.common['Authorization'] = `Bearer ${authentication.idToken}`;
  } catch (error) {
    await msalInstance.acquireTokenRedirect({
      scopes: ['user.read'],
    });
    console.error('Unable to get token from header !');
  }
};
