import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AMDMMxInfos } from '../../../../core/amdm';

export const getAgenciesToSecmdmThunk = createAsyncThunk<
  AMDMMxInfos[],
  number[],
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`mdm/agencies`, async (countriesId, { extra }): Promise<AMDMMxInfos[]> => {
  const secmdmService = extra.factory.getSecmdmService();
  try {
    return await secmdmService.listAgencies(countriesId);
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
