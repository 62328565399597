import { FC, useContext } from 'react';
import ReactModal from 'react-modal';
import UJContext from './UJContext';

const MatchingModal: FC = () => {
  const { segment, handleActivateStudioDataBricksMatch, enableExport, setMatchingModalOpen } = useContext(UJContext);

  return (
    <ReactModal className="matchingModalOpen c-modal" isOpen={true} ariaHideApp={false}>
      <div className="c-modal__header">
        <h3>Convert your Strategic Audience into an Activable Audience</h3>
      </div>
      <div className="c-modal__body">
        <h2 className="text-center" style={{ color: segment.color }}>
          {segment.name}
        </h2>
        <div>
          <p>
            This audience will be sent to the activation studio (in Converged Hub) for matching. Please, note that the
            matching process might take some minutes. Once the process is finished, this button will be enabled again
            and you will be redirected to Converged. Please, confirm you want to process.
          </p>
        </div>
      </div>
      <div className="c-modal__footer">
        <button
          className="btn btn-outline-primary"
          disabled={!enableExport}
          onClick={() => setMatchingModalOpen(false)}
        >
          Cancel
        </button>
        <button className="btn btn-primary" onClick={handleActivateStudioDataBricksMatch}>
          Push for matching
        </button>
      </div>
    </ReactModal>
  );
};

export default MatchingModal;
