import { DataScope } from 'hawaii';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from '../backendFacade';

export interface ResponseLevelServiceInterface {
  abort: () => void;
  // COUNTRY STATS -----
  getCountryRespondents: (countryId: number, datascope: DataScope) => Promise<number>;
}

const ResponseLevelService = (backend: BackendFacadeInterface): ResponseLevelServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    /**
     * based on a countryId (country_code in bigQuery).
     * Gets a count of the all the respondent in a specific country.
     */
    getCountryRespondents: async (countryId: number, datascope: DataScope): Promise<number> => {
      const body = { countryCode: countryId, datascope };
      const raw = await backend.post<number>('/v1/criteria/country-population', body);
      return raw;
    },
  };
};

export interface ResponseLevelServiceFactoryInterface {
  get: () => ResponseLevelServiceInterface;
}

const ResponseLevelServiceFactory = (
  backendFactory: BackendFacadeFactoryInterface,
): ResponseLevelServiceFactoryInterface => ({
  get: () => ResponseLevelService(backendFactory.get()),
});

export default ResponseLevelServiceFactory;
