import { CountryMetadata, countryMetadatas } from 'hawaii';

export const getCountryDim = (country_iso: string): CountryMetadata => {
  const result = countryMetadatas.find((c) => c.iso === country_iso);
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result;
};

export const getCountryDimFromId = (id: number): CountryMetadata => {
  const result = countryMetadatas.find((c) => c.id === id);
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result;
};

export const getCountryDimFromName = (name: string): CountryMetadata => {
  const result = countryMetadatas.find((c) => c.name === name);
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result;
};

export const getCountryDimFromIso = (iso: string): CountryMetadata => {
  const result = countryMetadatas.find((c) => c.iso.toLowerCase() === iso.toLowerCase());
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result;
};

export const getCountryIsoFromId = (id: number): string => {
  const result = countryMetadatas.find((c) => c.id === id);
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result.iso;
};

export const getCountryIdFromIso = (iso: string): number => {
  const result = countryMetadatas.find((c) => c.iso === iso);
  if (result === undefined) {
    throw Error('Unknown country dim');
  }
  return result.id;
};

export const getAvailableCountryName = (validCountries?: CountryMetadata[]): string[] => {
  return validCountries && validCountries.length
    ? validCountries.filter((country) => country.isGlobal).map((country) => country.name)
    : countryMetadatas.filter((country) => country.isGlobal).map((country) => country.name);
};
