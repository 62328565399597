import Highcharts from 'highcharts';
import { Tabs } from '../../react/interface';

export type SerieField =
  | 'index'
  | 'audienceCountryCount'
  | 'audienceResponsePercentage'
  | 'audienceUnweightedResponseCount';

export type SerieGraphType = 'bar' | 'line' | 'spline' | 'column';

export const getHighchartsSettings = (name: Tabs): Highcharts.Options => {
  switch (name) {
    case 'BARS':
      return {
        chart: {
          plotBackgroundColor: '#F3F4F6',
          polar: false,
          type: 'bar',
          style: {
            fontFamily: 'Baikal Regular',
          },
        },
        title: {
          text: '',
        },
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
          series: {
            borderColor: '#F3F4F6',
          },
        },
        yAxis: {
          labels: {
            style: {
              color: '#374151',
            },
          },
          title: {
            text: null,
          },
        },
        credits: {
          enabled: false,
        },
      };

    case 'COLUMNS':
      return {
        chart: {
          plotBackgroundColor: '#F3F4F6',
          polar: false,
          type: 'column',
          style: {
            fontFamily: 'Baikal Regular',
          },
        },
        title: {
          text: '',
          style: {
            color: '#F3F4F6',
          },
        },
        plotOptions: {
          series: {
            borderColor: '#F3F4F6',
          },
        },
        yAxis: {
          labels: {
            style: {
              color: '#374151',
              fontSize: '10px',
            },
          },
          title: {
            text: null,
          },
        },
        credits: {
          enabled: false,
        },
      };

    case 'SPIDER':
      return {
        chart: {
          polar: true,
          type: 'line',
          plotBackgroundColor: '#FFFFFF',
          style: {
            fontFamily: 'Baikal Regular',
          },
        },
        title: {
          text: '',
          style: {
            color: '#374151',
          },
        },
        plotOptions: {
          series: {
            marker: {
              radius: 0,
            },
          },
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
        },

        credits: {
          enabled: false,
        },
      };
    default:
      throw new Error(`The "${name}" hightcharts setting is not implemented`);
  }
};
