import { PropsWithChildren, ReactNode, createContext } from 'react';
import Factory, { FactoryInterface } from '../../../core/factory';

interface SegmentBuilderGlobalState {
  factory: FactoryInterface;
}

export const Context = createContext({} as SegmentBuilderGlobalState);

const ContextProvider = (props: PropsWithChildren<ReactNode>): JSX.Element => {
  const factory = Factory();

  const store: SegmentBuilderGlobalState = {
    factory: factory,
  };

  return <Context.Provider value={store}>{props.children}</Context.Provider>;
};

export default ContextProvider;
