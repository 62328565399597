export interface IVariablesHistory {
  variablesDetails: {
    countryId: number;
    selectedVariable: {
      variable_id: string;
      variable_name: string;
      variable_text: string;
      isparent: number;
      category_1: string;
      category_2: string;
      category_3: string;
    };
    responsePanel: {
      label: string;
      value: string;
      variableId: string;
    }[];
    dataScope: string;
  };
  crossResults: {
    countryIsos: string[];
    variableId: string;
    responseIds: string[];
  };
}

export type StateVariablesHistory = {
  data: IVariablesHistory[];
  cleanUpVariable: string;
  selectedVariables: string[];
};

type Action<T = string, P = object> = {
  type: T;
  payload: P;
};

type GetVariablesHistoryAction = Action<'HYDRATE_VARIABLES_HISTORY', { data: IVariablesHistory[] }>;
type CleanUpVariableAction = Action<'HYDRATE_CLEANUP_VARIABLES', { cleanUpVariable: string }>;
type SavedVariableHistoryAction = Action<'HYDRATE_SAVE_VARIABLES_HISTORY', { data: IVariablesHistory }>;
type SelectedVariableAction = Action<'HYDRATE_SELECTED_VARIABLE', { selectedVariable: string }>;
type Actions = GetVariablesHistoryAction | CleanUpVariableAction | SavedVariableHistoryAction | SelectedVariableAction;
type ReducerFunction = (state: StateVariablesHistory, action: Actions) => StateVariablesHistory;

const initialState: StateVariablesHistory = {
  data: [],
  cleanUpVariable: '',
  selectedVariables: [],
};

const reducers: ReducerFunction = (state = initialState, action) => {
  switch (action.type) {
    case 'HYDRATE_VARIABLES_HISTORY': {
      return { ...state, data: action.payload.data };
    }
    case 'HYDRATE_CLEANUP_VARIABLES': {
      const variableId = action.payload as unknown as string;
      const selectedVariables = [...state.selectedVariables];
      const isExists = selectedVariables.includes(variableId);

      if (variableId === 'all') {
        return { ...state, selectedVariables: [] };
      }

      if (isExists) {
        const newSelectedVariables = selectedVariables.filter((variable) => variable !== variableId);
        return { ...state, selectedVariables: newSelectedVariables };
      } else {
        return { ...state, selectedVariables: [...state.selectedVariables, variableId as unknown as string] };
      }
    }
    case 'HYDRATE_SAVE_VARIABLES_HISTORY': {
      return { ...state, data: [...state.data, action.payload.data] };
    }
    case 'HYDRATE_SELECTED_VARIABLE': {
      return { ...state, selectedVariables: [...state.selectedVariables, action.payload as unknown as string] };
    }
    default:
      return state;
  }
};

export default reducers;
