import './indexByCluster.less';
import { FC, useEffect, useState } from 'react';
import { Collapse, Segmented } from 'antd';
import { useClustersData } from '../hooks/useClustersData';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useParams } from 'react-router';

const IndexByCluster: FC = () => {
  const { segmentID } = useParams();
  const { clusterIndexV2 } = useClustersData(segmentID as string, 'clusters');
  const { data, getUniqueClusterKeys, colors } = clusterIndexV2();
  const defaultActiveKeys = data ? Object.entries(data).map((_, i) => i.toString()) : '0';
  const [segmentValue, setSegmentValue] = useState<{ [key: string]: string }>(makeSegmentValue());

  function makeSegmentValue() {
    return Object.keys(data).reduce((acc, item) => {
      acc[item] = 'Index';
      return acc;
    }, {} as { [key: string]: string });
  }

  useEffect(() => {
    setSegmentValue(makeSegmentValue());
  }, [segmentID, data]);
  const handleSegmented = (question: string, value: string) => {
    setSegmentValue((pervValues) => ({ ...pervValues, [question]: value }));
  };

  interface IAllResponses {
    [response: string]: {
      [clusterKey: string]: {
        default: number;
        percentage: number;
      };
    };
  }

  const getSeries = (responsesData: IAllResponses, question: string) => {
    const allResponses = Object.keys(responsesData);
    if (segmentValue[question] === '% and Index') {
      return (
        getUniqueClusterKeys &&
        getUniqueClusterKeys.flatMap((cluster, index) => [
          {
            name: `Cluster ${index + 1}`,
            id: `Cluster-${index + 1}`,
            dataSorting: {
              enabled: true,
            },
            data: allResponses
              .sort((a, b) => {
                return responsesData[a][cluster].default > responsesData[b][cluster].default ? -1 : 1;
              })
              .map((response) => responsesData[response][cluster].default),
            color: colors ? colors[index] : '#fff',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              formatter: function (this: Highcharts.PointLabelObject) {
                return `${
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  this.y?.toFixed().toString() || 0
                }`;
              },
            },
            tooltip: {
              pointFormatter: function (this: Highcharts.Point) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                  this.y?.toFixed().toString() || 0
                }</b><br/>`;
                return tooltip;
              },
            },
          },
          {
            name: `Cluster ${index + 1} - %`,
            linkedTo: `Cluster-${index + 1}`,
            pointPlacement: 0.04,
            dataSorting: {
              enabled: true,
            },
            data: allResponses
              .sort((a, b) => {
                return responsesData[a][cluster].percentage > responsesData[b][cluster].percentage ? -1 : 1;
              })
              .flatMap((response) => responsesData[response][cluster].percentage * 100),
            color: 'rgba(158, 159, 163, 1)',
            showInLegend: true,
            dataLabels: {
              enabled: true,
              formatter: function (this: Highcharts.PointLabelObject) {
                return `${
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  this.y?.toFixed().toString() || 0
                }%`;
              },
            },
            tooltip: {
              pointFormatter: function (this: Highcharts.Point) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  this.y?.toFixed().toString() + '%' || 0
                }</b><br/>`;
                return tooltip;
              },
            },
          },
        ])
      );
    } else {
      return (
        getUniqueClusterKeys &&
        getUniqueClusterKeys.map((cluster, index) => ({
          name: `Cluster ${index + 1}`,
          data: allResponses
            .sort((a, b) => {
              return segmentValue[question] === 'Index'
                ? responsesData[a][cluster].default > responsesData[b][cluster].default
                  ? -1
                  : 1
                : responsesData[a][cluster].percentage * 100 > responsesData[b][cluster].percentage * 100
                ? -1
                : 1;
            })
            .map((response) =>
              segmentValue[question] === 'Index'
                ? responsesData[response][cluster].default
                : responsesData[response][cluster].percentage * 100,
            ),
          dataSorting: {
            enabled: true,
          },
          color: colors ? colors[index] : '#fff',
          pointPlacement: 0,
          dataLabels: {
            enabled: true,
            formatter: function (this: Highcharts.PointLabelObject) {
              return segmentValue[question] === 'Index'
                ? `${this.y?.toFixed().toString() || 0}`
                : `${
                    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    this.y?.toFixed().toString() || 0
                  }%`;
            },
          },
          tooltip: {
            pointFormatter: function (this: Highcharts.Point) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                segmentValue[question] === 'Index'
                  ? this.y?.toFixed().toString() || 0
                  : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    this.y?.toFixed().toString() + '%' || 0
              }</b><br/>`;
              return tooltip;
            },
            useHTML: true,
          },
        }))
      );
    }
  };

  return (
    <div className="splitBars-container">
      {data && Object.entries(data).length > 0 && (
        <>
          <Collapse defaultActiveKey={defaultActiveKeys}>
            {Object.entries(data).map(([question, responsesData], index) => {
              const allResponses = Object.keys(responsesData);
              const series = getSeries(responsesData, question);
              const options: Options = {
                chart: {
                  height: 50 * allResponses.length + 500,
                  type: 'bar',
                  plotBackgroundColor: '#F3F4F6',
                  style: {
                    fontFamily: 'Baikal Regular, sans-serif',
                  },
                },
                title: {
                  text: '',
                },
                tooltip: {
                  shared: true,
                },
                xAxis: {
                  categories: allResponses,
                  crosshair: true,
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: 'Responses',
                  },
                },
                legend: {
                  enabled: true,
                },
                plotOptions: {
                  bar: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    minPointLength: 3,
                    colors: ['#00DCB9', '#785AFA', '#328CF5', '#F055FA', '#FFDC32', '#9BF5FC'],
                  },
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                series: series as any,
              };
              return (
                <Collapse.Panel header={question} key={index}>
                  <Segmented
                    options={['Index', 'By Percent', '% and Index']}
                    value={segmentValue[question]}
                    onChange={(v: string | number) => handleSegmented(question, v as string)}
                  />
                  <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        </>
      )}
    </div>
  );
};

export default IndexByCluster;
