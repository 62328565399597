import { createSelector } from '@reduxjs/toolkit';
import { GlobalState } from '../../global';
import {
  BricksClusterProfile,
  BricksIndexByCluster,
  BricksResponseData,
  RespondentsData,
} from '../../../modules/common/interface/interface';

type SelectClusterProfileType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => BricksClusterProfile[] | undefined;

type SelectClusterIndexType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => BricksIndexByCluster[] | undefined;

type SelectClusterResponseRateType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => BricksResponseData[] | undefined;

type SelectClusterRespondentsDataType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => RespondentsData[] | undefined;

type SelectClustersColors = (state: GlobalState, segmentID: string, module: string) => string[] | undefined;

export const selectClusterProfile: SelectClusterProfileType = (state: GlobalState, segmentID: string, module: string) =>
  state.databricks[module]?.[segmentID]?.results?.cluster_profile;

export const selectClusterIndex: SelectClusterIndexType = (state: GlobalState, segmentID: string, module: string) =>
  state.databricks[module]?.[segmentID]?.results?.cluster_index;

export const selectClusterIndexV2: SelectClusterIndexType = (state: GlobalState, segmentID: string, module: string) =>
  state.databricks[module]?.[segmentID]?.results?.clustering_index_v2;

export const selectClusterResponseRate: SelectClusterResponseRateType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => state.databricks[module]?.[segmentID]?.results?.audience_response_rate;

export const selectClusterRespondentsData: SelectClusterRespondentsDataType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => state.databricks[module]?.[segmentID]?.results?.respondent_cluster;

export const selectClusterColors: SelectClustersColors = (state: GlobalState, segmentID: string, module: string) =>
  state.databricks[module]?.[segmentID]?.results?.colors;

interface OrdenRespondentsData {
  [key: string]: RespondentsData[];
}

type SelectEachClusterRespondentsDataType = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => OrdenRespondentsData;

export const selectClusterRespondents: SelectEachClusterRespondentsDataType = createSelector(
  (state: GlobalState, segmentID: string, module: string) => selectClusterRespondentsData(state, segmentID, module),
  (clusterRepondents: RespondentsData[] | undefined): OrdenRespondentsData => {
    if (!clusterRepondents) return {};

    return clusterRepondents.reduce((acc: OrdenRespondentsData, current: RespondentsData) => {
      const clusterKey = current.cluster.toString();
      if (!acc[clusterKey]) {
        acc[clusterKey] = [];
      }
      acc[clusterKey].push(current);
      return acc;
    }, {});
  },
);

interface Profile {
  name: string;
  y: string;
  respondents: string;
}

type SelectChartClusterProfileType = (state: GlobalState, segmentID: string, module: string) => Profile[];

export const selectChartClusterProfile: SelectChartClusterProfileType = createSelector(
  (state: GlobalState, segmentID: string, module: string) => selectClusterProfile(state, segmentID, module),
  (clusterProfile: BricksClusterProfile[] | undefined): Profile[] => {
    if (!clusterProfile) return [];

    return clusterProfile.map((profile, i) => ({
      name: `Cluster ${i + 1}`,
      y: profile.weighted_proportion,
      respondents: profile.number_of_respondent.toString(),
    }));
  },
);

interface TransformedData {
  [question: string]: {
    [response: string]: {
      [clusterKey: string]: number;
    };
  };
}

type ClusterIndexTransformed = {
  data: TransformedData;
  getUniqueClusterKeys: string[];
  rawClusterIndex: BricksIndexByCluster[];
  colors: string[] | undefined;
};

type SelectChartClusterIndexType = (state: GlobalState, segmentID: string, module: string) => ClusterIndexTransformed;

export const selectChartClusterIndex: SelectChartClusterIndexType = createSelector(
  (state: GlobalState, segmentID: string, module: string) => selectClusterIndex(state, segmentID, module),
  (clusterIndex: BricksIndexByCluster[] | undefined) => {
    if (!clusterIndex) return { data: {}, getUniqueClusterKeys: [''], rawClusterIndex: [], colors: [] };
    const uniqueClusterKeys = getUniqueClusterKeys(clusterIndex);
    const charts_data: TransformedData = {};
    for (const entry of clusterIndex) {
      const question = entry['question'];
      const response = entry['response'];

      if (!charts_data[question]) {
        charts_data[question] = {};
      }

      for (const cluster_key of uniqueClusterKeys) {
        if (entry[cluster_key] === null) {
          continue;
        }

        if (!charts_data[question][response]) {
          charts_data[question][response] = {};
        }

        if (!charts_data[question][response][cluster_key]) {
          charts_data[question][response][cluster_key] = 0;
        }

        charts_data[question][response][cluster_key] += entry[cluster_key] as number;
      }
    }
    return { data: charts_data, getUniqueClusterKeys: uniqueClusterKeys, rawClusterIndex: clusterIndex, colors: [] };
  },
);

interface TransformedDataV2 {
  [question: string]: {
    [response: string]: {
      [clusterKey: string]: {
        percentage: number;
        default: number;
      };
    };
  };
}

type ClusterIndexTransformedV2 = {
  data: TransformedDataV2;
  getUniqueClusterKeys: string[];
  rawClusterIndex: BricksIndexByCluster[];
  colors: string[] | undefined;
};

type SelectChartClusterIndexTypeV2 = (
  state: GlobalState,
  segmentID: string,
  module: string,
) => ClusterIndexTransformedV2;

export const selectChartClusterIndexV2: SelectChartClusterIndexTypeV2 = createSelector(
  (state: GlobalState, segmentID: string, module: string) => selectClusterIndexV2(state, segmentID, module),
  (clusterIndex: BricksIndexByCluster[] | undefined) => {
    if (!clusterIndex) return { data: {}, getUniqueClusterKeys: [''], rawClusterIndex: [], colors: [] };
    const uniqueClusterKeys = getUniqueClusterKeys(clusterIndex);
    const charts_data: TransformedDataV2 = {};
    for (const entry of clusterIndex) {
      const question = entry['question'];
      const response = entry['response'];

      if (!charts_data[question]) {
        charts_data[question] = {};
      }

      for (const cluster_key of uniqueClusterKeys) {
        if (entry[cluster_key] === null) {
          continue;
        }

        if (!charts_data[question][response]) {
          charts_data[question][response] = {};
        }

        if (!charts_data[question][response][cluster_key]) {
          charts_data[question][response][cluster_key] = { percentage: 0, default: 0 };
        }

        const splitedData = (entry[cluster_key] as string).slice(1, -1).split(',');

        splitedData.forEach((value, index) => {
          if (index === 0) {
            charts_data[question][response][cluster_key].default += Number(value);
          } else {
            charts_data[question][response][cluster_key].percentage += Number(value);
          }
        });
      }
    }

    return { data: charts_data, getUniqueClusterKeys: uniqueClusterKeys, rawClusterIndex: clusterIndex, colors: [] };
  },
);

interface ResponseData {
  name: string;
  y: number;
}
interface QustionData {
  question: string;
  responses: ResponseData[];
}

type SelectChartClusterResponseRateType = (state: GlobalState, segmentID: string, module: string) => QustionData[];

export const selectChartClusterResponseRate: SelectChartClusterResponseRateType = createSelector(
  (state: GlobalState, segmentID: string, module: string) => selectClusterResponseRate(state, segmentID, module),
  (clusterResponseRate: BricksResponseData[] | undefined) => {
    if (!clusterResponseRate) return [];
    const dataByQuestion: { [key: string]: ResponseData[] } = {};

    for (const response of clusterResponseRate) {
      const question = response['question'];
      if (!dataByQuestion[question]) {
        dataByQuestion[question] = [];
      }

      dataByQuestion[question].push({
        name: response['response_text'],
        y: Number(response['percentage'].toFixed(0)),
      });
    }

    return Object.entries(dataByQuestion).map(([q, r]) => ({ question: q, responses: r }));
  },
);

const getUniqueClusterKeys = (clusterIndex: BricksIndexByCluster[]) => {
  const clusterKeySet = new Set();
  for (const entry of clusterIndex) {
    for (const key of Object.keys(entry)) {
      if (key.startsWith('cluster_') && entry[key] === null) {
        continue;
      }
      if (key.startsWith('cluster_')) {
        clusterKeySet.add(key);
      }
    }
  }
  return Array.from(clusterKeySet) as string[];
};
