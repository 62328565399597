import { Modal, Button, Select, Typography, Divider, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import { SegmentOrigin, SegmentWithUiMeta, countryMetadatas } from 'hawaii';
import { setAppIsLoadingState } from '../../../store/appIsLoadingSlice';
import { saveAudienceInAmdmThunk, updateAudienceInAmdmThunk } from '../../../store/thunk/amdm/saveAudience';
import { copySegmentProfilesThunk } from '../../../store/thunk/saveSegment';
import { setSaveAudienceAction, setSaveAudienceModalOpen } from '../../../store/amdmSlice';
import { useContext, useEffect, useMemo, useState } from 'react';
import segmentSlice from '../../../store/segmentSlice';
import { Context } from '../../../Common/Context/Context';
import { PlusSquareOutlined } from '@ant-design/icons';
import { getMdmDataThunk } from '../../../store/thunk/secmdm/getMdmData';
import {
  setClientInputValue,
  setIsPitchClient,
  setSelectedAgency,
  setSelectedClient,
} from '../../../store/secmdmSlice';
import './SaveAudienceModal.less';
import { getClientsToSecmdmThunk } from '../../../store/thunk/secmdm/getClients';
import { saveClusterAudienceInAmdmThunk } from '../../../store/thunk/amdm/saveClusterAudience';

const { Option } = Select;
const { Title, Text } = Typography;

const SaveAudienceModal = ({ segment, origin }: { segment: SegmentWithUiMeta; origin?: SegmentOrigin }) => {
  const { factory } = useContext(Context);

  const { audiences } = useSelector((state: GlobalState) => state.amdmState);
  const { agencies, clients } = useSelector((state: GlobalState) => state.secmdmState);
  const [selectorInputValue, setSelectorInputValue] = useState<string>('');
  const [agencyError, setAgencyError] = useState(false);

  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;
  const validCountries = useMemo(
    () => countries.filter(({ iso }) => countryMetadatas.find((country) => country.iso === iso)),
    [countries],
  );

  const selectedCountry = useSelector((state: GlobalState) => state.secmdmState.countries.selected);

  useEffect(() => {
    if (audiences.saveModalIsOpen && selectedCountry) {
      const countriesId = [];
      const countryId = validCountries.find(({ iso }) => iso === selectedCountry.iso)?.id;
      if (countryId) {
        countriesId.push(countryId);
      }
      dispatch(getMdmDataThunk(countriesId));
    }
  }, [audiences.saveModalIsOpen]);

  const dispatch = useDispatch();

  const sharedSegmentService = factory.getSharedSegmentService();

  const handleSaveInAmdm = () => {
    if (audiences.saveAction) {
      const existingClient = clients.list.find(
        (client) => clients.clientInputValue.toLowerCase() === client.name.toLowerCase(),
      );
      if (existingClient) {
        dispatch(setSelectedClient(existingClient.id));
        dispatch(setClientInputValue(''));
      }

      if (!agencies.selected) {
        setAgencyError(true);
        return;
      } else {
        setAgencyError(false);
      }

      dispatch(setAppIsLoadingState({ state: true }));

      if (audiences.saveAction === 'save' || audiences.saveAction === 'save-old') {
        if (origin === SegmentOrigin.CLUSTER_BUILDER) {
          dispatch(
            saveClusterAudienceInAmdmThunk({
              segment,
              agency: agencies.selected,
              client: clients.selected || clients.clientInputValue || -1,
            }),
          );
        } else {
          dispatch(
            saveAudienceInAmdmThunk({
              segment,
              agency: agencies.selected,
              client: clients.selected || clients.clientInputValue || -1,
            }),
          );
        }
      }

      if (audiences.saveAction === 'copy') {
        dispatch(
          copySegmentProfilesThunk({
            segment,
            agency: agencies.selected || -1,
            client: clients.selected || clients.clientInputValue || -1,
          }),
        );
      }

      if (audiences.saveAction === 'update-infos') {
        if (!agencies.selected) {
          setAgencyError(true);
          return;
        } else {
          setAgencyError(false);
        }

        dispatch(
          updateAudienceInAmdmThunk({
            segment,
            agency: agencies.selected,
            client: clients.selected || clients.clientInputValue || -1,
          }),
        );
      }

      if (clients.clientInputValue !== '') {
        dispatch(getClientsToSecmdmThunk());
      }

      dispatch(setSaveAudienceAction(undefined));
      dispatch(setSelectedAgency(undefined));
      dispatch(setSelectedClient(undefined));
      handleCloseSaveModal(false);
    }
  };

  const handleCloseSaveModal = (removeSegment: boolean) => {
    setAgencyError(false);
    if (audiences.saveAction === 'update-infos' && removeSegment) {
      dispatch(segmentSlice.actions.removeSegment({ id: segment.id }));
    }
    dispatch(setSaveAudienceModalOpen(false));
  };

  const handleDeleteAudience = async () => {
    dispatch(setAppIsLoadingState({ state: true }));
    await sharedSegmentService.delete(segment.id);
    dispatch(segmentSlice.actions.removeSegment({ id: segment.id }));
    setAgencyError(false);
    dispatch(setSaveAudienceModalOpen(false));
    dispatch(setAppIsLoadingState({ state: false }));
  };

  const modalTitle = (saveAction: 'save' | 'save-old' | 'copy' | 'update-infos' | undefined) => {
    switch (saveAction) {
      case 'save':
        return 'You are about to save a new audience in the AMDM.';
      case 'save-old':
        return 'You need to save your existing audience in the AMDM before being able to work with it.';
      case 'copy':
        return 'You are about to make a copy of your audience in the AMDM.';
      case 'update-infos':
        return 'Informations requiered.';
      default:
        return undefined;
    }
  };

  const modalSubTitle = (saveAction: 'save' | 'save-old' | 'copy' | 'update-infos' | undefined) => {
    switch (saveAction) {
      case 'save':
      case 'copy':
      case 'update-infos':
        return 'Please select your agency and the targeted client (if any).';
      case 'save-old':
        return 'Please select your agency and the targeted client (if any) or delete the audience.';
      default:
        return undefined;
    }
  };

  const modalLeftButton = (saveAction: 'save' | 'save-old' | 'copy' | 'update-infos' | undefined) => {
    switch (saveAction) {
      case 'save':
      case 'copy':
      case 'update-infos':
      case 'save-old':
        return (
          <Button key="close" type="primary" onClick={() => handleCloseSaveModal(true)} style={{ marginRight: '10px' }}>
            Close
          </Button>
        );
      // case 'save-old':
      //   return (
      //     <Button key="close" type="primary" onClick={handleDeleteAudience} style={{ marginRight: '10px' }}>
      //       Delete
      //     </Button>
      //   );
      default:
        return undefined;
    }
  };

  const agencySelector = () => {
    return (
      <>
        {
          <div style={{ height: '22px', color: 'red', marginLeft: '30%' }}>
            {agencyError ? 'Please Select an agency' : ''}
          </div>
        }
        <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '10%', textAlign: 'left', marginRight: '20px' }}>Agency:</span>
          <Select
            value={agencies.selected}
            showSearch
            placeholder="Select your agency"
            filterOption={(inputValue, option) => {
              if (!option || typeof option.children !== 'string') {
                return false;
              }
              return (option.children as string).toLowerCase().includes(inputValue.toLowerCase());
            }}
            allowClear
            onClear={() => dispatch(setSelectedAgency(undefined))}
            showArrow
            style={{ width: '60%' }}
            onSelect={(agency, opt) => {
              dispatch(setSelectedAgency(Number(opt.key as number)));
              setAgencyError(false);
            }}
          >
            {agencies.list.map((agency) => (
              <Option key={agency.id} value={agency.id}>
                {agency.name}
              </Option>
            ))}
          </Select>
        </div>
      </>
    );
  };

  const clientSelectorBlur = () => {
    const selector = document.getElementById('ClientSelector');
    if (selector) selector.blur();
  };

  const clientSelector = () => {
    const selector = () => {
      return (
        <>
          <Select
            id="ClientSelector"
            value={clients.clientInputValue || clients.selected}
            showSearch
            placeholder={clients.isPitchClient ? 'Select your pitch client from the list' : 'Select your client'}
            filterOption={(inputValue, option) => {
              if (!option || typeof option.children !== 'string') {
                return false;
              }
              return (option.children as string).toLowerCase().includes(inputValue.toLowerCase());
            }}
            allowClear
            onClear={() => {
              dispatch(setSelectedClient(undefined));
              dispatch(setClientInputValue(''));
            }}
            showArrow
            style={{ width: '100%' }}
            onSelect={(client, opt) => {
              dispatch(setSelectedClient(Number(opt.key as number)));
              dispatch(setClientInputValue(''));
            }}
            onSearch={(event) => {
              setSelectorInputValue(event);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                dispatch(setClientInputValue(selectorInputValue));
                clientSelectorBlur();
              }
            }}
            dropdownRender={(menu) => (
              <div>
                {menu}
                {clients.isPitchClient && (
                  <>
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{ padding: '4px 8px', cursor: 'pointer', color: '#373737' }}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        dispatch(setClientInputValue(selectorInputValue));
                        clientSelectorBlur();
                      }}
                    >
                      <PlusSquareOutlined /> Add New Pitch client
                    </div>
                  </>
                )}
              </div>
            )}
          >
            {clients.list
              .filter((c) => !c.isPitch)
              .map((client) => (
                <Option key={client.id} value={client.id}>
                  {client.name}
                </Option>
              ))}
          </Select>
        </>
      );
    };

    return (
      <>
        <div style={{ marginBottom: '16px', display: 'flex' }}>
          <span style={{ width: '10%', textAlign: 'left', marginRight: '20px' }}>Client:</span>
          <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
            <div style={{ marginRight: '20px', marginBottom: '16px' }}>
              <span style={{ marginRight: '8px' }}>Is it a Pitch -</span>
              <Radio.Group
                onChange={(e) => {
                  dispatch(setIsPitchClient(e.target.value as boolean));
                  dispatch(setClientInputValue(''));
                  dispatch(setSelectedClient(undefined));
                }}
                value={clients.isPitchClient}
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
            {selector()}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      title={
        <div>
          <span style={{ marginBottom: '1em' }}>
            <Title level={5}>{modalTitle(audiences.saveAction)}</Title>
          </span>
          <Text italic type="secondary">
            {modalSubTitle(audiences.saveAction)}
          </Text>
        </div>
      }
      open={audiences.saveModalIsOpen}
      closable={false}
      centered
      width={800}
      mask
      footer={null}
      className="saveAudienceModal"
    >
      <div style={{ margin: 'auto' }}>
        {agencySelector()}
        {clientSelector()}
        <Divider />

        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
          {modalLeftButton(audiences.saveAction)}
          <Button key="save" onClick={handleSaveInAmdm} danger>
            {audiences.saveAction === 'copy' ? 'Copy' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SaveAudienceModal;
