import { FC, useEffect, useRef, useState } from 'react';
import './HomePage.less';
import HeaderWrapper from '../../Common/Header/HeaderWrapper';
import { GlobalState } from '../../store/global';
import { useDispatch, useSelector } from 'react-redux';
import HomeCards from './Cards/HomeCards';
import HomeCta from './Cta/HomeCta';

import _ from 'lodash';
import HomeTable from './Table/HomeTable';
import { SegmentWithUiMeta } from 'hawaii';
import { factory } from '../../../core/factory';
import { refreshHawaiiStateThunk } from '../../store/thunk/refreshHawaiiState';
import Footer from '../../Common/Footer/Footer';
const HomePage: FC = () => {
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const [storedSegments, setStoredSegments] = useState<SegmentWithUiMeta[]>([]);
  const [sharedSegments, setSharedSegments] = useState<SegmentWithUiMeta[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [visibleRows, setVisibleRows] = useState<number>(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const sharedSegmentService = factory.getSharedSegmentService();
  const dispatch = useDispatch();

  const retrieveSegments = async (limit: number) => {
    setIsFetching(true);
    const userSegments = await sharedSegmentService.getMineFull({ dataScope: 'Original', limit });
    setStoredSegments(userSegments);

    const allSegments = await sharedSegmentService.getAllSharedFull({ dataScope: 'Original', limit });
    setSharedSegments(allSegments);
    setIsFetching(false);
  };

  useEffect(() => {
    dispatch(refreshHawaiiStateThunk());
  }, [!hawaiiState.surveyRefreshDate]);

  useEffect(() => {
    const handleResize = () => {
      if (tableContainerRef.current) {
        const containerHeight = tableContainerRef.current.clientHeight;
        const headerHeight = 58;
        const availableHeight = containerHeight - headerHeight;
        const rowHeight = 54;

        const newVisibleRows = Math.floor(availableHeight / rowHeight);
        setVisibleRows(newVisibleRows);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const run = async () => {
      if (visibleRows > 0) await retrieveSegments(visibleRows);
    };
    void run();
  }, [tableContainerRef?.current]);

  return (
    <div className="main home">
      <div className="header">
        <HeaderWrapper lastUpdate={hawaiiState.surveyRefreshDate} />
      </div>
      <div className="l-wrapper">
        <div className="l-wrapper__left">
          <HomeCards />
          <HomeCta />
        </div>
        <div className="l-wrapper__right">
          <HomeTable
            visibleRows={visibleRows}
            tableContainerRef={tableContainerRef}
            isFetching={isFetching}
            storedSegments={storedSegments}
            sharedSegments={sharedSegments}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
