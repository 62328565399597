import { CountryMetadata } from 'hawaii';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  marketSizedSeries,
  audienceCrossCountryResponsePercentageSeries,
  audienceResponsePercentageSeries,
  countryIndexSeries,
  crossResulsFilterByCountry,
  crossResultsFilterBySelectedResponse,
  indexSeries,
  sumByCountry,
  unweightedResponseCountSeries,
} from '../../../core/crossResults';
import { getCountryDimFromName } from '../../../core/helpers/country';
import { Serie, SerieTag } from '../../../core/series/interface';
import { serieApplyPermutations, seriePermutationsOfValues, seriesRegroup } from '../../../core/series/serie';
import FormatedNumber from '../../Common/FormatedNumber';
import OrderedCheckbox from '../../Common/OrderedCheckbox';
import SerieTable, { TableSortCriteria } from '../../Common/SerieTable';
import { GlobalState } from '../../store/global';
import FusionTable from '../SegmentBuilder/Table/FusionTable';

interface Props {
  series: Serie[];
  selectedResponses: Record<string, string[]>;
  selectedCountries: CountryMetadata[];
  focusedCountry: string | null;
}

const ResultsTable: FC<Props> = ({ series, selectedResponses, selectedCountries, focusedCountry }) => {
  const [sortCriteria, setSortCriteria] = useState<TableSortCriteria | undefined>(undefined);
  const [sectionsSelection, setSectionSelection] = useState<{ name: string; checked: boolean }[]>([
    { name: 'Unweighted respondents', checked: true },
    { name: 'Distribution of the respondents', checked: true },
    { name: 'Total market size', checked: true },
    { name: 'Index', checked: true },
  ]);

  const variableDetails = useSelector((state: GlobalState) => state.variablesDetails);

  const focusedPopulation = selectedCountries.find((country) => country.name === focusedCountry)?.population;

  const handleSortColumn = (criteria: TableSortCriteria): void => {
    setSortCriteria(criteria);
  };

  const formatTableLineLabel = (tags: SerieTag, globalTags: SerieTag): React.ReactElement => (
    <>
      <strong>{globalTags.variableName as string}</strong>
      <br />
      {globalTags.responseText}
    </>
  );

  const crossresultsComputedSeries = (focusedCountry: string | null, series: Serie[]): Serie[] => {
    let seriesStack;
    let filteredSerie: Serie[] = [];

    if (focusedCountry !== 'Total countries') {
      filteredSerie = crossResultsFilterBySelectedResponse(
        selectedResponses,
        crossResulsFilterByCountry(getCountryDimFromName(focusedCountry!).id, series),
      );

      seriesStack = sectionsSelection
        .map(({ name, checked }) => {
          if (!checked) {
            return [];
          }

          switch (name) {
            case 'Unweighted respondents':
              return [...unweightedResponseCountSeries(filteredSerie)().flat()];
            case 'Distribution of the respondents':
              return [...audienceResponsePercentageSeries(filteredSerie)().flat()];
            case 'Total market size':
              return [...marketSizedSeries(focusedPopulation!, filteredSerie)().flat()];
            case 'Index':
              return [...indexSeries(variableDetails, filteredSerie)().flat()];
            default:
              throw 'Unexpected label';
          }
        })
        .flat();
    } else {
      filteredSerie = crossResultsFilterBySelectedResponse(selectedResponses, series);

      seriesStack = sectionsSelection
        .map(({ name, checked }) => {
          if (!checked) {
            return [];
          }

          const totalMarketSizeSerie = sumByCountry(() =>
            selectedCountries
              .map((country) => {
                return marketSizedSeries(
                  country.population,
                  crossResulsFilterByCountry(country.id, filteredSerie),
                )().flat();
              })
              .flat(),
          );

          switch (name) {
            case 'Unweighted respondents':
              return [...sumByCountry(unweightedResponseCountSeries(filteredSerie))().flat()];
            case 'Distribution of the respondents':
              return [...audienceCrossCountryResponsePercentageSeries(filteredSerie)().flat()];
            case 'Total market size':
              return totalMarketSizeSerie();
            case 'Index':
              return [...countryIndexSeries(variableDetails, filteredSerie)().flat()];
            default:
              throw 'Unexpected label';
          }
        })
        .flat();
    }
    return seriesStack;
  };

  const crossresultsAlignVariable = (series: Serie[]): Serie[] =>
    seriesRegroup(['label', 'name'], ['variableName'])(() => series);

  const renderedSeries = crossresultsComputedSeries(focusedCountry, series);

  const variableFlatten = crossresultsAlignVariable(renderedSeries);

  let sortMethod;
  if (sortCriteria?.column !== undefined) {
    const targetedSerie = variableFlatten.filter(
      (serie: Serie) =>
        serie.globalTags.label === sortCriteria?.section && serie.globalTags.name === sortCriteria?.column,
    )[0];

    const permutations =
      undefined !== targetedSerie
        ? seriePermutationsOfValues(
            (a: number, b: number) => (sortCriteria?.order === 'ASC' ? a - b : b - a),
            targetedSerie,
          )
        : [];

    sortMethod =
      undefined === targetedSerie
        ? (serie: Serie) => serie
        : (serie: Serie) => serieApplyPermutations(permutations, serie);
  } else {
    sortMethod = (serie: Serie) => serie;
  }

  const handleChangeSectionSelection = (sectionsSelection: { name: string; checked: boolean }[]) => {
    setSectionSelection([...sectionsSelection]);
  };

  const handleMoveSectionSelection = (newSelectionValue: { name: string; checked: boolean }[]) => {
    setSectionSelection([...newSelectionValue]);
  };

  const valueFormatter = (val: number, globalTags: SerieTag, tags: SerieTag): JSX.Element => (
    <span style={{ fontStyle: tags.irrelevant ? 'italic' : 'normal' }} className={tags.irrelevant ? 'hawaii-grey' : ''}>
      <FormatedNumber value={val} />
    </span>
  );

  return (
    <>
      <OrderedCheckbox
        values={sectionsSelection}
        changeValueAction={handleChangeSectionSelection}
        moveValueAction={handleMoveSectionSelection}
      />
      <div className="table-container">
        {variableFlatten.length === 0 || (
          <>
            <FusionTable
              series={variableFlatten.map(sortMethod)}
              sectionGroupTag="label"
              sectionsSelection={sectionsSelection}
              columnGroupTag="name"
              formatLineLabel={formatTableLineLabel}
              valueFormatter={valueFormatter}
              focusedCountry={focusedCountry}
            />
            {/* <SerieTable
              series={variableFlatten.map(sortMethod)}
              sectionGroupTag="label"
              columnGroupTag="name"
              sortAction={handleSortColumn}
              formatLineLabel={formatTableLineLabel}
              sortCriteria={sortCriteria}
              valueFormatter={valueFormatter}
            /> */}
          </>
        )}
      </div>
    </>
  );
};

export default ResultsTable;
