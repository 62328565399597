import { FC, useState, useEffect } from 'react';
import './NotFound.less';
import { addBearerTokenInHeaders } from '../../core/helpers/addBearTokenInHeaders';
import { config } from '../../core/config';
import axios from 'axios';
import { Input, Button } from 'antd';

const CacheList: FC = () => {
  const BASE_URL = config.api.baseUrl as string;
  const [value, setValue] = useState<string | null>();
  const [variables, setVariables] = useState<string[]>([
    'variable:from-category-path',
    'variable:from-input',
    'variable:responses',
    'variable:properties',
    'variable-details',
    'criteria/get-population',
    'criteria/get-country-population',
    'criteria/cross-population',
    'category',
  ]);

  const runReset = async (key: string) => {
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const response = await axios.post(
        `${BASE_URL}/v1/redisCache/cleanByKey`,
        { key: key },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );
      if (response.status === 200) {
        console.log('response', response);
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };

  const cleanAllCache = async () => {
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const response = await axios.post(`${BASE_URL}/v1/redisCache/cleanAllCache`, {
        withCredentials: true,
        signal: abortController.signal,
      });
      if (response.status === 200) {
        console.log('response', response);
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };
  return (
    <div className="not-found">
      <div className="center">
        <h2>Reset cache</h2>
        <div>Selected variable: {value}</div>
        <div>
          {variables.map((v) => (
            <Button onClick={() => setValue(v)}>{v}</Button>
          ))}
        </div>
        {value && <Button onClick={() => runReset(value)}>Reset</Button>}
        <Button type="primary" onClick={() => cleanAllCache()}>
          CLEAN ALL CACHE
        </Button>
      </div>
    </div>
  );
};

export default CacheList;
