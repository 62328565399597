import './GraphWrapper.less';
import { FC, useState } from 'react';
import { Serie, SerieTag, MediaInter } from '../../../../../core/series/interface';
import { SerieField } from '../../../../../core/graph/highchartsAdapter';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../store/global';
import { seriesExplode, seriesGlobaltags, seriesRegroup } from '../../../../../core/series/serie';
import Select from 'react-select';
import { tagValues } from '../../../../../core/helpers/tag';
import { applyPermutations, getPermutationOrders } from '../../../../../core/helpers/permutations';
import { Tabs } from '../../../../interface';
import Table from '../../Table';
import {
  marketSizedSeries,
  audienceResponsePercentageSeries,
  indexSeries,
  unweightedResponseCountSeries,
} from '../../../../../core/crossResults';
import { CountryMetadata } from 'hawaii';
import Charts from '../../../../Common/charts';

interface Props {
  graphType: Tabs;
  series: Serie[];
  country: CountryMetadata;
}

interface SelectSerieOption {
  value: string;
  label: string;
}

const GraphWrapper: FC<Props> = ({ graphType, series, country }) => {
  const availableMainOrder = series
    .filter((serie) => serie.globalTags.isWeighted === true)
    .map((serie) => serie.globalTags.name as string)
    .filter((name: string, index, self) => self.indexOf(name) === index);

  const [mainOrder, setMainOrder] = useState<string>(availableMainOrder[0] ? availableMainOrder[0] : '');
  const [sortOrder, setSortOrder] = useState<number>(1);

  const variableDetails = useSelector((state: GlobalState) => state.variablesDetails);

  const [selectedFormula, setSelectedFormula] = useState<{ label: string; value: SerieField }>({
    label: 'Percentage',
    value: 'audienceResponsePercentage',
  });

  const handleSelectFormlula = (value: { label: string; value: SerieField } | null) => {
    if (value !== null) {
      setSelectedFormula(value);
    }
  };

  // const labelColors = ['#25cacf', '#862f2f'];
  const variableNames = series.length === 0 ? [] : tagValues('variableName')(seriesGlobaltags(() => series));
  const labelFormatter = (
    p: Highcharts.AxisLabelsFormatterContextObject,
    tags: Record<string, string | boolean | number | MediaInter[]>[],
  ) => {
    if (tags[p.pos]) {
      const displayVariable = p.pos === 0 || tags[p.pos].variableName !== tags[p.pos - 1].variableName;
      const variable = tags[p.pos].variableName as string;
      const variableName = tags[p.pos].variableName as string;
      const response = tags[p.pos].responseText as string;
      const variableIndex = variableNames.indexOf(variableName);

      // const color = '#374151';
      const responseHtml = `<span style="font-size: 12px;">${response}</span>`;
      return displayVariable
        ? `<div style="line-height: 1;"><strong>${variable}</strong></div>${responseHtml}`
        : responseHtml;
    } else {
      // if ever the serie doesn't fit with its response
      return '???';
    }
  };

  const tagConverter = (globalTags: SerieTag) => {
    return {
      name: globalTags.name,
      color: globalTags.color,
    };
  };

  const handleChangeMainOrder = (newMainOrder: SelectSerieOption | null) => {
    if (newMainOrder !== null) {
      setMainOrder(newMainOrder.value);
    }
  };

  const valueTypeOptions: { label: string; value: SerieField }[] = [
    { label: 'Market size', value: 'audienceCountryCount' },
    { label: 'Index', value: 'index' },
    { label: 'Unweighted count', value: 'audienceUnweightedResponseCount' },
    { label: 'Percentage', value: 'audienceResponsePercentage' },
  ];

  const getGraphSeries = (): Serie[] => {
    let displayedSerie: Serie[];

    switch (selectedFormula.value) {
      case 'audienceUnweightedResponseCount':
        displayedSerie = unweightedResponseCountSeries(series)();
        break;
      case 'audienceCountryCount':
        displayedSerie = marketSizedSeries(country.population, series)();
        break;
      case 'audienceResponsePercentage':
        displayedSerie = audienceResponsePercentageSeries(series)();
        break;
      case 'index':
      default:
        displayedSerie = indexSeries(variableDetails, series)();
        break;
    }

    const permute = (series: Serie[], mainOrder: string): Serie[] => {
      const mainSerie = series.filter((serie) => serie.globalTags.name == mainOrder)[0];
      if (mainSerie !== undefined) {
        const sorted = [...mainSerie.values].sort((a, b) => sortOrder * (a - b));
        const permutations = getPermutationOrders(sorted, mainSerie.values);
        return series.map((serie) => ({
          ...serie,
          tags: applyPermutations<SerieTag>(permutations)(serie.tags),
          values: applyPermutations<number>(permutations)(serie.values),
        }));
      } else {
        return series;
      }
    };

    const sortedSeries = seriesExplode('variableName', displayedSerie)
      .map((serie) => permute(serie, mainOrder))
      .flat();

    return seriesRegroup('segmentId', ['variableName'])(() => sortedSeries);
  };

  const handleToogleSortOrder = () => {
    setSortOrder(sortOrder * -1);
  };

  return graphType === 'TABLE' || graphType === 'FUSION TABLE' ? (
    <Table graphType={graphType} series={series} country={country} />
  ) : (
    <>
      <div className="form-field-group">
        <div className="form-field">
          <label className="form-label">Result type</label>
          <Select
            className="resulttype-selection"
            value={selectedFormula}
            onChange={handleSelectFormlula}
            options={valueTypeOptions}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              borderColor: '#9CA3AF',
              boxShadow: 'none',
              colors: {
                ...theme.colors,
                primary25: 'rgba(0, 0, 0, 0.05)',
                primary: 'black',
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#9CA3AF' : '#9CA3AF',
                boxShadow: state.isFocused ? 'none' : 'none',
                '&:hover': {
                  borderColor: 'black',
                },
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                borderWidth: 0,
                boxShadow: '0px 0px 16px 3px rgba(0, 0, 0, 0.05)',
              }),
            }}
          />
        </div>
        <div className="form-field">
          <label className="form-label">Sort by</label>
          <Select
            className="seriesort-selection"
            value={{ value: mainOrder, label: mainOrder }}
            onChange={handleChangeMainOrder}
            isMulti={false}
            options={availableMainOrder.map((opt) => {
              return { value: opt, label: opt };
            })}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              borderColor: '#9CA3AF',
              boxShadow: 'none',
              colors: {
                ...theme.colors,
                primary25: 'rgba(0, 0, 0, 0.05)',
                primary: 'black',
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused ? '#9CA3AF' : '#9CA3AF',
                boxShadow: state.isFocused ? 'none' : 'none',
                '&:hover': {
                  borderColor: 'black',
                },
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                borderWidth: 0,
                boxShadow: '0px 0px 16px 3px rgba(0, 0, 0, 0.05)',
              }),
            }}
          />
        </div>
        <button onClick={handleToogleSortOrder}>{sortOrder === 1 ? 'Ascending' : 'Descending'}</button>
      </div>
      <div className="pit-charts__content">
        <Charts
          highchartsSettings={graphType}
          series={getGraphSeries}
          labelFormatter={labelFormatter}
          tagConverter={tagConverter}
        />
        {/* {graphType === 'SPLIT BARS' ? (
        <SplitBars series={getGraphSeries} />
      ) : (
        <SegmentGraph
          highchartsSettings={graphType}
          series={getGraphSeries}
          labelFormatter={labelFormatter}
          tagConverter={tagConverter}
        />
      )} */}
      </div>
    </>
  );
};

export default GraphWrapper;
