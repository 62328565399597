import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AMDMActivateStudioState, DataBricksMatching } from '../../activateStudioSlice';
import { getActivateStudioState } from './getActivateStudioState';

export const dataBricksMatching = createAsyncThunk<
  void,
  {
    audienceRecordId: string;
    selectedCountry: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/activation-studio/get`, async ({ audienceRecordId, selectedCountry }, { dispatch, extra }): Promise<void> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    const response = await amdmService.dataBricksMatch(audienceRecordId);
    await dispatch(
      getActivateStudioState({
        selectedCountry,
        audienceRecordId,
      }),
    );
    // return response;
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
