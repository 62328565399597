import './SegmentManager.less';
import { FC } from 'react';
import { SharedSegmentServiceInterface } from '../../../core/sharedSegment';
import SegmentImporter from '../../Common/SegmentImporter';
import { ActivationProcess } from '../../store/activationProcessSlice';
import { useDispatch } from 'react-redux';
import { SegmentWithUiMeta } from 'hawaii';
import segmentSlice from '../../store/segmentSlice';
import { setAppIsLoadingState } from '../../store/appIsLoadingSlice';

interface Props {
  sharedSegmentService: SharedSegmentServiceInterface;
  newAudienceAction: (isSmart?: boolean) => SegmentWithUiMeta;
  importSegmentAction: (segment: SegmentWithUiMeta) => void;
  storedSegments: SegmentWithUiMeta[];
  sharedSegments: SegmentWithUiMeta[];
  refreshStoredSegmentsAction: () => void;
  activationProcesses: ActivationProcess[];
  buttonTitle?: string;
  handleSmartBuilder?: (newSegment: SegmentWithUiMeta) => void;
}

const SegmentManager: FC<Props> = ({
  sharedSegmentService,
  newAudienceAction,
  importSegmentAction,
  storedSegments,
  sharedSegments,
  refreshStoredSegmentsAction,
  activationProcesses,
  buttonTitle = 'Insert an Audience',
  handleSmartBuilder,
}) => {
  const dispatch = useDispatch();

  const handleImportSegment = (segment: SegmentWithUiMeta) => {
    importSegmentAction(segment);
  };

  const handleDeleteSharedSegment = async (segment: SegmentWithUiMeta) => {
    dispatch(setAppIsLoadingState({ state: true }));
    await sharedSegmentService.delete(segment.id).finally(() => {
      dispatch(setAppIsLoadingState({ state: false }));
    });
    dispatch(segmentSlice.actions.removeSegment({ id: segment.id }));
    refreshStoredSegmentsAction();
  };

  const handleShareUnshareSegment = async (segment: SegmentWithUiMeta) => {
    if (segment.shared) {
      await sharedSegmentService.unshare(segment.id);
    } else {
      await sharedSegmentService.share(segment.id);
    }
    refreshStoredSegmentsAction();
  };

  return (
    <>
      <div className="add-audience-btn">
        <SegmentImporter
          text={buttonTitle}
          segments={storedSegments}
          sharedSegments={sharedSegments}
          onSelect={handleImportSegment}
          deleteAction={handleDeleteSharedSegment}
          shareUnshareAction={handleShareUnshareSegment}
          newSegmentAction={newAudienceAction}
          activationProcesses={activationProcesses}
          handleSmartBuilder={handleSmartBuilder}
        />
      </div>
    </>
  );
};

export default SegmentManager;
