import './VariableSelector.less';
import { FC, useState } from 'react';
import { Spin } from 'antd';
import { SemanticColors } from '../../../core/helpers/colors';
import { ResponseInfos } from 'hawaii';

interface Props {
  // options === undefined -> is loading ...
  options: ResponseInfos[] | undefined;
  colors: SemanticColors;
  values: ResponseInfos[];
  onSelect: (value: ResponseInfos[]) => void;
}

const Item: FC<{
  selected: boolean;
  colors: SemanticColors;
  option: ResponseInfos;
  values: ResponseInfos[];
  onSelect: (value: ResponseInfos[]) => void;
}> = ({ selected, colors, option, values, onSelect }) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  return (
    <div
      className={'item-wrapper' + (selected ? ' selected' : '')}
      // style={{
      //   background: selected ? colors.darker2 : isHover ? colors.darker1 : 'transparent',
      //   borderStyle: 'solid',
      //   borderWidth: '1px',
      //   borderBottomColor: selected ? colors.darker3 : 'transparent',
      //   borderTopColor: selected ? colors.main : 'transparent',
      //   color: selected ? 'white' : colors.light3,
      //   paddingRight: '1em',
      // }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={option.value}
      onClick={() => {
        if (!selected) {
          onSelect([option, ...values]);
        } else {
          onSelect(values.filter((e) => e.value !== option.value));
        }
      }}
    >
      {option.label}
    </div>
  );
};

const Responses: FC<Props> = ({ options, colors, values, onSelect }) => {
  const valuesResponseId = values.map((e) => e.value);
  return (
    <>
      {(options !== undefined && options.length == 0) || (
        <div className="variable-selector-section">
          <div className="listing hawaii-thin-scrollbar">
            {options === undefined ? (
              <div className="loader-container">
                <Spin size="large" />
              </div>
            ) : (
              options.map((option) => (
                <Item
                  key={option.value}
                  selected={valuesResponseId.indexOf(option.value) !== -1}
                  colors={colors}
                  option={option}
                  values={values}
                  onSelect={onSelect}
                />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Responses;
