import './tabs.less';
import { FC, useState } from 'react';
import VerticalTabs from '../../../Common/VerticalTabs';
import IndexByCluster from './indexByClusterComponent';
import ClusterProfile from './clusterProfileComponent';
import ResponseRate from './responseRateComponent';

type Tabs = 'CLUSTER PROFILE' | 'INDEX BY CLUSTER' | 'RESPONSE RATE';

const TabsSelector: FC = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>('CLUSTER PROFILE');

  const tabs = ['CLUSTER PROFILE', 'INDEX BY CLUSTER', 'RESPONSE RATE'] as Tabs[];

  return (
    <>
      <VerticalTabs
        tabs={tabs}
        value={selectedTab}
        selectAction={(tab) => {
          setSelectedTab(tab as Tabs);
        }}
      >
        <div className="right-tab-content">
          <div className="cluster-tabs">
            <div className="pit-charts">
              {selectedTab === 'INDEX BY CLUSTER' && (
                <>
                  <IndexByCluster />
                </>
              )}

              {selectedTab === 'CLUSTER PROFILE' && (
                <>
                  <ClusterProfile />
                </>
              )}

              {selectedTab === 'RESPONSE RATE' && (
                <>
                  <ResponseRate />
                </>
              )}
            </div>
          </div>
        </div>
      </VerticalTabs>
    </>
  );
};

export default TabsSelector;
