export type StateMatchingProcess = {
  destination: string;
  provider: string;
  availableOptions: ActivationResultsAudience[];
};

export declare type ActivationCustomSettings = Record<string, string[]>;

export interface ActivationResultsAudience {
  audience_name: string;
  audience_label: string;
  audience_otql: string;
  audience_reach: number;
  audience_attributes: ActivationCustomSettings;
}

type Action<T = string, P = object> = {
  type: T;
  payload: P;
};

type SetActivationProcessAction = Action<'SET_PROVIDER', { value: string }>;
type SetDestinationAction = Action<'SET_DESTINATION', { value: string }>;
type SetAvailableOptionsAction = Action<'SET_AVAILABLE_OPTIONS', { value: ActivationResultsAudience[] }>;

type Actions = SetActivationProcessAction | SetDestinationAction | SetAvailableOptionsAction;

type ReducerFunction = (state: StateMatchingProcess, action: Actions) => StateMatchingProcess;

const initialState: StateMatchingProcess = {
  destination: 'Audience Studio',
  provider: 'All',
  availableOptions: [],
};

const reducers: ReducerFunction = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROVIDER': {
      return { ...state, provider: action.payload.value };
    }

    case 'SET_DESTINATION': {
      return { ...state, destination: action.payload.value };
    }

    case 'SET_AVAILABLE_OPTIONS': {
      return { ...state, availableOptions: action.payload.value };
    }
    default:
      return state;
  }
};

export default reducers;
