type Action<T = string, P = object> = {
  type: T;
  payload: P;
};

interface IEstTime {
  lower_bound: string;
  upper_bound: string;
}

type SetRunID = Action<'SET_RUN_ID', { module: string; value: string; actionID: string }>;
type SetRunIDBQ = Action<'SET_RUN_ID_BQ', { module: string; value: string; actionID: string }>;
type SetRunIDBQStatus = Action<'SET_RUN_ID_BQ_STATUS', { module: string; value: string; actionID: string }>;
type SetJobStatus = Action<'SET_JOB_STATUS', { module: string; value: string; actionID: string }>;
type SetResults = Action<'SET_RESULTS', { module: string; value: []; actionID: string }>;
type SetLoading = Action<'SET_LOADING', { module: string; value: boolean; actionID: string }>;
type SetJobRunStart = Action<'SET_JOB_RUN_START', { module: string; value: number; actionID: string }>;
type SetJobEstTime = Action<'SET_JOB_ESTIMATED_TIME', { module: string; value: IEstTime; actionID: string }>;
type SetSavedResults = Action<'SET_SAVED_RESULTS', { module: string; value: number; actionID: string }>;
type SetCountryCode = Action<'SET_COUNTRY_CODE', { module: string; value: number; actionID: string }>;
type SetNameCategory = Action<
  'SET_NAME_CATEGORY',
  { module: string; value: { name: string | undefined; category: string | undefined }; actionID: string }
>;

type Actions =
  | SetRunID
  | SetRunIDBQ
  | SetRunIDBQStatus
  | SetJobStatus
  | SetResults
  | SetLoading
  | SetJobRunStart
  | SetJobEstTime
  | SetSavedResults
  | SetNameCategory
  | SetCountryCode;

type SegmentJobState = {
  jobTimeStart: number;
  jobEstTime: IEstTime;
  runID: null | string;
  runIDBQ: null | string;
  runIDBQStatus: null | string;
  jobStatus: string;
  results: null | [];
  loading: boolean;
  savedResults: null | number[];
  name: string | undefined;
  category: string | undefined;
  countryCode: number;
};

type StateDatabrick = {
  [module: string]: {
    [actionID: string]: SegmentJobState;
  };
};

type ReducerFunction = (state: StateDatabrick, action: Actions) => StateDatabrick;
const initialState: StateDatabrick = {};

const reducers: ReducerFunction = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COUNTRY_CODE': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            countryCode: action.payload.value,
          },
        },
      };
    }

    case 'SET_RUN_ID': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            runID: action.payload.value,
          },
        },
      };
    }

    case 'SET_RUN_ID_BQ': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            runIDBQ: action.payload.value,
          },
        },
      };
    }

    case 'SET_RUN_ID_BQ_STATUS': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            runIDBQStatus: action.payload.value,
          },
        },
      };
    }

    case 'SET_JOB_RUN_START': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            jobTimeStart: action.payload.value,
          },
        },
      };
    }

    case 'SET_JOB_ESTIMATED_TIME': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            jobEstTime: action.payload.value,
          },
        },
      };
    }

    case 'SET_JOB_STATUS': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            jobStatus: action.payload.value,
          },
        },
      };
    }

    case 'SET_NAME_CATEGORY': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            name: action.payload.value.name,
            category: action.payload.value.category,
          },
        },
      };
    }

    case 'SET_RESULTS': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            results: action.payload.value,
          },
        },
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            loading: action.payload.value,
          },
        },
      };
    }

    case 'SET_SAVED_RESULTS': {
      return {
        ...state,
        [action.payload.module]: {
          ...(state[action.payload.module] || {}),
          [action.payload.actionID]: {
            ...(state[action.payload.module]?.[action.payload.actionID] || initialState),
            savedResults: [
              ...(state[action.payload.module]?.[action.payload.actionID].savedResults || []),
              action.payload.value,
            ],
          },
        },
      };
    }

    default:
      return state;
  }
};

export default reducers;
