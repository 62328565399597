import { CloseCircleTwoTone, UnlockTwoTone, LockTwoTone } from '@ant-design/icons';
import { Input, Button, Modal } from 'antd';
import { FC, useEffect } from 'react';
import { setConnectionModalIsOpen, setAmdmLoginError, setAmdmOwnerUpn } from '../../store/amdmSlice';
import { sendOwnerUpnToAmdmThunk } from '../../store/thunk/amdm/sendOwnerUpn';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import { getOwnerUpnToAmdmThunk } from '../../store/thunk/amdm/getOwnerUpn';

const AMDMModal: FC = () => {
  const dispatch = useDispatch();

  const amdmConnection = useSelector((state: GlobalState) => state.amdmState.connection);

  useEffect(() => {
    dispatch(getOwnerUpnToAmdmThunk());
  }, [dispatch]);

  const handleOk = () => {
    dispatch(sendOwnerUpnToAmdmThunk({ ownerUpn: amdmConnection.ownerUpn }));
  };

  const handleCancel = () => {
    dispatch(setConnectionModalIsOpen(false));
    dispatch(setAmdmLoginError(undefined));
  };

  const successContent = () => {
    return <p>You are connected to the AMDM.</p>;
  };

  const connectContent = () => {
    return (
      <>
        <span>{amdmConnection.loading ? ' ' : 'Enter you OwnerUpn:'}</span>
        <Input style={{ border: '1px #52c41a solid' }} onChange={(e) => dispatch(setAmdmOwnerUpn(e.target.value))} />
      </>
    );
  };

  const errorContent = () => {
    return (
      <>
        <div>
          <CloseCircleTwoTone twoToneColor="#d40817" /> {amdmConnection.error}
        </div>
        <span>Please check your entry: </span>
        <Input
          status="error"
          value={amdmConnection.ownerUpn}
          onChange={(e) => dispatch(setAmdmOwnerUpn(e.target.value))}
        />
      </>
    );
  };

  const modalContent = () => {
    if (amdmConnection.isConnected) {
      return successContent();
    } else if (amdmConnection.error) {
      return errorContent();
    } else {
      return connectContent();
    }
  };

  const modalTitle = () => {
    const icon = amdmConnection.isConnected ? (
      <UnlockTwoTone twoToneColor="#52c41a" />
    ) : (
      <LockTwoTone twoToneColor="#d40817" />
    );
    return <span> {icon} AMDM Login </span>;
  };

  const modalFooter = () => {
    if (amdmConnection.isConnected) {
      return [
        <Button key="cancel" type="primary" onClick={handleCancel}>
          Close
        </Button>,
      ];
    }
    return [
      <Button key="cancel" type="primary" onClick={handleCancel}>
        CANCEL
      </Button>,
      <Button
        key="ok"
        danger
        onClick={handleOk}
        loading={amdmConnection.loading}
        disabled={amdmConnection.ownerUpn.length < 1 || amdmConnection.loading || amdmConnection.isConnected}
      >
        OK
      </Button>,
    ];
  };

  return (
    <Modal title={modalTitle()} closable={false} centered open={amdmConnection.isModalOpen} footer={modalFooter()}>
      {modalContent()}
    </Modal>
  );
};

export default AMDMModal;
