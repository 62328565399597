import { createSelector } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActivationCustomSettings, ActivationMatchingResults } from 'hawaii';
import { GlobalState } from './global';

export interface ActivationProcessUiMeta {
  // the front-end state may difer from the back-end state
  stale: boolean;
}

export interface ActivationProcess {
  id: string;
  criteriaHash: string;
  datamartId: number;
  dateCreation: Date;
  dateLastUpdate: Date;
  dateEnd: Date | null;
  dateIndexation: Date | null;
  indexResult: string;
  segmentId: string;
  status: 'created' | 'indexing' | 'indexed' | 'failed' | 'success';
  userId: string;
  countryIsos: string[];
  matchingResults: ActivationMatchingResults;
  micsId: string | undefined;
  activationMatchingAudience: string | undefined;
  activationMatchingParams: ActivationCustomSettings;
  uiMeta: ActivationProcessUiMeta;
}

const activationProcessSlice = createSlice({
  name: 'activationProcess',
  initialState: [] as ActivationProcess[],
  reducers: {
    refreshActivationProcessFromBackend(
      draft: ActivationProcess[],
      action: PayloadAction<{ activationProcesses: ActivationProcess[] }>,
    ) {
      const aps = [...action.payload.activationProcesses];
      return aps.map((ap) => {
        ap.uiMeta = { stale: false };
        return ap;
      });
    },
    markProcessAsStale(draft: ActivationProcess[], action: PayloadAction<{ id: string }>) {
      const index = draft.findIndex((ap) => ap.id === action.payload.id);
      if (index === -1) {
        throw 'Unfound activationProcess';
      }
      const activationProcess = draft[index];
      activationProcess.uiMeta.stale = true;
      draft[index] = { ...activationProcess };
    },
  },
});

export const { refreshActivationProcessFromBackend, markProcessAsStale } = activationProcessSlice.actions;

export default activationProcessSlice;

// ****************************
//          SELECTORS
// ****************************

export const selectActivationProcessByCountry = createSelector(
  [(state: GlobalState) => state.activationProcess, (state: GlobalState, countryIso: string) => ({ countryIso })],
  (activationProcesses: ActivationProcess[], { countryIso }: { countryIso: string }) =>
    activationProcesses.filter(
      (activationProcess: ActivationProcess) => activationProcess.countryIsos[0] === countryIso,
    ),
);

export const selectActivationProcessBySegment = createSelector(
  [(state: GlobalState) => state.activationProcess, (state: GlobalState, segmentId: string) => ({ segmentId })],
  (activationProcesses: ActivationProcess[], { segmentId }: { segmentId: string }) =>
    activationProcesses.filter((activationProcess: ActivationProcess) => activationProcess.segmentId === segmentId),
);
