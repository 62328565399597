import { CountryMetadata } from 'hawaii';

interface Language {
  value: string;
  meta: CountryMetadata | object;
}

export type StateMatchingProcess = {
  language: Language;
};

type Action<T = string, P = object> = {
  type: T;
  payload: P;
};

type SetLanguageAction = Action<'SET_LANGUAGE', { value: Language }>;

type Actions = SetLanguageAction;

type ReducerFunction = (state: StateMatchingProcess, action: Actions) => StateMatchingProcess;

const initialState: StateMatchingProcess = {
  language: {
    value: 'fr',
    meta: {},
  },
};

const reducers: ReducerFunction = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      return { ...state, language: { value: action.payload.value.value, meta: action.payload.value.meta } };
    }

    default:
      return state;
  }
};

export default reducers;
