import { FC } from 'react';
import type { RadioChangeEvent } from 'antd';
import { Radio, Row, Col, Card } from 'antd';
import { IBrandsDetails } from '../../common/interface/interface';

interface props {
  data: IBrandsDetails[];
  handleSelectedBrand: (value: string) => void;
  value: number | null | undefined;
  selectedBrandsList: string[] | undefined;
}

const RadioGroups: FC<props> = ({ data, handleSelectedBrand, value, selectedBrandsList }) => {
  const onChange = (e: RadioChangeEvent) => handleSelectedBrand(e.target.value as string);

  const radioItems = Object.values(data);

  const gridStyle: React.CSSProperties = {
    width: '100%',
    textAlign: 'left',
    padding: '0px',
    backgroundColor: 'white',
    // maxHeight: '87px',
    height: '100%',
    // minHeight: 'fit-content',
  };
  return (
    <>
      {radioItems && (
        <Radio.Group onChange={onChange} value={value?.toString()}>
          <Row>
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              radioItems.map((item: IBrandsDetails, index) => (
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                <Col span={12} key={index}>
                  <Card.Grid style={gridStyle}>
                    <Radio
                      disabled={
                        item.brand_id && selectedBrandsList ? selectedBrandsList?.includes(item.brand_id) : false
                      }
                      value={item.brand_id}
                      style={{ width: '100%', padding: '12px', margin: 'auto 0px' }}
                    >
                      <div style={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
                        <span style={{ marginBottom: '0.25rem' }}>{item.brand_name}</span>
                        <span style={{ fontSize: '11px' }}>{item.category4}</span>
                      </div>
                    </Radio>
                  </Card.Grid>
                </Col>
              ))
            }
          </Row>
        </Radio.Group>
      )}
    </>
  );
};

export default RadioGroups;
