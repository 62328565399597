import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const selectedLanguageSlice = createSlice({
  name: 'selectedLanguage',
  initialState: 0,
  reducers: {
    selectLanguage(draft: number, action: PayloadAction<{ language: number }>) {
      return action.payload.language;
    },
  },
});

export const { selectLanguage } = selectedLanguageSlice.actions;

export default selectedLanguageSlice;
