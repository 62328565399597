/**
 * Return the permutations needs to transform array a1 to array a2.
 * the function assume that the array have the same length and that array elements are unique
 *
 * The returned number list represents the new position of each element to match to the target list
 *
 * @param target
 * @param tosort
 *
 * @returns
 */
export const getPermutationOrders = (target: Array<unknown>, tosort: Array<unknown>): Array<number> => {
  return target.map((value) => tosort.indexOf(value));
};

export const applyPermutations =
  <T = unknown>(permutationOrders: Array<number>): ((a: Array<T>) => Array<T>) =>
  (a: Array<T>) =>
    permutationOrders.map((position: number) => a[position]);
