import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AMDMClientsInfos, AMDMMxInfos } from '../../core/amdm';
import { getAgenciesToSecmdmThunk } from './thunk/secmdm/getAgencies';
import { getClientsToSecmdmThunk } from './thunk/secmdm/getClients';
import { getCountriesToSecmdmThunk } from './thunk/secmdm/getCountries';
import { CheckMDMUserInfo, SECMDMCountry } from '../../core/secmdm';
import { CountryMetadata } from 'hawaii';

export interface SecmdmState {
  agencies: {
    list: AMDMMxInfos[];
    selected: number | undefined;
  };
  clients: {
    list: AMDMClientsInfos[];
    selected: number | undefined;
    isPitchClient: boolean;
    clientInputValue: string;
  };
  countries: {
    list: SECMDMCountry[];
    selected: CountryMetadata | null;
  };
  user: Partial<CheckMDMUserInfo>;
}

export const initialState: SecmdmState = {
  agencies: { list: [], selected: undefined },
  clients: { list: [], selected: undefined, isPitchClient: false, clientInputValue: '' },
  countries: { list: [], selected: null },
  user: { userExist: undefined, isoAlpha2: undefined, marketId: undefined },
};

const secmdmSlice = createSlice({
  name: 'SecmdmState',
  initialState,
  reducers: {
    setSelectedAgency: (state, action: PayloadAction<number | undefined>) => {
      state.agencies.selected = action.payload;
    },
    setSelectedClient: (state, action: PayloadAction<number | undefined>) => {
      state.clients.selected = action.payload;
    },
    setIsPitchClient: (state, action: PayloadAction<boolean>) => {
      state.clients.isPitchClient = action.payload;
    },
    setClientInputValue: (state, action: PayloadAction<string>) => {
      state.clients.clientInputValue = action.payload;
    },
    setSelectedCountry: (state, action: PayloadAction<CountryMetadata | null>) => {
      state.countries.selected = action.payload;
    },
    setUser: (state, action: PayloadAction<CheckMDMUserInfo>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAgenciesToSecmdmThunk.fulfilled, (state, action) => {
      state.agencies.list = action.payload;
    });

    builder.addCase(getClientsToSecmdmThunk.fulfilled, (state, action) => {
      state.clients.list = action.payload;
    });

    builder.addCase(getCountriesToSecmdmThunk.fulfilled, (state, action) => {
      state.countries.list = action.payload;
    });
  },
});

export const {
  setSelectedAgency,
  setSelectedClient,
  setIsPitchClient,
  setClientInputValue,
  setSelectedCountry,
  setUser,
} = secmdmSlice.actions;

export default secmdmSlice;
