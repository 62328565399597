import { FC } from 'react';
import { HavasIconAddParameter, HavasIconDeleteParameter, HavasIconOr } from '../../Svg/HavasIcons';

interface Props {
  operator: 'INCLUDE' | 'EXCLUDE' | 'OR';
  color: string;
}

const SegmentOperatorIcon: FC<Props> = ({ operator, color }) => {
  switch (operator) {
    case 'EXCLUDE':
      return <HavasIconDeleteParameter className="c-icon-stroke c-icon--24" />;
    case 'INCLUDE':
      return <HavasIconAddParameter className="c-icon-stroke c-icon--24" />;
    case 'OR':
      return <HavasIconOr color={color} width="25px" />;
  }
};

export default SegmentOperatorIcon;
