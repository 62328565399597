import './clusterEditor.less';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCountryDimFromIso } from '../../../core/helpers/country';
import { initialSegmentProperties } from '../../../core/segment/helper';
import ApplicationLayout from '../../ApplicationLayout';
// import SegmentsWrapper from '../../modules/clusterSegment/components/clusterSegmentsWrapperComponent';
import SegmentsWrapper from '../../MetaComponents/Segments/SegmentsWrapper';
import { GlobalState } from '../../store/global';
import { getSegmentEditorData, getSegmentEditorUrlBack } from '../../store/segmentEditionSlice';
import ClusterEditorComponent from '../../modules/clusterSegment/components/clusterEditorComponent';
import segmentSlice, { selectSegmentByIdAndOldId, selectSegmentByScope } from '../../store/segmentSlice';
import { SegmentOrigin } from 'hawaii';

const ClusterEditorBootstrap: FC = () => {
  const handleAddSegment = () => {
    const { payload } = dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          countryIsos: segment.countryIsos,
          dataScope: segment.dataScope,
          origin: SegmentOrigin.CLUSTER_BUILDER,
        }),
      }),
    );
    return payload.segment;
  };

  const editorData = useSelector((state: GlobalState) => getSegmentEditorData(state));
  const segment = useSelector((state: GlobalState) =>
    selectSegmentByIdAndOldId(state, { id: editorData.segmentId!, oldSegmentId: editorData.oldSegmentId! }),
  );

  const lang = useSelector((state: GlobalState) => state.settingsUI.language.value);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (segment === undefined) {
      navigate(`/client/cluster-builder/${lang}`);
    }
  }, [segment]);

  const segments = useSelector((state: GlobalState) =>
    segment === undefined
      ? []
      : selectSegmentByScope(state, segment.dataScope, segment.countryIsos.map(getCountryDimFromIso)),
  );

  const leftSide =
    segment !== undefined ? (
      <SegmentsWrapper
        segments={segments}
        countryIsos={segment.countryIsos}
        newSegmentAction={handleAddSegment}
        dataScope={segment.dataScope}
        link={'cluster-editor'}
        origin={SegmentOrigin.CLUSTER_BUILDER}
      />
    ) : (
      <></>
    );

  const rightSide = segment !== undefined ? <ClusterEditorComponent lang={lang} segment={segment} /> : <></>;

  return segment === undefined ? (
    <></>
  ) : (
    <ApplicationLayout pageName="cluster-editor" leftSide={leftSide} rightSide={rightSide} />
  );
};

export default ClusterEditorBootstrap;
