import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './global';
import { createSelector } from '@reduxjs/toolkit';
import { PageProperties } from '../interface';
import { DataScope, SegmentOrigin, SegmentWithUiMeta } from 'hawaii';
import { SegmentWithUiMetaWithOldId } from './segmentSlice';

export interface SegmentEditionState {
  segmentId: string | undefined;
  dataScope: DataScope;
  countryIsos: string[];
  origin: SegmentOrigin;
  oldSegmentId?: SegmentWithUiMetaWithOldId['oldSegmentId'];
}

const segmentEditionSlice = createSlice({
  name: 'segment-edition',
  initialState: { dataScope: 'Original', countryIsos: ['fr'] } as SegmentEditionState,
  reducers: {
    setSegment(draft: SegmentEditionState, action: PayloadAction<{ segment: SegmentWithUiMetaWithOldId }>) {
      draft.segmentId = action.payload.segment.id;
      draft.oldSegmentId = action.payload.segment.oldSegmentId!;
      draft.dataScope = action.payload.segment.dataScope;
      draft.countryIsos = action.payload.segment.countryIsos;
      draft.origin = action.payload.segment.origin!;
    },
    reset(draft: SegmentEditionState) {
      draft.segmentId = undefined;
    },
  },
});

export const { setSegment: setEditionSegment, reset: segmentEditionReset } = segmentEditionSlice.actions;

export default segmentEditionSlice;

// ****************************
//          SELECTORS
// ****************************

export const getSegmentEditorData = createSelector(
  [(state: GlobalState) => state.segmentEdition],
  (segmentEdition: SegmentEditionState) => segmentEdition,
);

export const getSegmentEditorUrlBack = createSelector(
  [(state: GlobalState) => state.segmentEdition],
  (segmentEdition: SegmentEditionState) =>
    segmentEdition.dataScope === 'Harmonized'
      ? PageProperties['cross-country'].url.replace(':countries', segmentEdition.countryIsos.join(','))
      : PageProperties['segment-builder'].url.replace(':country', segmentEdition.countryIsos.join(',')),
);
