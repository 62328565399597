import './VariableSelector.less';
import '../../Common/VariableSelector/VariableSelector.less';

import { FC, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import DrawerTitle from './DrawerHeader/DrawerTitle';
import { VariablesSelectionServiceInterface } from '../../../core/variablesSelection';
import { error, warning } from '../../Common/Message';
import Variables from '../../Common/VariableSelector/Variables';
import Responses from '../../Common/VariableSelector/Responses';
import Categories from '../../Common/VariableSelector/Categories';
import { FirstLevelCategory } from '../../Common/VariableSelector/CategoriesSelector';
import { splitStringArrayToEveryPairs } from '../../Common/VariableSelector/helper';
import { colorTosemanticColors } from '../../../core/helpers/colors';
import Load from '../../Common/Load';
import { DataScope, ResponseInfos, VariableInfos } from 'hawaii';
import { IconArrowLeft } from '../../Svg/HavasIcons';

interface Props {
  categoryTreeProvider: (path: string[]) => Promise<FirstLevelCategory[]>;
  searchCallback: (regex: string) => Promise<VariableInfos[]>;
  visible: boolean;
  onClose: (variableService: VariablesSelectionServiceInterface) => void;
  color: string;
  title: string;
  subtitle: string;
  validationCaption: string;
  validateVariableChoice: (
    selectedVariable: VariableInfos,
    selectedResponses: ResponseInfos[],
    responsePanel: ResponseInfos[],
  ) => void;
  variableService: VariablesSelectionServiceInterface;
  countries: string[];
  languageCode: number;
  dataScope: DataScope;
}

const VariableSelector: FC<Props> = ({
  categoryTreeProvider: categoryTreeProvider,
  searchCallback,
  color,
  title,
  subtitle,
  validationCaption,
  visible,
  onClose,
  validateVariableChoice,
  variableService,
  countries,
  languageCode,
  dataScope,
}) => {
  // availables variables and responses ( === undefined means that variables are loading )
  const [variablePanel, setVariablePanel] = useState<VariableInfos[] | undefined>([]);
  const [responsePanel, setResponsePanel] = useState<ResponseInfos[] | undefined>([]);
  // current selected variable and responses
  const [selectedVariable, setSelectedVariable] = useState<VariableInfos | undefined>(undefined);
  const [selectedResponses, setSelectedResponses] = useState<ResponseInfos[]>([]);
  const [path, setPath] = useState<string[]>([]);
  const [categoryTrees, setCategoryTrees] = useState<FirstLevelCategory[][] | undefined>(undefined);
  // will the category selector doen't reach the leaf and should load next categories ?
  const [loadNextCategories, setLoadNextCategories] = useState<boolean>(true);

  useEffect(() => {
    return () => {
      variableService.abort();
    };
  }, []);

  useEffect(() => {
    const run = async () => {
      const splittedSelectionFullPaths = splitStringArrayToEveryPairs(path);
      const tree: FirstLevelCategory[][] = [];
      tree[0] = await categoryTreeProvider([]);
      // should we try to load categories after the last bloc ?
      const upLimitIndex = loadNextCategories
        ? splittedSelectionFullPaths.length
        : splittedSelectionFullPaths.length - 1;

      for (let i = 0; i < upLimitIndex; i++) {
        const path = splittedSelectionFullPaths[i];

        if (path.length % 2 === 0) {
          tree[i + 1] = await categoryTreeProvider(path);
        }
      }

      setCategoryTrees(tree);
    };
    void run();
  }, [path]);

  useEffect(() => {
    setPath([]);
    setVariablePanel([]);
    setResponsePanel([]);
    setSelectedVariable(undefined);
    setSelectedResponses([]);
  }, [categoryTreeProvider]);

  const allResponseSelected: boolean = responsePanel !== undefined && responsePanel.length === selectedResponses.length;

  const variableRetrieveBySearch = async (value: string): Promise<void> => {
    if (value.length === 0) {
      setVariablePanel([]);
      return;
    }

    if (value.length < 3) {
      error('Please input at least 3 letters.');
      return;
    }

    setPath([]);
    setVariablePanel(undefined);
    setResponsePanel([]);
    const variableInfos = await searchCallback(value);

    if (!variableInfos || variableInfos.length < 1) {
      warning(`No variables retrieved for key word: ${value}`);
    }

    setVariablePanel(variableInfos);
  };

  const variableRetrieveByCategories = async (categoryPath: string[]): Promise<VariableInfos[]> => {
    const variables = await variableService.getVariablesFromCategories(
      categoryPath,
      countries,
      languageCode,
      dataScope,
    );
    return variables;
  };

  const responseRetrieve = async (variable: VariableInfos): Promise<ResponseInfos[]> => {
    const responses = await variableService.getVariableResponses(
      variable.variable_id,
      variable.isparent,
      countries,
      languageCode,
      dataScope,
    );

    return responses;
  };

  const handleResponseChange = (responses: ResponseInfos[]) => {
    setSelectedResponses(responses);
  };

  const handleValidation = () => {
    selectedVariable === undefined || validateVariableChoice(selectedVariable, selectedResponses, responsePanel!);
  };

  const handleChangeCategory = async (categories: string[], loadNext: boolean) => {
    setPath(categories);
    setVariablePanel(undefined);
    setResponsePanel([]);
    handleResponseChange([]);
    setLoadNextCategories(loadNext);
    const variableInfos = await variableRetrieveByCategories(categories);
    setSelectedResponses([]);

    if (!variableInfos || variableInfos.length < 1) {
      warning(`No variables retrieved for categories: ${categories.join(' > ')}`);
    }

    setVariablePanel(variableInfos);
  };

  const handleSelectVariable = async (selectedVariable: VariableInfos | undefined) => {
    setSelectedVariable(selectedVariable);
    setSelectedResponses([]);
    if (selectedVariable !== undefined) {
      setResponsePanel(undefined);
      const response = await responseRetrieve(selectedVariable);
      response.sort((a, b) => a.label.localeCompare(b.label));
      setResponsePanel(response);
    }
  };

  const handleSelectResponse = (selectedResponses: ResponseInfos[]) => {
    setSelectedResponses(selectedResponses);
  };

  const handleSelectAll = () => {
    if (allResponseSelected) {
      setSelectedResponses([]);
    } else {
      setSelectedResponses([...responsePanel!]);
    }
  };

  const colors = colorTosemanticColors(color);

  return (
    <div className="variable-drawer">
      <Drawer
        className="ant-drawer-variable-selector"
        placement="right"
        onClose={() => onClose(variableService)}
        closeIcon={
          <>
            <IconArrowLeft className="c-icon-stroke c-icon--20" />
            <span>Back</span>
          </>
        }
        open={visible}
        width="70%"
        title={<DrawerTitle title={title} subtitle={subtitle} searchMtd={variableRetrieveBySearch} />}
      >
        {categoryTrees === undefined ? (
          <div className="variable-drawer-loading">
            <Load style={{ width: '20%' }} />
            <br />
            <br />
            LOADING ...
          </div>
        ) : (
          <>
            <div className="inner-variable-drawer">
              <div className="variable-selector">
                <Categories
                  colors={colors}
                  selection={path}
                  onSelect={handleChangeCategory}
                  categoryTrees={categoryTrees}
                />
                <Variables
                  colors={colors}
                  variables={variablePanel}
                  value={selectedVariable}
                  onSelect={handleSelectVariable}
                />
                <Responses
                  colors={colors}
                  options={responsePanel}
                  values={selectedResponses}
                  onSelect={handleSelectResponse}
                />
                {responsePanel == undefined || responsePanel.length == 0 || (
                  <div className="variable-selector__btn">
                    <input
                      className="btn--sm"
                      type="button"
                      value={allResponseSelected ? 'Unselect All' : 'Select All'}
                      onClick={handleSelectAll}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="drawer-footer">
              <input className="btn--md" type="button" value={validationCaption} onClick={handleValidation} />
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
};

export default VariableSelector;
