import { FC, useEffect, useState } from 'react';
import '../SmartBuilder.less';
import { Checkbox, Tooltip } from 'antd';
import 'react-multi-carousel/lib/styles.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Segment } from '../../../../core/segment/segments';

const CheckboxGroup = Checkbox.Group;

type Props = {
  segment: Segment;
  subtitle?: string;
  title: string;
  options: { value: string; label: string }[];
  index: number;
  onChange: (index: number, optionIndex: number) => void;
};

export const transformLabels = (str: string) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^(.*?)([:\[])(.*)$/;
  const match = str.match(regex);

  if (match) {
    return {
      mainLabel: match[1].trim(),
      toolTipLabel: match[2] === ':' ? match[3].trim() : match[2] + match[3].trim(),
    };
  } else {
    return {
      mainLabel: str,
      toolTipLabel: '',
    };
  }
};

const SubAudienceCard: FC<Props> = ({ segment, subtitle, title, options, index, onChange }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [savedSelections, setSavedSelections] = useState<string[]>([]);

  useEffect(() => {
    if (segment.smartBuilder?.savedSubAudiencies && segment.smartBuilder?.savedSubAudiencies.length > 0) {
      const selectedOptions = segment.smartBuilder?.savedSubAudiencies.find(
        (el: { index: number; options: number[] }) => el.index === index,
      );
      setSavedSelections(selectedOptions?.options.map((opt: number) => options[opt].value) || []);
    }
    if (segment.smartBuilder?.selectedSubAudiencies && segment.smartBuilder?.selectedSubAudiencies.length > 0) {
      const selectedOptions = segment.smartBuilder?.selectedSubAudiencies.find(
        (el: { index: number; options: number[] }) => el.index === index,
      );
      setSelectedValues(selectedOptions?.options.map((opt: number) => options[opt].value) || []);
    }
  }, [segment]);

  return (
    <div className="sub-audience">
      <span className="sub-audience__subtitle">
        Sub-Audience: <span>{subtitle}</span>
      </span>
      <h6 className="sub-audience__title">{title}</h6>
      <div className="sub-audience__card">
        <div className="sub-audience__card-header">Suggested Questions</div>
        <div className="sub-audience__card-body">
          <CheckboxGroup value={[...selectedValues, ...savedSelections]}>
            {options.map((opt, optionIndex) => {
              const labels = options.map((option) => transformLabels(option.label).mainLabel);
              const { mainLabel, toolTipLabel } = transformLabels(opt.label);
              return (
                <Checkbox
                  key={optionIndex}
                  disabled={savedSelections.includes(opt.value)}
                  value={opt.value}
                  checked={segment.smartBuilder?.savedSubAudiencies
                    .find((el: { index: number; options: number[] }) => el.index === index)
                    ?.options.includes(optionIndex)}
                  onChange={() => onChange(index, optionIndex)}
                >
                  <span>
                    {labels.filter((lab) => lab === mainLabel).length > 1
                      ? toolTipLabel.replace(/"/g, '').replace(/:None/g, '')
                      : mainLabel}
                  </span>
                  <Tooltip title={toolTipLabel}>
                    <InfoCircleOutlined style={{ color: 'black', marginLeft: '5px' }} />
                  </Tooltip>
                </Checkbox>
              );
            })}
          </CheckboxGroup>
        </div>
      </div>
    </div>
  );
};

export default SubAudienceCard;
