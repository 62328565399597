import { createAsyncThunk } from '@reduxjs/toolkit';
import { Serie, MediaInter } from '../../../core/series/interface';
import { GlobalState } from '../global';
import { selectSegmentById } from '../segmentSlice';
import seriesSlice from '../seriesSlice';
import mediaConsumption from '../mediaConsumption';
import crossResultSlice from '../crossResultsSlice';

export const injectCrossResultsThunk = createAsyncThunk<
  void,
  {
    serie: Serie;
    segmentId: string;
  },
  { state: GlobalState }
>(
  'series/inject',

  ({ serie, segmentId }, { getState, dispatch }): void => {
    const segment = selectSegmentById(getState(), segmentId);
    if (serie.tags[0]?.mediaCons) {
      const mediaCons = { [segmentId]: serie.tags[0]?.totalMediaCons };

      let pathname: string | string[] = window.location.pathname;
      pathname = pathname.split('/');
      pathname = pathname[pathname.length - 1];
      pathname = pathname.split(',');

      dispatch(mediaConsumption.actions.setMedia(mediaCons as Record<string, MediaInter[]>));

      if (
        !getState().crossResults.selectedResponses[pathname.sort().join('')][
          serie.globalTags.variableId as string
        ].some((el) => el === 'mediaConsumption')
      ) {
        dispatch(
          crossResultSlice.actions.removeVariable({
            countryIsos: pathname,
            variableId: serie.globalTags.variableId as string,
          }),
        );
        dispatch(
          crossResultSlice.actions.addResponses({
            countryIsos: pathname,
            variableId: serie.globalTags.variableId as string,
            responseIds: ['mediaConsumption'],
          }),
        );
      }
    }

    dispatch(
      seriesSlice.actions.setSeries({
        serie: {
          ...serie,
          globalTags: {
            ...serie.globalTags,
            segmentId,
            name: segment.name,
            color: segment.color,
            irrelevant: segment.uiMeta.irrelevant,
          },
        },
      }),
    );
  },
);
