import * as serviceWorker from './serviceWorker';

import App from './react/App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { Provider } from 'react-redux';
import store from './react/store/global';
import { enableMapSet } from 'immer';
import Login from './react/Pages/Auth/Login';
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

void msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  ReactDOM.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <AuthenticatedTemplate>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Login />
          </UnauthenticatedTemplate>
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});

enableMapSet();

// If you want your app to work offline and load faster, you can change
// unregister() to register(). Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
