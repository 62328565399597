import './SegmentsWrapper.less';
import { FC, useEffect, useState, useRef, useContext } from 'react';
import SegmentManager from '../SegmentManager/SegmentManager';
import SegmentCard from '../../Common/SegmentCard/SegmentCard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import { PageProperties } from '../../interface';
import { Context } from '../../Common/Context/Context';
import { importSegmentThunk } from '../../store/thunk/importSegment';
import { GlobalState } from '../../store/global';
import { selectActivationProcessByCountry } from '../../store/activationProcessSlice';
import { getSegmentEditorData, setEditionSegment } from '../../store/segmentEditionSlice';
import segmentSlice from '../../store/segmentSlice';
import { DataScope, SegmentOrigin, SegmentWithUiMeta } from 'hawaii';
import { useDataToCountDown } from '../../Common/hooks/useCountDown';
import { setActivateStudioState } from '../../store/activateStudioSlice';
import { SegmentStage, SmartSegmentStage } from '../../Pages/SegmentEditor/UJContext';

interface Props {
  segments: SegmentWithUiMeta[];
  countryIsos: string[];
  newSegmentAction: (isSmart?: boolean) => SegmentWithUiMeta;
  dataScope: DataScope;
  link: string;
  setSelectedStage?: React.Dispatch<React.SetStateAction<SegmentStage | SmartSegmentStage>>;
  origin: SegmentOrigin;
}

const SegmentsWrapper: FC<Props> = ({
  segments,
  countryIsos,
  newSegmentAction,
  dataScope,
  link,
  setSelectedStage,
  origin,
}) => {
  const databricks = useSelector((state: GlobalState) => state.databricks);
  const editorData = useSelector((state: GlobalState) => getSegmentEditorData(state));
  const { currentRunsTime, getCurrentRunsTime } = useDataToCountDown();
  const location = useLocation();
  const retrieveStoredSegments = async () => {
    const segments = await sharedSegmentService.getMine(countryIsos, dataScope);
    setStoredSegments(segments);
  };
  const retrieveSharedSegments = async () => {
    const segments = await sharedSegmentService.getAllShared(countryIsos, dataScope);
    setSharedSegments(segments);
  };

  const handleRemoveSegment = (id: string) => {
    dispatch(segmentSlice.actions.removeSegment({ id }));
    setSelectedSegment(selectedSegment ? selectedSegment - 1 : 0);
  };

  const handleRefreshStoredSegments = async () => {
    await retrieveStoredSegments();
  };

  const handleImportSegment = (segment: SegmentWithUiMeta) => {
    dispatch(importSegmentThunk({ segment }));
  };

  const handleSelect = (index: number) => {
    dispatch(setActivateStudioState(null));
    setSelectedStage && setSelectedStage(segments[index]?.smartBuilder?.active ? 'Smart Builder' : 'Definition');
    dispatch(
      setEditionSegment({
        segment: {
          ...segments[index],
          origin: origin,
        },
      }),
    );

    setSelectedSegment(index);

    if (databricks?.['clusters']?.[segments[index].publicId] && link === 'cluster-editor') {
      navigate(`/client/cluster-builder/${segments[index].countryIsos[0]}/${segments[index].publicId}`);
    } else {
      navigate(PageProperties[link].url);
    }
  };

  const handleSmartSelect = (newSegment: SegmentWithUiMeta) => {
    const index = segments.length;
    dispatch(setActivateStudioState(null));
    setSelectedStage && setSelectedStage('Smart Builder');
    setSelectedSegment(index);
    dispatch(
      setEditionSegment({
        segment: {
          ...newSegment,
          origin: origin,
        },
      }),
    );

    if (location.pathname === PageProperties[link].url) return;

    if (databricks?.['clusters']?.[segments[index].publicId] && link === 'cluster-editor') {
      navigate(`/client/cluster-builder/${segments[index].countryIsos[0]}/${segments[index].publicId}`);
    } else {
      navigate(PageProperties[link].url);
    }
  };

  const dispatch = useDispatch();

  const { factory } = useContext(Context);
  const sharedSegmentService = factory.getSharedSegmentService();

  const activationProcesses = useSelector((state: GlobalState) =>
    dataScope === 'Original' ? selectActivationProcessByCountry(state, countryIsos[0]) : [],
  );

  const elementRef = useRef<HTMLDivElement>(null);
  const [selectedSegment, setSelectedSegment] = useState<number | undefined | null>(segments.length - 1);
  const [storedSegments, setStoredSegments] = useState<SegmentWithUiMeta[]>([]);
  const [sharedSegments, setSharedSegments] = useState<SegmentWithUiMeta[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    !elementRef.current || elementRef.current.scrollIntoView();
  });

  useEffect(() => {
    const selectedSegmentFromState = segments.findIndex(({ id }) => id === editorData.segmentId);
    if (selectedSegmentFromState !== -1) setSelectedSegment(selectedSegmentFromState);
    else setSelectedSegment(null);
  }, [editorData, setSelectedSegment]);

  useEffect(() => {
    const run = async () => {
      await retrieveStoredSegments();
      await retrieveSharedSegments();
    };
    void run();
  }, [countryIsos]);

  useEffect(() => {
    if (Object.keys(databricks).length === 0) return;
    getCurrentRunsTime(databricks);
  }, [databricks]);

  const handleSmartBuilder = (newSegment: SegmentWithUiMeta) => {
    handleSmartSelect(newSegment);
    return;
  };

  return (
    <>
      <div className="audiences-wrapper no-scrollbar">
        <div className="audience-cards">
          {segments.map((segment, index) => {
            const ap = activationProcesses.find((ap) => ap.segmentId === segment.id);
            return (
              <SegmentCard
                segment={segment}
                indexingState={ap !== undefined ? ap.status : undefined}
                removeAction={() => handleRemoveSegment(segment.id)}
                selectionAction={(selected: boolean) => {
                  handleSelect(index);
                }}
                selected={selectedSegment === index}
                key={index}
                currentRunsTime={currentRunsTime}
              />
            );
          })}
          {(!segments.length || segments.some(({ amdmInfos }) => !amdmInfos)) && (
            <div className="notify-content">
              <p>Start by creating an Audience, select attributes and use AND / OR buttons to build your Persona</p>
            </div>
          )}
          <div ref={elementRef} />
        </div>
      </div>
      <SegmentManager
        newAudienceAction={newSegmentAction}
        importSegmentAction={handleImportSegment}
        sharedSegmentService={sharedSegmentService}
        storedSegments={storedSegments}
        sharedSegments={sharedSegments}
        refreshStoredSegmentsAction={handleRefreshStoredSegments}
        activationProcesses={activationProcesses}
        handleSmartBuilder={handleSmartBuilder}
      />
    </>
  );
};

export default SegmentsWrapper;
