import { message } from 'antd';

export interface HttpError {
  code?: number;
  error: string;
}

export const error = (msg: string, err?: HttpError): void => {
  if (err && !err.error) {
    err.error = 'Something went wrong';
  }
  if (err && !err.code) {
    err.code = 500;
  }
  const details = err ? `| Message: ${err.error} | Code: ${err.code!}` : '';
  void message.error(`${msg} ${details}`);
};

export const warning = (msg: string): void => {
  void message.warning(msg);
};

export const info = (msg: string): void => {
  void message.info(msg);
};
