import { FC } from 'react';
import { Link } from 'react-router-dom';
import { PageProperties } from '../interface';
import './NotFound.less';
// update

const NotFound: FC = () => (
  <div className="not-found">
    <div className="center">
      <h1>404 - Not found</h1>
      <p>The page you are looking for doesn't exists.</p>
      <Link to={PageProperties['segment-builder'].url}>&lt;&lt; Back To Segment Builder</Link>
    </div>
  </div>
);

export default NotFound;
