import './SegmentCard.less';
import { FC } from 'react';
import { SegmentWithUiMeta } from 'hawaii';
import SegmentCardHeader from './SegmentCardHeader';
import { usePercentageBar } from '../hooks/useCountDown';

interface Props {
  segment: SegmentWithUiMeta;
  indexingState: string | undefined;
  removeAction?: () => void;
  selectionAction: (state: boolean) => void;
  selected: boolean;
  currentRunsTime:
    | {
        [key: string]: {
          countDownTime: string;
          module: string;
          startTime: number;
          status: string;
        };
      }
    | undefined;
}

export const SegmentCard: FC<Props> = (props) => {
  const { segment, currentRunsTime } = props;
  const percentageProgress = usePercentageBar(
    currentRunsTime?.[segment.publicId]?.startTime,
    currentRunsTime?.[segment.publicId]?.countDownTime,
    currentRunsTime?.[segment.publicId]?.status,
  );

  return (
    <div className="audience-card" style={{ borderColor: segment.color }}>
      <SegmentCardHeader {...props} timeToCountDown={currentRunsTime} />
      <div className="card-footer">
        {percentageProgress && (
          <div className="progress-bar">
            <div
              style={{
                width: `${percentageProgress}%`,
                backgroundColor: segment.color,
                height: '100%',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SegmentCard;
