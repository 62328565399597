export interface CountryMetadata {
  name: string;
  iso: string;
  id: number;
  population: number;
  datamartId: string;
  isGlobal: boolean;
  activable: boolean;
  havasId: string;
}

export const countryMetadatas: CountryMetadata[] = [
  {
    name: "France",
    iso: "fr",
    id: 7,
    population: 53685000,
    datamartId: "1525",
    isGlobal: true,
    activable: true,
    havasId: "1450",
  },
  {
    name: "Australia",
    iso: "au",
    id: 1,
    population: 19950000,
    datamartId: "1576",
    isGlobal: true,
    activable: true,
    havasId: "1486",
  },
  {
    name: "Germany",
    iso: "de",
    id: 5,
    population: 69422000,
    datamartId: "1600",
    isGlobal: true,
    activable: false,
    havasId: "1487",
  },
  {
    name: "Hong Kong",
    iso: "hk",
    id: 9,
    population: 6546000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Philippines",
    iso: "ph",
    id: 11,
    population: 72507000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "UK",
    iso: "gb",
    id: 8,
    population: 52384000,
    datamartId: "1595",
    isGlobal: true,
    activable: false,
    havasId: "1522",
  },
  {
    name: "Italy",
    iso: "it",
    id: 10,
    population: 49811000,
    datamartId: "1499",
    isGlobal: true,
    activable: true,
    havasId: "1413",
  },
  {
    name: "China",
    iso: "cn",
    id: 4,
    population: 1113280000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Canada",
    iso: "ca",
    id: 2,
    population: 29400000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Spain",
    iso: "es",
    id: 6,
    population: 38614000,
    datamartId: "1500",
    isGlobal: true,
    activable: true,
    havasId: "1414",
  },
  {
    name: "India",
    iso: "in",
    id: 15,
    population: 818193000,
    datamartId: "1601",
    isGlobal: true,
    activable: false,
    havasId: "1484",
  },
  {
    name: "Belgium",
    iso: "be",
    id: 13,
    population: 10174000,
    datamartId: "1516",
    isGlobal: true,
    activable: false,
    havasId: "1439",
  },
  {
    name: "Ireland",
    iso: "ie",
    id: 14,
    population: 4052000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Portugal",
    iso: "pt",
    id: 17,
    population: 8592000,
    datamartId: "1501",
    isGlobal: true,
    activable: true,
    havasId: "1416",
  },
  {
    name: "Netherlands",
    iso: "nl",
    id: 16,
    population: 15304000,
    datamartId: "1494",
    isGlobal: true,
    activable: false,
    havasId: "1408",
  },
  {
    name: "United States",
    iso: "us",
    id: 18,
    population: 256038000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Mexico",
    iso: "mx",
    id: 19,
    population: 80479000,
    datamartId: "null",
    isGlobal: true,
    activable: false,
    havasId: "",
  },
  {
    name: "Poland",
    iso: "pl",
    id: 12,
    population: 30787000,
    datamartId: "1493",
    isGlobal: true,
    activable: true,
    havasId: "1407",
  },
  {
    name: "Argentina",
    iso: "ar",
    id: 21,
    population: 30800000,
    datamartId: "null",
    isGlobal: true,
    activable: true,
    havasId: "null",
  },
  {
    name: "Colombia",
    iso: "co",
    id: 25,
    population: 36784000,
    datamartId: "null",
    isGlobal: true,
    activable: true,
    havasId: "null",
  },
  {
    name: "Brazil",
    iso: "br",
    id: 23,
    population: 147306000,
    datamartId: "null",
    isGlobal: true,
    activable: true,
    havasId: "null",
  },
  {
    name: "Singapore",
    iso: "sg",
    id: 26,
    population: 3302000,
    datamartId: "null",
    isGlobal: true,
    activable: true,
    havasId: "null",
  },
  {
    name: "South Africa",
    iso: "za",
    id: 30,
    population: 25248000,
    datamartId: "null",
    isGlobal: true,
    activable: true,
    havasId: "null",
  },
];
