import './clusterProfile.less';
import { FC } from 'react';
import PieChart from '../../../Common/charts/pie';
import { useClustersData } from '../hooks/useClustersData';
import { useParams } from 'react-router';

import Table from './table';

const ClusterProfile: FC = () => {
  const { segmentID } = useParams();

  if (!segmentID) return <></>;

  const { clusterProfile, clusterIndexTable, clustersMarketSize } = useClustersData(segmentID, 'clusters');
  const pieData = clusterProfile();
  const tableData = clusterIndexTable();
  const marketSize = clustersMarketSize();

  return (
    <div className="cluster-profile">
      {(!tableData || pieData.length === 0 || !segmentID) && <div>No cluster profile data. Please try again.</div>}
      {tableData && pieData.length > 0 && segmentID && (
        <>
          <PieChart clusters={pieData} marketSize={marketSize}></PieChart>
          <Table data={tableData} marketSize={marketSize} title="Clusters Audiences" segmentID={segmentID} />
        </>
      )}
    </div>
  );
};

export default ClusterProfile;
