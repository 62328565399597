import { ActivationCustomSettings, DataScope, SegmentCommonProperties, SegmentWithUiMeta } from 'hawaii';
import { info, warning } from '../react/Common/Message';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';
import { getCountryDim } from './helpers/country';
import { initialSegmentProperties } from './segment/helper';

export interface SharedSegmentServiceInterface {
  abort: () => void;
  save: (segment: SegmentWithUiMeta) => Promise<SegmentWithUiMeta>;
  saveCopySegment: (
    segment: SegmentWithUiMeta,
    agency?: number,
    client?: number | string,
  ) => Promise<SegmentWithUiMeta>;
  getMine: (countryIsos: string[], dataScope: DataScope) => Promise<SegmentWithUiMeta[]>;
  getClustersMine: (countryIsos: string[], dataScope: DataScope) => Promise<SegmentWithUiMeta[]>;
  getMineFull: ({ dataScope, limit }: { dataScope: DataScope; limit?: number }) => Promise<SegmentWithUiMeta[]>;
  getAllShared: (countryIsos: string[], dataScope: DataScope) => Promise<SegmentWithUiMeta[]>;
  getClustersAll: (countryIsos: string[], dataScope: DataScope) => Promise<SegmentWithUiMeta[]>;
  getAllSharedFull: ({ dataScope, limit }: { dataScope: DataScope; limit?: number }) => Promise<SegmentWithUiMeta[]>;
  delete: (segmentId: string) => Promise<void>;
  share: (segmentId: string) => Promise<void>;
  unshare: (segmentId: string) => Promise<void>;
  micsExport: (segment: SegmentWithUiMeta, segmentName?: string, segmentDescription?: string) => Promise<void>;
  micsActivation: (
    activationProcessId: string,
    micsSegmentName: string,
    audienceName: string,
    customSettings: ActivationCustomSettings,
    provider: string,
    destination: string,
  ) => Promise<string>;
}

const SharedSegmentService = (backend: BackendFacadeInterface): SharedSegmentServiceInterface => {
  const mandatoryKeys = ['version', 'id', 'criterias'];

  /**
   * Check the result from backend
   *
   * @param raw
   */
  const checkKeys = (raw: SegmentCommonProperties) => {
    const keys = Object.keys(raw);
    mandatoryKeys.forEach((mandatoryKey) => {
      if (keys.indexOf(mandatoryKey) === -1) {
        throw new Error(`Mandatory key ${mandatoryKey} not present`);
      }
    });
  };

  /**
   * Assemble result from the backend response
   *
   * The id fields from the backend wrap takes precedence (hence the frontend cannot spoof it)
   *
   * @param raw
   */
  const assemble = (raw: SegmentCommonProperties): SegmentWithUiMeta => {
    checkKeys(raw);
    return initialSegmentProperties(raw);
  };

  /**
   * Assemble array of result from the backend response
   *
   * @param raw
   */
  const assembleArray = (raw: SegmentCommonProperties[]): SegmentWithUiMeta[] => {
    const segmentsArray = raw.map(assemble);
    const result: SegmentWithUiMeta[] = [];
    segmentsArray.forEach((segment) => {
      result.push(segment);
    });
    return result;
  };

  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },
    /**
     * Call the backend to save the segment parameters
     * @param segment
     */
    save: async (segment: SegmentWithUiMeta): Promise<SegmentWithUiMeta> => {
      const body = segment;
      const raw = await backend.post<SegmentCommonProperties>('/v1/shared-segment', body);
      return assemble(raw);
    },

    saveCopySegment: async (
      segment: SegmentWithUiMeta,
      agency?: number,
      client?: number | string,
    ): Promise<SegmentWithUiMeta> => {
      const body = { segment, agency, client };
      const raw = await backend.post<SegmentCommonProperties>('/v1/shared-segment/copySegment', body);
      return assemble(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country
     *
     * @param dashboard
     */
    getMine: async (countryIsos: string[], dataScope: DataScope): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-mine`;
      if (countryIsos.length < 1) {
        return [];
      }
      const raw = await backend.post<SegmentCommonProperties[]>(url, { countryIsos, dataScope });
      return assembleArray(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country
     *
     * @param dashboard
     */
    getClustersMine: async (countryIsos: string[], dataScope: DataScope): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-clusters-mine`;
      if (countryIsos.length < 1) {
        return [];
      }
      const raw = await backend.post<SegmentCommonProperties[]>(url, { countryIsos, dataScope });
      return assembleArray(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country
     *
     * @param dashboard
     */
    getMineFull: async ({
      dataScope,
      limit,
    }: {
      dataScope: DataScope;
      limit?: number;
    }): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-mine-full`;
      const raw = await backend.post<SegmentCommonProperties[]>(url, { dataScope, limit });
      return assembleArray(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country.
     *
     * @param string[]
     */
    getAllShared: async (countryIsos: string[], dataScope: DataScope): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-all`;
      if (countryIsos.length < 1) {
        return [];
      }
      const raw = await backend.post<SegmentCommonProperties[]>(url, { countryIsos, dataScope });
      return assembleArray(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country.
     *
     * @param string[]
     */
    getClustersAll: async (countryIsos: string[], dataScope: DataScope): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-clusters-all`;
      if (countryIsos.length < 1) {
        return [];
      }
      const raw = await backend.post<SegmentCommonProperties[]>(url, { countryIsos, dataScope });
      return assembleArray(raw);
    },

    /**
     * Retrieve all segment parameters from the backend related to
     * the current user, filtered by closure country.
     *
     * @param string[]
     */
    getAllSharedFull: async ({
      dataScope,
      limit,
    }: {
      dataScope: DataScope;
      limit?: number;
    }): Promise<SegmentWithUiMeta[]> => {
      const url = `/v1/shared-segment/get-all-full`;
      const raw = await backend.post<SegmentCommonProperties[]>(url, { dataScope, limit });
      return assembleArray(raw);
    },

    /**
     * Call the backend to delete the segment based on its id
     *
     * @param segmentId
     */
    delete: async (segmentId: string): Promise<void> => {
      const body = { id: segmentId };
      await backend.post('/v1/shared-segment/delete', body);
    },

    /**
     * Share the segment publicly based on its id
     *
     * @param segmentId
     */
    share: async (segmentId: string): Promise<void> => {
      const body = { id: segmentId };
      await backend.post('/v1/shared-segment/share', body);
    },

    /**
     * Unshare the segment based on its id
     *
     * @param segmentId
     */
    unshare: async (segmentId: string): Promise<void> => {
      const body = { id: segmentId };
      await backend.post('/v1/shared-segment/unshare', body);
    },

    /**
     * Create a segment on mics plateform based on the segment
     *
     * @param segment
     * @param segmentName
     * @param segmentDescription
     */
    micsExport: async (
      segment: SegmentWithUiMeta,
      segmentName?: string,
      segmentDescription?: string,
    ): Promise<void> => {
      const countryDim = getCountryDim(segment.countryIsos[0]);

      if (countryDim.datamartId === 'null') {
        warning(`${countryDim.name} does not have a datamart on mics side.`);
        return;
      }

      const body = {
        segmentId: segment.id,
        segmentName: segmentName || segment.name,
        segmentDescription,
        datamartId: countryDim.datamartId,
        countryCode: countryDim.id,
        audience: segment,
      };

      const result = await backend.post<{ segmentUrl: string; warn?: string }>('/v1/shared-segment/indexation', body);

      if (result.warn) {
        warning(result.warn);
        return;
      }

      info(`Matching in progress ...`);
      return;
    },

    micsActivation: async (
      activationProcessId: string,
      micsSegmentName: string,
      matchingAudienceName: string,
      customSettings: ActivationCustomSettings,
      provider: string,
      destination: string,
    ): Promise<string> => {
      const body = {
        activationProcessId,
        matchingAudienceName,
        micsSegmentName,
        customSettings,
        provider,
        destination,
      };
      const response = await backend.post<{ url: string; warn?: string }>('/v1/shared-segment/activation', body);
      return response.url;
    },
  };
};

export interface SegmentFavoritesServiceFactoryInterface {
  get: () => SharedSegmentServiceInterface;
}

const SharedSegmentServiceFactory = (
  backendFactory: BackendFacadeFactoryInterface,
): SegmentFavoritesServiceFactoryInterface => ({
  get: () => SharedSegmentService(backendFactory.get()),
});

export default SharedSegmentServiceFactory;
