import React, { useState } from 'react';
import { Modal, InputNumber, Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import '../../../../styles/components/modal.less';

type Props = {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClusterAnalysis: (value: string) => void;
};

const ClusterModal: React.FC<Props> = ({ setModalOpen, handleClusterAnalysis }) => {
  const [auto, setAuto] = useState<boolean>(false);
  const [value, setValue] = useState<number | null>(null);

  const onChange = (e: CheckboxChangeEvent | number | null) => {
    if (typeof e !== 'number' && e !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setValue(null);
      setAuto(e.target.checked);
    } else if (e !== null) {
      setValue(e);
    }
  };

  const onOK = () => {
    if (auto) {
      handleClusterAnalysis('0');
    } else if (value !== null) {
      handleClusterAnalysis(value.toString());
    }
  };

  return (
    <div>
      <Modal
        title="How many clusters do you wanna create ?"
        centered
        open={true}
        onOk={() => onOK()}
        onCancel={() => setModalOpen(false)}
        width={500}
      >
        <div className="cluster-body">
          <InputNumber disabled={auto} type="number" min={0} max={99} value={value} onChange={onChange} />
          <Checkbox onChange={onChange}>Auto select</Checkbox>
        </div>
      </Modal>
    </div>
  );
};

export default ClusterModal;
