import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { GlobalState } from '../global';
import { setHawaiiState } from '../hawaiiStateSlice';
import { handleGlobalErrorThunk } from './handleGlobalError';

export const refreshHawaiiStateThunk = createAsyncThunk<
  void,
  void,
  { state: GlobalState; extra: { factory: FactoryInterface } }
>('hawaiiState/refresh', async (arg, { dispatch, extra }): Promise<void> => {
  const hawaiiStateService = extra.factory.getHawaiiStateService();
  try {
    const state = await hawaiiStateService.retrieve();
    dispatch(setHawaiiState({ state }));
  } catch (error: unknown) {
    void dispatch(handleGlobalErrorThunk(error));
  }
});
