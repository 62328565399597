import { FC, useEffect, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

interface props {
  icon: any;
  title: string;
  placeholder: string;
  handleBrandSearch: (value: string | undefined) => void;
  setInputValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  inputValue: string | undefined;
}

const InputForm: FC<props> = ({ icon, title, placeholder, handleBrandSearch, setInputValue, inputValue }) => {
  return (
    <div className="search-bar">
      <div style={{ marginBottom: '4px' }}>
        {icon ? <span style={{ margin: '0px 5px' }}>{icon}</span> : null}
        <span>{title}</span>
      </div>
      <Input
        addonAfter={<SearchOutlined onClick={() => handleBrandSearch(inputValue)} />}
        // onBlur={() => handleBrandSearch(inputValue)}
        value={inputValue}
        onPressEnter={() => handleBrandSearch(inputValue)}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder={placeholder}
      />
    </div>
  );
};
export default InputForm;
