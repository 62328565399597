import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { sendOwnerUpnToAmdmThunk } from './thunk/amdm/sendOwnerUpn';
import { getOwnerUpnToAmdmThunk } from './thunk/amdm/getOwnerUpn';
import { AMDMClientsInfos, AMDMMxInfos } from '../../core/amdm';

import { getAgenciesToSecmdmThunk } from './thunk/secmdm/getAgencies';
import { getClientsToSecmdmThunk } from './thunk/secmdm/getClients';

export interface AmdmState {
  connection: {
    isConnected: boolean;
    ownerUpn: string;
    isModalOpen: boolean;
    loading: boolean;
    error: string | undefined;
  };
  agencies: {
    list: AMDMMxInfos[];
    selected: number | undefined;
  };
  clients: {
    list: AMDMClientsInfos[];
    selected: number | undefined;
    isPitchClient: boolean;
    clientInputValue: string;
  };
  audiences: {
    targetedAudience: Record<string, string | number>;
    getModalIsOpen: boolean;
    saveModalIsOpen: boolean;
    saveAction: 'save' | 'save-old' | 'copy' | 'update-infos' | undefined;
  };
}

export const initialState: AmdmState = {
  connection: {
    isConnected: false,
    isModalOpen: false,
    ownerUpn: '',
    loading: false,
    error: undefined,
  },
  agencies: { list: [], selected: undefined },
  clients: { list: [], selected: undefined, isPitchClient: false, clientInputValue: '' },
  audiences: {
    targetedAudience: {},
    getModalIsOpen: false,
    saveModalIsOpen: false,
    saveAction: undefined,
  },
};

const amdmSlice = createSlice({
  name: 'amdmState',
  initialState,
  reducers: {
    setAmdmOwnerUpn: (state, action: PayloadAction<string>) => {
      state.connection.ownerUpn = action.payload;
    },
    setConnectionModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.connection.isModalOpen = action.payload;
    },
    setAmdmLoginError: (state, action: PayloadAction<string | undefined>) => {
      state.connection.error = action.payload;
    },
    setSelectedAgency: (state, action: PayloadAction<number | undefined>) => {
      state.agencies.selected = action.payload;
    },
    setSelectedClient: (state, action: PayloadAction<number | undefined>) => {
      state.clients.selected = action.payload;
    },
    setIsPitchClient: (state, action: PayloadAction<boolean>) => {
      state.clients.isPitchClient = action.payload;
    },
    setClientInputValue: (state, action: PayloadAction<string>) => {
      state.clients.clientInputValue = action.payload;
    },
    setAmdmTargetedAudience: (state, action: PayloadAction<Record<string, string | number>>) => {
      state.audiences.targetedAudience = action.payload;
    },
    setSaveAudienceModalOpen: (state, action: PayloadAction<boolean>) => {
      state.audiences.saveModalIsOpen = action.payload;
    },
    setGetAudienceModalOpen: (state, action: PayloadAction<boolean>) => {
      state.audiences.getModalIsOpen = action.payload;
    },
    setSaveAudienceAction: (
      state,
      action: PayloadAction<'save' | 'save-old' | 'copy' | 'update-infos' | undefined>,
    ) => {
      state.audiences.saveAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendOwnerUpnToAmdmThunk.pending, (state) => {
      state.connection.loading = true;
      state.connection.error = undefined;
    });

    builder.addCase(sendOwnerUpnToAmdmThunk.fulfilled, (state, action) => {
      state.connection.loading = false;
      state.connection.isConnected = true;
      const amdmUpn = action.payload;
      state.connection.ownerUpn = amdmUpn.ownerUpn;
    });

    builder.addCase(sendOwnerUpnToAmdmThunk.rejected, (state) => {
      state.connection.loading = false;
    });

    builder.addCase(getOwnerUpnToAmdmThunk.fulfilled, (state, action) => {
      const amdmUpn = action.payload;
      if (amdmUpn !== undefined && Object.keys(amdmUpn).length > 0) {
        state.connection.ownerUpn = amdmUpn.ownerUpn;
        state.connection.isConnected = true;
      }
    });

    builder.addCase(getAgenciesToSecmdmThunk.fulfilled, (state, action) => {
      state.agencies.list = action.payload;
    });

    builder.addCase(getClientsToSecmdmThunk.fulfilled, (state, action) => {
      state.clients.list = action.payload;
    });
  },
});

export const {
  setAmdmOwnerUpn,
  setConnectionModalIsOpen,
  setAmdmLoginError,
  setSelectedAgency,
  setSelectedClient,
  setIsPitchClient,
  setClientInputValue,
  setAmdmTargetedAudience,
  setGetAudienceModalOpen,
  setSaveAudienceModalOpen,
  setSaveAudienceAction,
} = amdmSlice.actions;

export default amdmSlice;
