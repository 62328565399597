import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { saveAudienceInAmdmThunk } from './thunk/amdm/saveAudience';
import { copySegmentProfilesThunk, saveSegmentinProfilesThunk } from './thunk/saveSegment';

const appIsLoadingSlice = createSlice({
  name: 'appIsLoadingState',
  initialState: false,
  reducers: {
    setAppIsLoadingState(draft: boolean, action: PayloadAction<{ state: boolean }>) {
      return action.payload.state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveSegmentinProfilesThunk.fulfilled, () => {
      return false;
    });

    builder.addCase(saveSegmentinProfilesThunk.rejected, () => {
      return false;
    });

    builder.addCase(saveAudienceInAmdmThunk.fulfilled, () => {
      return false;
    });

    builder.addCase(saveAudienceInAmdmThunk.rejected, () => {
      return false;
    });

    builder.addCase(copySegmentProfilesThunk.fulfilled, () => {
      return false;
    });

    builder.addCase(copySegmentProfilesThunk.rejected, () => {
      return false;
    });
  },
});

export const { setAppIsLoadingState } = appIsLoadingSlice.actions;

export default appIsLoadingSlice;
