import './VariableSelector.less';
import { FC, useState } from 'react';
import { Spin } from 'antd';
import { SemanticColors } from '../../../core/helpers/colors';
import { VariableInfos } from 'hawaii';

interface Props {
  // variables === undefined => variables are loading ...
  variables: VariableInfos[] | undefined;
  colors: SemanticColors;
  value: VariableInfos | undefined;
  onSelect: (value: VariableInfos | undefined) => void;
}

const Item: FC<{
  variable: VariableInfos;
  value: VariableInfos | undefined;
  colors: SemanticColors;
  onSelect: (value: VariableInfos | undefined) => void;
}> = ({ colors, variable, value, onSelect }) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <div
      className={'item-wrapper left' + (variable === value ? ' selected' : '')}
      key={variable.variable_id}
      onClick={() => onSelect(variable)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {variable.variable_name}
    </div>
  );
};

const Variables: FC<Props> = ({ variables, colors, value, onSelect }) => {
  return (
    <>
      {(variables !== undefined && variables.length == 0) || (
        <div className="variable-selector-section">
          <div className="listing hawaii-thin-scrollbar">
            {variables === undefined ? (
              <div className="loader-container">
                <Spin size="large" />
              </div>
            ) : (
              variables.map((variable) => (
                <Item
                  key={variable.variable_id}
                  colors={colors}
                  variable={variable}
                  value={value}
                  onSelect={onSelect}
                />
              ))
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Variables;
