import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BackendErrorCanRetry,
  BackendErrorDontRetry,
  BackendForbidden,
  CanceledRequest,
  NotFoundException,
} from '../../../core/backendFacade';
import { FactoryInterface } from '../../../core/factory';
import { BackendUnmanagedException } from '../../interface';
import { GlobalState } from '../global';

export const handleGlobalErrorThunk = createAsyncThunk<
  void,
  unknown,
  { state: GlobalState; extra: { factory: FactoryInterface } }
>('global/global-error', (error): void => {
  if (error instanceof BackendErrorDontRetry) {
    console.error('dontRetry');
  } else if (error instanceof NotFoundException) {
    console.error('notFound');
  } else if (error instanceof BackendForbidden) {
    console.error('forbidden');
  } else if (error instanceof BackendErrorCanRetry) {
    console.error('canRetry');
  } else if (error instanceof CanceledRequest) {
    console.info('canceled');
  } else {
    throw new BackendUnmanagedException();
  }
});
