import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AMDMActivateStudioState } from '../../activateStudioSlice';

export const getActivateStudioState = createAsyncThunk<
  AMDMActivateStudioState,
  {
    audienceRecordId: string;
    selectedCountry: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(
  `amdm/activation-studio/get`,
  async ({ audienceRecordId, selectedCountry }, { dispatch, extra }): Promise<AMDMActivateStudioState> => {
    const amdmService = extra.factory.getAmdmService();
    try {
      const response = await amdmService.getAudienceMatchingState(audienceRecordId, selectedCountry);
      return response;
    } catch (e) {
      if (e instanceof Error) {
        throw e.message;
      } else {
        throw undefined;
      }
    }
  },
);
