import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { SECMDMCountry } from '../../../../core/secmdm';

export const getCountriesToSecmdmThunk = createAsyncThunk<
  SECMDMCountry[],
  void,
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`mdm/countries`, async (arg, { extra }): Promise<SECMDMCountry[]> => {
  const secmdmService = extra.factory.getSecmdmService();
  try {
    return await secmdmService.listCountries();
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
