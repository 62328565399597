import './CountrySelector.less';
import { FC, useState } from 'react';
import { Popover } from '../../Popover';
import { FiChevronDown } from 'react-icons/fi';
import { CountryMetadata, countryMetadatas } from 'hawaii';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import Load from '../../Load';
import { useMemo } from 'react';
import _ from 'lodash';
import ReactCountryFlag from 'react-country-flag';

export interface Props {
  selectedCountry: CountryMetadata;
  selectCountryAction: (iso: string) => void;
}

const CountrySelector: FC<Props> = ({ selectedCountry, selectCountryAction }) => {
  const countries = useSelector((state: GlobalState) => state.secmdmState).countries.list;

  const countriesOptions = useMemo(
    () =>
      _.orderBy(
        countryMetadatas.filter(({ iso }) => countries.find((country) => country.iso === iso)),
        [(data) => data.name.toLocaleLowerCase()],
        ['asc'],
      ),
    [countries],
  );

  const countryList = () => (
    <div
      className="hawaii-control country-selector tiny-scroll-selector"
      style={{ width: '13em', maxHeight: '500px', overflowY: 'auto' }}
    >
      <ul>
        {countriesOptions.map((country) => {
          return (
            <li key={country.iso} className={country.iso === selectedCountry.iso ? 'selected' : ''}>
              <a
                onClick={() => {
                  selectCountryAction(country.iso.toLocaleLowerCase());
                  setOpen(false);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {country.name}
                <ReactCountryFlag
                  svg
                  style={{
                    fontSize: '2em',
                    lineHeight: '2em',
                    marginLeft: '1rem',
                  }}
                  countryCode={country.iso}
                />
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover placement="bottom" render={countryList} isOpen={open} openClose={setOpen}>
      <a className="form-select" onClick={(e) => e.preventDefault()}>
        {selectedCountry.name}
        <ReactCountryFlag
          svg
          style={{
            fontSize: '2em',
            lineHeight: '2em',
            marginLeft: '1rem',
          }}
          countryCode={selectedCountry.iso}
        />
        <FiChevronDown size="1.25rem" />
      </a>
    </Popover>
  );
};

export default CountrySelector;
