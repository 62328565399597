import { FC } from 'react';
import './HomeCta.less';
import { IconArrowRight } from '../../../Svg/HavasIcons';

import _ from 'lodash';

const HomeCta: FC = () => {
  return (
    <div className="cta">
      <div>
        <h3 className="cta__title">Need extra help?</h3>
        <p className="cta__description">Find the answers to all your questions</p>
      </div>
      <button
        onClick={() => {
          window.open(`https://jira.havas.com/pm/servicedesk/customer/portal/195`, '_blank');
        }}
        className="btn btn-primary"
      >
        <span>Jira Service Desk</span>
        <div className="btn-arrow">
          <div className="btn-arrow__animate">
            <IconArrowRight className="c-icon-stroke c-icon--20" />
            <IconArrowRight className="c-icon-stroke c-icon--20" />
          </div>
        </div>
      </button>
    </div>
  );
};

export default HomeCta;
