import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { GlobalState } from '../global';
import segmentSlice from '../segmentSlice';
import { updateSegmentPopulationThunk } from './updateSegmentPopulation';
import { SegmentWithUiMeta } from 'hawaii';
import { setSaveAudienceAction, setSaveAudienceModalOpen } from '../amdmSlice';
import { exploreAudienceFromAmdmThunk } from './amdm/exploreAudience';

export const importSegmentThunk = createAsyncThunk<
  void,
  {
    segment: SegmentWithUiMeta;
  },
  { state: GlobalState; extra: { factory: FactoryInterface } }
>('segment/import', ({ segment }, { dispatch }): void => {
  if (segment.amdmInfos) {
    void dispatch(exploreAudienceFromAmdmThunk({ audienceRecordId: segment.amdmInfos.audienceRecordId }));
  } else {
    dispatch(setSaveAudienceAction('save-old'));
    dispatch(setSaveAudienceModalOpen(true));
  }
  dispatch(segmentSlice.actions.insertSegment({ segment }));
  void dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
});
