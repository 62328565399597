import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { getAgenciesToSecmdmThunk } from '../secmdm/getAgencies';
import { getClientsToSecmdmThunk } from '../secmdm/getClients';
import { getCountriesToSecmdmThunk } from './getCountries';
import { msalInstance } from '../../../..';
import { setAppIsLoadingState } from '../../appIsLoadingSlice';
import { CheckMDMUserInfo } from '../../../../core/secmdm';

export const getMdmDataThunk = createAsyncThunk<
  CheckMDMUserInfo | null,
  number[],
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`mdm/check/user`, async (countriesId, { extra, dispatch }): Promise<CheckMDMUserInfo | null> => {
  dispatch(setAppIsLoadingState({ state: true }));
  const secmdmService = extra.factory.getSecmdmService();
  try {
    const secmdmUserCountry = await secmdmService.checkUser();
    if (secmdmUserCountry) {
      await dispatch(getCountriesToSecmdmThunk());
      await dispatch(getAgenciesToSecmdmThunk(countriesId));
      await dispatch(getClientsToSecmdmThunk());
    } else {
      await msalInstance.logoutRedirect();
      dispatch(setAppIsLoadingState({ state: false }));
      return null;
    }
    dispatch(setAppIsLoadingState({ state: false }));
    return secmdmUserCountry;
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
