import { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import * as actions from '../../store/actions';
import { addVariableDetailsThunk } from '../../store/thunk/addVariableDetails';
import { crossResultsAddResponses } from '../../store/crossResultsSlice';
import { Empty, Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IconClose, IconRemove } from '../../Svg/HavasIcons';

const VariableList: FC<{
  variables: actions.IVariablesHistory[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPopconfirmStatus: Dispatch<SetStateAction<boolean>>;
}> = ({ variables, setOpen, setPopconfirmStatus }) => {
  const [selectedVariables, setSectedVariables] = useState<actions.IVariablesHistory | undefined>(undefined);
  const [listOfVariables, setListOfVariables] = useState<string[]>([]);
  const cleanUpVariable = useSelector((state: GlobalState) => state.variablesHistory.cleanUpVariable);
  const listOfSelectedVariables = useSelector((state: GlobalState) => state.variablesHistory.selectedVariables);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cleanUpVariable) return;

    if (cleanUpVariable === 'all') {
      setListOfVariables([]);
    } else {
      const variableId = variables.find(
        (variable) => variable.variablesDetails.selectedVariable.variable_id === cleanUpVariable,
      );

      if (variableId && listOfVariables.length > 0) {
        const newListOfVariables = listOfVariables.filter((variable) => variable !== variableId.variablesDetails._id);
        setListOfVariables(newListOfVariables);
      } else {
        setListOfVariables([]);
      }
    }
  }, [cleanUpVariable]);

  useEffect(() => {
    if (!selectedVariables) return;
    const selectedVariable = selectedVariables.variablesDetails.selectedVariable;
    const responsePanel = selectedVariables.variablesDetails.responsePanel;

    dispatch(
      crossResultsAddResponses({
        countryIsos: selectedVariables.crossResults.countryIsos,
        variableId: selectedVariables.crossResults.variableId,
        responseIds: selectedVariables.crossResults.responseIds.map((value: string) => value),
      }),
    );

    dispatch(
      addVariableDetailsThunk({
        countryId: selectedVariables.variablesDetails.countryId,
        selectedVariable,
        responsePanel,
        dataScope: 'Original',
      }),
    );
  }, [selectedVariables]);

  const confirm = () => {
    dispatch(actions.deleteVariablesHistory());
  };

  return (
    <>
      <div className="hawaii-control">
        <div className="history-header">
          <h3 className="title">History</h3>
          <div className="btns">
            <Popconfirm
              title="Are you sure to delete history ?"
              onConfirm={confirm}
              onOpenChange={(isOpen) => setPopconfirmStatus(isOpen)}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
              <span className="btn">
                <IconRemove className="c-icon-stroke c-icon--danger c-icon--20" />
              </span>
            </Popconfirm>
            <span className="btn" onClick={() => setOpen(false)}>
              <IconClose className="c-icon-stroke c-icon--20 c-icon--white" />
            </span>
          </div>
        </div>

        <div className="list hawaii-thin-scrollbar">
          {variables.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          {variables &&
            variables.map((variable: actions.IVariablesHistory, index) => {
              return (
                <div
                  key={index}
                  className={
                    listOfSelectedVariables.includes(variable.variablesDetails.selectedVariable.variable_id)
                      ? 'variable-selected'
                      : 'variable'
                  }
                  onClick={() => {
                    dispatch(actions.selectVariablesHistory(variable.variablesDetails.selectedVariable.variable_id));
                    setSectedVariables(variable);
                  }}
                >
                  <span>{variable.variablesDetails.selectedVariable.variable_name}</span>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default VariableList;
