export const extractPath = (path: string) => {
  path.replace(/^\/|\/$/g, '');
  const segments = path.split('/');

  const clientIndex = segments.indexOf('client');

  if (clientIndex !== -1 && segments.length > clientIndex + 1) {
    return segments[clientIndex + 1];
  }

  return path;
};
