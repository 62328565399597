import './DrawerTitle.less';

import { FC } from 'react';
import { IconSearch, IconSwap } from '../../../Svg/HavasIcons';
import SearchBar from './SearchBar';

interface Props {
  searchMtd?: (value: string) => Promise<void>;
  title: string;
  subtitle: string;
}

const DrawerTitle: FC<Props> = ({ searchMtd, title, subtitle }) => {
  const searchBarMessage = 'Search for a variable, then press enter.';

  const searchBarMtd = async (value: string) => {
    await Promise.resolve(value);
  };

  const searchBar = () => {
    return searchMtd === undefined ? undefined : (
      <>
        <SearchBar msg={searchBarMessage} searchMtd={searchMtd || searchBarMtd} />
        <IconSearch className="c-icon-stroke c-icon--20" />
      </>
    );
  };

  return (
    <>
      <div className="icon-titles-wrapper">
        <div className="icon">
          <IconSwap className="c-icon-stroke c-icon--30" />
        </div>
        <div className="titleGroup">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <div className="search-bar-wrapper">{searchBar()}</div>
    </>
  );
};

export default DrawerTitle;
