import { Segment } from '../segment/segments';

export const calculateEstTime = (actionID: string, num_cluster: string, segments: Segment[]) => {
  const segment: Segment | undefined = segments.find((segment) => segment.publicId === actionID);

  if (!segment) {
    throw new Error('Segment not found');
  }
  let numQuestions = 0;
  const numRespondents = segment?.uiMeta?.stats ? segment.uiMeta.stats : 0;
  const cluster_num = Number(num_cluster) > 0 ? 1 : 0;

  interface PanelCriteria {
    id: string;
    variable: {
      variable_id: string;
      variable_name: string;
      variable_text: string;
      isparent: 0 | 1;
    };
    responses: {
      label: string;
      value: string;
      variableId: string;
      parentResponseId?: string;
    }[];
    rule: 'INCLUDE' | 'EXCLUDE';
  }

  for (const criteria of segment.criterias.criterias as PanelCriteria[]) {
    if (criteria.variable.isparent === 0 || criteria.variable.variable_id.startsWith('bix_')) {
      numQuestions++;
    } else {
      numQuestions = numQuestions + criteria.responses.length;
    }
  }

  let est_time = 0.0431 * numRespondents + 226.8312 * cluster_num + 49.7823 * numQuestions - 286.716;
  if (est_time < 0) {
    est_time = -0.2411 * numRespondents - 0.00012 * cluster_num + 0.00023 * numQuestions + 637.348;
  }

  const timeFormat = (sec: number) => {
    const m = Math.floor(sec / 60);
    const s = Math.floor(sec % 60);
    const h = Math.floor(m / 60);
    const remainingMinutes = m % 60;
    return `${h.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${s
      .toString()
      .padStart(2, '0')}`;
  };

  return {
    lower_bound: timeFormat(est_time * 0.8),
    upper_bound: timeFormat(est_time * 1.2),
  };
};
