import { Serie, SerieTag } from './series/interface';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from './backendFacade';
import { DataScope, ResponseInfos, VariableInfos } from 'hawaii';

export interface VariableDetailsServiceInterface {
  abort: () => void;
  retrieveCountrySeries: (
    countryCode: number,
    variable: VariableInfos,
    responsePanel: ResponseInfos[],
    datascope: DataScope,
    selectedResponses?: ResponseInfos[],
  ) => Promise<Serie>;
}

const VariableDetailsService = (backend: BackendFacadeInterface): VariableDetailsServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    /**
     * For a given variable, retrieve the country total count and the count per responses.
     *
     * @param countryCode
     * @param variable
     * @param arbitraryResponse
     * @param responsePanel
     */
    retrieveCountrySeries: async (
      countryCode: number,
      variable: VariableInfos,
      responsePanel: ResponseInfos[],
      datascope: DataScope,
      selectedResponses?: ResponseInfos[],
    ): Promise<Serie> => {
      const body = {
        countryCode,
        selectedVariable: variable,
        datascope,
        selectedResponses,
      };

      // We fetch fetch the repartion of counts among all the variable's responses for a given country
      const countryCounts = await backend.post<Serie>('/v1/variable_details', body);

      const isMediaCons = countryCounts.tags.findIndex((item) => item.responseId === 'mediaConsumption');
      const panel =
        isMediaCons !== -1
          ? [
              {
                label: '***Media Consumption',
                value: countryCounts.tags[isMediaCons].responseId,
                variableId: countryCounts.tags[isMediaCons].variable_id as string,
                parentResponseId: undefined,
              },
              ...responsePanel,
            ]
          : responsePanel;

      panel.forEach((response) => {
        countryCounts.tags.forEach((tag, i) => {
          if (response.value === tag.responseId) {
            countryCounts.tags[i] = { ...tag, responseText: response.label };
          }
        });
      });

      const countryTotal = countryCounts.globalTags.countryTotal as number;

      return {
        ...countryCounts,
        globalTags: {
          ...countryCounts.globalTags,
          countryRatio: 100 / countryTotal,
          variableId: variable.isparent ? variable.variable_id : countryCounts.globalTags.variableId,
          variableName: variable.variable_name,
          isParent: variable.isparent,
          countryCode,
          dataScope: datascope,
        },
      };
    },
  };
};

export interface VariableDetailsServiceFactoryInterface {
  get: () => VariableDetailsServiceInterface;
}

const VariableDetailsServiceFactory = (
  backendFactory: BackendFacadeFactoryInterface,
): VariableDetailsServiceFactoryInterface => ({
  get: () => VariableDetailsService(backendFactory.get()),
});

export default VariableDetailsServiceFactory;
