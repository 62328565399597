/* eslint-disable */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { isACriteriaStack } from '../../core/segment/criteria';
import {
  addCriteria,
  findCriteriaById,
  removeCriteria,
  replaceCriteria,
  segmentGenerateId,
} from '../../core/segment/helper';
import { GlobalState } from './global';
import { saveSegmentinProfilesThunk, copySegmentProfilesThunk } from './thunk/saveSegment';
import { error, info } from '../Common/Message';
import { retrieveStatThunk } from './thunk/retrieveStats';
import {
  CountryMetadata,
  DataScope,
  PanelCriteria,
  ResponseInfos,
  VariableInfos,
  SegmentWithUiMeta,
  PanelCriteriaStack,
} from 'hawaii';
import { saveAudienceInAmdmThunk } from './thunk/amdm/saveAudience';

const importerSegmentSlice = createSlice({
  name: 'segment',
  initialState: [] as SegmentWithUiMeta[],
  reducers: {
    setImporterSEgments: (state, action: PayloadAction<SegmentWithUiMeta[]>) => {
      return (state = action.payload);
    },
  },
});

export default importerSegmentSlice;
