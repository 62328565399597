import { FC, Fragment, ReactElement, useState, useEffect } from 'react';
import Color from 'color';
import { Serie, SerieTag } from '../../../../core/series/interface';
import { CaretRightFilled, CaretLeftFilled } from '@ant-design/icons';
import FormatedNumber from '../../../Common/FormatedNumber';

interface InestedTableData {
  key: string;
  name: string;
  values: number[][];
  variableName: string;
  color: string;
}

export interface Props {
  ordonnedSeries: Serie[];
  sectionGroupTag: string | undefined;
  sections: Serie[];
  nestedTableData: Record<string, InestedTableData[]>;
  nestedTableKey: string;
  formatValue: (val: number, globalTags: SerieTag, tags: SerieTag) => ReactElement;
  formatLineLabel: (tags: SerieTag, globalTags: SerieTag, offTitle: boolean) => ReactElement;
  sectionsSelection: { name: string; checked: boolean }[];
}

const MainTable: FC<Props> = ({
  ordonnedSeries,
  sectionGroupTag,
  sections,
  nestedTableData,
  nestedTableKey,
  sectionsSelection,
}): ReactElement => {
  const [nestedTable, setNestedTable] = useState<Record<string, InestedTableData[]>>();
  const [key1, setKey1] = useState<string>('');
  const [key2, setKey2] = useState<string>('');
  const [indicatorNames, setIndicatorNames] = useState<string[]>(['']);
  const [indicatorSort, setIndicatorSort] = useState<Record<string, string>>({});
  const [audienceSort, setAudienceSort] = useState<string>('');
  const [responsesSort, setResponsesSort] = useState<string>('');
  const [responsesNames, setResponsesNames] = useState<string[]>(['']);

  useEffect(() => {
    if (!sectionGroupTag) return;
    const indicatorsArr = ordonnedSeries
      .map((serie) => serie.globalTags[sectionGroupTag])
      .filter((value, index, self) => index === self.indexOf(value))
      .map((value) => value);

    const responsesNames = sections
      .filter((section) => section.globalTags.variableName === nestedTableKey)
      .map((line) => line.globalTags.responseText);

    setResponsesNames(responsesNames as string[]);
    setIndicatorNames(indicatorsArr as string[]);
  }, [sections]);

  useEffect(() => {
    const indicatorsSelected: string[] = [];
    sectionsSelection.map((indicator) => (indicator.checked ? indicatorsSelected.push(indicator.name) : ''));
    setIndicatorSort(
      Object.fromEntries(Array.from({ length: indicatorsSelected.length }, (_, i) => [indicatorsSelected[i], ''])),
    );
    setIndicatorNames(indicatorsSelected.map((name) => name));
  }, [sectionsSelection]);

  useEffect(() => {
    setNestedTable(nestedTableData);
  }, [nestedTableData]);

  const handleDragStart = (e: React.DragEvent, key: string) => {
    setKey1(key);
  };

  const handleDragOver = (e: React.DragEvent, key: string) => {
    e.preventDefault();
    setKey2(key);
  };

  const handleOnDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (!nestedTable) return;
    const swapedData = swapColumns(nestedTable[nestedTableKey], key1, key2);
    if (!swapedData) return;
    const newData = { ...nestedTable };
    newData[nestedTableKey] = swapedData;
    setNestedTable(newData);
  };

  const handleIndicatorSort = (status: string, key: string, index: number) => {
    if (!nestedTable) return;
    const newNestedTable = { ...nestedTable };
    const newStatus: Record<string, string> = {};
    for (const k in indicatorSort) {
      if (k !== key) {
        newStatus[k] = '';
      }
    }
    newStatus[key] = status === '' ? 'ASC' : status === 'ASC' ? 'DESC' : '';
    setIndicatorSort(newStatus);

    if (newStatus[key] === '') {
      return setNestedTable(nestedTable);
    } else {
      const sorted = handleSortByIndicator(nestedTable[nestedTableKey], newStatus, key, index);
      if (sorted) {
        newNestedTable[nestedTableKey] = sorted;
        setNestedTable(newNestedTable);
      }
    }
  };

  const handleSort = (values: InestedTableData[], key: string, audienceName?: string) => {
    const sortedNestedTable = { ...nestedTable };

    if (audienceName) {
      if (indicatorNames.length === 1) {
        const sortedValues = audienceValuesSort(values, key, responsesNames, audienceName);
        if (!sortedValues) return;
        sortedNestedTable[nestedTableKey] = sortedValues.sortedAudiences;
        setResponsesNames(sortedValues.newArr);
      } else {
        const sortedValues = audienceValuesSort(values, key, indicatorNames, audienceName);
        if (!sortedValues) return;
        sortedNestedTable[nestedTableKey] = sortedValues.sortedAudiences;
        setIndicatorNames(sortedValues.newArr);
      }
      setAudienceSort(key === '' ? 'ASC' : key === 'ASC' ? 'DESC' : '');
    } else {
      const sortedValues = audienceValuesSort(values, key, responsesNames);
      if (!sortedValues) return;
      sortedNestedTable[nestedTableKey] = sortedValues.sortedAudiences;
      setResponsesNames(sortedValues.newArr);
      setResponsesSort(key === '' ? 'ASC' : key === 'ASC' ? 'DESC' : '');
    }
    setNestedTable(sortedNestedTable);
  };

  if (!nestedTable) return <></>;

  return (
    <table className="numbers-table fusion">
      <tbody>
        <tr>
          <th className="label">
            <div className="sort-container">
              Responses
              <div className="icons" onClick={() => handleSort(nestedTable[nestedTableKey], responsesSort)}>
                <CaretLeftFilled
                  style={{
                    fontSize: '12px',
                    color: `${responsesSort === 'DESC' ? '#25cad0' : 'gray'}`,
                    width: '35%',
                  }}
                />
                <CaretRightFilled
                  style={{
                    fontSize: '12px',
                    color: `${responsesSort === 'ASC' ? '#25cad0' : 'gray'}`,
                    width: '35%',
                  }}
                />
              </div>
            </div>
          </th>
          <th className="label">Indicator</th>

          {nestedTable[nestedTableKey].map((audience, i) => {
            const tag = audience.name;
            const color = audience.color;
            const key = audience.key;

            return (
              <Fragment key={i}>
                <th
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, key)}
                  onDragOver={(e) => handleDragOver(e, key)}
                  onDrop={(e) => handleOnDrop(e)}
                  style={{
                    backgroundColor: lightColor(color),
                    color: mediumColor(color),
                  }}
                  className="tags-container"
                >
                  <div className="sort-container">
                    <div className="tag">{tag}</div>
                    <div className="icons" onClick={() => handleSort(nestedTable[nestedTableKey], audienceSort, tag)}>
                      <CaretLeftFilled
                        style={{
                          fontSize: '12px',
                          color: `${audienceSort === 'DESC' ? '#25cad0' : 'gray'}`,
                          width: '35%',
                        }}
                      />
                      <CaretRightFilled
                        style={{
                          fontSize: '12px',
                          color: `${audienceSort === 'ASC' ? '#25cad0' : 'gray'}`,
                          width: '35%',
                        }}
                      />
                    </div>
                  </div>
                </th>
                <th className={nestedTable[nestedTableKey].length === i + 1 ? 'spacer last' : 'spacer'}></th>
              </Fragment>
            );
          })}
        </tr>

        {responsesNames &&
          sections
            .filter((section) => section.globalTags.variableName === nestedTableKey)
            .map((line, i) => {
              const tag = line.globalTags.mediaCons === 'true' ? '***Media Consumpcion' : responsesNames[i];
              return (
                <tr
                  key={i}
                  className={
                    (!(i % 2) ? 'odd' : '') + (i == 0 ? ' first' : '') + (i == sections.length - 1 ? ' last' : '')
                  }
                >
                  <td className="line-name">
                    <strong>{tag}</strong>
                  </td>
                  <td className="line-name-groups">
                    {indicatorNames &&
                      indicatorNames.map((value, i) => {
                        return (
                          <Fragment key={i}>
                            <div className="indicator">
                              <div>
                                <strong>{value}</strong>
                              </div>
                              <div
                                className="icons"
                                onClick={() => handleIndicatorSort(indicatorSort[value], value, i)}
                              >
                                <CaretLeftFilled
                                  style={{
                                    fontSize: '12px',
                                    color: `${indicatorSort[value] === 'DESC' ? '#25cad0' : 'gray'}`,
                                    width: '35%',
                                  }}
                                />
                                <CaretRightFilled
                                  style={{
                                    fontSize: '12px',
                                    color: `${indicatorSort[value] === 'ASC' ? '#25cad0' : 'gray'}`,
                                    width: '35%',
                                  }}
                                />
                              </div>
                            </div>
                          </Fragment>
                        );
                      })}
                  </td>

                  {typeof line.globalTags.variableName === 'string' &&
                    responsesNames &&
                    nestedTable[line.globalTags.variableName].map((val, j) => {
                      return (
                        <Fragment key={j}>
                          <td
                            className="numbers-line"
                            style={{
                              backgroundColor: !(i % 2) ? blendColor(val.color) : lightColor(val.color),
                            }}
                          >
                            {val.values[i] &&
                              val.values[i].map((value, k) => {
                                return (
                                  <div key={k}>
                                    <span style={{ fontStyle: 'normal' }}>
                                      <FormatedNumber value={value} />
                                    </span>
                                  </div>
                                );
                              })}
                          </td>
                          <td className="spacer">&nbsp;&nbsp;&nbsp;&nbsp;</td>
                        </Fragment>
                      );
                    })}
                </tr>
              );
            })}
      </tbody>
    </table>
  );
};

export default MainTable;

const blendColor = (hex: string): string => {
  const lighten = Color(lightColor(hex));
  return lighten.mix(Color('#25cacf'), 0.1).hex();
};

const mediumColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(50).hex();
};

const lightColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(95).hex();
};

const swapColumns = (arr: InestedTableData[], key1: string, key2: string) => {
  const newArr = [...arr];
  const index1 = newArr.findIndex((item) => item.key === key1);
  const index2 = newArr.findIndex((item) => item.key === key2);

  if (index1 === -1 || index2 === -1) {
    return;
  }

  [newArr[index1], newArr[index2]] = [newArr[index2], newArr[index1]];

  return newArr;
};

const handleSortByIndicator = (
  nestedTable: InestedTableData[],
  newStatus: Record<string, string>,
  key: string,
  index: number,
) => {
  const newData = [...nestedTable];
  newData.sort((a, b) => {
    const aValue = a.values.reduce((sum, arr) => sum + arr[index], 0);
    const bValue = b.values.reduce((sum, arr) => sum + arr[index], 0);

    if (aValue === bValue) {
      return 0;
    }

    if (newStatus[key] === 'ASC') {
      return aValue - bValue;
    } else if (newStatus[key] === 'DESC') {
      return bValue - aValue;
    }

    return 0;
  });
  return newData;
};

const audienceValuesSort = (audiences: InestedTableData[], key: string, forUpdate: string[], audienceName?: string) => {
  const newArr = [...forUpdate];

  const sortedAudiences = audiences.map((audience) => ({
    ...audience,
    values: audience.values.map((arr) => [...arr]),
  }));

  let sortedIndexes: number[] = [];

  if (audienceName) {
    const targetAudience = sortedAudiences.find((aud) => aud.name === audienceName);
    if (!targetAudience) {
      console.error('Audience not found.');
      return;
    }

    if (targetAudience.values[0].length > 1) {
      const sums = targetAudience.values[0].map((_, index) =>
        targetAudience.values.reduce((acc, aud) => acc + aud[index], 0),
      );

      sortedIndexes = sums
        .map((sum, index) => ({ sum, index }))
        .sort((a, b) => (key === 'ASC' ? b.sum - a.sum : a.sum - b.sum))
        .map((item) => item.index);

      sortedAudiences.forEach((audience) => {
        audience.values.forEach((subArr) => {
          const originalSubArr = [...subArr];
          sortedIndexes.forEach((oldIdx, newIdx) => {
            subArr[newIdx] = originalSubArr[oldIdx];
          });
        });
      });
    } else {
      const sums = targetAudience.values.map((subArr) => subArr[0]);

      sortedIndexes = sums
        .map((sum, idx) => ({ sum, idx }))
        .sort((a, b) => (key === 'ASC' ? b.sum - a.sum : a.sum - b.sum))
        .map((item) => item.idx);

      sortedAudiences.forEach((audience) => {
        const orginalVal = [...audience.values];
        sortedIndexes.forEach((oldIdx, newIdx) => {
          audience.values[newIdx] = orginalVal[oldIdx];
        });
      });
    }
  } else {
    const sums = audiences[0].values.map((_, index) =>
      sortedAudiences.reduce((acc, aud) => acc + aud.values[index].reduce((sum, val) => sum + val, 0), 0),
    );

    sortedIndexes = sums
      .map((sum, index) => ({ sum, index }))
      .sort((a, b) => (key === 'ASC' ? b.sum - a.sum : a.sum - b.sum))
      .map((item) => item.index);

    sortedAudiences.forEach((audience) => {
      const orginalVal = [...audience.values];
      sortedIndexes.forEach((oldIndex, newIndex) => {
        audience.values[newIndex] = orginalVal[oldIndex];
      });
    });
  }
  sortedIndexes.forEach((oldIndex, newIndex) => {
    newArr[newIndex] = forUpdate[oldIndex];
  });

  return { sortedAudiences, newArr };
};
