import { FC } from 'react';
import { SegmentWithUiMeta } from 'hawaii';
// import { MdClose } from 'react-icons/md';
import FormatedNumber from '../FormatedNumber';
import Load from '../Load';
import { useCountDown } from '../hooks/useCountDown';
import { IconRemove } from '../../Svg/HavasIcons';

interface Props {
  segment: SegmentWithUiMeta;
  indexingState: string | undefined;
  removeAction?: () => void;
  selectionAction: (state: boolean) => void;
  selected: boolean;
  timeToCountDown:
    | {
        [key: string]: {
          countDownTime: string;
          module: string;
          startTime: number;
          status: string;
        };
      }
    | undefined;
}

const SegmentCardHeader: FC<Props> = ({
  segment,
  indexingState,
  removeAction,
  selectionAction,
  selected,
  timeToCountDown,
}) => {
  const estimatedTime = useCountDown(
    timeToCountDown?.[segment.publicId]?.startTime,
    timeToCountDown?.[segment.publicId]?.countDownTime,
    timeToCountDown?.[segment.publicId]?.status,
  );

  return (
    <div
      className={'card-header ' + (selected ? 'selected' : 'selectable')}
      style={{ borderColor: segment.color }}
      onClick={() => {
        segment.uiMeta.disabled || selectionAction(!selected);
      }}
    >
      <div className="audience-row-name">
        <span className="name">
          {segment.name}
          {segment.uiMeta.modified && ' *'}
        </span>

        <div className="active-modules">
          <div className="modules">
            <span style={{ backgroundColor: indexingState === 'indexing' ? segment.color : '#008596' }}>A</span>
            <span
              style={{
                backgroundColor: timeToCountDown?.[segment.publicId]?.module === 'clusters' ? segment.color : 'gray',
              }}
            >
              C
            </span>
          </div>
        </div>
        {segment.uiMeta.disabled || (
          <div className="card-header-options">
            {!removeAction || (
              <div
                className="picto"
                onClick={(e) => {
                  removeAction();
                  e.stopPropagation();
                }}
                title="Remove"
              >
                <IconRemove className="c-icon-stroke c-icon--18 c-icon--danger" />
              </div>
            )}
          </div>
        )}
      </div>

      <div className="stats">
        <div className={segment.uiMeta.irrelevant ? 'hawaii-grey' : ''}>
          Resp:
          {segment.uiMeta.isComputing ? (
            <Load />
          ) : segment.uiMeta.stats === null ? (
            '--'
          ) : (
            <FormatedNumber value={segment.uiMeta.stats} />
          )}
          &nbsp;/ Market:
          {segment.uiMeta.isComputing ? (
            <Load />
          ) : segment.uiMeta.marketSize === null ? (
            '--'
          ) : (
            <FormatedNumber value={segment.uiMeta.marketSize} />
          )}
        </div>
      </div>
      <div className="time">
        {indexingState === 'indexing' && <span className="indexing">(matching)</span>}
        {indexingState === 'indexed' && <span className="indexed">(matched)</span>}
        {estimatedTime && <span className="clusters-timer">{estimatedTime}</span>}
      </div>
    </div>
  );
};

export default SegmentCardHeader;
