import { FC } from 'react';
import './LanguageSelector.less';

interface Props {
  countryIso: string;
  value: number;
  changeAction: (value: number) => void;
}

const LanguageSelector: FC<Props> = ({ countryIso, value, changeAction }) => {
  const countryChoice = ['fr', 'it', 'es', 'pt', 'de', 'br', 'co', 'ar'];

  return (
    <div className="language-selector">
      {countryChoice.indexOf(countryIso) === -1 || (
        <div title="Switch to display variables in english or locale language. (May not apply to all countries)">
          {value === 0 ? <span>en</span> : <a onClick={() => changeAction(0)}>en</a>}
          {value === 1 ? <span>{countryIso}</span> : <a onClick={() => changeAction(1)}>{countryIso}</a>}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
