import { Serie } from '../series/interface';
import { SelectedVariable } from '../../react/Common/Context/interfaces/audiences';
import { getSegmentVariablesNumber } from './helper';
import { BackendFacadeFactoryInterface, BackendFacadeInterface } from '../backendFacade';
import { tagValues } from '../helpers/tag';
import { matchSerieTagOrder, serieConcat, serieTagEnrich } from '../series/serie';
import { countryMetadatas, DataScope, SegmentWithUiMeta } from 'hawaii';
import { Segment } from './segments';
import { AMDMInfos } from '../amdm';

export interface SegmentServiceInterface {
  abort: () => void;
  retrieveStats: (
    segment: SegmentWithUiMeta,
    countryCodes: number[],
  ) => Promise<{ count: number; totalRespondents: number; marketSize: number }>;
  retrieveCrossResults: (
    countryCode: number,
    segment: SegmentWithUiMeta,
    variable: SelectedVariable,
    dataScope: DataScope,
    variableDetails: Serie,
  ) => Promise<Serie[]>;
  retrieveAudienceSegment: (audienceRecordId: AMDMInfos['audienceRecordId']) => Promise<SegmentWithUiMeta>;
}

const SegmentService = (backend: BackendFacadeInterface): SegmentServiceInterface => {
  return {
    /**
     * abort all AJAX request
     */
    abort: () => {
      backend.abort();
    },

    /**
     * Retrieve respondent number based on an audience and a country
     *
     *
     * @param segment
     * @param countryCodes
     * @returns
     */
    retrieveStats: async (
      segment: SegmentWithUiMeta,
      countryCodes: number[],
    ): Promise<{ count: number; totalRespondents: number; marketSize: number }> => {
      if (getSegmentVariablesNumber(segment) <= 0) {
        return { count: 0, totalRespondents: 0, marketSize: 0 };
      }

      const countriesPopulation = countryMetadatas
        .filter((c) => countryCodes.indexOf(c.id) !== -1)
        .reduce((acc, curr) => acc + curr.population, 0);

      const body = {
        criteria: segment.criterias,
        countries: countryCodes,
        datascope: segment.dataScope,
      };
      const counts = await backend.post<
        {
          countryCode: number;
          audienceCount: number;
          audienceWeiCount: number;
          totalRespondentCount: number;
          totalRespondentWeiCount: number;
        }[]
      >('/v1/criteria/stats', body);

      const audienceCount = counts.reduce((acc, curr) => (acc += curr.audienceCount), 0);
      const audienceWeiCount = counts.reduce((acc, curr) => (acc += curr.audienceWeiCount), 0);
      const totalRespondentCount = counts.reduce((acc, curr) => (acc += curr.totalRespondentCount), 0);
      const totalRespondentWeiCount = counts.reduce((acc, curr) => (acc += curr.totalRespondentWeiCount), 0);
      const marketSize = Math.round((audienceWeiCount * countriesPopulation) / totalRespondentWeiCount);
      return {
        count: audienceCount,
        totalRespondents: totalRespondentCount,
        marketSize,
      };
    },

    /**
     * Retrieve cross population between segment and single variable
     * Fill items left empty by backend (it doesn't return any 0)
     * Enrich serie with responseText
     *
     * @return  {[type]}
     */
    retrieveCrossResults: async (
      countryCode: number,
      segment: SegmentWithUiMeta,
      variable: SelectedVariable,
      dataScope: DataScope,
      variableDetails: Serie,
    ): Promise<Serie[]> => {
      const body = {
        countryCode,
        criteria: segment.criterias,
        variable,
        datascope: dataScope,
      };

      // We fetch fetch the repartion of counts among all the variable's responses for a given audience
      const [unweightedCountSerie, weightedCountSerie] = await backend.post<Serie[]>('/v1/criteria/cross-result', body);

      const vdIds = tagValues('responseId')(() => variableDetails.tags);

      // Add missing responses (cause 0 respondents) and fill them with 0.
      const fillMissingResponses = (serie: Serie): Serie => {
        const serieIds = tagValues('responseId')(() => serie.tags);
        const missingIds = vdIds.filter((id) => serieIds.indexOf(id) === -1);
        const zeroSerie: Serie = {
          globalTags: {},
          tags: missingIds.map((id) => ({ responseId: id })),
          values: missingIds.map(() => 0),
        };

        return serieConcat([serie, zeroSerie]);
      };

      // add responseText in series
      const enrich = (serie: Serie) => {
        return serieTagEnrich(variableDetails, ['responseId'], ['responseText'])(serie);
      };

      // sort according to VariableDetails
      // @TODO : it should be moved to the UI control
      const sort = matchSerieTagOrder(variableDetails.tags, 'responseId');

      return [unweightedCountSerie, weightedCountSerie].map(fillMissingResponses).map(enrich).map(sort);
    },

    retrieveAudienceSegment: async (audienceRecordId): Promise<SegmentWithUiMeta> => {
      const segment = await backend.post<SegmentWithUiMeta>(`/v1/shared-segment/audience-segment`, {
        audienceRecordId,
      });
      return segment;
    },
  };
};

export interface SegmentServiceFactoryInterface {
  get: (languageCode: number) => SegmentServiceInterface;
}

const SegmentServiceFactory = (backendFactory: BackendFacadeFactoryInterface) => ({
  get: () => SegmentService(backendFactory.get()),
});

export default SegmentServiceFactory;
