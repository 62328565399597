import React, { FC } from 'react';
import { Input } from 'antd';

interface Props {
  setInputVal: React.Dispatch<React.SetStateAction<string>>;
  defaultValue: string;
}

const InputComponent: FC<Props> = ({ defaultValue, setInputVal }) => {
  return (
    <Input
      value={defaultValue}
      placeholder="Insert a prompt to create a new audience"
      onChange={(e) => setInputVal(e.target.value)}
    />
  );
};

export default InputComponent;
