import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { Serie, MediaInter } from '../../core/series/interface';
import thunkMiddleware from 'redux-thunk';
import { HawaiiState } from '../../core/hawaiiState';
import hawaiiStateSlice from './hawaiiStateSlice';
import segmentSlice, { SegmentWithUiMetaWithOldId } from './segmentSlice';
import selectedLanguageSlice from './selectedLanguageSlice';
import seriesSlice from './seriesSlice';
import mediaConsumption from './mediaConsumption';
import variablesDetailsSlice from './variablesDetailsSlice';
import crossResultSlice, { CrossResults } from './crossResultsSlice';
import Factory from '../../core/factory';
import activationProcessSlice, { ActivationProcess } from './activationProcessSlice';
import segmentEditionSlice, { SegmentEditionState } from './segmentEditionSlice';
import amdmSlice from './amdmSlice';
import appIsLoadingSlice from './appIsLoadingSlice';
import variablesHistory, { StateVariablesHistory } from './modules/variablesHistory/reducers';
import { SegmentWithUiMeta } from 'hawaii';
import { AmdmState } from './amdmSlice';
import activationProcess, { StateMatchingProcess } from './modules/activationProcess/reducers';
import settingsUI from './modules/settingsUI/reducers';
import databricks from './modules/databricks/reducers';
import { CountryMetadata } from 'hawaii';
import { SegmentJobState } from '../modules/common/interface/interface';
import secmdmSlice, { SecmdmState } from './secmdmSlice';
import activateSudioSlice, { ActivateStudioState } from './activateStudioSlice';
import importerSegmentSlice from './importerSegmentSlice';
export interface GlobalState {
  hawaiiState: HawaiiState;
  crossResults: CrossResults;
  segments: SegmentWithUiMetaWithOldId[];
  importerSegments: SegmentWithUiMetaWithOldId[];
  variablesDetails: Serie[];
  selectedLanguage: number;
  series: Serie[];
  activationProcess: ActivationProcess[];
  segmentEdition: SegmentEditionState;
  amdmState: AmdmState;
  secmdmState: SecmdmState;
  activateStudioState: ActivateStudioState;
  appIsLoadingState: boolean;
  variablesHistory: StateVariablesHistory;
  matchingData: StateMatchingProcess;
  settingsUI: {
    language: {
      value: string;
      meta: CountryMetadata;
    };
  };
  databricks: {
    [module: string]: {
      [actionID: string]: SegmentJobState;
    };
  };
  mediaConsumption: Record<string, Record<string, MediaInter[]>>;
}

const reducer = combineReducers({
  hawaiiState: hawaiiStateSlice.reducer,
  crossResults: crossResultSlice.reducer,
  segments: segmentSlice.reducer,
  importerSegments: importerSegmentSlice.reducer,
  variablesDetails: variablesDetailsSlice.reducer,
  selectedLanguage: selectedLanguageSlice.reducer,
  series: seriesSlice.reducer,
  activationProcess: activationProcessSlice.reducer,
  segmentEdition: segmentEditionSlice.reducer,
  amdmState: amdmSlice.reducer,
  secmdmState: secmdmSlice.reducer,
  activateStudioState: activateSudioSlice.reducer,
  appIsLoadingState: appIsLoadingSlice.reducer,
  variablesHistory: variablesHistory,
  matchingData: activationProcess,
  settingsUI: settingsUI,
  databricks: databricks,
  mediaConsumption: mediaConsumption.reducer,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const factory = Factory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware.withExtraArgument({ factory: factory }))),
);

export default store;
