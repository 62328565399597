export { type CountryMetadata, countryMetadatas } from "./countryDefinition";
export {
  type DataScope,
  type PanelCriteria,
  type PanelCriteriaStack,
  type ResponseInfos,
  type VariableInfos,
  type VariableUse,
  type SegmentCommonProperties,
  type SegmentWithUiMeta,
  API_VERSION_SEGMENT,
  SegmentOrigin,
} from "./SegmentCommonProperties";
export {
  type ActivationResultsAudience as ActivationResultsAudience,
  type ActivationMatchingResults,
  type ActivationPossibleStatus,
  type ActivationCustomSettings,
} from "./activation";
export { isPanelCriteriaStack } from "./segment-helpers";
