import React, { FC, useState } from 'react';
import './Login.less';
import { AppLogo } from '../../Svg/HavasIcons';
import bgHavas from '../../../assets/images/bg.login.jpg';
// import havasMediaLogo from '../../../assets/images/havasmedia.white.2.svg';
import BrandLogo from '../../../assets/images/brand.svg';
import havasLogo from '../../../assets/images/havas.white.svg';
import havasLogin from '../../../assets/images/havas_login_button.png';
import { msalInstance } from '../../..';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';
import Footer from '../../Common/Footer/Footer';

const Login: FC = () => {
  const [isAdminAccess, setIsAdminAccess] = useState(false);

  return (
    <section className="l-login">
      <div className="l-login-wrapper">
        <div className="l-login-left">
          <div className="l-login__bg">
            <img className="object-cover" src={bgHavas} alt="havas background" />
          </div>
          <div className="l-login__brand">
            <img src={havasLogo} alt="Havas logo" />
          </div>
        </div>
        <div className="l-login-right">
          <div className="l-login-right__admin">
            {/* {!isAdminAccess && (
              <button className="btn btn-link" onClick={() => setIsAdminAccess(true)}>
                Admin Access
              </button>
            )} */}
            {isAdminAccess && (
              <button className="btn btn-link" onClick={() => setIsAdminAccess(false)}>
                Back to Havas Login
              </button>
            )}
          </div>
          <div className="l-login__form">
            <div className="l-login__box">
              <div className="l-login__logo">
                <img src={BrandLogo} alt="Havas Converged logo" />
                <h2 className="l-login__title">
                  <AppLogo />
                </h2>
              </div>

              {/* Havas login */}
              {!isAdminAccess && (
                <div className="c-login-access-havas">
                  <button
                    onClick={async () => {
                      await msalInstance.loginRedirect();
                    }}
                  >
                    <img alt="havas login button" src={havasLogin} />
                  </button>
                  <span>Build and analyze audiences through panel data.</span>
                </div>
              )}

              {/* Admin Access */}
              {isAdminAccess && (
                <div className="c-login-access-admin">
                  <div className="l-login__subtitle">Admin</div>
                  <form>
                    <Input placeholder="Basic usage" />
                    <Input.Password
                      placeholder="input password"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                    <div className="c-login-access-admin__btn">
                      <button type="submit" className="btn btn--lg btn-outline-light">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>

            <div className="l-login__warning">
              <span>If you're having a problem logging in, please contact us by clicking</span>{' '}
              <a href="https://jira.havas.com/pm/servicedesk/customer/portal/195" target="_blank">
                here.
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Login;
