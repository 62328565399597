import { MediaInter } from '../../core/series/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const mediaConsumption = createSlice({
  name: 'mediaConsumption',
  initialState: {} as Record<string, Record<string, MediaInter[]>>,
  reducers: {
    // update if already existing
    setMedia(draft: Record<string, Record<string, MediaInter[]>>, action: PayloadAction<Record<string, MediaInter[]>>) {
      const updateState = JSON.parse(JSON.stringify(draft)) as Record<string, Record<string, MediaInter[]>>;
      const cloneAction = JSON.parse(JSON.stringify(action.payload)) as Record<string, MediaInter[]>;

      for (const key in cloneAction) {
        if (!updateState[key]) {
          updateState[key] = {};
        }
        if (cloneAction[key].length === 0) {
          return (updateState[key] = {});
        }

        cloneAction[key].forEach((obj) => {
          const country = obj.country;
          if (!updateState[key][country]) {
            updateState[key][country] = [obj];
          } else {
            const index = updateState[key][country].findIndex((o) => o.variableId === obj.variableId);
            if (index === -1) {
              updateState[key][country].push(obj);
            } else {
              updateState[key][country][index] = obj;
            }
          }
        });
      }

      return { ...draft, ...updateState };
    },
  },
});

export default mediaConsumption;
