import { CategoryTree, SubCategory } from '../../../core/interfaces/referenceData';
import { isArray } from 'lodash';

export const extractCategoryTreeAtPath = (
  tree: CategoryTree | SubCategory,
  path: string[],
  contained = false,
): SubCategory => {
  if (path.length === 0) {
    return tree;
  }
  let subtree: CategoryTree | SubCategory | string[] = tree;
  if (contained) {
    if (path.length === 0) {
      return tree;
    }

    path.slice(0, -1).forEach((id) => {
      subtree = !subtree ? subtree : isArray(subtree) ? [] : subtree[id];
    });
  } else {
    path.forEach((id) => {
      subtree = !subtree ? subtree : isArray(subtree) ? [] : subtree[id];
    });
  }
  return subtree;
};

export const splitStringArrayInPairs = (list: string[]): string[][] => {
  let result = [] as string[][];
  for (let index = 0; index < list.length; index++) {
    result = index % 2 === 0 ? [...result, list.slice(index, index + 2)] : result;
  }
  return result;
};

export const splitStringArrayToEveryPairs = (list: string[]): string[][] => {
  let result = [] as string[][];
  for (let index = 0; index < list.length; index++) {
    result = index % 2 === 0 ? [...result, list.slice(0, index + 2)] : result;
  }
  return result;
};
