import { FC, useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { useClustersData } from '../hooks/useClustersData';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Color from 'color';
import { random } from 'lodash';
import { useParams } from 'react-router';

const { Panel } = Collapse;
let cursorColor = random(360);

const ResponseRate: FC = () => {
  const { segmentID } = useParams();
  const { clusterRates } = useClustersData(segmentID as string, 'clusters');
  const responseRates = clusterRates();
  const defaultActiveKeys = responseRates ? responseRates.map((_, i) => i.toString()) : '0';
  const [colors, setColors] = useState<string[][]>();

  useEffect(() => {
    if (!responseRates) return;
    const colors = responseRates.map((resp, index) =>
      resp.responses.flatMap((_, index) => Color.hsl((cursorColor += 100 * index), 70, 50).hex()),
    );
    const Hcolors = colors.map((color) =>
      color.flatMap((color) => {
        return {
          radialGradient: {
            cx: 0.5,
            cy: 0.3,
            r: 0.7,
          },
          stops: [
            [0, color],
            [1, Highcharts.color(color).brighten(-0.3).get('rgb')],
          ],
        };
      }),
    ) as any[];
    setColors(Hcolors);
  }, [responseRates]);

  return (
    <>
      {colors && responseRates.length > 0 && (
        <div className="splitBars-container">
          <Collapse defaultActiveKey={defaultActiveKeys}>
            {responseRates.map((item, index) => {
              return (
                <Panel header={item.question} key={index}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'pie',
                      },
                      title: {
                        text: item.question,
                      },
                      tooltip: {
                        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
                      },
                      accessibility: {
                        point: {
                          valueSuffix: '%',
                        },
                      },
                      plotOptions: {
                        pie: {
                          // borderColor: '#ffffff',
                          borderWidth: 0,
                          depth: 50,
                          allowPointSelect: true,
                          cursor: 'pointer',
                          dataLabels: {
                            enabled: true,
                            format: '<b>{point.name}</b>:  {point.percentage:.0f} %',
                            connectorColor: 'silver',
                            style: {
                              fontSize: '12px',
                              color: '#333',
                            },
                          },
                          showInLegend: true,
                          events: {
                            legendItemClick: function () {
                              return (this as unknown as Highcharts.Point).series.update({
                                type: 'pie',
                              });
                            },
                          },
                          colors: ['#00DCB9', '#785AFA', '#328CF5', '#F055FA', '#FFDC32', '#9BF5FC'],
                        },
                      },
                      legend: {
                        enabled: true,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                      },
                      series: [
                        {
                          name: 'Percentage',
                          data: item.responses,
                          showInLegend: true,
                          // colors: colors[index],
                        },
                      ],
                    }}
                  />
                </Panel>
              );
            })}
          </Collapse>
        </div>
      )}
    </>
  );
};

export default ResponseRate;
