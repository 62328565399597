import './ResponseSelector.less';

import { FC, useEffect, useState } from 'react';
import { Drawer } from 'antd';
import Color from 'color';
import DrawerTitle from './DrawerHeader/DrawerTitle';
import { VariablesSelectionServiceInterface } from '../../../core/variablesSelection';
import Responses from '../../Common/VariableSelector/Responses';
import { colorTosemanticColors } from '../../../core/helpers/colors';
import { DataScope, ResponseInfos, VariableInfos } from 'hawaii';
import { IconArrowLeft } from '../../Svg/HavasIcons';

interface Props {
  color: string;
  selectedVariable: VariableInfos;
  selectedResponses: ResponseInfos[];
  onClose: (variableService: VariablesSelectionServiceInterface) => void;
  visible: boolean;
  validateUpdateChoice: (selectedResponses: ResponseInfos[]) => void;
  variableService: VariablesSelectionServiceInterface;
  countries: string[];
  languageCode: number;
  dataScope: DataScope;
}

const ResponsesSelector: FC<Props> = ({
  color,
  selectedVariable,
  selectedResponses,
  onClose,
  visible,
  validateUpdateChoice,
  variableService,
  countries,
  languageCode,
  dataScope,
}) => {
  const [availableResponses, setAvailableResponses] = useState<ResponseInfos[]>([]);
  const [responses, setResponses] = useState<ResponseInfos[]>(selectedResponses);

  useEffect(() => {
    const responseRetrieve = async () => {
      const responses = await variableService.getVariableResponses(
        selectedVariable.variable_id,
        selectedVariable.isparent,
        countries,
        languageCode,
        dataScope,
      );

      responses.sort((a, b) => a.label.localeCompare(b.label));

      setAvailableResponses(responses);
    };

    void responseRetrieve();
  }, [selectedVariable]);

  const handleSelectResponse = (selectedResponses: ResponseInfos[]) => {
    setResponses(selectedResponses);
  };

  const colors = colorTosemanticColors(color);

  return (
    <div className="variable-drawer">
      <Drawer
        placement="right"
        onClose={() => onClose(variableService)}
        closeIcon={
          <>
            <IconArrowLeft className="c-icon-stroke c-icon--20" />
            <span>Back</span>
          </>
        }
        open={visible}
        width="70%"
        title={<DrawerTitle title="SELECT THE RESPONSES" subtitle="With a selection of available choice" />}
      >
        <div className="variable-drawer-content">
          <div className="variable-selector">
            <Responses
              options={availableResponses}
              colors={colors}
              onSelect={handleSelectResponse}
              values={responses}
            />
          </div>
          {responses.length > 0 ? (
            <div className="drawer-footer">
              <input
                className="btn--md"
                type="button"
                value={'Validate'}
                onClick={() => validateUpdateChoice(responses)}
              />
            </div>
          ) : undefined}
        </div>
      </Drawer>
    </div>
  );
};

export default ResponsesSelector;
