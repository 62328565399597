import { FC } from 'react';
import { Segment } from '../../../../core/segment/segments';
import ClusterEditorHeaderComponent from './clusterEditorHeaderComponent';
import ClusterDefinitionComponent from './clusterDefinitionComponent';
import SegmentEditorHeader from '../../../Pages/SegmentEditor/Header';
interface Props {
  segment: Segment;
  lang: string;
}

const ClusterEditorComponent: FC<Props> = ({ lang, segment }) => (
  <div className="segment-editor">
    <SegmentEditorHeader urlBack={`/client/cluster-builder/${lang}`} parentSegment={segment} />
    <ClusterDefinitionComponent segment={segment} disabled={false} />
  </div>
);

export default ClusterEditorComponent;
