import { FC, useEffect, useState } from 'react';
import { SegmentOrigin, SegmentWithUiMeta, countryMetadatas } from 'hawaii';
import { Tooltip } from 'antd';
import FormatedNumber from '../../../Common/FormatedNumber';
import { useNavigate } from 'react-router';
import Load from '../../../Common/Load';
import { AMDMActivateStudioState } from '../../../store/activateStudioSlice';
import { factory } from '../../../../core/factory';
import { AMDMInfos } from '../../../../core/amdm';
import { importSegmentThunk } from '../../../store/thunk/importSegment';
import { useDispatch } from 'react-redux';

const segmentOriginTranslator: Record<SegmentOrigin, string> = {
  [SegmentOrigin.SEGMENT_BUILDER]: 'Segment Builder',
  [SegmentOrigin.CLUSTER_BUILDER]: 'Cluster Builder',
  [SegmentOrigin.CROSS_COUNTRY]: 'Cross Country',
};

interface IHomeTableRowProps {
  segment: SegmentWithUiMeta;
}
const HomeTableRow: FC<IHomeTableRowProps> = ({ segment }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activateStudioState, setActivateStudioState] = useState<AMDMActivateStudioState | null>(null);

  useEffect(() => {
    void (async () => {
      if (!segment.amdmInfos) return;
      setIsLoading(true);
      const amdmService = factory.getAmdmService();
      const response = await amdmService.getAudienceMatchingState(
        (segment?.amdmInfos as AMDMInfos).audienceRecordId,
        segment.countryIsos[0],
      );
      setActivateStudioState(response);
      setIsLoading(false);
    })();
  }, []);

  const redirectToScreen = () => {
    const routes: Record<SegmentOrigin, () => void> = {
      [SegmentOrigin.SEGMENT_BUILDER]: () => {
        navigate(`/client/segment-builder/${segment.countryIsos[0]}`);
      },
      [SegmentOrigin.CLUSTER_BUILDER]: () => {
        navigate(`/client/cluster-builder/${segment.countryIsos[0]}`);
      },
      [SegmentOrigin.CROSS_COUNTRY]: () => {
        navigate(`/client/cross-country-analysis/${segment.countryIsos.join()}`);
      },
    };

    dispatch(importSegmentThunk({ segment }));
    routes[segment.origin || 0]();
  };

  const segmentCountryNames = countryMetadatas
    .filter(({ iso }) => segment.countryIsos.includes(iso))
    .map(({ name }, index) => (index > 0 ? `, ${name}` : name));

  return (
    <tr key={segment.id}>
      <td
        style={{
          cursor: 'pointer',
        }}
        onClick={redirectToScreen}
      >
        <div className="audience-table__name">
          <div className="text-truncate">
            <Tooltip
              placement="topRight"
              color="black"
              title={segment.origin !== undefined ? segmentOriginTranslator[segment.origin] : ''}
            >
              {segment.name}
            </Tooltip>
          </div>
          {isLoading ? (
            <Load />
          ) : activateStudioState?.data.find(({ statusActivateStudio }) =>
              ['MATCHING', 'MATCHED', 'READY', 'PUSHED', 'ACTIVATED'].includes(statusActivateStudio),
            ) || segment.activationStatus === 'success' ? (
            <div className="status">A</div>
          ) : null}
        </div>
      </td>
      <td align="right">
        <FormatedNumber value={segment.uiMeta.totalRespondents} />
      </td>
      <td>
        <Tooltip placement="topRight" color="black" title={segmentCountryNames}>
          {[...segmentCountryNames].splice(0, 3)}
          {countryMetadatas.filter(({ iso }) => segment.countryIsos.includes(iso)).length > 3 ? '...' : ''}
        </Tooltip>
      </td>
    </tr>
  );
};

export default HomeTableRow;
