import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { SegmentWithUiMeta } from 'hawaii';
import { saveSegmentinProfilesThunk } from '../saveSegment';

export const saveAudienceInAmdmThunk = createAsyncThunk<
  void,
  {
    segment: SegmentWithUiMeta;
    agency: number;
    client?: number | string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/save`, async ({ segment, agency, client }, { extra, dispatch }): Promise<void> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    const amdmInfos = await amdmService.save(segment, agency, client);
    await dispatch(saveSegmentinProfilesThunk({ segment: { ...segment, amdmInfos } }));
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});

export const updateAudienceInAmdmThunk = createAsyncThunk<
  void,
  {
    segment: SegmentWithUiMeta;
    agency: number;
    client?: number | string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/save`, async ({ segment, agency, client }, { extra }): Promise<void> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    await amdmService.updateInfos(segment, agency, client);
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
