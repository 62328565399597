import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AMDMClientsInfos } from '../../../../core/amdm';

export const getClientsToSecmdmThunk = createAsyncThunk<
  AMDMClientsInfos[],
  void,
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`mdm/clients`, async (arg, { extra }): Promise<AMDMClientsInfos[]> => {
  const secmdmService = extra.factory.getSecmdmService();
  try {
    return await secmdmService.listClients();
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
