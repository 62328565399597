import { FC, useEffect, useState, CSSProperties } from 'react';
import { ClusterTree } from './clusterTreeComponent';
import ResponsesSelector from '../../../MetaComponents/VariablesDrawer/ResponsesSelector';
import VariableSelector from '../../../MetaComponents/VariablesDrawer/VariableSelector';
import { useDispatch, useSelector } from 'react-redux';
import Color from 'color';
import { Segment } from '../../../../core/segment/segments';
import segmentSlice, { selectSegmentByIdAndOldId } from '../../../store/segmentSlice';
import { updateSegmentPopulationThunk } from '../../../store/thunk/updateSegmentPopulation';
import { findCriteriaById } from '../../../../core/segment/helper';
import { FirstLevelCategory } from '../../../Common/VariableSelector/CategoriesSelector';
import { GlobalState } from '../../../store/global';
import { VariablesSelectionServiceInterface } from '../../../../core/variablesSelection';
import { PanelCriteria, ResponseInfos, SegmentOrigin, VariableInfos } from 'hawaii';
import Factory from '../../../../core/factory';
import ButtonIcon from '../../../Common/ButtonIcon';
import { ThunderboltOutlined } from '@ant-design/icons';
import { useDataBricks } from '../../common/hooks/useDataBricksV2';

import { useClustersData } from '../hooks/useClustersData';
import ClusterModal from './clusterModal';
import { useNavigate } from 'react-router';
import SaveAudienceModal from '../../../Pages/SegmentEditor/AMDM/SaveAudienceModal';
import GetAudienceModal from '../../../Pages/SegmentEditor/AMDM/GetAudienceModal';
import { setSaveAudienceAction, setSaveAudienceModalOpen } from '../../../store/amdmSlice';
import { Select, Spin } from 'antd';
import { getSegmentEditorData } from '../../../store/segmentEditionSlice';
import { selectSegmentById } from '../../../store/segmentSlice';

interface Props {
  segment: Segment;
  disabled: boolean;
}

const ClusterDefinitionComponent: FC<Props> = ({ segment, disabled }) => {
  const navigate = useNavigate();
  const editorData = useSelector((state: GlobalState) => getSegmentEditorData(state));
  const selectedSegment = useSelector((state: GlobalState) =>
    selectSegmentByIdAndOldId(state, { id: editorData.segmentId!, oldSegmentId: editorData.oldSegmentId! }),
  );
  const segments = useSelector((state: GlobalState) => state.segments);
  const { startClusterAnalysisPipeline, getPrevClusterAnalysis } = useDataBricks(
    selectedSegment.publicId,
    'clusters',
    segments,
  );
  const { saveHistoryClusters, getHistoryClusters } = useClustersData(selectedSegment.publicId, 'clusters');
  const [options, setOptions] = useState<{ label: string; value: string }[]>([{ label: '-----', value: 'empty' }]);
  const [selectedOption, setSelectedOption] = useState<{ label: string; value: string }>({
    label: '-----',
    value: 'empty',
  });

  const handleRemoveCriteria = (criteriaId: string) => {
    if (!segment.uiMeta.disabled) {
      dispatch(segmentSlice.actions.segmentRemoveCriteria({ id: segment.id, criteriaId }));
      dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
    }
  };

  const handleSelectCriteria = (criteriaId: string | null, operator: string) => {
    const criteria = criteriaId ? (findCriteriaById(segment, criteriaId) as PanelCriteria) : null;

    setUpdatingSegment(segment);
    setUpdatingCriteria(criteria);
    setUpdatingOperator(operator);
    setVariableSelectorOpen(true);
  };

  const handleSelectResponse = (criteriaId: string) => {
    const criteria = findCriteriaById(segment, criteriaId) as PanelCriteria;

    setUpdatingSegment(segment);
    setUpdatingCriteria(criteria);
    setResponseSelectorOpen(true);
  };

  const handleRemoveResponse = (criteriaId: string, response: ResponseInfos) => {
    dispatch(segmentSlice.actions.removeResponse({ segmentId: segment.id, criteriaId, responseValue: response.value }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
  };

  const handleUpdateZIndex = (criteriaId: string, zIndex: number) => {
    dispatch(segmentSlice.actions.updateZIndex({ segmentId: segment.id, criteriaId, zIndex }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
  };

  const handleChangeCriteriaRule = (segment: Segment, criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') => {
    dispatch(segmentSlice.actions.updateRule({ segmentId: segment.id, criteriaId, rule }));
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
  };

  const handleVariableValidation = (selectedVariable: VariableInfos, selectedResponses: ResponseInfos[]) => {
    dispatch(
      segmentSlice.actions.segmentAddCriteria({
        id: segment.id,
        criteriaId: updatingCriteria === null ? updatingCriteria : updatingCriteria.id,
        selectedVariable,
        selectedResponses,
        operator: updatingOperator,
      }),
    );
    dispatch(updateSegmentPopulationThunk({ segmentId: segment.id }));
    setVariableSelectorOpen(false);
  };

  const searchCallback = async (regex: string): Promise<VariableInfos[]> => {
    return await variableService.getVariablesFromInput(regex, segment.countryIsos, selectedLanguage, segment.dataScope);
  };

  const categoryTreeProvider = (path: string[]): Promise<FirstLevelCategory[]> => {
    return categoryService.get(path, segment.countryIsos, selectedLanguage, segment.dataScope);
  };

  const responseSelectorValidation = (selectedResponses: ResponseInfos[]) => {
    dispatch(
      segmentSlice.actions.updateResponse({
        segmentId: updatingSegment!.id,
        criteriaId: updatingCriteria!.id,
        selectedResponses,
      }),
    );
    dispatch(updateSegmentPopulationThunk({ segmentId: updatingSegment!.id }));
    setResponseSelectorOpen(false);
  };

  const dispatch = useDispatch();

  const categoryService = Factory().getCategoryService();
  const variableService = Factory().getVariableSelectionService();

  const [updatingSegment, setUpdatingSegment] = useState<Segment | undefined>(undefined);
  const [updatingCriteria, setUpdatingCriteria] = useState<PanelCriteria | null>(null);

  const [updatingOperator, setUpdatingOperator] = useState<string>('AND');
  const [variableSelectorOpen, setVariableSelectorOpen] = useState<boolean>(false);
  const [responseSelectorOpen, setResponseSelectorOpen] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const selectedLanguage = useSelector((state: GlobalState) => state.selectedLanguage);
  const metaLanguage = useSelector((state: GlobalState) => state.settingsUI.language);

  // const bkColor = new Color(segment.color);

  const handleClusterAnalysis = async (value: string) => {
    const body = {
      job_id: null,
      country_code: metaLanguage.meta.id,
      audience_name: selectedSegment.name,
      audience_criteria: {
        criterias: selectedSegment.criterias,
      },
      num_cluster: value,
    };

    setModalOpen(false);
    await startClusterAnalysisPipeline(body, selectedSegment.publicId);
    await saveHistoryClusters(selectedSegment, metaLanguage.meta.id);
    navigate(`/client/cluster-builder/${metaLanguage.meta.iso}/${selectedSegment.publicId}`);
  };

  useEffect(() => {
    if (!modalOpen) return;
    if (segment.publicId === '' || !segment.amdmInfos) {
      dispatch(setSaveAudienceAction('save'));
      dispatch(setSaveAudienceModalOpen(true));
      setModalOpen(!modalOpen);
    }
  }, [modalOpen]);

  useEffect(() => {
    const handleSelectDropDown = async () => {
      const baseOpt = [{ label: '-----', value: 'empty' }];
      setSelectedOption(baseOpt[0]);
      const historyClusters = await getHistoryClusters(segment, metaLanguage.meta.id);
      if (historyClusters) {
        const concated: { label: string; value: string }[] = baseOpt.concat(historyClusters);
        setOptions(concated);
      }
    };

    void handleSelectDropDown();
  }, [segment]);

  const handlePreviousClusters = async () => {
    navigate(`/client/cluster-builder/${metaLanguage.meta.iso}/${selectedSegment.publicId}`);
    await getPrevClusterAnalysis(selectedSegment.publicId, selectedOption.value);
  };

  const styleDisableContent: CSSProperties = {
    backgroundColor: '#80808075',
    zIndex: '999',
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
  };

  return (
    <>
      <SaveAudienceModal segment={segment} origin={SegmentOrigin.CLUSTER_BUILDER} />
      {modalOpen && segment.publicId !== '' && (
        <div className="cluster-modal">
          <ClusterModal setModalOpen={setModalOpen} handleClusterAnalysis={handleClusterAnalysis} />
        </div>
      )}
      <div className="editor-content-border">
        <div className="disable-content" style={selectedOption.value !== 'empty' ? styleDisableContent : {}}></div>
        <div className="editor-content hawaii-thin-scrollbar">
          <ClusterTree
            criterias={segment.criterias}
            color={segment.color}
            disabled={false}
            addOperatorButton={false}
            removeCriteriaAction={handleRemoveCriteria}
            addCriteriaAction={handleSelectCriteria}
            removeResponsesAction={handleRemoveResponse}
            updateResponsesAction={handleSelectResponse}
            updateZIndexAction={handleUpdateZIndex}
            changeCriteriaRuleAction={(criteriaId: string, rule: 'INCLUDE' | 'EXCLUDE') =>
              handleChangeCriteriaRule(segment, criteriaId, rule)
            }
          />
          {disabled || (
            <>
              <button className="operator" onClick={() => handleSelectCriteria(null, segment.criterias.operator)}>
                {segment.criterias.operator}...
              </button>
            </>
          )}
        </div>
      </div>
      <div className="clustering-btn" style={selectedOption.value !== 'empty' ? { zIndex: 9999 } : {}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <span style={{ margin: '5px 0px' }}>Previous Analysis:</span>
          <Select
            value={selectedOption.value}
            style={{ width: 145, marginBottom: '24px' }}
            dropdownStyle={selectedOption.value !== 'empty' ? { zIndex: 9999 } : {}}
            onSelect={(v: string, opt) =>
              setSelectedOption({
                label: opt.children ? opt.children.toString() : '-----',
                value: opt.value ? opt.value.toString() : 'empty',
              })
            }
          >
            {options &&
              (options as { label: string; value: string }[]).map((opt, i) => (
                <Select.Option key={i} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
          </Select>
        </div>
        {selectedOption.value === 'empty' ? (
          <div
            className="editor-content-footer"
            style={segment.criterias.criterias.length === 0 ? { pointerEvents: 'none', opacity: '0.5' } : {}}
            onClick={() => setModalOpen(true)}
          >
            <ButtonIcon className="btn--md" label="Run Clustering Analysis"></ButtonIcon>
          </div>
        ) : (
          <div
            className="editor-content-footer"
            style={segment.criterias.criterias.length === 0 ? { pointerEvents: 'none', opacity: '0.5' } : {}}
            onClick={async () => await handlePreviousClusters()}
          >
            <ButtonIcon className="btn--md" label="Run Previous Analysis"></ButtonIcon>
          </div>
        )}
      </div>

      {updatingCriteria !== undefined && variableSelectorOpen && (
        <VariableSelector
          color={segment.color}
          categoryTreeProvider={categoryTreeProvider}
          title="Choose a variable for the audience"
          subtitle="With a selection of variables and responses"
          validationCaption="Add"
          visible={true}
          variableService={variableService}
          countries={segment.countryIsos}
          languageCode={selectedLanguage}
          dataScope={segment.dataScope}
          onClose={(variableService: VariablesSelectionServiceInterface) => {
            variableService.abort();
            setVariableSelectorOpen(false);
          }}
          searchCallback={searchCallback}
          validateVariableChoice={handleVariableValidation}
        />
      )}
      {responseSelectorOpen &&
        updatingSegment !== undefined &&
        updatingCriteria !== undefined &&
        updatingCriteria !== null && (
          <ResponsesSelector
            color={updatingSegment.color}
            onClose={() => {
              setResponseSelectorOpen(false);
            }}
            selectedResponses={updatingCriteria.responses}
            selectedVariable={updatingCriteria.variable}
            validateUpdateChoice={responseSelectorValidation}
            variableService={variableService}
            visible={true}
            countries={segment.countryIsos}
            languageCode={selectedLanguage}
            dataScope={updatingSegment.dataScope}
          />
        )}
    </>
  );
};

export default ClusterDefinitionComponent;
