import './EditionPopup.less';
import { Input } from 'antd';
import Color from 'color';
import { FC, FormEvent, useState } from 'react';
import { ColorChangeHandler, ColorResult, CustomPicker, HuePicker, InjectedColorProps } from 'react-color';

interface Props {
  onValidation: (name: string, color: string) => boolean;
  currentName: string;
  currentColor: string;
  errMsg: string;
}

const EditionPopup: FC<Props> = ({ onValidation, currentName, currentColor, errMsg }) => {
  const [name, setName] = useState(currentName);
  const [color, setColor] = useState(currentColor);
  const [isError, setIsError] = useState(false);

  const sanitizeInput = (input: string): string => {
    const escapeChars = { '"': '\\"', "'": "\\'", '\\': '\\\\' };
    return input.replace(
      /["'\\]/g,
      (matched) =>
        escapeChars[
          matched as keyof {
            '"': string;
            "'": string;
            '\\': string;
          }
        ],
    );
  };

  const validate = () => setIsError(onValidation(sanitizeInput(name), color));

  const handleTextChange = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsError(false);
    setName(event.currentTarget.value);
  };

  const handleChangeColor = (value: ColorResult) => {
    setColor(value.hex);
  };

  const HueChooser: FC<InjectedColorProps> = (props: InjectedColorProps) => {
    const handleChange: ColorChangeHandler = (color: ColorResult) => {
      const value = Color.hsl(color.hsl.h, 70, 50).hex();
      !props.onChange || props.onChange(value);
    };
    const hueProps = { ...props, onChangeComplete: handleChange };
    return <HuePicker {...hueProps} />;
  };

  const ColorSelector = CustomPicker(HueChooser);

  return (
    <>
      <div className="input-container">
        <Input
          className={isError ? 'text-input-err' : 'text-input'}
          type="text"
          value={name}
          onChange={handleTextChange}
          size={'small'}
          onPressEnter={validate}
        />
        <button className="btn btn--xs" onClick={validate}>
          OK
        </button>
      </div>
      {isError && <div className="errMsg">{errMsg}</div>}
      <div className="color-selector">
        <ColorSelector color={color} onChangeComplete={handleChangeColor} />
      </div>
    </>
  );
};

export default EditionPopup;
