/* eslint-disable @typescript-eslint/restrict-template-expressions */

import './HeaderWrapper.less';

import { FC, useEffect, useState } from 'react';
import { BrandLogo, AppLogo, HavasIconModules, IconLogout, IconLock, IconUnlock } from '../../Svg/HavasIcons';
import { UserOutlined } from '@ant-design/icons';
import { PageProperties } from '../../interface';
import { Popover } from '../Popover';
import { Link, useFetcher, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../store/global';
import { setConnectionModalIsOpen } from '../../store/amdmSlice';
import AMDMModal from './AMDMModal';
import { msalInstance } from '../../..';
import segmentSlice from '../../store/segmentSlice';
import { config } from '../../../core/config';
interface Props {
  lastUpdate: string;
}

const HeaderWrapper: FC<Props> = ({ lastUpdate }) => {
  const dispatch = useDispatch();

  const logout = async () => {
    await msalInstance.logoutRedirect();
  };

  const params = useParams();
  const location = useLocation();

  const [openPageSelection, setOpenPageSelection] = useState<boolean>(false);
  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const amdmConnection = useSelector((state: GlobalState) => state.amdmState.connection);

  const controleItems = () => {
    return [
      {
        icon: <IconLogout className="c-icon-stroke c-icon--16" />,
        name: 'Logout',
        action: () => logout(),
      },
      {
        icon: amdmConnection.isConnected ? (
          <IconUnlock className="c-icon-stroke c-icon--16" color="#52c41a" />
        ) : (
          <IconLock className="c-icon-stroke c-icon--16" color="#d40817" />
        ),
        name: 'AMDM',
        action: () => dispatch(setConnectionModalIsOpen(true)),
      },
    ];
  };

  const dropdown = () => {
    return (
      <>
        <Popover
          placement="bottom"
          isOpen={openPageSelection}
          openClose={setOpenPageSelection}
          render={() => (
            <div className="hawaii-control header-menu">
              {Object.keys(PageProperties).map((key: string) => {
                const properties = PageProperties[key];
                let url = properties.url;

                if (properties.defaultParams) {
                  const pageParams = Object.keys(properties.defaultParams).map((param) => [
                    param,
                    properties.defaultParams![param],
                  ]);
                  url = pageParams.reduce<string>(
                    (url: string, curr: string[]) => url.replace(`:${curr[0]}`, curr[1]),
                    properties.url,
                  );
                } else if (
                  [
                    '/client/cluster-builder/:country',
                    '/client/segment-builder/:country',
                    '/client/propensity/:country',
                  ].find((url) => new RegExp(url).test(properties.url)) &&
                  ['/segment-builder', '/cluster-builder', '/client/propensity'].find((url) =>
                    new RegExp(url).test(location.pathname),
                  )
                ) {
                  url = url.replace(':country', params.country as string);
                }

                if (!properties.menuCaption) return <></>;

                return (
                  <li key={key}>
                    <Link to={url}>{properties.menuCaption}</Link>
                  </li>
                );
              })}
            </div>
          )}
        >
          <a onClick={(e) => e.preventDefault()}>
            <HavasIconModules className="c-icon-stroke c-icon--white" color="#FFFFFF" />
          </a>
        </Popover>

        <Popover
          placement="bottom"
          isOpen={openLogout}
          openClose={setOpenLogout}
          render={() => (
            <div className="hawaii-control header-menu">
              {controleItems().map((item) => {
                return (
                  <li key={item.name}>
                    <a onClick={item.action}>
                      {item.name}
                      {item.icon}
                    </a>
                  </li>
                );
              })}
            </div>
          )}
        >
          <UserOutlined color="#FFFFFF" style={{ fontSize: '24px', position: 'relative' }} />
        </Popover>
        {<AMDMModal />}
      </>
    );
  };

  useEffect(() => {
    if (['/cluster-builder'].find((url) => new RegExp(url).test(location.pathname))) {
      dispatch(segmentSlice.actions.removeInvalidSegmentClusters());
    }
  }, [location.pathname]);

  return (
    <>
      <div className="header__brand">
        <a href={`${config.converged.convergedUrl}/converged/#/home`} target="_blank" className="header__brand-logo">
          <BrandLogo />
        </a>
      </div>

      <div className="header__app">
        <a href="/" className="header__app-logo">
          <AppLogo />
        </a>
      </div>

      <div className="header__end">
        <div className="c-data">
          <span className="c-data__label">Last update</span> {lastUpdate}
        </div>
        <div className="header__actions">{dropdown()}</div>
      </div>
    </>
  );
};

export default HeaderWrapper;
