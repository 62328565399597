import { Input } from 'antd';
import { FC } from 'react';

export interface Props {
  msg: string;
  searchMtd: (value: string) => Promise<void>;
  disabled?: boolean;
}

const SearchBar: FC<Props> = ({ msg, searchMtd, disabled }) => {
  return (
    <Input
      placeholder={msg}
      onPressEnter={(e) => searchMtd((e.target as HTMLInputElement).value)}
      disabled={disabled}
    />
  );
};

export default SearchBar;
