import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AmdmUpn } from '../../../../core/amdm';

export const sendOwnerUpnToAmdmThunk = createAsyncThunk<
  AmdmUpn,
  {
    ownerUpn: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/send-owner-upn`, async ({ ownerUpn }, { extra }): Promise<AmdmUpn> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    return amdmService.setOwnerUpn({ ownerUpn });
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
