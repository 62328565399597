import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { getCountriesToSecmdmThunk } from './getCountries';
import { msalInstance } from '../../../..';
import { setAppIsLoadingState } from '../../appIsLoadingSlice';
import { setSelectedCountry, setUser } from '../../secmdmSlice';
import { countryMetadatas } from 'hawaii';
import { CheckMDMUserInfo } from '../../../../core/secmdm';

export const verifyMdmUser = createAsyncThunk<
  CheckMDMUserInfo | null,
  undefined,
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`mdm/check/user`, async (_, { extra, dispatch }): Promise<CheckMDMUserInfo | null> => {
  dispatch(setAppIsLoadingState({ state: true }));
  const secmdmService = extra.factory.getSecmdmService();
  try {
    const checkMdmUser = await secmdmService.checkUser();
    if (checkMdmUser && checkMdmUser.userExist) {
      await dispatch(getCountriesToSecmdmThunk());
      const country = countryMetadatas.find(({ iso }) => iso.toUpperCase() === checkMdmUser.isoAlpha2.toUpperCase());

      if (country) {
        dispatch(setSelectedCountry(country));
        dispatch(setUser(checkMdmUser));
      }
    } else {
      await msalInstance.logoutRedirect();
      dispatch(setAppIsLoadingState({ state: false }));
      return null;
    }
    dispatch(setAppIsLoadingState({ state: false }));
    return checkMdmUser;
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
