import { Dispatch } from 'redux';
import { SET_LANGUAGE } from './constants';
import { CountryMetadata } from 'hawaii';

interface Language {
  value: string;
  meta: CountryMetadata;
}

export const setLanguage = (language: Language) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: {
      value: language,
    },
  });
};
