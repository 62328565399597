import { Popover } from './Popover';
import { FC, useEffect, useState } from 'react';
import { Serie } from '../../core/series/interface';
import { HavasIconExport } from '../Svg/HavasIcons';
import './DisplaySelector.less';
import { isArray } from 'lodash';
import { MdClear, MdDone, MdKeyboardArrowRight } from 'react-icons/md';
import { offset, flip, shift, autoUpdate, useFloating } from '@floating-ui/react-dom-interactions';

interface Props {
  selectedResponses: Record<string, string[]>;
  onToogleSelectedResponses: (variableId: string, responseId: string) => void;
  xlsExportAction: () => void;
  variables: Serie[] | (() => Serie[]);
  deleteVariableAction: (variableId: string) => void;
  clearSelection: (variablesId: string[]) => void;
}

const VariableList: FC<{
  variables: Serie[];
  selectedResponses: Record<string, string[]>;
  onToogleSelectedResponses: (variableId: string, responseId: string) => void;
  deleteVariableAction: (variableId: string) => void;
}> = ({ variables, selectedResponses, onToogleSelectedResponses, deleteVariableAction }) => {
  const [open, setOpen] = useState(false);
  const [selectedVariable, setSectedVariable] = useState<Serie | undefined>(undefined);

  const { x, y, reference, floating, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift()],
    placement: 'right-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const selectedKeys = variables
    .map((variable: Serie) => {
      const variableId = variable.globalTags.variableId as string;
      return variable.tags
        .map((tag) => {
          const responses = selectedResponses[variableId] ? selectedResponses[variableId] : [];
          const responseId = tag.responseId as string;
          if (responses.includes(responseId)) {
            return `${variableId} - ${responseId}`;
          }
          return;
        })
        .filter((el) => !!el);
    })
    .flat() as string[];

  return (
    <div className="hawaii-control" ref={reference}>
      <div className="list hawaii-thin-scrollbar">
        {variables.map((variable: Serie) => {
          const variableId = variable.globalTags.variableId as string;
          return (
            <div
              className="variable"
              onClick={() => {
                setSectedVariable(variable);
              }}
            >
              {selectedVariable?.globalTags.variableId === variableId && <MdKeyboardArrowRight className="chevron" />}
              <MdClear
                onClick={() => {
                  deleteVariableAction(variableId);
                  setOpen(false);
                }}
                className="icon"
              />
              &nbsp;&nbsp;
              {variable.globalTags.variableName}
            </div>
          );
        })}
      </div>
      {open || selectedVariable === undefined || (
        <div
          className="hawaii-control"
          ref={floating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
        >
          <div className="list hawaii-thin-scrollbar">
            {selectedVariable.tags.map((tag) => {
              const variableId = selectedVariable.globalTags.variableId as string;
              const responseId = tag.responseId as string;
              const key = `${variableId} - ${responseId}`;
              const unselected = selectedKeys.indexOf(key) === -1;
              return (
                <div
                  className={`variable ${unselected ? 'unselected' : ''}`}
                  key={key}
                  onClick={() => onToogleSelectedResponses(variableId, responseId)}
                >
                  {unselected ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : <MdDone />}
                  &nbsp;&nbsp;
                  {tag.responseText}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

const DisplaySelector: FC<Props> = ({
  selectedResponses,
  onToogleSelectedResponses,
  xlsExportAction,
  variables,
  deleteVariableAction,
  clearSelection,
}) => {
  const evaluatedVariables = isArray(variables) ? variables : variables();
  const variablesId = evaluatedVariables.map((variable) => variable.globalTags.variableId) as string[];
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!evaluatedVariables) return;

    if (evaluatedVariables.length === 0) {
      setOpen(false);
    }
  }, [evaluatedVariables]);

  return evaluatedVariables.length > 0 ? (
    <div className="pit-header">
      <Popover
        placement="bottom-end"
        isOpen={open}
        openClose={setOpen}
        render={() => (
          <VariableList
            variables={evaluatedVariables}
            selectedResponses={selectedResponses}
            onToogleSelectedResponses={onToogleSelectedResponses}
            deleteVariableAction={deleteVariableAction}
          />
        )}
      >
        <button onClick={(e) => e.preventDefault()}>Variables / Responses selection</button>
      </Popover>
      <button className="link" onClick={() => clearSelection(variablesId)}>
        Clear selection
      </button>
      <button onClick={xlsExportAction} style={{ float: 'right' }} title="Export">
        <HavasIconExport color="white" width="1em" />
      </button>
    </div>
  ) : (
    <></>
  );
};

export default DisplaySelector;
