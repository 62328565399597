import { useEffect, useState } from 'react';
import { config } from '../../../../core/config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store/global';
import { setAppIsLoadingState } from '../../../store/appIsLoadingSlice';
import { message } from 'antd';
import {
  setSavedResults,
  setPropensityDataResults,
  databricksJobStart,
} from '../../../store/modules/databricks/actions';
import { addBearerTokenInHeaders } from '../../../../core/helpers/addBearTokenInHeaders';
import { chunkArray } from '../../../../core/helpers/chunkArray';
import { IBrandsDetails } from '../../common/interface/interface';

const BASE_URL = config.api.baseUrl as string;

type MetaEntity = {
  propensityToSaveName: string | undefined;
  selectedAgency: number | undefined;
  selectedClient: number | string | undefined;
  youGovIDs: string[];
  brandID: string | undefined;
  selectedRange: [number, number];
  marketSize: number;
};

export const useBrandData = (selectedCountry: number) => {
  const dispatch = useDispatch();
  const [brandsResponseData, setBrandsResponseData] = useState<Record<string, IBrandsDetails[]> | null>(null);
  const [paginationResponseData, setPaginationResponseData] = useState<IBrandsDetails[] | null>(null);
  const [errorClusterSave, setErrorClusterSave] = useState<Error | null>(null);
  const settingsUI = useSelector((state: GlobalState) => state.settingsUI);
  const infoAMDM = useSelector((state: GlobalState) => state.amdmState);
  const hawaiiState = useSelector((state: GlobalState) => state.hawaiiState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [historyBrands, setHistoryBrands] = useState<
    | {
        name: string;
        category: string;
        id: number;
        date: string;
        country: number;
      }[]
    | null
  >(null);

  const savePropensityAMDM = async (metaEntity: MetaEntity) => {
    const { language } = settingsUI;
    const { connection } = infoAMDM;
    const hide = message.loading({ content: 'Loading...' });
    dispatch(setAppIsLoadingState({ state: true }));

    if (!connection.isConnected) throw new Error('AMDM is not connected !');

    const propensityEntity = {
      agencyId: Number(metaEntity.selectedAgency),
      clientId: metaEntity.selectedClient,
      name: metaEntity.propensityToSaveName,
      datasetDate: new Date(hawaiiState.surveyRefreshDate).toISOString(),
      population: metaEntity.marketSize,
      countryCode: language.meta.iso,
    };

    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const chunkyData = chunkArray(metaEntity.youGovIDs, 81920);
      const totalChunks = chunkyData.length;

      await Promise.all(
        chunkyData.map(async (chunk, index) => {
          const payload = {
            entity: totalChunks - 1 === index ? propensityEntity : undefined,
            chunkRespondentsList: chunk,
            totalChunks: totalChunks,
            chunkIndex: index,
          };
          return await axios.post(`${BASE_URL}/v1/propensity/save-model`, payload, {
            withCredentials: true,
            signal: abortController.signal,
          });
        }),
      ).then(async (promises) => {
        const res200 = promises.every((r) => r.status !== 200);

        if (!res200) {
          dispatch(
            setSavedResults(
              Array.isArray(metaEntity.selectedRange) ? metaEntity.selectedRange : Number(metaEntity.selectedRange),
              metaEntity.brandID || '',
              'propensity',
            ),
          );
          await message.success({ content: 'Cluster saved on AMDM direction.', duration: 3 });
        } else {
          await message.error({ content: 'Error cluster saving on AMDM direction.', duration: 3 });
          setErrorClusterSave(new Error('Error cluster saving on AMDM direction.'));
        }
      });

      hide();
    } catch (err) {
      if (err instanceof Error) {
        await message.error({ content: err.message, duration: 3 });
        setErrorClusterSave(err);
      } else {
        await message.error(new Error('Unknown error occurred'));
        setErrorClusterSave(new Error('Unknown error occurred'));
      }
    } finally {
      dispatch(setAppIsLoadingState({ state: false }));
    }
  };

  const getBrands = async (brand_name: string) => {
    setLoading(true);
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();

      const response = await axios.post<{
        Brands: IBrandsDetails[];
      }>(
        `${BASE_URL}/v1/propensity/get-brands`,
        { COUNTRY_ID: selectedCountry, BRAND_NAME: brand_name },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );
      const brandsByCategory = response.data.Brands.reduce(
        (acc: Record<string, IBrandsDetails[]>, item: IBrandsDetails) => {
          const category: string | undefined = item.category4;
          if (category) {
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(item);
          }

          return acc;
        },
        {},
      );

      setBrandsResponseData(brandsByCategory);
      setError(null);
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        setError(err);
      } else {
        console.log(new Error('Unknown error occurred'));
        setError(new Error('Unknown error occurred'));
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteHistoryBrandsClusters = async () => {
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const response = await axios.post(
        `${BASE_URL}/v1/propensity/deletePropensityHistory`,
        { COUNTRY_ID: selectedCountry },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );
      if (response.status === 200) {
        console.log('deleted');
        setHistoryBrands([]);
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };

  const getHistoryBrands = () => {
    useEffect(() => {
      setHistoryBrands([]);
      const fetch = async () => {
        try {
          const abortController = new AbortController();
          await addBearerTokenInHeaders();
          const response = await axios.post(
            `${BASE_URL}/v1/propensity/getPropensityHistory`,
            { COUNTRY_ID: selectedCountry },
            {
              withCredentials: true,
              signal: abortController.signal,
            },
          );
          if (response.status === 200) {
            setHistoryBrands(
              (response.data as {
                name: string;
                category: string;
                id: number;
                date: string;
                country: number;
              }[]) || [],
            );
          } else {
            setHistoryBrands([]);
          }
        } catch (err) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log(new Error('Unknown error occurred'));
          }
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetch();
    }, [selectedCountry]);
    return historyBrands;
  };

  interface IResponse {
    response: {
      propensity: {
        yougov_id: string;
        probability: string;
      }[];
      distinct: {
        dataset: string;
        distinct_yougov_id_count: number;
      }[];
    };
  }

  const getHistoryBrandsClusters = async (brand: {
    name: string;
    category: string;
    id: number;
    date: string;
    country: number;
  }) => {
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      const results = {
        run_id: brand.id.toString(),
        status: 'SUCCESS',
        statusBQ: 'RUNNING',
        name: brand.name,
        category: brand.category,
        countryCode: brand.country,
        loading: true,
        jobTimeStart: new Date().getHours() * 3600 + new Date().getMinutes() * 60 + new Date().getSeconds(),
        estimatedTime: '00:00:00',
      };

      dispatch(databricksJobStart(results, 'History-' + brand.id.toString() + brand.date, 'propensity'));

      const response = await axios.post<IResponse>(
        `${BASE_URL}/v1/propensity/getPropensityClustersHistory`,
        { COUNTRY_ID: brand.country, BRAND_ID: brand.id, DATE: brand.date },
        {
          withCredentials: true,
          signal: abortController.signal,
        },
      );

      if (response.status === 200) {
        const grupedData = response.data.response.propensity.reduce(
          (list, item) => {
            const key = parseFloat(item.probability).toFixed(2);
            if (!list.probabilities[key]) {
              list.probabilities[key] = [];
            }

            if (!list.yougovIDs[key]) {
              list.yougovIDs[key] = [];
            }

            list.yougovIDs[key].push(item.yougov_id);
            list.probabilities[key].push(Number(item.probability));
            return list;
          },
          { probabilities: {} as { [key: string]: number[] }, yougovIDs: {} as { [key: string]: string[] } },
        );

        const generateScale = () => {
          const numbers = [];
          for (let i = 0; i <= 100; i++) {
            const num = (i / 100).toFixed(2).toString();
            numbers.push(num);
          }
          return numbers;
        };
        const toChartData: [string, number][] = generateScale().map((key) => {
          return [key, grupedData?.probabilities[key]?.length || 0];
        });

        const results = {
          toChartData: toChartData,
          yougovIDs: grupedData.yougovIDs,
          distinct: response.data.response.distinct,
          status: 'SUCCESS',
        };

        dispatch(setPropensityDataResults(results, 'History-' + brand.id.toString() + brand.date, 'propensity'));
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };

  const saveHistoryBrands = async (body: {
    name: string;
    category: string;
    id: number;
    date: string;
    country: number;
  }) => {
    setHistoryBrands((prev) => {
      if (prev?.find((el) => el.id === body.id) || prev === null) {
        return prev;
      }
      prev.push(body);
      return prev;
    });
    try {
      const abortController = new AbortController();
      await addBearerTokenInHeaders();
      await axios.post(`${BASE_URL}/v1/propensity/savePropensityHistory`, body, {
        withCredentials: true,
        signal: abortController.signal,
      });
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log(new Error('Unknown error occurred'));
      }
    }
  };

  const getPaginationBrands = (page_num: number) => {
    useEffect(() => {
      const fetchPagination = async () => {
        try {
          const abortController = new AbortController();
          await addBearerTokenInHeaders();
          const response = await axios.post(
            `${BASE_URL}/v1/propensity/get-brands-pagination`,
            { COUNTRY_ID: selectedCountry, PAGE_NUM: page_num },
            {
              withCredentials: true,
              signal: abortController.signal,
            },
          );
          console.log('getPaginationBrands', response);
          if (response.status === 200) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setPaginationResponseData(response.data.Brands as IBrandsDetails[]);
          }
        } catch (err) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log(new Error('Unknown error occurred'));
          }
        }
      };
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchPagination();
    }, [page_num]);
  };
  return {
    brandsResponseData,
    setBrandsResponseData,
    loading,
    error,
    getBrands,
    getPaginationBrands,
    savePropensityAMDM,
    errorClusterSave,
    setErrorClusterSave,
    getHistoryBrands,
    saveHistoryBrands,
    getHistoryBrandsClusters,
    deleteHistoryBrandsClusters,
  };
};
