import { FC, Dispatch, SetStateAction } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Select } from 'antd';

type Options = {
  value: string | number | boolean;
  label: string;
};

interface Props {
  label: string;
  toolTip: string;
  options: Options[];
  defaultValue: string | number | boolean;
  handleChange: Dispatch<SetStateAction<any>>;
}

const Selector: FC<Props> = ({ label, toolTip, options, defaultValue, handleChange }) => {
  return (
    <div>
      <div className="label">
        {label}
        <Tooltip placement="top" title={toolTip}>
          <InfoCircleOutlined style={{ marginLeft: '5px' }} />
        </Tooltip>
      </div>
      <Select
        defaultValue={defaultValue}
        style={{ width: '100%' }}
        onChange={(e) => {
          console.log(e);
          handleChange(e);
        }}
        options={options}
      />
    </div>
  );
};

export default Selector;
