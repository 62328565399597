import { FC, useState, useMemo } from 'react';
import './HomeTable.less';
import { IconArrowUpRight } from '../../../Svg/HavasIcons';

import _ from 'lodash';
import { SegmentOrigin, SegmentWithUiMeta, countryMetadatas } from 'hawaii';
import Load from '../../../Common/Load';
import { config } from '../../../../core/config';
import { Tooltip } from 'antd';
import FormatedNumber from '../../../Common/FormatedNumber';
import { useNavigate } from 'react-router';
import HomeTableRow from './TableRow';

const segmentOriginTranslator: Record<SegmentOrigin, string> = {
  [SegmentOrigin.SEGMENT_BUILDER]: 'Segment Builder',
  [SegmentOrigin.CLUSTER_BUILDER]: 'Cluster Builder',
  [SegmentOrigin.CROSS_COUNTRY]: 'Cross Country',
};

interface IHomeTableProps {
  sharedSegments: SegmentWithUiMeta[];
  storedSegments: SegmentWithUiMeta[];
  isFetching: boolean;
  visibleRows: number;
  tableContainerRef: React.RefObject<HTMLDivElement>;
}
const HomeTable: FC<IHomeTableProps> = ({
  storedSegments,
  sharedSegments,
  isFetching,
  visibleRows,
  tableContainerRef,
}) => {
  const [activeTab, setActiveTab] = useState<'stored' | 'shared'>('stored');

  const handleTabClick = (tab: 'stored' | 'shared') => {
    setActiveTab(tab);
  };

  const audiences = useMemo(
    () => (activeTab === 'stored' ? storedSegments : sharedSegments),
    [activeTab, storedSegments, sharedSegments],
  );

  return (
    <>
      <div className="audience-tabs">
        <button
          className={`audience-tabs__tab ${activeTab === 'stored' ? 'active' : ''}`}
          onClick={() => handleTabClick('stored')}
        >
          Last created audiences
        </button>
        <button
          className={`audience-tabs__tab ${activeTab === 'shared' ? 'active' : ''}`}
          onClick={() => handleTabClick('shared')}
        >
          Last shared audiences
        </button>
        <a
          target="_blank"
          href={`${config.converged.convergedUrl as string}/converged/#/tools/intelligence/native/audienceFinder/4`}
          className="audience-tabs__link btn-link"
        >
          Go to Audience Finder
          <IconArrowUpRight className="c-icon-stroke c-icon--16" />
        </a>
      </div>
      <div className="audience-table" ref={tableContainerRef}>
        {isFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100%',
            }}
          >
            <Load style={{ width: '3.5%' }} />
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Audience name</th>
                <th align="right">Respondents</th>
                <th>Market</th>
              </tr>
            </thead>
            <tbody>
              {audiences.slice(0, visibleRows).map((segment, index) => (
                <HomeTableRow segment={segment} key={`${segment.id}-${index}`} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default HomeTable;
