import { FC, useState, useEffect } from 'react';
import './SmartBuilder.less';
import { IconArrowLeft, IconEdit } from '../../Svg/HavasIcons';
import Settings from './Components/settings';
import SubAudiences from './Components/subAudiences';
import { useDataBricks } from '../../modules/common/hooks/useDataBricks';
import { Segment } from '../../../core/segment/segments';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import segmentSlice from '../../store/segmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { countryMetadatas } from 'hawaii';

type Props = {
  segment: Segment;
  selectedStage: string;
  smartStage: string;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const SmartBuilder: FC<Props> = ({ selectedStage, segment, smartStage, openModal, setOpenModal }) => {
  const dispatch = useDispatch();

  const [maxAlternate, setMaxAlternate] = useState<number>(2);
  const [maxResults, setMaxResults] = useState<number>(2);
  const [valResults, setValResults] = useState<boolean>(true);
  const [matchCriteria, setMatchCriteria] = useState<string>('Medium');
  const [loading, setLoading] = useState<boolean>(false);
  const { startSmartBuilderSentimentKeys, getSmartBuilderOutput, startSmartBuilderRecommander } = useDataBricks(
    segment.id,
    'smartBuilder',
  );

  const handleSubAudiencesSelect = (index: number, optionIndex: number) => {
    dispatch(
      segmentSlice.actions.setSmartSelectedSubAudiencies({
        id: segment.id,
        subAudiences: { index, option: optionIndex },
      }),
    );
  };

  useEffect(() => {
    if (!segment.smartBuilder?.prompt) return;

    const step1 = async () => {
      try {
        const prompt = segment.smartBuilder?.prompt;
        if (prompt) {
          const runId = await startSmartBuilderSentimentKeys(prompt);
          if (runId) {
            dispatch(
              segmentSlice.actions.setSmartRunID({
                id: segment.id,
                runId: runId,
              }),
            );

            dispatch(
              segmentSlice.actions.setSmartSettings({
                id: segment.id,
                settings: {
                  MAX_ALT_SEGMENT: maxAlternate,
                  MAX_ALT_QUESTION: maxResults,
                  RESULT_VALIDATION: valResults ? 'True' : 'False',
                  VALIDATION_SCORE: matchCriteria,
                },
              }),
            );
          }
        } else {
          return;
        }
      } catch (error) {
        console.error('Error of running propensity first job.', error);
      }
    };

    const step2 = async (runId: string) => {
      try {
        const results = await getSmartBuilderOutput(runId);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (results?.state.life_cycle_state === 'TERMINATED') {
          dispatch(
            segmentSlice.actions.setSmartSentimentStatus({
              id: segment.id,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              sentimentStatus: results?.state.result_state,
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (results?.notebook.result) {
            dispatch(
              segmentSlice.actions.setSmartSentiments({
                id: segment.id,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                sentiments: JSON.parse(results?.notebook.result),
              }),
            );
          }
        }
      } catch (error) {
        console.error('Error of running propensity second job.', error);
      }
    };

    const step3 = async (segment: Segment) => {
      const getCountryId = countryMetadatas.find((country) => country.iso === segment.countryIsos[0]);

      try {
        const notebook_params = {
          RUN_ID: segment.smartBuilder?.runId,
          COUNTRY_ID: getCountryId?.id || 7,
          ...segment.smartBuilder?.settings,
        };
        const runId = await startSmartBuilderRecommander(notebook_params);

        if (runId) {
          dispatch(
            segmentSlice.actions.setSmartRecommanderRunID({
              id: segment.id,
              runId: runId,
            }),
          );
        }
      } catch (error) {
        console.error('Error of running propensity third job.', error);
      }
    };

    const step4 = async (runId: string) => {
      try {
        const results = await getSmartBuilderOutput(runId);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (results?.state.life_cycle_state === 'TERMINATED') {
          dispatch(
            segmentSlice.actions.setSmartRecommanderStatus({
              id: segment.id,
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
              recommanderStatus: results?.state.result_state,
            }),
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (results?.notebook.result) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
            const recommander = Object.keys(JSON.parse(results?.notebook.result))
              .map((key) => ({
                title: key,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
                options: Object.entries(JSON.parse(results?.notebook.result)[key]).map(([value, label]) => ({
                  label,
                  value,
                })),
              }))
              .filter((el) => el.options.length > 0) as {
              title: string;
              options: {
                value: string;
                label: string;
              }[];
            }[];

            dispatch(
              segmentSlice.actions.setSmartRecommander({
                id: segment.id,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                recommander: recommander,
              }),
            );

            dispatch(
              segmentSlice.actions.setSmartLevel({
                id: segment.id,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                level: '1',
              }),
            );
            setLoading(false);
          }
        }
      } catch (error) {
        console.error('Error of running propensity third job.', error);
      }
    };

    const interval = setInterval(() => {
      if (segment.smartBuilder?.recommanderStatus === 'SUCCESS') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        clearInterval(interval);
      }
      if (!segment.smartBuilder?.runId) {
        void step1();
      }
      if (segment.smartBuilder?.runId && !segment.smartBuilder?.sentimentStatus) {
        void step2(segment.smartBuilder?.runId);
      }
      if (segment.smartBuilder?.sentimentStatus === 'SUCCESS' && !segment.smartBuilder.recomannderRunId) {
        void step3(segment);
      }
      if (segment.smartBuilder?.recomannderRunId && !segment.smartBuilder?.recommanderStatus) {
        void step4(segment.smartBuilder?.recomannderRunId);
      }
    }, 5000);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return () => clearInterval(interval);
  }, [segment]);

  const handleSearch = (prompt: string) => {
    if (!prompt) return;
    setLoading(true);

    dispatch(
      segmentSlice.actions.setSmartPrompt({
        id: segment.id,
        prompt: prompt,
      }),
    );
  };
  return (
    <>
      {loading && (
        <div className="loading">
          <Spin
            tip={'The Smart builder analysis is running. Please wait, it might take 2-3 minutes…'}
            indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
          />
        </div>
      )}
      {smartStage === '0' && !loading && (
        <>
          <div style={{ minHeight: 'fit-content', marginBottom: '1rem' }}>
            <p>
              The Smart Builder option is based on an AI approach and will help you build Audiences based on a prompt.
            </p>
            <p>
              The AI will break down your prompt into subgroups of words and analyze their sentiments. It will then
              propose the most relevant attributes for each subgroup.
            </p>
            <p>Before prompting, you need to configure the AI parameters.</p>
          </div>
          <Settings
            handleSearch={handleSearch}
            setMaxAlternate={setMaxAlternate}
            setMaxResults={setMaxResults}
            setValResults={setValResults}
            setMatchCriteria={setMatchCriteria}
            maxAlternate={maxAlternate}
            maxResults={maxResults}
            valResults={valResults}
            matchCriteria={matchCriteria}
            defaultPrompt={segment.smartBuilder?.prompt || ''}
          />
        </>
      )}
      {smartStage === '1' && !loading && (
        <SubAudiences
          handleSearch={handleSearch}
          segment={segment}
          openModal={openModal}
          setOpenModal={setOpenModal}
          onChange={handleSubAudiencesSelect}
          defaultPrompt={segment.smartBuilder?.prompt || ''}
        />
      )}
    </>
  );
};

export default SmartBuilder;
