import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { AmdmUpn } from '../../../../core/amdm';
import { getAgenciesToSecmdmThunk } from '../secmdm/getAgencies';
import { getClientsToSecmdmThunk } from '../secmdm/getClients';

export const getOwnerUpnToAmdmThunk = createAsyncThunk<
  AmdmUpn,
  void,
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/get-owner-upn`, async (arg, { extra, dispatch }): Promise<AmdmUpn> => {
  const amdmService = extra.factory.getAmdmService();
  try {
    const amdmUpn = await amdmService.getOwnerUpn();
    return amdmUpn;
  } catch (e) {
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
});
