import { FC } from 'react';

import { BiLoader } from 'react-icons/bi';

interface Props {
  value: number | undefined | null;
}

const FormatedNumber: FC<Props> = ({ value }) =>
  value === undefined ? (
    <BiLoader className="hawaii-rotating" />
  ) : value === null ? (
    <>--</>
  ) : (
    <>{value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</>
  );

export default FormatedNumber;
