import { FC } from 'react';
import './warning.less';
interface Props {
  title: string;
  content: string;
  setWarningOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Warning: FC<Props> = ({ title, content, setWarningOpen }) => {
  const closeBtn = () => {
    setWarningOpen(false);
  };
  return (
    <div className="warining-container">
      <div className="content">
        <div className="close-btn" onClick={() => closeBtn()}>
          x
        </div>
        <div className="title">
          <strong>Warning: </strong>
          {title}
        </div>
        <div>{content}</div>
      </div>
    </div>
  );
};

export default Warning;
