import './spider-chart.less';
import { FC, useEffect, useState, useRef } from 'react';
import { Modal, Segmented, Slider, Checkbox, Divider } from 'antd';
import Highcharts, { PointLabelObject } from 'highcharts';
import HcMore from 'highcharts/highcharts-more';
import { useClustersData } from '../hooks/useClustersData';
import { useParams } from 'react-router';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import HighchartsReact from 'highcharts-react-official';

HcMore(Highcharts);

interface Props {
  setSpiderView: (arg: boolean) => void;
}

interface ISpider {
  questions: string[];
  responses: string[];
  clusters: {
    [key: string]: (number | null)[];
  };
  clusterByPercent: {
    [key: string]: (number | null)[];
  };
}

const SpiderChart: FC<Props> = ({ setSpiderView }) => {
  const minHeight = 936.5;
  const base = 22;

  const setIndexToRemove = new Set();
  const { segmentID } = useParams();
  const { clusterIndexTable } = useClustersData(segmentID as string, 'clusters');
  const clusters = clusterIndexTable();
  const [selectedQustions, setSelectedQuestions] = useState<string[]>([]);
  const [valueState, setValueState] = useState('default');
  const [labels, setLabels] = useState<ISpider>();
  const [range, setRagne] = useState<[number, number]>(countRange(generateLabels().clusters) as [number, number]);
  const [startFrom, setStartFrom] = useState<boolean>(false);
  const [isArea, setIsArea] = useState<boolean>(false);
  const [isQuestions, setIsQuestions] = useState<boolean>(false);
  const [isPointLabel, setIsPointLabel] = useState<boolean>(false);
  const [percentRange, setPercentRange] = useState<[number, number]>(
    countRange(generateLabels().clusterByPercent) as [number, number],
  );
  const [chartHight, setChartHight] = useState<string | number>(
    Math.max(
      minHeight,
      (labels && labels.responses.filter((_, index) => !setIndexToRemove.has(index)).length) || 0 * base,
    ),
  );
  const [options, setOptions] = useState<Highcharts.Options>();
  const [chartKey, setChartKey] = useState<number>(Date.now());
  const [chartInstance, setChartInstance] = useState(null);
  const colors = [
    '#00DCB9',
    '#785AFA',
    '#328CF5',
    '#F055FA',
    '#FFDC32',
    '#9BF5FC',
    '#69EBF8',
    '#008596',
    '#145566',
    '#001C32',
    '#AAFA96',
    '#FF9696',
  ];

  useEffect(() => {
    if (!labels) return;
    setOptions({
      chart: {
        polar: true,
        type: 'line',
        height: Number(chartHight) >= 2000 ? 2000 : chartHight, // 55 * labels.responses.length < 700 ? 700 : '100%', 55 * labels.responses.length,
        spacingTop: 70,
        zoomType: 'x',
        animation: false,
      },

      title: {},

      pane: {
        size: '90%',
      },
      xAxis: {
        categories: labels.responses.filter((_, index) => !setIndexToRemove.has(index)),
        plotLines: [
          {
            color: 'rgba(0,120,200,0.3)',
          },
        ],
        tickmarkPlacement: 'between',
        lineWidth: 0,
        labels: {
          enabled: !isPointLabel,
          formatter: function () {
            let color = '';
            labels.questions.forEach((question, index) => {
              const isQuestion = this.value.toString().includes(question);

              if (isQuestion) {
                color = colors[index];
              }
            });

            return `<span style="color: ${color}"><strong>${this.value}</strong></span>`;
          },
        },
      },

      yAxis: {
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: startFrom ? (valueState !== 'percentage' ? range[0] : percentRange[0]) : 0,
        labels: {
          formatter: function () {
            return `${valueState === 'percentage' ? `${this.value}%` : `${this.value}`}`;
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: isPointLabel,
            align: 'right',
            formatter: function (this: PointLabelObject) {
              const responses = labels.responses
                .filter((_, index) => !setIndexToRemove.has(index))
                .map((_, i) => i.toString());
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const tooltip = `<span style="color:${this.color}; zindex:99999;position: relative">●</span> ${
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.key
              }: <b>${
                valueState === 'default'
                  ? this.y?.toFixed().toString() || 0
                  : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    this.y?.toFixed().toString() + '%' || 0
              }</b><br/>`;

              return tooltip;
            },
          },
          enableMouseTracking: true,
        },
      },
      legend: {
        // enabled: true,
        // align: 'center',
        // verticalAlign: 'top',
        // layout: 'horizontal',
        // y: 0,
        // x: 0,
        itemStyle: {
          fontSize: '14px',
        },
      },

      series: generateSeries(labels),
    });
  }, [labels, chartHight, isArea, isPointLabel]);

  useEffect(() => {
    setChartKey(Date.now());

    setOptions((prev) => ({
      ...prev,
      yAxis: {
        min: startFrom ? (valueState !== 'percentage' ? range[0] : percentRange[0]) : 0,
      },
    }));
  }, [startFrom]);

  useEffect(() => {
    setOptions({});
    setChartKey(Date.now());
    setRagne(countRange(generateLabels().clusters) as [number, number]);
    setPercentRange(countRange(generateLabels().clusterByPercent) as [number, number]);
    setChartHight(
      Math.max(
        minHeight,
        generateLabels().responses.filter((_, index) => !setIndexToRemove.has(index)).length * Number(base),
      ),
    );
    setLabels(generateLabels());
  }, [valueState]);

  useEffect(() => {
    if (!clusters) return;
    setLabels(generateLabels());
  }, []);

  useEffect(() => {
    setLabels(generateLabels());
    setChartHight(
      Math.max(
        minHeight,
        generateLabels().responses.filter((_, index) => !setIndexToRemove.has(index)).length * Number(base),
      ),
    );
    afterRangeRun(range, percentRange);
  }, [selectedQustions]);

  function generateLabels() {
    return Object.values(clusters).reduce(
      (list, item) => {
        const question = item.question;
        const response = item.response;
        const clusters = item.clusters;
        const responseWithQuestion = response + ' - ' + '(' + question + ')';

        if (!list?.questions?.includes(question)) {
          list.questions.push(question);
        }

        if (selectedQustions.includes(question)) {
          return list;
        }
        if (!list?.responses?.includes(responseWithQuestion)) {
          list.responses.push(responseWithQuestion);
        }

        clusters.forEach((cluster, index) => {
          if (!list.clusters[index]) {
            list.clusters[index] = [];
          }
          if (!list.clusterByPercent[index]) {
            list.clusterByPercent[index] = [];
          }
          list.clusters[index].push(cluster.default);
          list.clusterByPercent[index].push(cluster.percentage * 100);
        });

        return list;
      },
      { questions: [], responses: [], clusters: {}, clusterByPercent: {} } as ISpider,
    );
  }

  function countRange(labels: { [key: string]: (number | null)[] }) {
    const allNumbers = Object.values(labels)
      .flat()
      .filter((v) => v !== null) as number[];
    const minNum = Math.min(...allNumbers);
    const maxNum = Math.max(...allNumbers);
    return [minNum, maxNum];
  }

  function afterRangeRun(range: [number, number], percentRange: [number, number]) {
    const newLabels = generateLabels();
    setOptions({});
    let indexToRemove: number[] = [];
    let newCluster: { [key: string]: (number | null)[] } = {};

    const rangeFilter = (clusters: { [key: string]: (number | null)[] }, range: [number, number]) => {
      Object.keys(clusters).forEach((key) => {
        newCluster[key] = (clusters[key].filter((v) => v) as number[]).map((value) =>
          value >= range[0] && value <= range[1] ? value : null,
        );
      });

      const maxLeng = Math.max(...Object.values(clusters).map((cluster) => cluster.length));
      for (let i = 0; i < maxLeng; i++) {
        if (Object.values(newCluster).every((cluster) => cluster[i] === null)) {
          indexToRemove.push(i);
        }
      }
      return newCluster;
    };

    if (valueState === 'default') {
      newLabels.clusters = rangeFilter(newLabels.clusters, range);
    } else if (valueState === 'percentage') {
      newLabels.clusterByPercent = rangeFilter(newLabels.clusterByPercent, percentRange);
    } else if (valueState === 'byIndex') {
      newLabels.clusterByPercent = rangeFilter(newLabels.clusterByPercent, percentRange);
      indexToRemove = [];
      newCluster = {};
      newLabels.clusters = rangeFilter(newLabels.clusters, range);
    }
    const newResponses = newLabels.responses.filter((_, index) => !indexToRemove.includes(index));
    newLabels.responses = newResponses;

    setLabels(newLabels);
    setChartHight(
      Math.max(minHeight, newLabels.responses.filter((_, index) => !setIndexToRemove.has(index)).length * Number(base)),
    );
    // if (chartRef && chartRef.current) {
    //   const chart = chartRef.current.chart;
    //   chart.redraw();
    // }
  }
  const toRemoveIndex = (clusterValues: (number | null)[], clusters: { [key: string]: (number | null)[] }) => {
    clusterValues.forEach((point, index) => {
      if (point === null) {
        if (Object.values(clusters || {}).every((c: (number | null)[]) => c[index] === null)) {
          setIndexToRemove.add(index);
        }
      } else {
        setIndexToRemove.delete(index);
      }
    });
  };

  if (!labels) return <></>;

  function generateSeries(labels: ISpider): Highcharts.SeriesOptionsType[] {
    const clusters = valueState !== 'percentage' ? labels.clusters : labels.clusterByPercent;
    if (valueState === 'byIndex') {
      return Object.values(labels.clusters).flatMap((_, index) => {
        toRemoveIndex(labels.clusters[index], labels.clusters);

        return [
          {
            type: isArea ? 'area' : 'line',
            name: `Cluster - ${index + 1}`,
            id: `Cluster-${index + 1}`,
            data: labels.clusters[index].filter((_, index) => !setIndexToRemove.has(index)),
            pointPlacement: 'on',
            connectNulls: true,
            showInLegend: true,
            tooltip: {
              pointFormatter: function (this: Highcharts.Point) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                  this.y?.toFixed().toString() || 0
                }</b><br/>`;
                return tooltip;
              },
              useHTML: true,
            },
          },
          {
            name: `Cluster - ${index + 1} - %`,
            type: isArea ? 'area' : 'line',
            linkedTo: `Cluster-${index + 1}`,
            pointPlacement: 'on',
            data: labels.clusterByPercent[index].filter((_, index) => !setIndexToRemove.has(index)),
            dataLabels: {
              enabled: true,
              formatter: function (this: Highcharts.PointLabelObject) {
                return `${
                  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                  this.y?.toFixed().toString() || 0
                }%`;
              },
            },
            connectNulls: true,
            color: 'rgba(158, 159, 163, 1)',
            // showInLegend: true,
            tooltip: {
              pointFormatter: function (this: Highcharts.Point) {
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                  this.y?.toFixed().toString() || 0
                }%</b><br/>`;
                return tooltip;
              },
              useHTML: true,
            },
          },
        ];
      });
    } else {
      return Object.values(clusters).map((_, index) => {
        toRemoveIndex(clusters[index], clusters);
        return {
          type: isArea ? 'area' : 'line',
          name: `Cluster - ${index + 1}`,
          data: clusters[index].filter((_, index) => !setIndexToRemove.has(index)),
          pointPlacement: 'on',
          connectNulls: true,
          showInLegend: true,
          tooltip: {
            pointFormatter: function (this: Highcharts.Point) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const tooltip = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${
                valueState !== 'percentage'
                  ? this.y?.toFixed().toString() || 0
                  : // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                    this.y?.toFixed().toString() + '%' || 0
              }</b><br/>`;
              return tooltip;
            },
            useHTML: true,
          },
        };
      });
    }
  }

  const handleClickQuestion = (question: string) => {
    setSelectedQuestions((prev) => {
      if (prev.includes(question)) {
        return prev.filter((item) => item !== question);
      } else {
        return [...prev, question];
      }
    });
  };

  const handleSegmentState = (v: string | number) => {
    if (v === 'Index') {
      setValueState('default');
    } else if (v === 'By Percent') {
      setValueState('percentage');
    } else {
      setValueState('byIndex');
    }
  };

  return (
    <Modal
      open={true}
      width={'100%'}
      className="spider-container"
      style={{ height: '90vh', top: '10px' }}
      title={
        <div className="config-bar">
          <div className="title">
            <span>Clusters Index</span>
          </div>
          <div className="right-divider"></div>
          <Segmented
            style={{ marginRight: '50px', userSelect: 'none' }}
            options={['Index', 'By Percent', '% and Index']}
            value={valueState === 'default' ? 'Index' : valueState === 'percentage' ? 'By Percent' : '% and Index'}
            onChange={(v: string | number) => handleSegmentState(v)}
          />
          <div className="right-divider"></div>

          <div className="block">
            <div className="block-elements">
              <div className="check-box">
                <Checkbox onChange={() => setIsArea(!isArea)}>Area</Checkbox>
              </div>
              <div className="check-box">
                <Checkbox checked={isQuestions} onChange={() => setIsQuestions(!isQuestions)}>
                  Questions
                </Checkbox>
              </div>
            </div>
            <div className="block-elements">
              <div className="check-box">
                <Checkbox onChange={() => setIsPointLabel(!isPointLabel)}>
                  <span style={{ fontSize: '12px' }}>Point Label</span>
                </Checkbox>
              </div>
            </div>
            <div className="block-elements">
              <Checkbox disabled={true} onChange={() => setIsPointLabel(!isPointLabel)}>
                Label
              </Checkbox>
              <Checkbox disabled={true} onChange={() => setIsPointLabel(!isPointLabel)}>
                Value
              </Checkbox>
            </div>
            <div className="block-elements">
              <Checkbox disabled={true} onChange={() => setIsPointLabel(!isPointLabel)}>
                Highest
              </Checkbox>
              <Checkbox disabled={true} onChange={() => setIsPointLabel(!isPointLabel)}>
                Lowest
              </Checkbox>
            </div>
          </div>
          <div className="right-divider"></div>

          <div className="block" style={{ flexDirection: 'row', alignItems: 'center' }}>
            <span style={{ marginRight: '12px' }}>Zoom</span>
            <div className="block-elements">
              <div className="element zoom" onClick={() => setChartHight((Number(chartHight) + 100).toString())}>
                <PlusSquareOutlined /> Plus
              </div>
              <div className="element zoom" onClick={() => setChartHight((Number(chartHight) - 100).toString())}>
                <MinusSquareOutlined /> Minus
              </div>
            </div>
          </div>
        </div>
      }
      footer={false}
      onCancel={() => setSpiderView(false)}
    >
      <div className="ant-modal-body__content">
        {isQuestions && (
          <div className="questions">
            <div className="questions-title">Questions:</div>
            <div className="questions-container">
              {labels.questions.map((question, index) => (
                <div
                  key={index}
                  className="question"
                  style={{ color: `${selectedQustions.includes(question) ? 'grey' : ''}` }}
                  onClick={() => handleClickQuestion(question)}
                >
                  <span
                    className="color"
                    style={{ backgroundColor: `${selectedQustions.includes(question) ? 'grey' : colors[index]}` }}
                  />
                  {question}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="spider">
          <HighchartsReact highcharts={Highcharts} options={options} key={chartKey} />
        </div>
      </div>
    </Modal>
  );
};

export default SpiderChart;
