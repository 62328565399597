import './VerticalTabs.less';
import { FC } from 'react';

interface Props {
  tabs: string[];
  value: string | null;
  selectAction: (tab: string) => void;
}

const VerticalTabs: FC<Props> = ({ tabs, value, selectAction, children }) => (
  <div className="tab-system">
    <div className="tab-navigation">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={'tab-item ' + (value === tab ? 'active' : '')}
          onClick={() => {
            selectAction(tab);
          }}
        >
          {tab}
        </div>
      ))}
    </div>
    {children}
  </div>
);

export default VerticalTabs;
