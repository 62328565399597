export const SET_RUN_ID = 'SET_RUN_ID';
export const SET_JOB_STATUS = 'SET_JOB_STATUS';
export const SET_RESULTS = 'SET_RESULTS';
export const SET_LOADING = 'SET_LOADING';
export const SET_JOB_RUN_START = 'SET_JOB_RUN_START';
export const SET_JOB_ESTIMATED_TIME = 'SET_JOB_ESTIMATED_TIME';
export const SET_SAVED_RESULTS = 'SET_SAVED_RESULTS';
export const SET_NAME_CATEGORY = 'SET_NAME_CATEGORY';
export const SET_RUN_ID_BQ = 'SET_RUN_ID_BQ';
export const SET_RUN_ID_BQ_STATUS = 'SET_RUN_ID_BQ_STATUS';
export const SET_COUNTRY_CODE = 'SET_COUNTRY_CODE';
