import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../../core/factory';
import { GlobalState } from '../../global';
import { setAmdmTargetedAudience } from '../../amdmSlice';
import { setAppIsLoadingState } from '../../appIsLoadingSlice';

export const getAudienceFromAmdmThunk = createAsyncThunk<
  void,
  {
    audienceRecordId: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`amdm/get`, async ({ audienceRecordId }, { dispatch, extra }): Promise<void> => {
  dispatch(setAppIsLoadingState({ state: true }));
  const amdmService = extra.factory.getAmdmService();
  try {
    const audience = await amdmService.get(audienceRecordId);
    dispatch(setAmdmTargetedAudience(audience));
  } catch (e) {
    dispatch(setAppIsLoadingState({ state: false }));
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  }
  dispatch(setAppIsLoadingState({ state: false }));
});
