import { FC, Fragment, useState, useEffect, useRef } from 'react';
import { CaretRightFilled, CaretLeftFilled } from '@ant-design/icons';
import FormatedNumber from '../../../Common/FormatedNumber';
import Color from 'color';

export interface Props {
  data: {
    values: {
      [key: string]: {
        [key: string]: { [key: string]: number[] };
      };
    };
    segmentNames: { name: string; color: string }[];
  };
  focusedCountry?: string | null;
  selectedCountry: string | null | undefined;
  sectionsSelection: { name: string; checked: boolean }[];
}

const MediaConsTable: FC<Props> = ({ data, selectedCountry, sectionsSelection }) => {
  const originalIndex = useRef(new Map());
  const initialIndex = useRef(new Map());
  const [selectedTabs, setSelectedTabs] = useState([...sectionsSelection]);
  const [valueIndex, setValueIndex] = useState(sectionsSelection.map((_, i) => i));
  const [audienceName, setAudienceName] = useState<string>('');
  const [audienceSort, setAudienceSort] = useState<string>('');

  useEffect(() => {
    if (data.segmentNames.length === 0 || !selectedCountry) return;
    setAudienceName('');
    sectionsSelection
      .filter((el) => el.checked)
      .map((item, index) => {
        if (initialIndex.current.size !== sectionsSelection.length) {
          initialIndex.current.set(item.name, index);
        }
        originalIndex.current.set(item.name, index);
      });

    setValueIndex(
      sectionsSelection.filter((el) => el.checked).map((item) => initialIndex.current.get(item.name) as number),
    );
    setSelectedTabs(sectionsSelection.filter((el) => el.checked));
  }, [sectionsSelection]);

  const handleSort = (tag: string, key: string) => {
    setAudienceName(tag);
    setAudienceSort(key === '' ? 'ASC' : key === 'ASC' ? 'DESC' : '');
  };
  return (
    <table className="numbers-table fusion" style={{ border: '1px solid #787878', height: 'auto' }}>
      <tbody>
        <tr>
          <th className="label">
            <div className="sort-container">Media consumption</div>
          </th>
          <th className="label">Indicator</th>

          {data &&
            data.segmentNames &&
            data.segmentNames?.map((audience, i) => {
              return (
                <Fragment key={i}>
                  <th
                    className="tags-container"
                    style={{
                      backgroundColor: lightColor(audience.color),
                      color: mediumColor(audience.color),
                      cursor: 'default',
                    }}
                  >
                    <div className="sort-container">
                      <div className="tag">{audience.name}</div>
                      <div className="icons" onClick={() => handleSort(audience.name, audienceSort)}>
                        <CaretLeftFilled
                          style={{
                            fontSize: '12px',
                            color: `${audienceSort === 'DESC' ? '#25cad0' : 'gray'}`,
                            width: '35%',
                          }}
                        />
                        <CaretRightFilled
                          style={{
                            fontSize: '12px',
                            color: `${audienceSort === 'ASC' ? '#25cad0' : 'gray'}`,
                            width: '35%',
                          }}
                        />
                      </div>
                    </div>
                  </th>
                  <th className={data.segmentNames.length === i + 1 ? 'spacer last' : 'spacer'}></th>
                </Fragment>
              );
            })}
        </tr>

        {data &&
          selectedCountry &&
          Object.keys(data?.values[selectedCountry]).map((response, i) => {
            let sorted: number[] = [];
            let tabs = selectedTabs;

            if (audienceName) {
              const orignalIdx = Array.from(
                { length: data.values[selectedCountry][response][audienceName].length },
                (_, index) => ({ value: data.values[selectedCountry][response][audienceName][index], index }),
              );

              audienceSort === 'ASC'
                ? (sorted = [...orignalIdx].sort((a, b) => a.value - b.value).flatMap((item) => item.index))
                : audienceSort === 'DESC'
                ? (sorted = [...orignalIdx].sort((a, b) => b.value - a.value).flatMap((item) => item.index))
                : (sorted = orignalIdx.flatMap((item) => item.index)),
                (tabs = sorted.map((index: number) => {
                  return selectedTabs[index];
                }));
            }

            return (
              <tr
                key={i}
                className={
                  (!(i % 2) ? 'odd' : '') +
                  (i == 0 ? ' first' : '') +
                  (i == Object.keys(data.values[selectedCountry]).length - 1 ? ' last' : '')
                }
              >
                <td className="line-name">
                  <strong>{`(${i + 1}) -` + response}</strong>
                </td>
                <td className="line-name-groups">
                  {tabs.map((value, j) => {
                    return (
                      <Fragment key={j}>
                        <div className="indicator">
                          <div>
                            <strong>{value.name}</strong>
                          </div>
                        </div>
                      </Fragment>
                    );
                  })}
                </td>

                {data &&
                  data.segmentNames &&
                  data.segmentNames?.map((seg, j) => {
                    const values: number[] = (audienceSort === '' ? valueIndex : sorted)?.map(
                      (index: number) => data?.values[selectedCountry][response][seg.name][index],
                    );

                    return (
                      <Fragment key={j}>
                        <td
                          className="numbers-line"
                          style={{
                            backgroundColor: !(i % 2) ? blendColor(seg.color) : lightColor(seg.color),
                          }}
                        >
                          {values?.map((value, k) => {
                            return (
                              <div key={k}>
                                <span style={{ fontStyle: 'normal' }}>
                                  <FormatedNumber value={value} />
                                </span>
                              </div>
                            );
                          })}
                        </td>
                        <td className="spacer">&nbsp;&nbsp;&nbsp;&nbsp;</td>
                      </Fragment>
                    );
                  })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const mediumColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(50).hex();
};

const lightColor = (hex: string): string => {
  const color = Color(hex);
  return color.lightness(95).hex();
};
const blendColor = (hex: string): string => {
  const lighten = Color(lightColor(hex));
  return lighten.mix(Color('#25cacf'), 0.1).hex();
};

export default MediaConsTable;
