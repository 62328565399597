import { CountryMetadata } from 'hawaii';
import {
  marketSizedSeries,
  audienceCrossCountryResponsePercentageSeries,
  audienceResponsePercentageSeries,
  countryIndexSeries,
  indexSeries,
  sumByCountry,
  unweightedResponseCountSeries,
  crossResulsFilterByCountry,
} from '../crossResults';
import { Serie } from '../series/interface';
import { serieConcat } from '../series/serie';

/**
 * Return array of string/number ready to be used for a CSV export.
 *
 * @param series
 * @param variableDetails
 * @param population
 * @returns
 */
export const forExcel = (
  series: () => Serie[],
  variableDetails: () => Serie[],
  countries: CountryMetadata[],
): (() => (string | number)[][]) => {
  return (): (string | number)[][] => {
    const computed = series();

    const totalMarketSizeSerie = sumByCountry(() =>
      countries
        .map((country) => {
          return marketSizedSeries(country.population, crossResulsFilterByCountry(country.id, computed))().flat();
        })
        .flat(),
    );

    const unweighted: Serie =
      countries.length === 1
        ? serieConcat(unweightedResponseCountSeries(computed)(), ['name', 'variableName'])
        : serieConcat(sumByCountry(unweightedResponseCountSeries(computed))(), ['name', 'variableName']);

    const percentage: Serie =
      countries.length === 1
        ? serieConcat(audienceResponsePercentageSeries(computed)(), ['name', 'variableName'])
        : serieConcat(audienceCrossCountryResponsePercentageSeries(computed)(), ['name', 'variableName']);

    const countryCount: Serie =
      countries.length === 1
        ? serieConcat(marketSizedSeries(countries[0].population, computed)(), ['name', 'variableName'])
        : serieConcat(totalMarketSizeSerie(), ['name', 'variableName']);

    const index: Serie =
      countries.length === 1
        ? serieConcat(indexSeries(variableDetails(), computed)(), ['name', 'variableName'])
        : serieConcat(countryIndexSeries(variableDetails(), computed)(), ['name', 'variableName']);

    return [
      ['Audience', 'Variable', 'Response', 'Respondents', 'Distribution', 'Market size', 'Index'],
      ...unweighted.values.map((value, i: number) => [
        unweighted.tags[i].name as string,
        unweighted.tags[i].variableName as string,
        unweighted.tags[i].responseText as string,
        unweighted.values[i],
        percentage.values[i],
        countryCount.values[i],
        index.values[i],
      ]),
    ];
  };
};
