import { FC } from 'react';
import Highcharts from 'highcharts';
import { Serie, SerieTag, MediaInter } from '../../../core/series/interface';
import SegmentGraph from '../SegmentGraph';
import SplitCharts from './split-charts';
export type Charts = 'SPIDER' | 'BARS' | 'SPLIT BARS' | 'COLUMNS' | 'SPLIT COLUMNS' | 'PIE';

interface Props {
  highchartsSettings: Charts;
  series: Serie[] | (() => Serie[]);
  labelFormatter?: (
    p: Highcharts.AxisLabelsFormatterContextObject,
    tags: Record<string, string | boolean | number | MediaInter[]>[],
  ) => string;
  tagConverter: (globalTags: SerieTag) => SerieTag;
}

const Charts: FC<Props> = ({ highchartsSettings, series, labelFormatter = undefined, tagConverter }) => {
  const chartComponents: Record<Charts, React.ReactElement> = {
    'SPLIT BARS': <SplitCharts series={series} type={'bar'} />,
    SPIDER: (
      <SegmentGraph
        highchartsSettings={highchartsSettings}
        series={series}
        labelFormatter={labelFormatter}
        tagConverter={tagConverter}
      />
    ),
    BARS: (
      <SegmentGraph
        highchartsSettings={highchartsSettings}
        series={series}
        labelFormatter={labelFormatter}
        tagConverter={tagConverter}
      />
    ),
    COLUMNS: (
      <SegmentGraph
        highchartsSettings={highchartsSettings}
        series={series}
        labelFormatter={labelFormatter}
        tagConverter={tagConverter}
      />
    ),
    'SPLIT COLUMNS': <SplitCharts series={series} type={'column'} />,
    PIE: <></>,
  };

  return <>{chartComponents[highchartsSettings] && chartComponents[highchartsSettings]}</>;
};

export default Charts;
