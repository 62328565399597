import { createAsyncThunk } from '@reduxjs/toolkit';
import { FactoryInterface } from '../../../core/factory';
import { GlobalState } from '../global';
import { setAppIsLoadingState } from '../appIsLoadingSlice';
import { setEditionSegment, segmentEditionReset } from '../segmentEditionSlice';
import segmentSlice from '../segmentSlice';
import { initialSegmentProperties } from '../../../core/segment/helper';

export const retrieveSegmentFromAudienceThunk = createAsyncThunk<
  void,
  {
    audienceRecordId: string;
  },
  { state: GlobalState; extra: { factory: FactoryInterface }; rejectValue: string | undefined }
>(`audience-segment/get`, async ({ audienceRecordId }, { dispatch, extra }): Promise<any> => {
  dispatch(setAppIsLoadingState({ state: true }));
  const segmentService = extra.factory.getSegmentService();
  try {
    const audienceSegment = await segmentService.retrieveAudienceSegment(audienceRecordId);
    dispatch(segmentEditionReset());
    dispatch(
      segmentSlice.actions.insertSegment({
        segment: initialSegmentProperties({
          ...audienceSegment,
        }),
      }),
    );
    dispatch(setEditionSegment({ segment: audienceSegment }));
  } catch (e) {
    dispatch(setAppIsLoadingState({ state: false }));
    if (e instanceof Error) {
      throw e.message;
    } else {
      throw undefined;
    }
  } finally {
    dispatch(setAppIsLoadingState({ state: false }));
  }
});
