import { FC } from 'react';
import { SemanticColors } from '../../../core/helpers/colors';
import CategoriesSelector, { FirstLevelCategory } from './CategoriesSelector';
import { splitStringArrayInPairs, splitStringArrayToEveryPairs } from './helper';

interface Props {
  selection: string[];
  colors: SemanticColors;
  onSelect: (categories: string[], loadNext: boolean) => void;
  categoryTrees: FirstLevelCategory[][];
}

const Categories: FC<Props> = ({ selection, colors, onSelect, categoryTrees }) => {
  const splittedSelectionFullPaths = splitStringArrayToEveryPairs(selection);

  const handleSelection = (index: number, selectionPart: string[], loadNext: boolean) => {
    const newSelection = [...(index === 0 ? [] : splittedSelectionFullPaths[index - 1]), ...selectionPart];
    onSelect(newSelection, loadNext);
  };

  const splittedSelectionPath = splitStringArrayInPairs(selection);
  const empty = categoryTrees[0] === undefined || categoryTrees[0].length === 0;
  return (
    <>
      {empty && <div className="message">There is currently no categories to display.</div>}
      {empty ||
        categoryTrees.map((categories, index) => (
          <CategoriesSelector
            key={index}
            categories={categories}
            selection={splittedSelectionPath[index]}
            colors={colors}
            onSelect={(selectionPart: string[], loadNext: boolean) => handleSelection(index, selectionPart, loadNext)}
          ></CategoriesSelector>
        ))}
    </>
  );
};

export default Categories;
